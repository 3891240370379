import {
  GET_ALL_COMPANY_USERS,
  BASE_API_URL,
  FB_URL,
  USER_EXISTS,
  GET_ALL_COMPANY_GROUPS,
  GET_ALL_GROUP_USERS,
  CREATE_GROUP_SUCCESS,
  GET_GROUP_DETAILS,
  GET_GROUP_EVENTS,
  GET_GROUP_EVENTS_REMINDERS,
  GET_ACTIVE_STUDY_MATERIALS,
  GET_GROUPS_ATTENDANCE_REGISTER,
  GET_COMPANY_PROGRAMMES,
  GET_COMPANY_SURVEYS,
  GET_GROUP_SURVEYS,
  GET_INACTIVE_STUDY_MATERIALS,
  GET_ASSIGNED_STUDY_MATERIALS,
  GET_USER_GROUPS,
  GET_ALL_COMPANY_SETTINGS,
  GET_ALL_GENERAL_SETTINGS,
  GET_USER_PROFILE,
  LEADERBOARD,
  GET_ALL_TOKENS,
  GET_MY_TOKEN,
  GET_COMPANY_ADMIN,
  CHECK_TOKEN,
  GET_AI
  //FIXED_LEADER_LIST
} from './types'
import { PHOTO_PROFILE } from '../../utils/photo_utils'
import firebase from '../firebase'
//import * as firebase from "firebase/app";
//import firebase from "firebase/compat/app"
import { initializeApp } from 'firebase/app';


import { getFunctions, httpsCallable } from 'firebase/functions';

import { getAuth, updateUser } from "firebase/auth";
import { Buffer } from "buffer";

import axios from 'axios'
import moment from 'moment'
import { _toArray, _toArrayUsers, _toArrayUsersx, _toArrayCalendar, _toArrayGroups, _toArrayUsersL,  _toArrayUsersM, findToken, findAppOwner } from './commonActions'
import { number } from 'prop-types';

//PATREON API//////////////////////////////////////////////////////////
// import url from 'url'
// import patreonAPI, { oauth as patreonOAuth } from 'patreon'

// const CLIENT_ID = '4uk2TdW-YL2r-YB-sVcqw9jzGQhzZX-YFJs6b10hR372n5qjoQG0PsLLfIvga-Ti'     // Replace with your data
// const CLIENT_SECRET = 'aQdMxoIIhT_NWoiKgxmD_KBcmzshHDkKvYD_7W5-UZS62DGTwlFDM79exw2NmbIn' // Replace with your data
// const redirectURL = 'thinklead.app'   // Replace with your data

// const patreonOAuthClient = patreonOAuth(CLIENT_ID, CLIENT_SECRET)
///////////////////////////////////////////////////////////////////////

//import { ChatGPTUnofficialProxyAPI } from 'chatgpt'

// const ussserse = (props) => {
//   for (let x in props) {
//     return props[x].email
//   }
// }
// const usssers = (props) => {
//   for (let x in props) {
//     return props[x].firstName
//   }
// }

export const getAllCompanyUsers = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref('user')
      .orderByChild(params.orderChild)
      .equalTo(params.companyID)
      //.equalTo('-N3xp_AnYTDW_vLIB6h3') //clean tanks
      //.equalTo('-KgsUcgfo7z1U9MXgd9i') //leadership platform
      //.equalTo('-L7tEbNwW0sobxvNblWp') // ER24
      //.equalTo('-LEiZPT-C2PyLu_YLKNU') //public app
      //.equalTo('-MZwJp46RoDtehNEGly3') //Leadership express
      //.equalTo('-Mci2tVbY406VWKWa5Ov') // The book every leader needs to read
      //.equalTo('-LjW7UI7e0uUikYrw-Vj') // ThinkLead ecosystem / MCA
      //.equalTo('-MloJFGCyJJbs_bNlwTg') // Ledaine Trading
      //.equalTo('-M4LtrI17CY5H1Krhi5J') // towerstone
      //.limitToLast(300)
      .on('value', function (data) {
        if (data.val()) {
          let users = data.val()
          let receivedData = {}
          delete users.undefined

          if (Object.keys(users).length > 0) {
            let sortedData = _toArrayUsers(users)
            sortedData.sort(function (a, b) {

              if (a.firstName > b.firstName) {
                // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                return 1
              }
              if (a.firstName < b.firstName) {
              //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                return -1
              }
              return 0
            })

            receivedData = { ...sortedData }

            receivedData = Object.assign({}, sortedData)
            //console.log('green company users : ', ussserse(users) + "  " + data.val().email)
            
           }

          dispatch({
            type: GET_ALL_COMPANY_USERS,
            users: receivedData
          })
        } else {
          dispatch({
            type: GET_ALL_COMPANY_USERS,
            users: {}
          })
        }
      })
  }
}

export const getAdmin = params => {
  console.log('green createToken usersData 3 : ', params);
  return async (dispatch, getState) => {
     await firebase.database
      .ref('user')
      //.orderByChild('companyID_userType')
      //.equalTo(params.companyID_userType)
      .orderByChild('appOwner')
      .equalTo(true)
      .once('value', function (data) {
        if (data.val()) {
          let users = data.val()
          let receivedData = {}

          if (Object.keys(users).length > 0) {
            let sortedData = findAppOwner(users, params.companyID)

            receivedData = { ...sortedData }
           // receivedData = Object.assign({}, sortedData)
    
           }
           console.log('this.props.adminAcc 2 : ', receivedData);
            dispatch({
            type: GET_COMPANY_ADMIN,
            oadmin: receivedData
          })
        } else {
          dispatch({
            type: GET_COMPANY_ADMIN,
            oadmin: {}
          })
        }
      })
  }
}

const handleSaveToPC = (jsonData) => {
  const fileData = JSON.stringify(jsonData);
  const blob = new Blob([fileData], {type: "text/plain"});
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = `../../Dialogs/leaderboard/leaderlist.json`;
  link.href = url;
  link.click();
}

// async function collect() {
//   var coll = {};
//   var result = {};
//   var resultx = {}
//   var resultS = "";
//   var resultSs = "";
//   var resultSs_s = "";
//   var resultSss = "";
//   var resultSM = "";
//   var resultN = {};
//   var resultM = {};
//   var resultF = {};
//   var sArr1 = [];
//   var sArr2 = [];
//   var sArr3 = [];
//   var collL = "";
//   var collL2 = "";
//   var num = "";
//   var zz;
//   var x = 0;
//   var y = 0;
//   var q = 0;
//   var r = -1;
//   var coll2 = {}
//   //const z = [1, 31, 61]
//   const z = [1, 7, 13]

//   for (const elem of z) { //loop
//   //    console.log(elem)
//   //var elem = 1;
//   ////////////////////////////////
//   await axios
//   .post(`${FB_URL}/m100-leaderboardData?n=${elem}`, {})
//   .then(res => {
//     let resultx = res.data;
//     //console.log('green coll 1 : , ', resultx);
//     let result = resultx.data;
//     // resultN = [];
//     // for (var key in result) {
//     //   resultN.push(result[key]);
//     // }
//     //resultN = result;
//     // var resultN = Object.keys(result).map(function (i) {
//     //   return result[i];
//     // });
 
//     //coll.concat(result)
//     // resultN = Object.assign(coll, result)
//     resultS = JSON.stringify(result);
//     resultSs = resultS.replace(/\//g, "");
//     console.log('green res 1 : ', resultSs);
    
//     num = elem.toString();
//     console.log('green res 2 : ', num);
//     y = num.length + 2; // string length of {"0 or {"12 for example
//     console.log('green res 3 : ', y);
//     x = resultSs.length //length of whole string
//     zz = x - y;
//     q = y - 1; //starting position to slice string
//     console.log('green res 4 : ', zz);

//     resultSss = resultSs.slice(q, zz);
//     console.log('green res 5 : ', resultSss);
//     //resultSss = resultSs.replace(/\//g, "");
//     r = r + 1;
//     coll = r.toString();
//     console.log('green res 6 : ', coll);
//     // collL = ""{" + x 
//     // collL2 = "{"
//     // let resultSs_s = resultSss.replace(/blue/g, "red");
//     // resultSss = "{" + resultSs.replace(/\//g, "") + "}";
//     //  console.log('green coll s : ', resultSss);

//     if (resultSM.length > 1) {
//       resultSM = resultSM + ", " + "\"" + coll + resultSss 
//     }
//       else
//       {
//         resultSM = '"' + x.toString() + resultSss 
//       }

//     //resultM = JSON.parse(resultSss);
//     console.log('green coll 1 : ', resultSM);
//   })
//   //console.log('green coll 3 : ', coll);
  
//   //resultN = JSON.parse(resultSM);
//   //resultN = Object.assign(coll, result)
//   //console.log('green coll 3 : ', result);

//   // if ( Object.keys(coll).length > 1) {
//   //    sArr1 = Object.entries(coll);
//   //    sArr2 = Object.entries(resultN);

//   //   Array.prototype.push.apply(sArr1, sArr2);
//   //   //resultF = coll.concat(resultN);
    
//   //    console.log('green coll 3 : ', sArr1);
//   //    //resultM = Object.assign({}, sArr1, sArr2)  

//   //   //  resultM = { ...resultF }
//   //   //  resultM = Object.assign({}, resultF)
//   // }
//   // else{
//   //   resultM = resultN;
//   // }
 
//   //resultSM = resultSM + resultSss

//   console.log('green coll 2 : ', resultSM.length);

//   //resultSM = resultSM + "" + resultS;
//   //console.log('green coll 3 : ', JSON.parse(JSON.stringify(resultSM)));
//   /////////////////////////////////

//   } //loop
//   //resultSM = "{" + resultSM + "}";
//   console.log('green coll 3 : ', resultSM.length);
//   console.log('green coll 4 : ', resultSM);
//   resultM = JSON.parse("{" + resultSM + "}");
//   console.log('green coll 5 : ', resultM);
//     // try {
//     //   let obj = JSON.parse(resultSM)
//     //   console.log('green coll 5 : ', JSON.parse(obj));
//     // } catch( e) {
//     // // conversion fails
//     // console.error('green coll 5 : ', e ) 
//     // }

//  //console.log('green coll 4 : , ', coll);
//  //return JSON.parse(resultSM);
// //return coll;
// }
export const checkToken = params => {

  console.log('greentokenusers 1 : ', params);
  return async (dispatch, getState) => {
    await firebase.database
      .ref('tokens')
      .orderByChild(params.orderChild)
      .equalTo(params.userID)
      .on('value', function (data) {
        if (data.val()) {
          console.log('greentokenusers 2 : ', data.val());
          let users = data.val()
          let receivedData = {}
          var hastoken = 'no'
          if (Object.keys(users).length > 0) {
      
           let sortedData = findToken(users) 
            receivedData = sortedData
            
           }
           console.log('greentokenusers 3 : ', receivedData);
          if (receivedData) {hastoken = 'yes'} else {hastoken = 'no'}
          console.log('greentokenusers 4 : ', hastoken);
          dispatch({
            type: CHECK_TOKEN,
            hasaToken: hastoken
          })
        } else {
          dispatch({
            type: CHECK_TOKEN,
            hasaToken: 'no'
          })
        }
      })
  }
}

export const getAllTokens = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref('tokens')
      .orderByChild(params.orderChild)
      .equalTo(params.userID)
      .on('value', function (data) {
        if (data.val()) {
          let users = data.val()
          let receivedData = {}
          delete users.undefined

          if (Object.keys(users).length > 0) {
            let sortedData = _toArrayUsers(users)
            sortedData.sort(function (a, b) {

              if (a.firstName > b.firstName) {
                // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                return 1
              }
              if (a.firstName < b.firstName) {
              //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                return -1
              }
              return 0
            })

            receivedData = { ...sortedData }

            receivedData = Object.assign({}, sortedData)
            console.log('green token users : ', receivedData)
           }

          dispatch({
            type: GET_ALL_TOKENS,
            users: receivedData
          })
        } else {
          dispatch({
            type: GET_ALL_TOKENS,
            users: {}
          })
        }
      })
  }
}

async function collect() {
  var result = {};
  var resultx = {}
  var resultS = "";
  var resultSs = "";
  var resultSs_s = "";
  var resultSss = "";
  var resultSM = "";
  var resultN = {};
  var resultM = {};
  var resultF = {};
  var sArr1 = [];
  var sArr2 = [];
  var sArr3 = [];
  var coll = "";
  var coll2 = "";
  var coll3 = "";
  var obj1 = "";
  var obj2 = "";
  var obj3 = "";
  var num = "";
  var zz;
  var x = 0;
  var y = 0;
  var q = 0;
  var l = 0;
  var r = -1;
  var coll2 = {}
  //const z = [1, 31, 61]
  const z = [1, 7, 13]

  for (const elem of z) { //loop
  //    console.log(elem)
  //var elem = 1;
  ////////////////////////////////
  //.post(`${FB_URL}/m100-leaderboardData?n=${elem}`, {})
  await axios
  .post(`${FB_URL}/m105-leaderboardDataxy?n=${elem}`, {})
  .then(res => {
    let resultx = res.data;
    let result = resultx.data;

    resultS = JSON.stringify(result);
    resultSs = resultS.replace(/\//g, "");
    console.log('green coll 1: ', resultSs);
    //loop collection
    
    y = elem - 1; //starts
    x = y + 6;    //ends
    resultSss = resultSs
    for (var i = 0; i < 6; i++) {
      // console.log('green coll q: ', coll + " - " + coll2);
      if (i===0) {
        coll = '"' + i.toString() + '"'
        coll2 = '"' + q.toString() + '"'
        q = q + 1;
        resultSss = resultSss.replace(coll, coll2);
      }
      else
      {
        coll = ',"' + i.toString() + '"'
        coll2 = ',"' + q.toString() + '"'
        q = q + 1;
        resultSss = resultSss.replace(coll, coll2);       
      }

    }

      l = resultSss.length - 1;
      resultSs_s = resultSss.slice(1, l); //take away {.....}

    console.log('green coll 2 : ', resultSs_s);

  })
  if (resultSM.length > 1) {
          resultSM = resultSM + ", " + resultSs_s 
          console.log('green coll 3 : ', q);
        }
          else
          {
            resultSM = resultSs_s 
            console.log('green coll 3 : ', q);
          }
    

  } //loop
  console.log('green coll 4 : ', resultSM.length);
  console.log('green coll 5 : ', resultSM);
  resultM = JSON.parse("{" + resultSM + "}");
  console.log('green coll 6 : ', resultM);
  return (resultM);
}

const invokeHttpFunction = async (elem, inc) => {
  const functionName = 'm100-leaderboardDataxy'; // Update with your actual function name
  //const FB_URL = 'https://your-project-id.cloudfunctions.net'; // Update with your Firebase project URL

  try {
    const response = await axios.post(`${FB_URL}/${functionName}`, null, {
      params: {
        n: elem,
        inc: inc,
      },
    });

    const resultx = response.data;
    const result = resultx.data;

    // Handle the result as needed
    console.log('Function response :', result);
  } catch (error) {
    console.error('Error invoking function:', error);
  }
};

// Usage
//invokeHttpFunction('exampleElem', 5); // Replace with your desired values

async function fetchLeaderboardData() {
  try {
    const response = await fetch(
      "https://us-central1-leadershipplatform-158316.cloudfunctions.net/m100-leaderboardDataxy"
      , // Replace with your Cloud Function URL
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      console.log("Received data :", data);
      // Handle the data as needed
    } else {
      console.error("Request failed :", response.statusText);
      // Handle the error
    }
  } catch (error) {
    console.error("An error occurred :", error);
    // Handle the error
  }
}

// export const getAllCompanyLeadersx = params => {
//   return async (dispatch, getState) => {
//     var result = {};
//     var resultx = {}
//     var resultS = "";
//     var resultSs = "";
//     var resultSs_s = "";
//     var resultSss = "";
//     var resultSM = "";
//     var resultSMs = "";
//     var resultN = {};
//     var resultM = {};
//     var resultF = {};
//     var sArr1 = [];
//     var sArr2 = [];
//     var sArr3 = [];
//     var coll = "";
//     var coll2 = "";
//     var coll3 = "";
//     var obj1 = "";
//     var obj2 = "";
//     var obj3 = "";
//     var num = "";
//     var cut = "";
//     var zz;
//     var x = 0;
//     var y = 0;
//     var q = 0;
//     var l = 0;
//     var le = 0;
//     var r = -1;
//     var coll2 = {}
//     const firebaseConfig = {
//       apiKey: "AIzaSyAcAjiyCIE1T0QOl-xF37nnFK11Jd8cq2w",
//       authDomain: "leadershipplatform-158316.firebaseapp.com",
//       databaseURL: "https://leadershipplatform-158316.firebaseio.com",
//       projectId: "leadershipplatform-158316",
//       storageBucket: "leadershipplatform-158316.appspot.com",
//       messagingSenderId: "438289288307",
//       appId: "1:438289288307:web:5a8fafc9aeb1164e6cee06"
//     };

//     const app = initializeApp(firebaseConfig);
//     const functions = getFunctions(app);
//     const leaderboardDatax = httpsCallable(functions, 'leaderboardDatax');

//     try {
//       const elemx = 1; // Define your elem value
//       const incx = 200; // Define your inc value
  
//       const result = await leaderboardDatax({ n: elemx, inc: incx });
//       console.log('Function response :', result);

//       if (result && result.data) {
//         const resultS = JSON.stringify(result.data);
//         // Process your resultS here
//         // ...

//         dispatch({
//           type: LEADERBOARD,
//           leaderboard_action: resultM
//         });
//       } else {
//         dispatch({
//           type: LEADERBOARD,
//           leaderboard_action: {}
//         });
//       }
//     } catch (error) {
//       console.error('Error invoking function:', error);
//     }
//   };
// };

export const getAllCompanyLeaders = params => {
   return async (dispatch, getState) => {
/////////////////////////////////////////
    var result = {};
    var resultx = {}
    var resultS = "";
    var resultSs = "";
    var resultSs_s = "";
    var resultSss = "";
    var resultSM = "";
    var resultSMs = "";
    var resultN = {};
    var resultM = {};
    var resultF = {};
    var sArr1 = [];
    var sArr2 = [];
    var sArr3 = [];
    var coll = "";
    var coll2 = "";
    var coll3 = "";
    var obj1 = "";
    var obj2 = "";
    var obj3 = "";
    var num = "";
    var cut = "";
    var zz;
    var x = 0;
    var y = 0;
    var q = 0;
    var l = 0;
    var le = 0;
    var r = -1;
    var coll2 = {}

    // const z = [1, 7, 13]
    // const inc = 6;
    //const z = [1, 31, 61]
    //const z = [1, 100, 199]
    //const z = [1, 6, 11]

    // const z = [1, 11, 21, 31, 41, 51]
    // const inc = 10;
   
    // const z = [1, 21, 41, 61, 81, 101, 121, 141, 161, 181, 201]
    // const inc = 20;

    const z = [1]
    const inc = 200;
    
    //fetchLeaderboardData();
    for (const elem of z) { //loop
    //    console.log(elem)
    //var elem = 1;
    ////////////////////////////////
    //.post(`${FB_URL}/m100-leaderboardData?n=${elem}`, {})
    //.post(FB_URL + '/m100-leaderboardData?n=' + elem + '&inc=' + inc, {})
    //.post(`${FB_URL}/m100-leaderboardData`, {params: {elem: elem, inc: inc}})

    // try {
    //   await axios
    //   .post(`${FB_URL}/m100-leaderboardData`, {params: {n: elem, inc: inc}})
    //   .then(res => {
    //     console.log('inside getAllCompanyLeaders 1 ') })
    // } catch (error) {
    //   console.log('inside getAllCompanyLeaders err ', error)
    //   // Expected output: ReferenceError: nonExistentFunction is not defined
    //   // (Note: the exact output may be browser-dependent)
    // }

    console.log('inside getAllCompanyLeaders 0 ', params.companyID)
    var result = ""
    var results = ""
    var receivedData = []
    var sortedData = []
    var str;
   // const res = await axios.post(`https://us-central1-leadershipplatform-158316.cloudfunctions.net/m100-leaderboardDatax`, {params: {n: elem, inc: inc}});
   var res = {}
   try {
   res = await axios.post(`${FB_URL}/m100-leaderboardDataxy`, {params: {companyID: params.companyID, n: elem, inc: inc}});
    }
    catch(err) {
      console.log('Error requesting leaderboard data(1): ', err)

      try { //AGAIN
        res = await axios.post(`${FB_URL}/m100-leaderboardDataxy`, {params: {companyID: params.companyID, n: elem, inc: inc}});
        }
        catch(err) {
          console.log('Error requesting leaderboard data(2): ', err)   
          try { //AGAIN
            res = await axios.post(`${FB_URL}/m100-leaderboardDataxy`, {params: {companyID: params.companyID, n: elem, inc: inc}});
            }
            catch(err) {
              console.log('Error requesting leaderboard data(3): ', err)   
            }
        }
    }
 
   // const res = await axios.post(`${FB_URL}/m100-leaderboardDataxy`, {});

  //  console.log('inside getAllCompanyLeaders 1 ', res)
  //  if (res.data) {
  //   result = res.data
  //  } else {
  //   result = null
  //  }
    //  console.log('inside getAllCompanyLeaders 1 ', res)
   // await axios
    //.post(`${FB_URL}/m100-leaderboardDatax`, {params: {n: elem, inc: inc}})
    //.then(res => {

      // let resulty = invokeHttpFunction(elem, inc); //res.data;
      // console.log('inside getAllCompanyLeaders 1 ', resulty)

  //  const res = await axios.post(`${FB_URL}/m100-leaderboardDatax`, {params: {n: elem, inc: inc}});
  //   if (res.data) {   

  // const response = await axios.get(
  //   "https://us-central1-leadershipplatform-158316.cloudfunctions.net/m100-leaderboardDataxy"
  // );

    // const firebaseConfig = {
    //   apiKey: "AIzaSyAcAjiyCIE1T0QOl-xF37nnFK11Jd8cq2w",
    //   authDomain: "leadershipplatform-158316.firebaseapp.com",
    //   databaseURL: "https://leadershipplatform-158316.firebaseio.com",
    //   projectId: "leadershipplatform-158316",
    //   storageBucket: "leadershipplatform-158316.appspot.com",
    //   messagingSenderId: "438289288307",
    //   appId: "1:438289288307:web:5a8fafc9aeb1164e6cee06"
    // };

  // const app = initializeApp(firebaseConfig);
  // const functions = getFunctions(app);
  // //const leaderboardDatax = functions.httpsCallable('m100-leaderboardDatax');
  // const leaderboardDataxy = httpsCallable(functions, 'm100-leaderboardDataxy');

  // try {
  //   const elem = 1; // Define your elem value
  //   const inc = 200; // Define your inc value

  //  // const results = await leaderboardDatax({ n: elem, inc: inc });
  //   results = await leaderboardDataxy();
  //   result = JSON.stringify(results.data);
  //   console.log('inside getAllCompanyLeaders 1 ', results)
  // } catch (error) {
  //      console.error('Error invoking function:', error);
  //      // Handle the error if needed
  //    }

  //   if (Object.keys(resultSs).length > 0) {
  //     dispatch({
  //       type: LEADERBOARD,
  //       leaderboard_action: resultSs
  //     });
  //   } else {
  //     dispatch({
  //       type: LEADERBOARD,
  //       leaderboard_action: {}
  //     });
  //   }
  // } catch (error) {
  //   console.error('Error invoking function:', error);
  //   // Handle the error if needed
  // }

  if (res.data) {
     console.log('inside getAllCompanyLeaders 1 ', res.data)
      result = res.data.data;

      // result.sort(function(a, b) {

      //   if (a.feedback > b.feedback) {
      //     // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
      //     return -1
      //   }
      //   if (a.feedback < b.feedback) {
      //   //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
      //   return 1
      //   }
      //   return 0
      // })

     // result.sort((a, b) => parseFloat(b.feedback) - parseFloat(a.feedback));

        receivedData = { ...result }
        receivedData = Object.assign({}, result)
      console.log('inside getAllCompanyLeaders 1a ', receivedData)
      //JSON.parse(
      resultS = JSON.stringify(receivedData);
      str = resultS.replaceAll("\"", "&quot;")
      str = str.replaceAll("'","\"");
      resultSs = resultS; 
      //resultSs = resultS.replace(/\//g, "");

      //console.log('green coll 1: ', resultSs);
      //loop collection
      
      y = elem - 1; //starts
      x = y + inc;    //ends
      resultSss = resultSs
      console.log('green coll 1: ', resultSs.length);
      console.log('inside getAllCompanyLeaders 2 ')

      for (var i = 0; i < inc; i++) {
        // console.log('green coll q: ', coll + " - " + coll2);
        if (i===0) { //first object in series
          coll = '"' + i.toString() + '"'
          coll2 = '"' + q.toString() + '"'
          q = q + 1;
          resultSss = resultSss.replace(coll, coll2);
        }
        else
        {
          coll = ',"' + i.toString() + '"'
          coll2 = ',"' + q.toString() + '"'
          q = q + 1;
          resultSss = resultSss.replace(coll, coll2);       
        }
       }
     
        l = resultSss.length - 1;
        resultSs_s = resultSss.slice(1, l); //take away {.....}

      console.log('green coll 2 : ', resultSs_s);

    }
    //)
    if (resultSs_s) {
    if (resultSM.length > 1) {
            resultSM = resultSM + ", " + resultSs_s 
            console.log('green colll 3 : ', q);
          }
            else
            {
              resultSM = resultSs_s 
              console.log('green colll 3 : ', q);
            }
          }

    } //loop
    console.log('green coll 4 : ', resultSM.length);
    console.log('green coll 5 : ', resultSM);
    console.log('inside getAllCompanyLeaders 3 ')
    ////////last single space////////////////////////////////
    le = resultSM.length - 1;
    cut = resultSM.substr(le, 1);
    if (cut === '}') {  //CORRECT -> KEEP STRING INTACT
      resultSMs = resultSM //keep
      console.log('green coll 6 : ', resultSMs);
    } //if result was smaller than 
    ////////last 2 spaces////////////////////////////////
    le = resultSM.length - 2;
    cut = resultSM.substr(le, 2);
    if (cut === '},') { 
      resultSMs = resultSM.substr(0, le) 
      console.log('green coll 6 : ', resultSMs);
    } //if result was smaller than 
    if (cut === 'se' || cut === 'ue') { 
      resultSMs = resultSM + '}'
      console.log('green coll 8 : ', resultSMs);
    } //if result was smaller than 
    ////////last 3 spaces////////////////////////////////
    le = resultSM.length - 3;
    cut = resultSM.substr(le, 3);
    if (cut === ', ,') { 
      resultSMs = resultSM.substr(0, le) 
      console.log('green coll 6 : ', resultSMs);
    } //if result was smaller than 

    ////////last 7 spaces////////////////////////////////
    le = resultSM.length - 7;
    cut = resultSM.substr(le, 7);
    console.log('green coll 6 : ', cut);

    if (cut === ', , , ,' || cut === '  , , ,') { 
      resultSMs = resultSM.substr(0, le) 
     // console.log('green coll 6 : ', resultSMs);
    } //if result was smaller than 

    ////////last 8 spaces////////////////////////////////
    le = resultSM.length - 8;
    cut = resultSM.substr(le, 8);
    console.log('green coll 6 : ', cut);

    if (cut === ' , , , ,' || cut === '   , , ,') { 
      resultSMs = resultSM.substr(0, le) 
     // console.log('green coll 6 : ', resultSMs);
    } //if result was smaller than 
    
    resultM = JSON.parse("{" + resultSMs + "}");

    // resultM.sort(function(a, b) {

    //   if (a.feedback > b.feedback) {
    //     // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
    //     return -1
    //   }
    //   if (a.feedback < b.feedback) {
    //   //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
    //   return 1
    //   }
    //   return 0
    // })

    console.log('green coll 7 : ', resultM);
    /////////////////////////////////////////
    console.log('inside getAllCompanyLeaders 4 ', resultM)
 if (Object.keys(resultM).length > 0) {     
      dispatch({
         type: LEADERBOARD,
         leaderboard_action: resultM
          })
        } else {
          dispatch({
            type: LEADERBOARD,
            leaderboard_action: {}
          })
      }
  }
}

// export const getAllCompanyLeaders = params => {
//   return (dispatch, getState) => {
//    //  axios
//    //   .post(`${FB_URL}/m100-leaderboardData`, {})
//    //   .then(res => {
//    //     let result = res.data;  
//  var result;
//  result = collect(); 
//  console.log('green coll 7 : ', result.data);

// if (Object.keys(result).length > 0) {     
//      dispatch({
//         type: LEADERBOARD,
//         leaderboard_action: result
//          })
//        } else {
//          dispatch({
//            type: LEADERBOARD,
//            leaderboard_action: {}
//          })
//      }
//  }
// }

// export const getAllCompanyLeaders = params => {
//   return (dispatch, getState) => {
//     axios
//      .post(`${FB_URL}/m100-leaderboardData`, {})
//      .then(res => {
//        let result = res.data;
//        //var receivedData = {};
//        // let sortedData = Object.keys(result).reduce((array, key) => {
//        //   return [...array, {key: result[key]}]
//        // }, [])

//        console.log('green getAllCompanyLeaders 2 : , ', result.data);

//      dispatch({
//         type: LEADERBOARD,
//         leaderboard_action: result.data
//          })
//      })
//      .catch(err => {
//        if (err.response) {
//          dispatch({
//            type: LEADERBOARD,
//            leaderboard_action: err.response
//          })
//        }
//      })
//  }
// }

export const xgetAllCompanyLeaders = params => {
  console.log('green this.props.leaderboardData CC: ');
  //reading from database to write leaderboardData into JSON file
  return (dispatch, getState) => {
    firebase.database
      .ref('user')
      .on('value', function (data) {
        if (data.val()) {
          let users = data.val();

          let receivedData = {}
          delete users.undefined

          if (Object.keys(users).length > 0) {
            let sortedData = _toArrayUsersM(users)
            sortedData.sort(function (a, b) {

              if (a.firstName > b.firstName) {
                // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                return 1
              }
              if (a.firstName < b.firstName) {
              //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                return -1
              }
              return 0
            })

            receivedData = { ...sortedData }

            receivedData = Object.assign({}, sortedData)
           }
           //WRITE INTO JSON file
           //handleSaveToPC(receivedData, 'leaderlist.json')
           
          dispatch({
            type: LEADERBOARD,
            //leaderboard_action: receivedData
          })
        } else {
          dispatch({
            type: LEADERBOARD,
            //leaderboard_action: {}
          })
        }
      })
  }
}

//export const getAllCompanyLeaders = params => {
export const getAllCompanyLeaders_5july = params => {
  console.log('green this.props.leaderboardData CC: ');
  //reading from database to write leaderboardData into redux state
  return (dispatch, getState) => {
    firebase.database
      .ref('user')
      // .orderByChild(params.orderChild)
      // .equalTo(params.companyID)
      .on('value', function (data) {
        if (data.val()) {
          let users = data.val();

          let receivedData = {}
          delete users.undefined

          if (Object.keys(users).length > 0) {
            let sortedData = _toArrayUsersM(users)
            sortedData.sort(function (a, b) {

              if (a.firstName > b.firstName) {
                // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                return 1
              }
              if (a.firstName < b.firstName) {
              //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
                return -1
              }
              return 0
            })

            receivedData = { ...sortedData }

            receivedData = Object.assign({}, sortedData)
            console.log('leaderboardData exp : ', receivedData)
           }

          dispatch({
            type: LEADERBOARD,
            leaderboard_action: receivedData
          })
        } else {
          dispatch({
            type: LEADERBOARD,
            leaderboard_action: {}
          })
        }
      })
  }
}

// export const keepTopLeaders = params => {
//   var objLeng = Object.keys(params.Alldata).length;
//   console.log('green getTopLeaders 2: ', objLeng)

//     return (dispatch, getState) => {
//       // firebase.database
//       //   .ref('user')
//       //   .on('value', function (data) {
//         if (params.Alldata) {
//           let users = params.Alldata;
//           let receivedData = {}
//           delete users.undefined
//           console.log('green this.props.leaderboardData Y ', users);

//           if (Object.keys(users).length > 0) {
//             let sortedData = _toArrayUsers(users)
//             sortedData.sort(function(a, b){
//               return b.points - a.points
//             });

//             receivedData = { ...sortedData }
//             receivedData = Object.assign({}, sortedData)
//            }
//            console.log('green this.props.leaderboardData Z ', receivedData);

//           dispatch({
//             type: FIXED_LEADER_LIST,
//             users: receivedData
//           })
//         } else {
//           dispatch({
//             type: FIXED_LEADER_LIST,
//             users: {}
//           })
//         }
//       //})
//     }
//   }

// export const getChatx = params => {
//   console.log('green this.props.GPT 2 : ', params.ask)
//     return async (dispatch, getState) => {
//       var gptanswer;
//       const api = new ChatGPTUnofficialProxyAPI({
//         accessToken: process.env.OPENAI_ACCESS_TOKEN
//       })
    
//       const res = await api.sendMessage('Hello World!')
//       console.log('green this.props.GPT 2a : ', res.text)
//         if (params.ask) {


//           dispatch({
//             type: GET_AI,
//             gptanswer: Object.entries(gptanswer)
//           })
//         } else {
//           dispatch({
//             type: GET_AI,
//             gptanswer: {}
//           })
//         }
//       //})
//     }
//   }
export const getChatHistory = params => {
  
  console.log('green this.props.GPT 2 : ', params.ask)
 // var objLeng = Object.keys(params.Alldata).length;

    return async (dispatch, getState) => {
      var gptanswer;
      var refgpt;

        if (params.ask) {
        let q = {
          question: params.ask,
          email: params.email
        }
        var strx = JSON.stringify(q);

        console.log('JSON.strx 1: ', params.ask);
        var qq = JSON.parse(strx)

        //CALL API
        try {
        await axios
         .post(`${FB_URL}/m104-chatgpthistory`, q)  

        .then(res => {
           gptanswer = res.data;
        })
          dispatch({
            type: GET_AI,
            gptanswer: Object.entries(gptanswer)
          })
        } catch (error) {
          console.error(error);
        }
        } else {
          dispatch({
            type: GET_AI,
            gptanswer: {}
          })
        }
    }
  }

export const getChat = params => {
  
  console.log('green this.props.GPT 2 : ', params.ask)
    return async (dispatch, getState) => {
      var gptanswer;
      var refgpt;

        if (params.ask) {
        let q = {
          question: params.ask,
          email: params.email
        }

        var strx = JSON.stringify(q);

        console.log('JSON.strx 1: ', params.ask);
        var qq = JSON.parse(strx)

        //CALL API
        try {
        await axios
         .post(`${FB_URL}/m103-chatgpt`, q)  

        .then(res => {
           gptanswer = res.data;
        })
          dispatch({
            type: GET_AI,
            gptanswer: Object.entries(gptanswer)
          })
        } catch (error) {
          console.error(error);
        }
        } else {
          dispatch({
            type: GET_AI,
            gptanswer: {}
          })
        }
    }
  }

export const getTopLeadersFromProps = params => {
  
  console.log('green this.props.leaderboardData X : ', params.Alldata)
  var objLeng = Object.keys(params.Alldata).length;
  console.log('green getTopLeaders 2: ', objLeng)

    return (dispatch, getState) => {
        if (params.Alldata) {
          let users = params.Alldata;
          let receivedData = {}
         // let sortedData = {}
          delete users.undefined
         // console.log('green this.props.leaderboardData Y ', users);

          if (Object.keys(users).length > 0) {
            let sortedData = _toArrayUsersL(params.companyID, users, params.LeaderboardKey, params.pointsy)

            console.log('green-getTopLeadersFromProps 3 ', sortedData)
            // if (params.feedback) {
              // sortedData.sort(function(a, b){
              //  return b.feedback - a.feedback
              // });
            // }

            // sortedData.sort(function(a, b){
            //   return b.feedback - a.feedback
            //  });

            if (params.pointsy) {
             // let sortedData = _toArrayUsersL(params.companyID, users, params.LeaderboardKey, params.pointsy)
              console.log('greeng in pointsy')
              sortedData.sort(function(a, b){
               // return (b.pointsy + b.pointsCalf) - (a.pointsy + b.pointsCalf)
               return b.pointsAlly - a.pointsAlly
               // return b.feedback - a.feedback
              });
            }
            else
           {
           // let sortedData = _toArrayUsersL(params.companyID, users, params.LeaderboardKey, params.points)
              sortedData.sort(function(a, b){
                return b.pointsAll - a.pointsAll
               //return b.feedback - a.feedback
              });
            }

            // sortedData.sort(function(a, b){
            //   return b.feedback - a.feedback
            //  });
            //////////////////////////////////////
            if (params.feedback) {
              sortedData.sort(function(a, b){
               return b.feedback - a.feedback
              });
            }

            sortedData.sort(function(a, b){
              return b.feedback - a.feedback
             });
            ///////////////////////////////////////
            
            receivedData = { ...sortedData }
            receivedData = Object.assign({}, sortedData)

           }
           console.log('green this.props.leaderboardData receivedData ', receivedData);

          dispatch({
            type: GET_ALL_COMPANY_USERS,
            users: receivedData
          })
        } else {
          dispatch({
            type: GET_ALL_COMPANY_USERS,
            users: {}
          })
        }
      //})
    }
  }

export const getTopLeaders = params => {
  var dept;
 // console.log('green this.props.leaderboardData B: ', params)
  if (params.firstOrder===false) { 
    return (dispatch, getState) => {
      firebase.database
        .ref('user')
        .on('value', function (data) {
        if (data.val()) {
          let users = data.val()
          let receivedData = {}
          delete users.undefined
         // console.log('green this.props.leaderboardData B2: ', users);

          //  if (Object.keys(users).length > 0) {
          //    let sortedData = _toArrayUsersL(users, params.LeaderboardKey)
          //   sortedData.sort(function(a, b){
          //     return b.points - a.points
          //   });

          //   receivedData = { ...sortedData }
          //   receivedData = Object.assign({}, sortedData)
          //  }
         //  console.log('green this.props.leaderboardData C: ', sortedData);

          dispatch({
            type: LEADERBOARD,
            leaderboard_action: users
          })
        } else {
          dispatch({
            type: LEADERBOARD,
            leaderboard_action: {}
          })
        }
      })
    }
  }
  else //FIRST ORDER DEFAULT ORDER
  {
    return (dispatch, getState) => {
      firebase.database
        .ref('user')
        .orderByChild('companyID')
        .equalTo(params.companyID)
        .on('value', function (data) {
          if (data.val()) {
            let users = data.val()
            let receivedData = {}
            delete users.undefined

            if (Object.keys(users).length > 0) {
              let sortedData = _toArrayUsersx(users)

              sortedData.sort(function(a, b){ 
                  return b.points - a.points
              });

              receivedData = { ...sortedData }
              receivedData = Object.assign({}, sortedData)
             }

            dispatch({
              type: GET_ALL_COMPANY_USERS,
              users: receivedData
            })
          } else {
            dispatch({
              type: GET_ALL_COMPANY_USERS,
              users: {}
            })
          }
        })
    }
  }
}

export const getTopLeaders_backup = params => {
  var dept;
  console.log('green getTopLeaders : ', params)
  if (params.firstOrder===false) { 
    return (dispatch, getState) => {
      firebase.database
        .ref('user')
        .orderByChild(params.orderChild)
        .equalTo(params.LeaderboardKey)
        .on('value', function (data) {
        if (data.val()) {
          let users = data.val()
          let receivedData = {}
          delete users.undefined

          if (Object.keys(users).length > 0) {
            let sortedData = _toArrayUsersL(users, params.LeaderboardKey)
            sortedData.sort(function(a, b){
              return b.points - a.points
            });

            receivedData = { ...sortedData }
            receivedData = Object.assign({}, sortedData)
           }

          dispatch({
            type: GET_ALL_COMPANY_USERS,
            users: receivedData
          })
        } else {
          dispatch({
            type: GET_ALL_COMPANY_USERS,
            users: {}
          })
        }
      })
    }
  }
  else //FIRST ORDER DEFAULT ORDER
  {
    // companyID: this.props.userdata.companyID,
    // orderChild: 'companyID',
    //         .startAt(1)
    // .orderByChild(params.orderChild)
    //.startAt(1)
    return (dispatch, getState) => {
      firebase.database
        .ref('user')
        .orderByChild('companyID')
        .equalTo(params.companyID)
        .on('value', function (data) {
          if (data.val()) {
            let users = data.val()
            let receivedData = {}
            delete users.undefined

            if (Object.keys(users).length > 0) {
              let sortedData = _toArrayUsersx(users)

              sortedData.sort(function(a, b){ 
                  return b.points - a.points
              });

              receivedData = { ...sortedData }
              receivedData = Object.assign({}, sortedData)
             }

            dispatch({
              type: GET_ALL_COMPANY_USERS,
              users: receivedData
            })
          } else {
            dispatch({
              type: GET_ALL_COMPANY_USERS,
              users: {}
            })
          }
        })
    }
  }
}

function replaceEmptyWith(arr,val) {
  var _arr = [];
  if (arr && arr.length > 0) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === undefined) _arr[i] = val;
      else _arr[i] = arr[i];
    }
    return _arr;
  }
  return arr;
}

export const getUserProfile = params => {
  console.log('green getUserData 1:', params)
    return (dispatch, getState) => {
      firebase.database
        .ref('user')
        .orderByChild('userID')
        .equalTo(params.userID)
        .on('value', function (data) {
        if (data.val()) {
          let user = data.val()
          let receivedData = {}
          delete user.undefined
          
          if (Object.keys(user).length > 0) {
            let sortedData = _toArrayUsers(user)
            sortedData.sort(function(a, b){
              return b.points - a.points
            });

            receivedData = { ...sortedData }
            receivedData = Object.assign({}, sortedData)
           }
           console.log('green getUserData 3:', receivedData)
          dispatch({
            type: GET_USER_PROFILE,
            userInfo: receivedData
          })
        } else {
          dispatch({
            type: GET_USER_PROFILE,
            userInfo: {}
          })
        }
      })
    }
}

export const xgetTopLeaders = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref('user')
      .orderByChild(params.orderChild)
      .equalTo(params.department)
      .on('value', function (data) {
        if (data.val()) {
          let users = data.val()
          let receivedData = {}
          var dataPiece
          var allData = '';
          var rpoints;
          delete users.undefined

          if (Object.keys(users).length > 0) {
            let sortedData = _toArrayUsers(users)
            sortedData.sort(function (a, b) {
              if (a.points) {rpoints = a.points} else {rpoints = 0}
              //dataPiece = '['+a.firstName+','+a.lastName+','+rpoints+']'
              dataPiece = a.firstName+','+a.lastName+','+rpoints+'/'
              // if (a.firstName > b.firstName) {
              //   // if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
              //   return 1
              // }
              // if (a.firstName < b.firstName) {
              // //  if (a.email==='mychristo@gmail.com') { console.log('Callie roos: ', a.uid); }
              //   return -1
              // }
              // return 0
              if (allData) {
                allData = allData + ',' + dataPiece;
              }
              else
              {
                allData = dataPiece ;
              }
          
            })
             // receivedData = JSON.parse('{'+allData+'}') ;
             sortedData = allData;
             //receivedData = Object.assign({}, sortedData)
             receivedData = sortedData.split('/');

             console.log('getTopLeaders allData', receivedData );
            // receivedData = { ...sortedData }

            // receivedData = Object.assign({}, sortedData)
           }

          dispatch({
            type: GET_ALL_COMPANY_USERS,
            users: receivedData
          })
        } else {
          dispatch({
            type: GET_ALL_COMPANY_USERS,
            users: {}
          })
        }
      })
  }
}

const getvalueDesc = (props) => {
  for (let x in props) {
    console.log('green getAllCompanySettings 4 : ', props[x].valueDesc)
    return props[x].valueDesc

  }
}
  export const getAllCompanySettings = params => {
  console.log('getAllCompanySettings : ', params)
  let companyID = '-LEiZPT-C2PyLu_YLKNU'; //params.companyID;
  //var company_settings;

  return (dispatch, getState) => {
    firebase.database
     .ref('company-values/' + companyID)
      .on('value', function (data) {
      if (data.val()) {
        //var company_settings = data.val()
        let filteredCompanyValues = Object.values(data.val()).filter(v => {
          console.log('green getAllCompanySettings 1  : ', getvalueDesc(v.companyID))
          return v.companyID ? v.companyID : null
        })
        let sortedData = _toArray(filteredCompanyValues)
        sortedData.sort(function (a, b) {
          if (a.valueDesc > b.valueDesc) {
            return 1
          }
          if (a.valueDesc < b.valueDesc) {
            return -1
          }
          return 0
        })
        // getvalueDesc(sortedData)
        // console.log('green getAllCompanySettings 1 : ', filteredCompanyValues[3].valueDesc)
        // console.log('green getAllCompanySettings 2 : ', getvalueDesc(sortedData[3]))
        // console.log('green getAllCompanySettings 3 : ', data.val())

        const departments = [];
        for (let i = 0; i < filteredCompanyValues.length; i++) {
          departments.push(filteredCompanyValues[i].valueDesc)
         // text += cars[i] + "<br>";
          //departments.push("Kiwi");
          //getvalueDesc(sortedData[3])
        }
        console.log('green getAllCompanySettings 5 : ', departments)
        dispatch({
          type: GET_ALL_COMPANY_SETTINGS,
          company_settings: {'departments': departments}
        })
      } else {
        dispatch({
          type: GET_ALL_COMPANY_SETTINGS,
          company_settings: []
        })
      }
    })
  }
}

 const xgetAllCompanySettings = params => {
  console.log('getAllCompanySettings params: ', params);
  
  return (dispatch, getState) => {
    firebase.database
      .ref('company-settings/' + params.companyID)
      .on('value', function (data) {
        if (data.val()) {
          console.log('green getAllCompanySettings 1 : ', data.val())
          dispatch({
            type: GET_ALL_COMPANY_SETTINGS,
            company_settings: data.val()
          })
        } else {
          dispatch({
            type: GET_ALL_COMPANY_SETTINGS,
            company_settings: {}
          })
        }
      })
  }
}

export const getAllGeneralSettings = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref('general-settings')
      .on('value', function (data) {
        if (data.val()) {
          let general_settings = data.val()

          dispatch({
            type: GET_ALL_GENERAL_SETTINGS,
            general_settings: general_settings
          })
        } else {
          dispatch({
            type: GET_ALL_GENERAL_SETTINGS,
            general_settings: {}
          })
        }
      })
  }
}


export const getAllCompanyGroups = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref('company_groups/' + params.companyID)
      .on('value', function (data) {
        if (data.val()) {
          let groups = data.val()
          let receivedData = {}

          if (Object.keys(groups).length > 0) {
            // let sortedData = _toArray(groups)
            let sortedData = _toArrayGroups(groups)

            receivedData = { ...sortedData }

            receivedData = Object.assign({}, sortedData)
          }

          dispatch({
            type: GET_ALL_COMPANY_GROUPS,
            company_groups: receivedData
          })
        } else {
          dispatch({
            type: GET_ALL_COMPANY_GROUPS,
            company_groups: {}
          })
        }
      })
  }
}

export const getAllCompanyGroupUsers = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref('company_groups_users/' + params.companyID + '/' + params.groupID)
      .on('value', function (data) {
        if (data.val()) {
          dispatch({
            type: GET_ALL_GROUP_USERS,
            group_users: data.val()
          })
        } else {
          dispatch({
            type: GET_ALL_GROUP_USERS,
            group_users: {}
          })
        }
      })
  }
}


export const getGroupDetails = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref('company_groups/' + params.companyID + '/' + params.group_id)
      .on('value', function (data) {
        if (data.val()) {
          dispatch({
            type: GET_GROUP_DETAILS,
            group_details: data.val()
          })
        } else {
          dispatch({
            type: GET_GROUP_DETAILS,
            group_details: {}
          })
        }
      })
  }
}

export const getGroupEvents = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref('group_events/' + params.group_id)
      .on('value', function (data) {
        if (data.val()) {
          let sortedData = _toArrayCalendar(data.val())
          dispatch({
            type: GET_GROUP_EVENTS,
            group_events: sortedData
          })
        } else {
          dispatch({
            type: GET_GROUP_EVENTS,
            group_events: []
          })
        }
      })
  }
}


export const getGroupEventsReminders = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref('group_events_reminders/')
      .orderByChild('group_id')
      .equalTo(params.group_id)
      .on('value', function (data) {
        if (data.val()) {
          dispatch({
            type: GET_GROUP_EVENTS_REMINDERS,
            group_events_reminders: data.val()
          })
        } else {
          dispatch({
            type: GET_GROUP_EVENTS_REMINDERS,
            group_events_reminders: {}
          })
        }
      })
  }
}

export const getScores = params => {
  return (dispatch, getState) => {
  let database = firebase.database
  console.log('green score 4 : ', params.email)

  var bodyFormData = new FormData();
  bodyFormData.append('email_id', params.email);
  //bodyFormData.append('page', 1);
  var calfscore = 0;
  var username = "mca_training";
  var password = "mcatraining@2022";
  var auth = 'Basic ' + Buffer.from(username + ':' + password).toString('base64');

  const optionsgame = {
  method: 'POST',
  headers: { 
  'Content-type': 'application/x-www-form-urlencoded',
  'Authorization':auth,
   },
  data: bodyFormData,
  //url:"https://exec-club.com/api/paricipant-statistics-scores"
  url: "https://exec-club.com/api/participant-gamification-details"
  }
   axios(optionsgame)
         .then(res => {
          console.log('green score 5 : ', res.data.status);
          console.log('green exec-clubscore : ', res.data);
          if (res.data.status==="Success") {
              calfscore = res.data.score;
          }
          else
          {
            calfscore = 0;
          }
         ///update here
         console.log('green score 6 : ', calfscore);
         let updates = {}
         updates['/user/' + params.uid + '/pointsCalf'] = parseInt(calfscore)/5

         database
         .ref()
         .update(updates)
         .then(() => {
          //  dispatch({
          //    type: 'SHOW_TOAST',
          //    variant: 'success',
          //    message: 'Executive club points updated!'
          //  })
          console.log('yello success')
         })
         .catch(() => {
          //  dispatch({
          //    type: 'SHOW_TOAST',
          //    variant: 'error',
          //    message: 'Error in points update!'
          //  })
          console.log('yello fail')
         })
         ///////////////
         })
       

        }
    }

export const deleteGroupEvents = params => {
  return (dispatch, getState) => {
    let database = firebase.database
    let group_id = params.group_id
    let event_id = params.event_id

    // specific events
    let updates = {}
    updates['/group_events/' + group_id + '/' + event_id] = null

    // delete all reminnders
    deleteGroupAllEventReminders(group_id)

    database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'success',
          message: 'Event deleted successfully.'
        })
      })
      .catch(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: 'Error deleting event.'
        })
      })
  }
}

export const deleteGroupEventReminders = params => {
  return (dispatch, getState) => {
    let database = firebase.database
    let group_id = params.group_id
    let group_reminder_id = params.group_reminder_id

    // specific reminders
    let updates = {}

    updates['/group_events_reminders/' + group_reminder_id] = null

    database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'success',
          message: 'Reminder deleted successfully.'
        })
      })
      .catch(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: 'Error deleting reminder.'
        })
      })
  }
}

function deleteGroupAllEventReminders(group_id) {
  return (dispatch, getState) => {
    let database = firebase.database

    let ref = database.ref('group_events_reminders/')
    ref.orderByChild('group_id')
      .equalTo(group_id)
      .once('value')
      .then(function (snapshot) {
        snapshot.forEach(function (childSnapshot) {
          let childData = childSnapshot.val()
          if (childData.status === 'unsent') {
            ref.child(childSnapshot.key).remove()
          }
        })
      })
  }
}

export const postGroupEvent = params => {
  return (dispatch, getState) => {
    let database = firebase.database
    let group_id = params.group_id
    let send_email_immediately = params.send_email_immediately
    let group_no = params.group_no ? params.group_no : ''
    let study_school = params.study_school ? params.study_school : ''
    let group_name = params.group_name
    let date_created = Date.now()
    let date_updated = Date.now()
    let event_id = ''

    if (params.event_id.length > 0) {
      event_id = params.event_id
      // delete all existing reminders
      deleteGroupAllEventReminders(group_id)
    } else {
      event_id = database.ref().child('/group_events/' + params.group_id).push().key
    }

    // if we are adding new group

    let group_event = {
      event_id: event_id,
      group_id: group_id,
      group_name: group_name,
      group_no: group_no,
      study_school: study_school,
      company_id: params.companyID,
      companyID: params.companyID,
      companyName: params.companyName,
      date_created: date_created,
      date_updated: date_updated,
      event_title: params.event_title,
      event_description: params.event_description,
      event_start_date: params.event_start_date,
      event_start_epoch: params.event_start_epoch,
      event_end_date: params.event_end_date,
      event_end_epoch: params.event_end_epoch,
      event_location: params.event_location,
      send_email_immediately: params.send_email_immediately,
      active: true
    }


    database
      .ref('company_groups_users/' + params.companyID + '/' + group_id)
      .on('value', function (data) {
        let users = data.val()
        let userEmails = Object.keys(users).map(uid => {
          return [users[uid].email]
        })

        let strEmails = userEmails.join()

        group_event.email = strEmails
        group_event.to = strEmails
        group_event.email_type = 'event_reminder'

        if (send_email_immediately) {
          database.ref('/resend-groupwelcomemail/' + group_id).set(group_event)
        }
      })

    let updates = {}

    // 1 update group
    updates['/group_events/' + group_id + '/' + event_id] = group_event

    // // 3 add group events reminders
    for (let x in params.reminders) {
      updates['/group_events_reminders/' + group_id + '_' + params.reminders[x].reminder_id] = {
        reminder_id: params.reminders[x].reminder_id,
        reminder_type: params.reminders[x].reminder_type,
        reminder_time: params.reminders[x].reminder_time,
        reminder_frequency: params.reminders[x].reminder_frequency,
        group_reminder_id: group_id + '_' + params.reminders[x].reminder_id,
        group_id: group_id,
        group_name: params.group_name,
        group_no: params.group_no ? params.group_no : '',
        study_school: params.study_school ? params.study_school : '',
        event_id: event_id,
        company_id: params.companyID,
        companyID: params.companyID,
        companyName: params.companyName,
        event_title: params.event_title,
        event_description: params.event_description,
        event_start_date: params.event_start_date,
        event_start_epoch: params.event_start_epoch,
        event_end_date: params.event_end_date,
        event_end_epoch: params.event_end_epoch,
        event_location: params.event_location,
        send_email_immediately: params.send_email_immediately,
        status: 'unsent'
      }
    }

    database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'success',
          message: 'Event posted successfully.'
        })
      })
      .catch(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: 'Error creating event.'
        })
      })
  }
}

export const addStudyMaterials = params => {
  return (dispatch, getState) => {
    let database = firebase.database
    let study_material_id = ''

    study_material_id = database.ref().child('/company_study_materials/' + params.companyID).push().key

    params.study_material_id = study_material_id
    params.status = true

    let updates = {}

    delete params.cacheControl
    delete params.contentLanguage
    delete params.customMetadata

    // 1 update group
    updates['/company_study_materials/' + params.companyID + '/' + study_material_id] = params

    database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'success',
          message: 'File uploaded successfully.'
        })
      })
      .catch(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: 'Error uploading file.'
        })
      })
  }
}

export const addAttendanceRegisters = params => {
  return (dispatch, getState) => {
    let database = firebase.database
    let attendance_register_id = ''

    attendance_register_id = database.ref().child('/group_attendance_registers/' + params.group_id).push().key

    params.attendance_register_id = attendance_register_id

    let updates = {}

    delete params.cacheControl
    delete params.contentLanguage
    delete params.customMetadata

    // 1 update group
    updates['/group_attendance_registers/' + params.group_id + '/' + attendance_register_id] = params

    database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'success',
          message: 'Attendance register posted successfully.'
        })
      })
      .catch(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: 'Error posting attendance register.'
        })
      })
  }
}

export const assignGroupStudyMaterials = params => {
  return (dispatch, getState) => {
    let database = firebase.database
    let selectedgroups = params.selectedgroups
    let userdata = params.userdata
    let file = params.file
    let updates = {}

    for (let i = 0, l = selectedgroups.length; i < l; i = i + 1) {
      if (selectedgroups[i] !== undefined) {
        let selectedFile = {
          group_id: selectedgroups[i].group_id,
          group_name: selectedgroups[i].group_name,
          group_no: selectedgroups[i].group_no ? selectedgroups[i].group_no : '',
          study_school: selectedgroups[i].study_school ? selectedgroups[i].study_school : '',
          companyName: userdata.companyName,
          companyID: userdata.companyID,
          title: file.name,
          ...file
        }
        // 1 update
        updates['/study_materials_assigned_groups/' + file.study_material_id + '/' + selectedgroups[i].group_id] = selectedFile
        updates['/groupfiles/' + selectedgroups[i].group_id + '/' + file.study_material_id] = selectedFile
      }
    }

    database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'success',
          message: 'Groups assigned successfully.'
        })
      })
      .catch(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: 'Error updating file.'
        })
      })
  }
}

export const removeGroupStudyMaterials = params => {
  return (dispatch, getState) => {
    let database = firebase.database
    let file = params
    let updates = {}

    // 1 update
    updates['/study_materials_assigned_groups/' + file.study_material_id + '/' + file.group_id] = null
    updates['/groupfiles/' + file.group_id + '/' + file.study_material_id] = null

    database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'success',
          message: 'Group removed successfully.'
        })
      })
      .catch(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: 'Error deleting group.'
        })
      })
  }
}

export const updateStudyMaterials = params => {
  return (dispatch, getState) => {
    let database = firebase.database
    let study_material_id = params.id

    let updates = {}

    // 1 update
    updates['/company_study_materials/' + params.companyID + '/' + study_material_id + '/status'] = params.status === 'activate' ? true : false

    database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'success',
          message: 'File updated successfully.'
        })
      })
      .catch(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: 'Error updating file.'
        })
      })
  }
}


export const deleteAttendanceRegister = params => {
  return (dispatch, getState) => {
    let database = firebase.database
    // var study_material_id = params.id;

    let updates = {}

    // // 1 update
    updates['/group_attendance_registers/' + params.group_id + '/' + params.id] = null

    database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'success',
          message: 'Attendance register deleted successfully!'
        })
      })
      .catch(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: 'Error deleting attendance register!'
        })
      })
  }
}


export const updateOrCreateProgrammes = params => {
  return (dispatch, getState) => {
    let database = firebase.database
    let programme_id = params.updateValue ? params.programme_id : database.ref().child('/company_programmes/' + params.companyID).push().key

    params.programme_id = programme_id

    let timeInMs = params.updateValue ? params.dateRegistered : Date.now()

    let data = {
      companyID: params.companyID,
      programme_id: programme_id,
      programme_name: params.programme_name,
      companyName: params.companyName,
      dateRegistered: timeInMs,
      status: params.status,
      companyID_status: params.companyID + '_' + params.status
    }
    // Write the new rating data
    let updates = {}
    updates['/company_programmes/' + params.companyID + '/' + programme_id] = data

    firebase.database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'SHOW_TOAST',
          message: 'Record posted successfully!',
          variant: 'success'
        })
      })
      .catch(err => {
        dispatch({
          type: 'SHOW_TOAST',
          message: err.message,
          variant: 'error'
        })
      })
  }
}

export const newSurveyEmail = params => {
  return (dispatch, getState) => {
    let database = firebase.database

    let selectedgroups = params.selectedgroups
    let survey_id = params.survey_id
    let timeInMs = params.dateRegistered.length > 0 ? params.dateRegistered : Date.now()

    let currRecord = {
      company_id: params.companyID,
      companyID: params.companyID,
      companyName: params.companyName ? params.companyName : '',
      survey_id: survey_id,
      survey_title: params.survey_title,
      survey_link: params.survey_link,
      selectedgroups: params.selectedgroups,
      dateRegistered: timeInMs,
      status: params.filterby,
      companyID_status: params.companyID + '_' + params.filterby
    }

    // Write the new notification's data
    let updates = {}

    for (let i = 0, l = params.selectedgroups.length; i < l; i = i + 1) {
      currRecord.group_id = params.selectedgroups[i].group_id
      currRecord.group_name = params.selectedgroups[i].group_name

      database
        .ref('company_groups_users/' + currRecord.companyID + '/' + currRecord.group_id)
        .on('value', function (data) {
          let users = data.val()

          let userEmails = Object.keys(users).map(uid => {
            let notificationData = {
              appNotificationID: survey_id,
              newNotificationID: survey_id,
              notificationType: 'survey',
              notificationMsg: 'New Survey - ' + params.survey_title,
              journalUserID: survey_id,
              journalUserName: params.survey_title,
              photoURL: '',
              notificationDate: timeInMs,
              seen: false,
              opened: false,
              companyID: params.companyID,
              companyName: params.companyName ? params.companyName : '',
              thoughtType: 'survey'
            }

            let newNotificationID = database.ref().child('users/' + users[uid].userID + '/notifications').push().key

            notificationData.newNotificationID = newNotificationID

            updates['users/' + users[uid].userID + '/notifications/' + newNotificationID] = notificationData

            return [users[uid].email]
          })

          let strEmails = userEmails.join()

          currRecord.survey_id = currRecord.survey_id
          currRecord.firstName = currRecord.group_name
          currRecord.lastName = currRecord.group_name
          currRecord.password = currRecord.group_name
          currRecord.email = strEmails
          currRecord.to = strEmails
          currRecord.new_update = 1
          currRecord.email_type = 'new_survey'

          // let notifyID = database.ref('/resend-groupwelcomemail/' + currRecord.group_id).push().key

          // updates['resend-groupwelcomemail/' + currRecord.group_id] = currRecord;

          // console.log("updates: ", updates)

          database.ref('/resend-groupwelcomemail/' + currRecord.group_id).set(currRecord)

          database
            .ref()
            .update(updates)
            .then(() => {
              console.log('Success setting app notification')
            })
            .catch(err => {
              console.log('Error setting app notification')
            })


          // console.log("currRecord: ", currRecord)
          // let currentIndex = selectedgroups.indexOf(currRecord.group_id);
          // console.log("currRecord: ", currRecord)

          // if (currentIndex >= 0) {

          // }
        })
    }

    dispatch({
      type: 'SHOW_TOAST',
      variant: 'success',
      message: 'Email notification sent to group participants'
    })
  }
}


export const addSurvey = params => {
  return (dispatch, getState) => {
    let database = firebase.database

    let survey_id = params.survey_id.length > 0 ? params.survey_id : database.ref().child('/company_surveys/' + params.companyID).push().key

    params.survey_id = survey_id

    let timeInMs = params.dateRegistered.length > 0 ? params.dateRegistered : Date.now()

    let data = {
      companyID: params.companyID,
      survey_id: survey_id,
      survey_title: params.survey_title,
      survey_link: params.survey_link,
      selectedgroups: params.selectedgroups,
      dateRegistered: timeInMs,
      status: params.filterby,
      companyID_status: params.companyID + '_' + params.filterby
    }

    // Write the new rating data
    let updates = {}
    updates['/company_surveys/' + params.companyID + '/' + survey_id] = data
    let selectedgroups_ids = ''
    let selectedgroups_names = ''

    // assign multiple groups
    for (let i = 0, l = params.selectedgroups.length; i < l; i = i + 1) {
      if (i === 0) {
        selectedgroups_ids = params.selectedgroups[i].group_id
        selectedgroups_names = params.selectedgroups[i].group_name
      } else {
        selectedgroups_ids = selectedgroups_ids + ', ' + params.selectedgroups[i].group_id
        selectedgroups_names = selectedgroups_names + ', ' + params.selectedgroups[i].group_name
      }
    }

    data.selectedgroups_ids = selectedgroups_ids
    data.selectedgroups_names = selectedgroups_names

    for (let i = 0, l = params.selectedgroups.length; i < l; i = i + 1) {
      data.group_id = params.selectedgroups[i].group_id
      data.group_name = params.selectedgroups[i].group_name

      updates['/group_surveys/' + params.selectedgroups[i].group_id + '/' + survey_id] = data
    }

    // console.log("updates: ", updates);

    firebase.database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'SHOW_TOAST',
          message: 'Survey posted successfully!',
          variant: 'success'
        })
      })
      .catch(err => {
        dispatch({
          type: 'SHOW_TOAST',
          message: err.message,
          variant: 'error'
        })
      })
  }
}

export const getCompanySurveys = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref('company_surveys/' + params.companyID)
      .on('value', function (data) {
        if (data.val()) {
          dispatch({
            type: GET_COMPANY_SURVEYS,
            company_surveys: data.val()
          })
        } else {
          dispatch({
            type: GET_COMPANY_SURVEYS,
            company_surveys: {}
          })
        }
      })
  }
}

export const getCompanyActiveSurveys = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref('company_surveys/' + params.companyID)
      .orderByChild('status')
      .equalTo('active')
      .on('value', function (data) {
        if (data.val()) {
          dispatch({
            type: GET_COMPANY_SURVEYS,
            company_surveys: data.val()
          })
        } else {
          dispatch({
            type: GET_COMPANY_SURVEYS,
            company_surveys: {}
          })
        }
      })
  }
}

export const getGroupSurveys = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref('group_surveys/' + params.group_id)
      .on('value', function (data) {
        if (data.val()) {
          dispatch({
            type: GET_GROUP_SURVEYS,
            group_surveys: data.val()
          })
        } else {
          dispatch({
            type: GET_GROUP_SURVEYS,
            group_surveys: {}
          })
        }
      })
  }
}

export const getCompanyProgrammes = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref('company_programmes/' + params.companyID)
      .on('value', function (data) {
        if (data.val()) {
          dispatch({
            type: GET_COMPANY_PROGRAMMES,
            company_programmes: data.val()
          })
        } else {
          dispatch({
            type: GET_COMPANY_PROGRAMMES,
            company_programmes: {}
          })
        }
      })
  }
}

export const getGroupAttendanceRegister = params => {
  return (dispatch, getState) => {
    // console.log("params ", params)

    firebase.database
      .ref('group_attendance_registers/' + params.group_id)
      .on('value', function (data) {
        if (data.val()) {
          dispatch({
            type: GET_GROUPS_ATTENDANCE_REGISTER,
            group_attendance_registers: data.val()
          })
        } else {
          dispatch({
            type: GET_GROUPS_ATTENDANCE_REGISTER,
            group_attendance_registers: {}
          })
        }
      })
  }
}

export const getActiveStudyMaterials = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref('company_study_materials/' + params.companyID)
      .orderByChild('status')
      .equalTo(true)
      .limitToLast(300)
      .on('value', function (data) {
        if (data.val()) {
          dispatch({
            type: GET_ACTIVE_STUDY_MATERIALS,
            active_study_materials: data.val()
          })
        } else {
          dispatch({
            type: GET_ACTIVE_STUDY_MATERIALS,
            active_study_materials: {}
          })
        }
      })
  }
}

export const getAssignedStudyMaterials = params => {
  return (dispatch, getState) => {
    let userdata = params.userdata
    let file = params.file

    firebase.database
      .ref('study_materials_assigned_groups/' + file.study_material_id)
      .on('value', function (data) {
        if (data.val()) {
          dispatch({
            type: GET_ASSIGNED_STUDY_MATERIALS,
            assigned_study_materials: data.val()
          })
        } else {
          dispatch({
            type: GET_ASSIGNED_STUDY_MATERIALS,
            assigned_study_materials: {}
          })
        }
      })
  }
}

export const getInActiveStudyMaterials = params => {
  return (dispatch, getState) => {
    firebase.database
      .ref('company_study_materials/' + params.companyID)
      .orderByChild('status')
      .equalTo(false)
      .limitToLast(300)
      .on('value', function (data) {
        if (data.val()) {
          dispatch({
            type: GET_INACTIVE_STUDY_MATERIALS,
            inactive_study_materials: data.val()
          })
        } else {
          dispatch({
            type: GET_INACTIVE_STUDY_MATERIALS,
            inactive_study_materials: {}
          })
        }
      })
  }
}

export const updateCompanyUser = params => {
  return (dispatch, getState) => {
    switch (params.changeType) {
    case 'changeActiveStatus':
      deactivateUser(params, dispatch)
      break

    case 'changePassword':
      //  updateUserPassword(params);
      // console.log("changePassword[params]", params);
      break
    default:
      dispatch({
        type: 'SHOW_TOAST',
        variant: 'error',
        message: 'Something went wrong!!'
      })
      break
    }
  }
}

export const deleteToken = params => {
  console.log('green handleDeleteUser 3', params);

   let userProfile = params.userProfile
 
   return (dispatch, getState) => {
     if (userProfile.isCredit===false) {
      findAndDeleteToken('tokens', userProfile.tokenID)
     }
   }
 }

export const deleteCompanyUser = params => {
 console.log('testhd inside deleteCompanyUser : ', params);

  let userProfile = params.userProfile
  // params.confirmDelete = true;

  return (dispatch, getState) => {
    // if (params.confirmDelete) {

    findAndDeleteDocument('subscribed', userProfile.userID)
    findAndDeleteDocument('users', userProfile.userID)
    findAndDeleteDocument('user', userProfile.uid)

    // dispatch({
    //   type: 'SHOW_TOAST',
    //   variant: 'success',
    //   message: `Deleted ${userProfile.firstName} ${
    //     userProfile.lastName
    //     } successfully!`
    // })

    // } else {
    //   dispatch({
    //     type: "SHOW_TOAST",
    //     variant: "error",
    //     message: `User cannot be deleted!`
    //   });
    // }
  }
}

export const resetYCompanyUser = parabolan => {
  let params = parabolan.userProfile

  return (dispatch, getState) => {
    const updates = {}
    let database = firebase.database

    const d = new Date();
    //const day = d.getDate();
    //const month = d.getMonth();
    var calfPoints;

    console.log('yupdated 4 : ', params)
    if (params.pointsCalf) {
      calfPoints = params.pointsCalf;
    }
    else{
      calfPoints = 0;
    }
  //  var calfPoints = params.pointsCalf ? params.pointsCalf : 0
  //  if ( params.yupdated===false ) { //not updated yet for this year
   //   console.log('yupdated 5 : ', params)
  
      updates['/user/' + params.uid + '/pointsy'] = 0;
      updates['/users/' + params.userID + '/pointsy'] = 0;
      updates['/user/' + params.uid + '/pointsCalfy'] = calfPoints;
      updates['/users/' + params.userID + '/pointsCalfy'] = calfPoints;
      updates['/user/' + params.uid + '/yupdated'] = d;
      updates['/users/' + params.userID + '/yupdated'] = d;
  
      database
        .ref()
        .update(updates)
        .then(() => {
          // dispatch({
          //   type: 'SHOW_TOAST',
          //   variant: 'success',
          //   message: 'Your Annual Points were successfully updated.'
          // })
          console.log('User ' + params.firstName + ' ' + params.lastName + ' Anual Points reset.')
        })
        .catch(() => {
          // dispatch({
          //   type: 'SHOW_TOAST',
          //   variant: 'error',
          //   message: 'Error deleting event.'
          // })
          console.log('Error with User ' + params.firstName + ' ' + params.lastName + '.')
        })
    //  }
    }
 }

export const createCompanyGroup = params => {
  return (dispatch, getState) => {
    let database = firebase.database
    let group_id = ''
    let date_created = ''
    let date_updated = Date.now()
    let total_users = Object.keys(params.selectedUsers).length

    // if we are adding new group
    if (params.group_id.trim().length === 0) {
      group_id = database
        .ref()
        .child('/company_groups/' + params.companyID)
        .push().key
      date_created = Date.now()
    } else {
      group_id = params.group_id
      date_created = params.date_created ? params.date_created : Date.now()
    }

    let group_info = {
      group_id: group_id,
      group_no: params.group_no ? params.group_no : '',
      study_school: params.study_school ? params.study_school : '',
      group_name: params.group_name,
      company_id: params.companyID,
      group_start_date: params.group_start_date,
      group_end_date: params.group_end_date,
      group_status: params.group_status,
      date_created: date_created,
      date_updated: date_updated,
      total_users: total_users
    }

    let updates = {}
    let userObj = {}
    updates = params.updates

    // 1 update group
    updates['/company_groups/' + params.companyID + '/' + group_id] = group_info

    Object.keys(params.selectedUsers).map((user) => {
      userObj[params.selectedUsers[user].userID] = {
        firstName: params.selectedUsers[user].firstName,
        lastName: params.selectedUsers[user].lastName,

        email: params.selectedUsers[user].email,
        userID: params.selectedUsers[user].userID,
        uid: params.selectedUsers[user].uid,
        photoURL: PHOTO_PROFILE(params.selectedUsers[user].photoURL),
        can_add_remove_users: false,
        can_edit: false,
        can_post: true,
        company_ID: params.companyID,
        company_name: params.companyName
      }

      updates['/company_user_groups/' + params.selectedUsers[user].userID + '/' + group_id] = {
        user_id: params.userID,
        group_id: group_id,
        group_no: params.group_no ? params.group_no : '',
        study_school: params.study_school ? params.study_school : '',
        group_name: params.group_name
      }
    })

    // // 2 add users to group
    updates['/company_groups_users/' + params.companyID + '/' + group_id] = userObj

    database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'success',
          message: 'Group posted successfully.'
        })
      })
      .catch(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: 'Error creating group.'
        })
      })
  }
}
export const deleteUserFromGroup = params => {
  return (dispatch, getState) => {
    let database = firebase.database

    let updates = {}
    updates['/company_user_groups/' + params.userID + '/' + params.group_id] = null

    database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'NO_DATA',
          message: {}
        })
      })
      .catch(() => {
        dispatch({
          type: 'NO_DATA',
          message: {}
        })
      })
  }
}
export const fetchUserGroups = params => {
  return (dispatch, getState) => {
    let database = firebase.database

    let ref = 'company_user_groups/' + params.userID

    if (params.userType === 7) {
      ref = 'company_groups/' + params.companyID
    }
    if (params.userType === 14) {
      ref = 'company_groups/' + params.companyID
    }

    database
      .ref(ref)
      .on('value', function (data) {
        if (data.val()) {
          dispatch({
            type: GET_USER_GROUPS,
            user_groups: data.val()
          })
        } else {
          dispatch({
            type: GET_USER_GROUPS,
            user_groups: {}
          })
        }
      })
  }
}
export const deleteGroup = params => {
  // params.confirmDelete = true;

  return (dispatch, getState) => {
    // if (params.confirmDelete) {
    // findAndDeleteDocument('company_groups', group.group_id)
    firebase.database.ref('company_users/' + params.companyID + '/' + params.group_id).remove()
    dispatch({
      type: ' DELETE_GROUP',
      variant: 'success',
      message: `Deleted ${params.group_name}
      } successfully!`
    })
  }
}

//PATREON API//////////////////////////////////////////////////////////
// import url from 'url'
// import patreonAPI, { oauth as patreonOAuth } from 'patreon'

// const CLIENT_ID = ''     // Replace with your data
// const CLIENT_SECRET = '' // Replace with your data
// const redirectURL = ''   // Replace with your data

// const patreonOAuthClient = patreonOAuth(CLIENT_ID, CLIENT_SECRET)

// function handleOAuthRedirectRequest(request, response) {
//     const oauthGrantCode = url.parse(request.url, true).query.code

//     patreonOAuthClient
//         .getTokens(oauthGrantCode, redirectURL)
//         .then(tokensResponse => {
//             const patreonAPIClient = patreonAPI(tokensResponse.access_token)
//             return patreonAPIClient('/current_user')
//         })
//         .then(({ store }) => {
//             response.end(store.findAll('user').map(user => user.serialize()))
//         })
//         .catch(err => {
//             console.error('error!', err)
//             response.end(err)
//         })
// }

// //Get the raw json from the response. See for the expected format of the data
// var patreon_response = patreon_client('/current_user').then(function(result) {
//   user_store = result.store
//   const data = result.rawJson
//   /*  data.data will contain the current_user, but there might be more users returned and loaded into the store.
//    *  Get the id of the requested user, and find it in the store
//    */
//   console.log('patreon-green : ', data)
//   const myUserId = data.data.id
//   creator = user_store.find('user', myUserId)
// })
///////////////////////////////////////////////////////////////////////

export const changeUserType = params => {
  return (dispatch, getState) => {
    let database = firebase.database

    // SelectedUser
    let userProfile = params.usersData
    let selected = getSelectedUserTypeDescription(params.selectedUserType)

    let uid = userProfile.uid
    let userID = userProfile.userID
    let companyID = userProfile.companyID
    // var userType = this._getUserType(selected);
    let userType = params.selectedUserType

    // Currently Logged In User
    let currUserType = params.currentloggedinuser.userType
    let currUserID = params.currentloggedinuser.userID

    if (currUserID === userID) {
      if (currUserType === 7 && userType !== 7) {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: 'Could not remove Admin Role from your own account!'
        })

        return
      }
      if (currUserType === 14 && userType !== 14) {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: 'Could not remove Admin Role from your own account!'
        })

        return
      }
    }

    // Update user data
    let updates = {}
    updates['/users/' + userID + '/companyID_userType'] =
      companyID + '_' + userType
    updates['/users/' + userID + '/userType'] = userType
    updates['/users/' + userID + '/userDescription'] = selected
    updates['/user/' + uid + '/companyID_userType'] =
      companyID + '_' + userType
    updates['/user/' + uid + '/userType'] = userType
    updates['/user/' + uid + '/userDescription'] = selected

    database
      .ref()
      .update(updates)
      .then(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'success',
          message: 'User updated successfully.'
        })
      })
      .catch(() => {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: 'Error updating user details.'
        })
      })
  }
}

const changeEmail = async params => {
  return (dispatch, getState) => {
  let updates = {}
  let database = firebase.database
  firebase
      .auth()
      .signInWithEmailAndPassword('ashleyhayle121@gmail.com', 'Leadership12345')
      .then(function (userCredential) {
          var user = userCredential.user;
          return user.updateEmail(params.Email);
      })
      .then(function () {
          //return firebaseRefs.ref("users/" + data.Id).update({ email: data.Email});
          updates['/users/' + params.userID + '/email'] = params.email;
          updates['/user/' + params.uid + '/email'] = params.email
          database.ref().update(updates, function (error) {
            if (error) {
              dispatch({
                type: 'SHOW_TOAST',
                variant: 'error',
                message: `${error}`
              })
            } else {
              dispatch({
                type: 'SHOW_TOAST',
                variant: 'success',
                message: `User updated successfully!`
              })
            }
          })
      })
      .catch(function (error) {
          console.log("Login Failed!", error);
      });
    }
  }

const changeEmailb = async params => {
  //return async (dispatch, getState) => {
    var mresult;
    console.log('green changeEmail 2: ', params)
   await axios
   .post(`${FB_URL}/m101-updateEmail`, {params: {userID: params.userID, uid: params.uid, email: params.email}})
   .then(res => {
     let result = res.data;
     mresult = result;
     console.log('green changeEmail 3: ', result)
   })
}

export const checkUserExistsEdit = (params, editUser) => {
  return async(dispatch, getState) => {
    let email = params.email
    console.log('greeny checkUserExists 1 ', params.email);
    // post new data
    await axios
      .post(`${FB_URL}/m45-userExists?email=${email}`, {})
      .then(res => {
        // passpass
        // console.log('feedback check if user exists',res);
        let _userexists = res.data
        _userexists.params = params
       console.log('greeny checkUserExists 2 ', _userexists.code);

        if (_userexists.code === 'auth/email-already-exists') {
          console.log('green editUser 1')
         // params.changeEmail = false;
         // editUser(); //edit user but not email address

          // dispatch({
          //   type: 'SHOW_TOAST',
          //   variant: 'success',
          //   message: 'User details edited successfully. The table below will refresh once done.'
          // })
          // dispatch({
          //   type: 'SHOW_TOAST',
          //   variant: 'warning',
          //   message: 'The provided email is already in use by an existing user. Please use a different email address.'
          // })
        } else {
         // createNewUser()
         console.log('green editUser 1b: ', params);
         //changeEmail({uid: params.uid, email: email});
         changeEmailb(params);
        // ////////////////////////////////////////////////////////////////////////////////
         console.log('greeny checkUserExists 3', params);
         // params.changeEmail = true;
         // editUser(); //STILL CANT USE UNTIL USER CAN BE AUTHENTICATED AGAIN
          // dispatch({
          //   type: 'SHOW_TOAST',
          //   variant: 'success',
          //   message: 'User edited successfully. The table below will refresh once done.'
          // })
        }
      })
      .catch(err => {
        let _userexists = { proceed: false }
        if (err.response) {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'response: ' + err.response.data.message
          })
        } else if (err.request) {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'request: ' + err
          })
        } else {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'message: ' + err.message
          })
        }
      })
  }
}

export const editUser = params => {
  console.log('green editUser 2', params)
  return async (dispatch, getState) => {
   // let changeEmail = params.changeEmail
   // let email = params.email 
    let firstName = params.firstName
    let lastName = params.lastName ? params.lastName : ''
    let surName = params.surName ? params.surName : ''
    let gender = params.gender ? params.gender : ''
    let points = params.points ? params.points : 0
    let addpoints = params.addpoints ? params.addpoints : 0
    let race = params.race ? params.race : ''
    let position = params.position ? params.position : ''
    let nationality = params.nationality ? params.nationality : ''
    let idNumber = params.idNumber ? params.idNumber : ''
    let department = params.department ? params.department : ''
    let operation = params.operation ? params.operation : ''
    let demoOn = params.demoOn ? params.demoOn : ''
    //console.log('green editUser 3')
    // Validate thought category

    let userDescription = 'Standard User'
    let userType = params.userType

    switch (params.userType) {
    case 4:
      userDescription = 'Global Contributor'
      break
    case 5:
      userDescription = 'Master Contributor'
      break
    case 3:
      userDescription = 'Public I-Leader'
      break
    case 7:
      userDescription = 'Company Admin'
      break
    case 8:
      userDescription = 'Standard User'
      break
    case 10:
      userDescription = 'Corporate I-Leader'
      break
    case 11:
      userDescription = 'Organisation Contributor'
      break
    case 12:
      userDescription = 'Delegate'
      break
    default:
      userDescription = 'Standard User'
      break
    }

    // Get a reference to the database service
    let database = firebase.database
    if (!firstName) {
      dispatch({
        type: 'SHOW_TOAST',
        variant: 'error',
        message: `Firstname is required!`
      })
      return
    }

    let userID = params.userID
    let uid = params.uid
    let addpointsS = parseFloat(addpoints)
    console.log('green editUser 4')
    if (addpointsS) {
          //////UPDATE LCPOOL////////////////////////////////////////////////////
          // // Path to the integer field you want to update
          console.log('LCPOOL Transaction start', addpointsS);
          const fieldPath = '/patreon/LCPOOL';

          // Perform a transaction
          database.ref(fieldPath).transaction(currentValue => {
           // console.log('LCPOOL Transaction currentvalue', currentValue);
            
              if (currentValue === null || currentValue === undefined || currentValue === '') {
                  // If the current value is null, undefined, or empty, set it to the params.pointsAdded
                  return addpointsS; // Parse params.pointsAdded as integer
              } else {
                  // If the current value exists, subtract from LC POOL
                  
                //  if (trans===false)  {  
                    console.log('LCPOOL Transaction int', currentValue + ' -- ' + params.addpoints);    
                   // trans = true;                   
                    return  (currentValue || 0) - ((addpointsS)*100); // Add params.pointsAdded to the existing value
                 // }
              }
          })
          .then(result => {
              console.log('LCPOOL Transaction completed successfully!');
          })
          .catch(error => {
              console.error('LCPOOL Transaction failed:', error);
          });
          
      //////////////////////////////////////////////////////////////////////////
        }
    // Write the new rating data
    let updates = {}
    let companyID_userType = params.companyID + '_' + userType

    // users table
    updates['/users/' + userID + '/firstName'] = firstName
    updates['/users/' + userID + '/lastName'] = lastName
    // if (changeEmail===true) {
    //  updates['/users/' + userID + '/email'] = email
    // }
    updates['/users/' + userID + '/surName'] = surName
    updates['/users/' + userID + '/gender'] = gender
    updates['/users/' + userID + '/points'] = addpoints > 0 ? (parseFloat(points) + (parseFloat(addpoints)*100)) : points
    updates['/users/' + userID + '/race'] = race
    updates['/users/' + userID + '/position'] = position
    updates['/users/' + userID + '/nationality'] = nationality
    updates['/users/' + userID + '/idNumber'] = idNumber
    updates['/users/' + userID + '/department'] = department
    updates['/users/' + userID + '/operation'] = operation
    updates['/users/' + userID + '/userDescription'] = userDescription
    updates['/users/' + userID + '/userType'] = userType
    updates['/users/' + userID + '/companyID_userType'] = companyID_userType
    updates['/users/' + userID + '/demoOn'] = demoOn
    updates['/users/' + userID + '/LeaderboardKey'] = params.companyID + '_' + nationality + '_' + position + '_' + department
    
     //////////////////////////////////////////////////////////////////////////////
    // updates['/users/' + userID + '/companyID'] = "-NRbTwxbqFLepk62Eq6M";
    // updates['/users/' + userID + '/companyName'] = "Me-Vision-Academy";
    // updates['/users/' + userID + '/userDescription'] = 'Public Contributor';
    // updates['/users/' + userID + '/userType'] = 3;

    // updates['/users/' + userID + '/companyID_userType'] = params.companyID + '_' + '3';
    //updates['/users/' + userID + '/LeaderboardKey'] = params.companyID + '_' + nationality + '_' + position + '_' + department
    // updates['/users/' + userID + '/feedbackOn'] = true;
    //updates['/users/' + userID + '/gfeedbackOn'] = true;
    
    //updates['/users/' + userID + '/feedback'] = 1;
    //updates['/users/' + userID + '/execmember'] = true;
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // user table
    updates['/user/' + uid + '/firstName'] = firstName
    updates['/user/' + uid + '/lastName'] = lastName

    // if (changeEmail===true) {
    //  updates['/user/' + uid + '/email'] = email
    // }
    updates['/user/' + uid + '/surName'] = surName
    updates['/user/' + uid + '/gender'] = gender
    updates['/user/' + uid + '/points'] = parseFloat(points) + (parseFloat(addpoints)*100)
    updates['/user/' + uid + '/race'] = race
    updates['/user/' + uid + '/position'] = position
    updates['/user/' + uid + '/nationality'] = nationality
    updates['/user/' + uid + '/idNumber'] = idNumber
    updates['/user/' + uid + '/department'] = department
    updates['/user/' + uid + '/operation'] = operation
    updates['/user/' + uid + '/userDescription'] = userDescription
    updates['/user/' + uid + '/userType'] = userType
    updates['/user/' + uid + '/companyID_userType'] = companyID_userType
    updates['/user/' + uid + '/LeaderboardKey'] = params.companyID + '_' + nationality + '_' + position + '_' + department
    updates['/user/' + uid + '/demoOn'] = demoOn


   ////////////////////////////////////////////////////////////////////////////////////////
    // updates['/user/' + uid + '/companyID'] = "-NRbTwxbqFLepk62Eq6M";
    // updates['/user/' + uid + '/companyName'] = "Me-Vision-Academy";
   
    //updates['/user/' + uid + '/userDescription'] = 'Public Contributor';
    //updates['/user/' + uid + '/userType'] = 3;

    //updates['/user/' + uid + '/companyID_userType'] = params.companyID + '_' + '3';
    //updates['/user/' + uid + '/LeaderboardKey'] = params.companyID + '_' + nationality + '_' + position + '_' + department
    //updates['/user/' + uid + '/feedbackOn'] = true;
    //updates['/user/' + uid + '/gfeedbackOn'] = true;

    //updates['/user/' + uid + '/feedback'] = 1;
    //updates['/user/' + uid + '/execmember'] = true;
    //////////////////////////////////////////////////////////////////////////////////////////////

    console.log('green editUser 5')
   // checkUserExistsEdit
    await database.ref().update(updates, function (error) {
      if (error) {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: `${error}`
        })
      } else {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'success',
          message: `User updated successfully!`
        })
      }
    })

  }
 //}
}

// check if user exists

export const checkUserIs = (params, createCompanyUser) => {
  return (dispatch, getState) => {
    let email = params.email
    // post new data
    axios
      .post(`${FB_URL}/m45-userExists?email=${email}`, {})
      .then(res => {
        // passpass
        console.log('feedback check if user exists', res);
        let _userexists = res.data
        _userexists.params = params
        console.log('feedback check if user exists ', _userexists.code);
        // || _userexists.code === 'auth/user-not-found'

        if (_userexists.code === 'auth/email-already-exists') {
          dispatch({
            type: 'SHOW_TOAST',
            variant: 'warning',
            message:
              // eslint-disable-next-line max-len
              'The provided email is already in use by an existing user. Please use a different email address.'
          })
        } else {
          //createNewUser()
          console.log('green createCompanyUser 0 ', params)
        // createCompanyUser(params)
         // closeDialog()

          dispatch({
            type: 'SHOW_TOAST',
            variant: 'success',
            message: 'Creating new user. The table below will refresh once done.'
          })
        }
      })
      .catch(err => {
        let _userexists = { proceed: false }
        // console.log('checkUserExists dispatch catch', err);
        //closeDialog()

        if (err.response) {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'response: ' + err.response.data.message
          })
        } else if (err.request) {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'request: ' + err
          })
        } else {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'message: ' + err.message
          })
        }
      })
  }
}

export const checkUserExists = (params, createCompanyUser, closeDialog) => {
  // console.log('checkUserExists', params, createNewUser);
  return (dispatch, getState) => {
    let email = params.email
    // post new data
    axios
      .post(`${FB_URL}/m45-userExists?email=${email}`, {})
      .then(res => {
        // passpass
        // console.log('feedback check if user exists',res);
        let _userexists = res.data
        _userexists.params = params

        if (_userexists.code === 'auth/email-already-exists') {
          dispatch({
            type: 'SHOW_TOAST',
            variant: 'warning',
            message:
              // eslint-disable-next-line max-len
              'The provided email is already in use by an existing user. Please use a different email address.'
          })
        } else {
          //createNewUser()
          createCompanyUser(params)
          closeDialog()

          dispatch({
            type: 'SHOW_TOAST',
            variant: 'success',
            message: 'Creating new user. The table below will refresh once done.'
          })
        }
      })
      .catch(err => {
        let _userexists = { proceed: false }
        // console.log('checkUserExists dispatch catch', err);
        closeDialog()

        if (err.response) {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'response: ' + err.response.data.message
          })
        } else if (err.request) {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'request: ' + err
          })
        } else {
          dispatch({
            type: 'SHOW_TOAST',
            open: true,
            variant: 'error',
            message: 'message: ' + err.message
          })
        }
      })
  }
}

const createUser = userData => {
  fetch('https://execclub.mcatraininginternational.com/api/create-user', {
    method: 'POST',
    body: JSON.stringify(userData)
  }).then((response)=>{
    response.json().then((result)=>{
      console.warn('result', result)
      localStorage.setItem('createUser', JSON.stringify({
        login: true,
        token: result.token,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        companyID: this.props.userdata.companyID,
        companyName: this.props.userdata.companyName,
        userType: userData.usertype,
        selectedCategory: userData.usertype
      }))
      this.setState({ login: true })
    })
  })
}

export const createCompanyUser = params => {
  console.log('green createCompanyUser 1 ', params)
  return (dispatch, getState) => {
    console.log('green createCompanyUser 2 ', params)
    let email = params.email
    let firstName = params.firstName
    let lastName = params.lastName ? params.lastName : ''

    let surName = params.surName ? params.surName : ''
    let gender = params.gender ? params.gender : ''
    let points = params.points ? params.points : 0
    let addpoints = params.addpoints ? params.addpoints : 0
    let race = params.race ? params.race : ''
    let position = params.position ? params.position : ''
    let nationality = params.nationality ? params.nationality : ''
    let idNumber = params.idNumber ? params.idNumber : ''
    let department = params.department ? params.department : ''
    let operation = params.operation ? params.operation : ''
    //
    // var emailVal = this.$.email.validate();
    // var firstNameVal = this.$.firstName.validate();
    // var lastNameVal = this.$.lastName.validate();

    // let companyID = "-LDVbbRyIMhukVtTVQ0n";
    // let companyName = "OneConnect Technologies";
    // let companyID = "-KgsUcgfo7z1U9MXgd9i";
    // let companyName = "Global Leadership Platform";
    // let companyID = "-LFWwJDGboULCmA0aC_9";
    // let companyName = "McDonald's";
    let companyID =  params.companyID //'-LjW7UI7e0uUikYrw-Vj';
    let companyName = params.companyName

    // Validate thought category

    let userDescription = 'Standard User'
    let userType = params.userType
    let demoOn = params.demoOn

    switch (params.userType) {
    case 4:
      userDescription = 'Global Contributor'
      break
    case 5:
      userDescription = 'Master Contributor'
      break
    case 3:
      userDescription = 'Public Contributor'
      break
    case 7:
      userDescription = 'Company Admin'
      break
    case 8:
      userDescription = 'Standard User'
      break
    case 10:
      userDescription = 'Corporate I-Leader'
      break
    case 11:
      userDescription = 'Organisation Contributor'
      break
    case 12:
      userDescription = 'Delegate'
      break
    case 14:
      userDescription = 'Client Admin'
      break
    default:
      userDescription = 'Standard User'
      break
    }

    // get date and time
    let timeInMs = Date.now()
    let datetime = new Date(timeInMs)
    // format date
    datetime = moment(datetime).format('DD MMMM YYYY hh:mm A') // 23 February 2019 09:36 PM"

    // Get a reference to the database service
    let database = firebase.database

    if (!email) {
      dispatch({
        type: 'SHOW_TOAST',
        variant: 'error',
        message: `Email is required`
      })
      return
    } else if (!firstName) {
      dispatch({
        type: 'SHOW_TOAST',
        variant: 'error',
        message: `Firstname is required!`
      })
      return
    }
    // else if (!lastName) {
    //   dispatch({
    //     type: 'SHOW_TOAST',
    //     variant: 'error',
    //     message: `Lastname is required!`
    //   })
    //   return
    // }
         if (addpoints > 0) {
          //////UPDATE LCPOOL////////////////////////////////////////////////////
          // // Path to the integer field you want to update
          console.log('LCPOOL Transaction start', params.pointsAdded);
          const fieldPath = '/patreon/LCPOOL';

          // Perform a transaction
          database.ref(fieldPath).transaction(currentValue => {
           // console.log('LCPOOL Transaction currentvalue', currentValue);
            
              if (currentValue === null || currentValue === undefined || currentValue === '') {
                  // If the current value is null, undefined, or empty, set it to the params.pointsAdded
                  return parseFloat(params.pointsAdded); // Parse params.pointsAdded as integer
              } else {
                  // If the current value exists, subtract from LC POOL
                  
                //  if (trans===false)  {  
                    console.log('LCPOOL Transaction int', currentValue + ' -- ' + params.pointsAdded);    
                   // trans = true;                   
                    return (parseFloat(currentValue) || 0) - parseFloat(addpoints)*100; // Add params.pointsAdded to the existing value
                 // }
              }
          })
          .then(result => {
              console.log('LCPOOL Transaction completed successfully!');
          })
          .catch(error => {
              console.error('LCPOOL Transaction failed:', error);
          });
          
      //////////////////////////////////////////////////////////////////////////
        }
    let userID = database
      .ref()
      .child('/newUploadUsers')
      .push().key

      console.log('green createCompanyUser 3 ', userID)
      
    let defPassword = randomString(15)
    // let defPassword =
    //   Math.random()
    //     .toString(36)
    //     .substring(2, 18) +
    //   Math.random()
    //     .toString(36)
    //     .substring(2, 18)
    defPassword = defPassword.toUpperCase()
    if (demoOn===true) {
      defPassword = 'Leadership12345';
    }
    let data = {
      companyID: companyID,
      companyName: companyName,
      dateRegistered: timeInMs,
      email: email,
      firstName: firstName,
      lastName: lastName,
      password: defPassword,
      surName: surName,
      gender: gender,
      points: addpoints ? parseFloat(addpoints) * 100 : 0,
      race: race,
      position: position,
      nationality: nationality,
      idNumber: idNumber,
      department: department,
      operation: operation,
      stringDateRegistered: datetime,
      userDescription: userDescription,
      newUploadUserID: userID,
      feedbackOn: true,
      gfeedbackOn: true,
      feedback: 0,
      receiveEmails: 'true',
      receivePLDPEmails: 'true',
      userType: userType,
      companyID_userType: companyID + '_' + userType,
      APIKey: 'gCZjoEnFZB-e8w49GvgHP',
      APISecretKey: 'KSYxjZTj7zNkME7p8MM7IYrt3PjvVtBo211i',
      demoOn: demoOn,
      LeaderboardKey: companyID + '_' + nationality + '_' + position + '_' + department
    }

    // Write the new rating data
    if (companyID === '-LjW7UI7e0uUikYrw-Vj') {
      // createUser(data); // for CALF
    }
    console.log('green createCompanyUser 4 ', data)
    let updates = {}
    updates['/newUploadUsers/' + userID] = data


    database.ref().update(updates, function (error) {
      if (error) {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: `${error}`
        })
      } else {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'success',
          message: `User created successfully!`
        })
      }
    })
  }
  console.log('green not access create user')
}

export const getMyToken = params => {
    return (dispatch, getState) => {
      let database = firebase.database
      database
        .ref('myTokens')
        .orderByChild('tpersonID')
        .equalTo(params.tpersonID)
        .once('value', function (data) {
          console.log('green token reducer 2 :', params);
          if (data.val()) {
            console.log('green token reducer 3 :', data.val());
            let token = data.val()
            let receivedData = {}

            //if (Object.keys(token).length > 0) {
              token && Object.keys(token).map((tok, id) => {
                const rtoken = token[tok].token;
                const rtokenType = token[tok].tokenType;

              console.log('green token reducer 4 :', rtoken);
                receivedData = {
                token: rtoken,
                tokenType: rtokenType
                }
             })
             console.log('green token reducer 5 :', receivedData);
            dispatch({
              type: GET_MY_TOKEN,
              mtoken: receivedData
            })
          } else {
            dispatch({
              type: GET_MY_TOKEN,
              mtoken: {}
            })
          }
        })
    }
  }

export const createToken = params => {
  return (dispatch, getState) => {
    console.log('green createToken params 3 : ', params);  
    let email = params.email;
    let firstName = params.firstName;
    let lastName = params.lastName; 
    let personID = params.userID;
    let companyID = params.companyID; //'-LjW7UI7e0uUikYrw-Vj';

    let tpersonID = params.personID;
    let tpersonName = params.personName;
    let tpersonLastName = params.personLastName;
    let tpersonEmail = params.personEmail;
    let tokenType = params.tokenType;

    // let companyName = params.companyName;

    // get date and time
    // let timeInMs = Date.now()
    // let datetime = new Date(timeInMs)
    // format date
    //datetime = moment(datetime).format('DD MMMM YYYY hh:mm A') // 23 February 2019 09:36 PM"

    // Get a reference to the database service
    let database = firebase.database

    if (!email) {
      dispatch({
        type: 'SHOW_TOAST',
        variant: 'error',
        message: `Email is required`
      })
      return
    } else if (!firstName) {
      dispatch({
        type: 'SHOW_TOAST',
        variant: 'error',
        message: `Firstname is required!`
      })
      return
    }

    let userID = database
      .ref()
      .child('/tokens')
      .push().key

    let newToken = randomString(20)
    let tokenID = randomString(15)
    newToken = newToken.toUpperCase()

    let data = {
      tokenID: tokenID,
      token: newToken,
      userID: personID,
      companyID: companyID,
      email: email,
      firstName: firstName,
      lastName: lastName,  
      tpersonID: tpersonID,
      tpersonName: tpersonName,
      tpersonLastName: tpersonLastName,
      tpersonEmail: tpersonEmail,  
      tokenType : tokenType,      
      isCredit: false
    }
    let datax = {
      token: newToken,
      parentID: personID,
      parentCompanyID: companyID,
      parentEmail: email,
      parentName: firstName,
      parentlName: lastName,  
      tpersonID: tpersonID,
      tpersonName: tpersonName,
      tpersonLastName: tpersonLastName,
      tpersonEmail: tpersonEmail,  
      tokenType : tokenType,      
    }   
    console.log('greencreateToken 2 : ', data);

    let updates = {}
    updates['/myTokens/' + tpersonID] = datax
    updates['/tokens/' + tokenID] = data

    database.ref().update(updates, function (error) {
      if (error) {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'error',
          message: `${error}`
        })
      } else {
        dispatch({
          type: 'SHOW_TOAST',
          variant: 'success',
          message: 'Token sent to ' + tpersonName + ' ' + tpersonLastName
        })
      }
    })
  }
}

export const convertToken = params => {
  return async (dispatch, getState) => {
    console.log('green createToken 1 : ', params);
    let token = params.token;
    var tokenID;
    // Get a reference to the database service
     let database = firebase.database

     // create 1 credit for parent
     // need: 

    var data;
    const updates = {}
    var QueryContent = [];
     var updateContentP;
     var updateContentGP;
     var parentID;
     console.log('green update parent 1 : ', params.token);
     let postRefa = database.ref('tokens').orderByChild('token').equalTo(params.token) //parent collection
     postRefa.once('value')
     .then(async snapshot => {
      console.log('green update parent 2 : ', snapshot);
       snapshot.forEach(function (subChild) {
        tokenID =  randomString(15);
        parentID = subChild.val().tokenID;
        console.log('green update parent 3 : ', subChild);
        // tokenID: tokenID,
        // token: newToken,
        // userID: personID,
        // companyID: companyID,
        // email: email,
        // firstName: firstName,
        // lastName: lastName,  
        // tpersonID: tpersonID,
        // tpersonName: tpersonName,
        // tpersonLastName: tpersonLastName,
        // tpersonEmail: tpersonEmail,  
        // tokenType : tokenType,      
        // isCredit: false

        let data = {
          tokenID: tokenID, //create new tokenID
          token: params.token,
          userID: subChild.val().userID,
          companyID: subChild.val().companyID,
          email: subChild.val().email,
          firstName: subChild.val().firstName,
          lastName: subChild.val().lastName,   
          tpersonID: subChild.val().tpersonID,
          tpersonName: subChild.val().tpersonName,
          tpersonLastName: subChild.val().tpersonLastName,
          tpersonEmail: subChild.val().tpersonEmail,  
          tokenType : subChild.val().tokenType,     
          child: params.userID,  
          money: '500',
          isCredit: true
        }
        console.log('green update parent 4 : ', data)
        updateContentP = '/tokens/' + tokenID;
        QueryContent.push(updateContentP);
        QueryContent.push(data);
        QueryContent.push(parentID);
       })

       console.log('green update parent 6 : ', QueryContent[0])
       console.log('green update parent 7 : ', QueryContent[1])
       console.log('green update parent 8 : ', QueryContent[2])

        updates[QueryContent[0]] = QueryContent[1];  //create parent credit
       // updates['/tokens/' + QueryContent[2]] = null; //delete parent token
        database.ref('/tokens/' + QueryContent[2]).remove()

      await database.ref().update(updates, function (error) {
         if (error) {
           dispatch({
             type: 'SHOW_TOAST',
             variant: 'error',
             message: `${error}`
           })
         } else {
           dispatch({
             type: 'SHOW_TOAST',
             variant: 'success',
             message: `Thank you for joining the Executive Club!`
           })
         }
       })

       console.log('green update parent 5 : ', QueryContent[0])
        //  updates[updateContentP] = data;
     })

    // create 1 credit for grandparent
     // need: 

    // var grandChild;
    // var QueryContent = [];
    // //database
    //   let postRef = database.ref('tokens').orderByChild('child').equalTo(parentID) //grandparent collection
    //   postRef.once('value')
    //   .then(async snapshot => {
    //     snapshot.forEach(function (subChild) {
    //      tokenID =  randomString(15);
    //      let data = {
    //        tokenID: tokenID, //create new tokenID
    //        token: params.token,
    //        userID: subChild.val().userID,
    //        companyID: subChild.val().companyID,
    //        email: subChild.val().email,
    //        firstName: subChild.val().firstName,
    //        lastName: subChild.val().lastName,   
    //        grandChild: params.userID,  
    //        money: '200',
    //        isCredit: true
    //      }
    //      updateContentGP = '/tokens/' + tokenID;
    //     })
    //        updates[updateContentGP] = data;
    //   })


    // console.log('green update parent 6 : ', QueryContent[0])
    // console.log('green update parent 7 : ', data)

    //  updates[QueryContent[0]] = data;
    // database.ref().update(updates, function (error) {
    //   if (error) {
    //     dispatch({
    //       type: 'SHOW_TOAST',
    //       variant: 'error',
    //       message: `${error}`
    //     })
    //   } else {
    //     dispatch({
    //       type: 'SHOW_TOAST',
    //       variant: 'success',
    //       message: `Thank you for joining the Executive Club!`
    //     })
    //   }
    // })
  }
  }//if snapshot exists
 //}
//}

function randomString(length) {
  let text = ''
  let possible = 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789'
  for (let i = 0; i < length; i++) {
    text = text + possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

export const resendWelcomeEmail = params => {
  return (dispatch, getState) => {
    let database = firebase.database
    let data = params.userProfile // user profile

    delete data.$key

    database.ref('/resend-welcomemail/' + data.uid).set(data)
    dispatch({
      type: 'SHOW_TOAST',
      variant: 'success',
      message: `Welcome Email resent to ${data.firstName} ${data.lastName}`
    })
  }
}

export const resendGroupWelcomeEmail = params => {
  return (dispatch, getState) => {
    let database = firebase.database
    let data = params // user profile

    data.start_date = moment(data.group_start_date).format('DD MMM YYYY')
    data.end_date = moment(data.group_end_date).format('DD MMM YYYY')

    database.ref('/resend-groupwelcomemail/' + data.group_id).set(data)
    dispatch({
      type: 'SHOW_TOAST',
      variant: 'success',
      message: `Email sent to ${data.firstName} members`
    })
  }
}

export const studyMaterialsEmail = params => {
  return (dispatch, getState) => {
    let database = firebase.database
    let data = params.data // user profile
    let selectedgroups = params.selectedgroups // user profile

    Object.keys(data).map(id => {
      let currRecord = data[id]
      database
        .ref('company_groups_users/' + currRecord.companyID + '/' + currRecord.group_id)
        .on('value', function (data) {
          let users = data.val()
          let userEmails = Object.keys(users).map(uid => {
            return [users[uid].email]
          })

          let strEmails = userEmails.join()

          currRecord.firstName = currRecord.group_name
          currRecord.lastName = currRecord.group_name
          currRecord.password = currRecord.group_name
          currRecord.email = strEmails
          currRecord.to = strEmails
          currRecord.new_update = 1
          currRecord.email_type = 'study_materials'

          // console.log("currRecord: ", currRecord)

          let currentIndex = selectedgroups.indexOf(currRecord.group_id)

          if (currentIndex >= 0) {
            database.ref('/resend-groupwelcomemail/' + currRecord.group_id).set(currRecord)
          }
        })
      // console.log("data: ", data[id]);
    })

    dispatch({
      type: 'SHOW_TOAST',
      variant: 'success',
      message: 'Email notification sent to group members'
    })
  }
}

function findAndDeleteDocument(documentPathUrl, objectId) {
  // Get a reference to the database service
  const database = firebase.database
  database.ref(`${documentPathUrl}/${objectId}`).remove()
}

function findAndDeleteToken(documentPathUrl, objectId) {
  // Get a reference to the database service
  console.log('green handleDeleteUser 4A', documentPathUrl);
  console.log('green handleDeleteUser 4B', objectId);

  console.log('green findAndDeleteToken ', objectId)

  const database = firebase.database
  database.ref(`${documentPathUrl}/${objectId}`).remove()
}

function deactivateUser(params, dispatch) {
  // console.log("params: ", params);
  let database = firebase.database
  // Update user data
  let updates = {}
  let userProfile = params.userProfile
  let disabled = userProfile.hasOwnProperty('disabled') ?
    !userProfile.disabled :
    true
  updates['/users/' + userProfile.userID + '/disabled'] = disabled
  updates['/user/' + userProfile.uid + '/disabled'] = disabled
  updates[
    'writeOperations/disableUserElastic/' +
    userProfile.uid +
    '/writeOperationsID'
  ] = userProfile.uid
  updates['writeOperations/disableUserElastic/' + userProfile.uid + '/uid'] =
    userProfile.uid
  updates[
    'writeOperations/disableUserElastic/' + userProfile.uid + '/disabled'
  ] = disabled

  database
    .ref()
    .update(updates)
    .then(() => {
      dispatch({
        type: 'SHOW_TOAST',
        variant: 'success',
        message: ` User Account Status Updated!`
      })
    })
    .catch(err => {
      dispatch({
        type: 'SHOW_TOAST',
        variant: 'error',
        message: `User cannot be deleted! ` + err.message
      })
    })
}

function getSelectedUserTypeDescription(selectedCategory) {
  let userDescription = 'Standard User'

  if (selectedCategory === 4) {
    userDescription = 'Global Contributor'
    return userDescription
  } else if (selectedCategory === 3) {
    userDescription = 'Public Contributor'
    return userDescription
  } else if (selectedCategory === 5) {
    userDescription = 'Master Contributor'
    return userDescription
  } else if (selectedCategory === 7) {
    userDescription = 'Company Admin'
    return userDescription
  } else if (selectedCategory === 8) {
    userDescription = 'Standard User'
    return userDescription
  } else if (selectedCategory === 10) {
    userDescription = 'Corporate I-Leader'
    return userDescription
  } else if (selectedCategory === 11) {
    userDescription = 'Organisation Contributor'
    return userDescription
  } else if (selectedCategory === 12) {
    userDescription = 'Delegate'
    return userDescription
  } else if (selectedCategory === 14) {
    userDescription = 'Client Admin'
    return userDescription
  }
  return userDescription
}
