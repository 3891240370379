// import firebase from 'firebase/app'
// import 'firebase/auth'
// import 'firebase/database'
// import 'firebase/storage'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/storage'

// import { getAuth } from "../firebase/auth";

// import { initializeApp } from 'firebase/app';

import { app_config } from '../../src/constants'

// fix: remove cached live server url if still persists
// localStorage.removeItem('firebase:host:leadershipplatform-158316.firebaseio.com');

// Initialize Firebase
// Test server configuration
const config = {
  test: {
    apiKey: 'AIzaSyDR_1stg_cHHG6Ikg6eful1gVkP3Ay2OYg',
    authDomain: 'glp-test-f6590.firebaseapp.com',
    databaseURL: 'https://glp-test-f6590.firebaseio.com',
    projectId: 'glp-test-f6590',
    storageBucket: 'glp-test-f6590.appspot.com',
    messagingSenderId: '809505702015',
    appId: '1:809505702015:web:838b987f342ee57bf27f4a'
  },
  live: {
    // //apiKey: "AIzaSyAcAjiyCIE1T0QOl-xF37nnFK11Jd8cq2w",
    // apiKey: 'AIzaSyC9PXWFI_v6UEt11sMsDPZEKa1Hjn4ob08',
    // authDomain: 'leadershipplatform-158316.firebaseapp.com',
    // databaseURL: 'https://leadershipplatform-158316.firebaseio.com',
    // projectId: 'leadershipplatform-158316',
    // storageBucket: 'leadershipplatform-158316.appspot.com',
    // messagingSenderId: '438289288307',
    // appId: '1:438289288307:web:685728fef1a796d46cee06'

    apiKey: "AIzaSyAcAjiyCIE1T0QOl-xF37nnFK11Jd8cq2w",
    authDomain: "leadershipplatform-158316.firebaseapp.com",
    databaseURL: "https://leadershipplatform-158316.firebaseio.com",
    projectId: "leadershipplatform-158316",
    storageBucket: "leadershipplatform-158316.appspot.com",
    messagingSenderId: "438289288307",
    appId: "1:438289288307:web:5a8fafc9aeb1164e6cee06"


     //apiKey: 'AIzaSyCmZzBTbCAEso6UjT2KxhqMwFLNviP_p24',
  //   apiKey: 'AIzaSyAcAjiyCIE1T0QOl-xF37nnFK11Jd8cq2w',
  //   //apiKey: 'AIzaSyC9PXWFI_v6UEt11sMsDPZEKa1Hjn4ob08',
  //   authDomain: 'leadershipplatform-158316.firebaseapp.com',
  //   databaseURL: 'https://leadershipplatform-158316.firebaseio.com',
  //   projectId: 'leadershipplatform-158316',
  //   storageBucket: 'leadershipplatform-158316.appspot.com',
  // //   messagingSenderId: '438289288307',
  // //  // appId: '1:438289288307:web:685728fef1a796d46cee06',
  //    appId: '1:438289288307:web:5a8fafc9aeb1164e6cee06'
  },
  // live: {
  //   apiKey: "AIzaSyAf_oJi6fgm6-ZrVPeOVdW2DDoeu3v5SxY",
  //   databaseURL: "https://leadershipplatform-158316.firebaseio.com",
  //   storageBucket: "leadershipplatform-158316.appspot.com",
  //   authDomain: "leadershipplatform-158316.firebaseapp.com",
  //   messagingSenderId: "438289288307",
  //   projectId: "leadershipplatform-158316"
  // },
  public_test: {
    apiKey: 'AIzaSyDVi2UiJi2BA1rDTHCQHVdnfvuLmpmGSAU',
    authDomain: 'thinklead-b3299.firebaseapp.com',
    databaseURL: 'https://thinklead-b3299.firebaseio.com',
    projectId: 'thinklead-b3299',
    storageBucket: 'thinklead-b3299.appspot.com',
    messagingSenderId: '595347650471',
    appId: '1:595347650471:web:89d012f77aad33e80f2882'
  }
}

firebase.initializeApp(config[app_config.DB_CONFIG])

// export const auth = firebase.auth();

// const provider = new firebase.auth.GoogleAuthProvider();
// provider.setCustomParameters({ prompt: 'select_account'});

// export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase
