/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-inline-comments */
import React from 'react'
import Grid from '@material-ui/core/Grid'
// import ReactPlayer from 'react-player'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
// import Box from '@material-ui/core/Box'
import logo from '../../assets/ThinkLead.svg'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import { withStyles, makeStyles } from '@material-ui/core/styles'
// import { spacing } from '@material-ui/system'
import { connect } from 'react-redux'
import { signInWithEmailAndPassword, signInWithGoogle, signUpNewUsers, resetUserPassword } from '../../store/actions/authActions'
// import { Route } from 'react-router-dom'
//import { useLocation } from "react-router-dom";
import {
  // getAllCompanyUsers,
  // deleteCompanyUser,
  createCompanyUser,
  // updateCompanyUser,
  // resendWelcomeEmail,
  // changeUserType,
  checkUserIs
  // getAllCompanySettings,
  // getAllGeneralSettings,
  // editUser,
  // checkUserExistsEdit
} from '../../store/actions/companyAdminActions'
import Media from 'react-media'
import ForgotPassword from './ForgotPassword'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
import { SHOW_LOADER } from '../../store/actions/types'
import { SHOW_TOAST, LOGIN_SUCCESS
} from '../../store/actions/types'
import { showToast } from '../../store/actions/toastAction'
import { hideLoader, showLoader } from '../../store/actions/loaderActions'
import WindowsDButton from './windowsdbutton'
// import Snackbar from '@material-ui/core/Snackbar'
// import Slide from '@material-ui/core/Slide'
import { APP_TYPE, SERVER_TYPE, IS_HARMONY } from '../../store/actions/types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import harmonyImg from '../../assets/harmony/LDPportal.jpg'
import {
  FormControl,
  MenuItem,
  input,
  InputLabel
} from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import Hidden from '@material-ui/core/Hidden'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import ThinkLeadLogo from '../../assets/thinkleadEcosystem.jpg'

import { THEMECOLOR } from '../../utils/color_utils'
import SvgIcon from '@material-ui/core/SvgIcon'
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
  },
  snackbar: {
    margin: theme.spacing(1)
  },
  formControl: {
    'width': '100%',
    '& label span': {
      color: 'red'
    }
  }
}))

const coverPageHeader = {
  // marginBottom: 26,
  fontFamily: 'Roboto',
  fontSize: '1.2rem'
}

const coverPageText = {
  // marginBottom: 15,
  fontFamily: 'Roboto',
  fontSize: '0.9rem'
}

const coverPagePoints = {
  color: '#BE2528'
}

// ===============
const ImgLogo = {
  marginTop: '-170px'
}

const ImgLogoSm = {
  marginTop: '-140px'
}


// ===============
const coverPageTextDiv = {
  marginTop: -180
}

const coverPageTextDivSm = {
  marginTop: -100
}

function GoogleIcon(props) {
  return (
    <SvgIcon {...props} fill='#000000' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='64px' height='64px'>
      <path d='M 16.003906 14.0625 L 16.003906 18.265625 L 21.992188 18.265625 C 21.210938 20.8125 19.082031 22.636719 16.003906 22.636719 C 12.339844 22.636719 9.367188 19.664063 9.367188 16 C 9.367188 12.335938 12.335938 9.363281 16.003906 9.363281 C 17.652344 9.363281 19.15625 9.96875 20.316406 10.964844 L 23.410156 7.867188 C 21.457031 6.085938 18.855469 5 16.003906 5 C 9.925781 5 5 9.925781 5 16 C 5 22.074219 9.925781 27 16.003906 27 C 25.238281 27 27.277344 18.363281 26.371094 14.078125 Z'/>
    </SvgIcon>
  )
}

// ===============
const LoginButton = withStyles(() => ({
  root: {
    'marginTop': 20,
    'marginRight': 5,
    'backgroundColor': '#000',
    'borderRadius': 3,
    'border': 0,
    'color': 'white',
    'height': 48,
    'padding': '0 20px',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  hover: {},
  label: {
    textTransform: 'uppercase'
  }
}))(Button)

const SignInButton = withStyles(() => ({
  root: {
    'marginTop': 20,
    'marginRight': 5,
    'backgroundColor': '#000',
    'borderRadius': 3,
    'border': 0,
    'color': 'white',
    'height': 48,
    'padding': '0 20px',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  hover: {},
  label: {
    textTransform: 'uppercase'
  }
}))(Button)

// ===============
const GoogleButton = withStyles(() => ({
  root: {
    'marginTop': 20,
    'marginLeft': 5,
    'backgroundColor': 'rgb(0, 0, 0)',
    'borderRadius': 3,
    'border': 0,
    'color': 'white',
    'height': 48,
    'padding': '0 20px',
    '&:hover': {
      backgroundColor: 'rgb(0, 0, 0)'
    }
  },
  label: {
    textTransform: 'uppercase'
  }
}))(Button)

// ===============
const styles = {
  root: {
    margin: '15px'
  },
  paper: {
    textAlign: 'center',
    color: '#efefef'
  },
  menuButton: {
    marginRight: 20
  },
  textField: {
    marginLeft: 10,
    marginRight: 10
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  button: {
    'marginTop': 20,
    'backgroundColor': THEMECOLOR,
    'borderRadius': 3,
    'border': 0,
    'color': '#000',
    'height': 48,
    'padding': '0 20px',
    '&:hover': {
      backgroundColor: THEMECOLOR
    },
    'textTransform': 'none'
  },
  createAccount: {
    'marginRight': '5px',
    'textDecoration': 'underline',
    'color': '#2980B9',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  main: {
    marginTop: '20vh'
  }
}

// ===============
class BodyContent extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
    //this.signInWithGoogle = this.signInWithGoogle.bind(this);
  }

  state = {
    username: '',
    userpassword: '',
    open: true,
    openPolicy: false,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    showPassword: false,
    agreeTCs: false,
    isHarmony: IS_HARMONY,
    selectClub: 'Me-Vision',//'-LjW7UI7e0uUikYrw-Vj',
    clubName: 'Select Your ThinkLead Executive Club',
    //clubName: 'ThinkLead Ecosystem',
    showLogin: true,
    directJoin: false
  };

  componentDidMount() {
    console.log('green signInEmail 2 : ', this.props)
    let search = window.location.search

    this.setState({
      selectClub: '', // Default value
      clubName: 'Select Your ThinkLead Executive Club' // Default displayed text
    });

    if (search) {
      console.log("propsIs 2 : ", search.split("=")[1]); 
      var appName = search.split("=")[1];
      console.log('green signInEmail x : ', appName)
      if (appName) {

      }
      else{
        appName = 'Select Your ThinkLead Executive Club' // Default displayed text
      }
      if (appName.length > 1) {
        this.setState({
          showLogin: false,
          directJoin: true,
          selectClub: appName
        });
      }
    }
  }


   signInEmailx = () => {
     // event.preventDefault();
     this.props.showLoader({ type: SHOW_LOADER })

     // Only on Live server
     if (SERVER_TYPE === 'LIVE') {
       // this.checkUserDomain(this.state.username)
     }
     this.props.signInEmail(JSON.parse(JSON.stringify(this.state)))
   };

  handlePressEnter = (event) => {
    console.log('green signInEmail 1 : ', event)
    if (event.key === 'Enter') {
      this.signInEmailx(event)
      // this.props.signInEmail(this.state);
    }
  }

  handleClose = () => {
    this.setState({
      ...this.state,
      open: false
    })
  };

  handleCloseTandC = () => {
    this.setState({
      ...this.state,
      openTandC: false
    })
  };


  handleClosePolicy = () => {
    this.setState({
      ...this.state,
      open: false,
      openPolicy: false
    })
  };

  handleViewTermsAndConditions = () => {
    this.setState({
      ...this.state,
      openTandC: true
    })
  };


  handleViewPolicy = () => {
    this.setState({
      ...this.state,
      open: false,
      openPolicy: true
    })
  };

  // ---------------
  handleChange = prop => event => {
    this.setState({
      [prop]: event.target.value
    })
  };

  handlePass = prop => event => {
    this.setState({
      password: 'DefaultPassword123*'
    })
  };

  // ---------------
  handleChecked = prop => event => {
    if (this.state.selectClub) { //person followed link given by other user
        let clubName
        switch (this.state.selectClub) {
        case '-LjW7UI7e0uUikYrw-Vj':
          clubName = 'ThinkLead Ecosystem'
          break
        case '-NRbTwxbqFLepk62Eq6M':
          clubName = 'Adriaan Groenewald'
            break  
        case '-MHRD2gH8B-4umEza1eG':
          clubName = 'ActionSA South Africa'
          break
        case '-M4LtrI17CY5H1Krhi5J':
          clubName = 'Towerstone South Africa'
          break
        case '-Lr8gaCje_jeM5RVUauP':
          clubName = 'Texas USA Accountability Cop'
          break
        case '-LoacuJOqQP7sZU7_sFQ':
          clubName = 'GLI in Canada'
          break
        case '-MZwJp46RoDtehNEGly3':
          clubName = 'Leadership Express Nigeria'
          break
        case '-MRZC7xU_69moSco5_sX':
          clubName = 'ThinkLead Power Sector'
          break
        default:
          clubName = 'Public Ecosystem'
        }
        this.setState({ clubName: clubName })
      }
    
    this.setState({
      [prop]: event.target.checked
    })
  };

  handleToggleLoginSignUp = () => {
    this.setState({ showLogin: !this.state.showLogin })
  }
  // ---------------
  signInGoogle = async () => {
   const selectC = this.state.selectClub;
    this.props.showLoader({ type: SHOW_LOADER });

    if (this.state.showLogin) { //is login page
      await this.props.signGoogle({
        selectClubb: JSON.parse(JSON.stringify(this.state))
      })
     // this.props.signGoogle(JSON.parse(JSON.stringify(this.state)))
    }
    else { //is signUp page
      let selectClubb = selectC;
      console.log('showLogin 2 : ', this.state);
      if (this.state.agreeTCs) { 
        console.log('showLogin 2a : ', selectClubb);

        // await this.props.signGoogle({
        //  selectClubb: selectClubb
        // });
        await this.props.signGoogle(selectClubb); //selectClubb;
     }
     else{
       let params = {
         type: SHOW_TOAST,
         open: true,
         variant: 'warning',
         message: 'Please agree to the ThinkLead Terms and Conditions.',
         pos: 'top'
       }
       this.props.showToast(params)
     }
    }

  };

  // ---------------
  SignUpWithEmail = e => {
    e.preventDefault()
    
   // console.log('agreeTCs : ', this.state.agreeTCs)
    if (this.state.agreeTCs) {
      this.props.SignUpWithEmail(this.state)
    // console.log('agreeTCs : ', 'SUCCESS!')
    }
    else{
      let params = {
        type: SHOW_TOAST,
        open: true,
        variant: 'warning',
        message: 'Please agree to the ThinkLead Terms and Conditions.',
        pos: 'top'
      }
      this.props.showToast(params)
    }

  };

  handleSubmitUserxx = userData => {
    console.log('green userData : ', userData)
  }

  handleSubmitUserx = e => {
    e.preventDefault()
    console.log('green userData 1')
    let params = {
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      companyID: this.state.selectClub,
      companyName: this.state.clubName,
      userType: 3,
      selectedCategory: 3,
      gender: 'x',
      points: 1,
      race: 'x',
      position: 'x',
      nationality: 'x',
      idNumber: '555',
      department: 'x',
      feedback: 0,
      feedbackOn: true,
      LeaderboardKey: this.state.selectClub + '_' + 'x' + '_' + 'x' + '_' + 'x'
    }
    this.props.checkUserIs(params, () => {
      this.props.createCompanyUser(params)
    }, () => {
      console.log('User not added')
    })
    // this.props.checkUserIs(this.state);
  };

  // ---------------
  checkUserDomain = usr => {
    let url = window.location.origin

    let corporates = [
      'edcon.co.za',
      'edcon@',
      'oneconnect.co.za',
      'oneconnect@',
      // 'blsa.org.za',
      // 'blsa@',
      // 'businessleadership.org.za',
      'harmony@',
      'harmony.thinklead.co.za',
      'harmony.co.za'
      // 'businessleadership@'
    ]

    for (let x = 0; x < corporates.length; x++) {
      let y = corporates[x]
      let patt = new RegExp(y)

      if (patt.test(usr)) {
        let corporateDomains = {
          'oneconnect.co.za': 'https://oneconnect.thinklead.co.za',
          'oneconnect@': 'https://oneconnect.thinklead.co.za',
          'edcon.co.za': 'https://edcon.thinklead.co.za',
          'edcon@': 'https://edcon.thinklead.co.za',
          // 'blsa.org.za': 'https://blsa.thinklead.co.za',
          // 'blsa@': 'https://blsa.thinklead.co.za',
          'harmony@': 'https://harmony.thinklead.co.za',
          'harmony.thinklead.co.za': 'https://harmony.thinklead.co.za',
          'harmony.co.za': 'https://harmony.thinklead.co.za'
          // 'businessleadership.org.za': 'https://blsa.thinklead.co.za',
          // 'businessleadership@': 'https://blsa.thinklead.co.za'
        }

        let companyurl = corporateDomains[y]

        if (url !== companyurl) {
          window.location.replace(companyurl)
        }
      }
    }
  };

  // ---------------
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  };

  // ---------------
  resetPassword = e => {
    e.preventDefault()

    this.props.resetPassword({
      email: this.state.email,
      actionCodeSettings: {
        url: window.location.origin
      }
    })
  };


  showVideo = () => {
    let coverPageTextMargin = window.innerWidth < 600 ? coverPageTextDivSm : coverPageTextDiv
    let ImgLogoMargin = window.innerWidth < 600 ? ImgLogoSm : ImgLogo

    return (
      <div className='player-wrapper'>
        <img src={logo} style={ImgLogoMargin} />
        <div style={coverPageTextMargin}>
          <Typography variant='h5' style={coverPageHeader}>
            Welcome to ThinkLead<sup>&reg;</sup>. One platform for all things leadership
          </Typography>
          <Typography variant='h5' style={coverPageText}>
            <span style={coverPagePoints}>We-Lead Forum - </span>a collaboration hub to connect a global leadership community
          </Typography>
          <Typography variant='h5' style={coverPageText}>
            <span style={coverPagePoints}>I-Learn Library - </span>a resource centre for leadership knowledge
          </Typography>
          <Typography variant='h5' style={coverPageText}>
            <span style={coverPagePoints}>I-Lead Toolkit - </span>a self-development area to build positive habits
          </Typography>
        </div>

      </div>
    )
  }

  showThinkLeadVideo = () => {
    return (
      <Media query='(min-width: 960px)'>
        {matches =>
          matches ? (
            this.showVideo('65vh')
          ) : (
            this.showVideo('32vh')
          )
        }
      </Media>
    )
  }

  showHarmonyImg = () => {
    return (
      <div>
        <img src={harmonyImg} style={{ width: '98%' }} />
      </div>
    )
  }
  handleClubChange = (e) => {
    let clubName
    switch (e.target.value) {
    case '-LjW7UI7e0uUikYrw-Vj':
      clubName = 'ThinkLead Ecosystem'
      break
    case '-MHRD2gH8B-4umEza1eG':
      clubName = 'ActionSA South Africa'
      break
    case '-M4LtrI17CY5H1Krhi5J':
      clubName = 'Towerstone South Africa'
      break
    case '-Lr8gaCje_jeM5RVUauP':
      clubName = 'Texas USA Accountability Cop'
      break
    case '-LoacuJOqQP7sZU7_sFQ':
      clubName = 'GLI in Canada'
      break
    case '-MZwJp46RoDtehNEGly3':
      clubName = 'Leadership Express Nigeria'
      break
    case '-MRZC7xU_69moSco5_sX':
      clubName = 'ThinkLead Power Sector'
      break
    default:
      clubName = 'Public Ecosystem'
    }

    this.setState({ selectClub: e.target.value })
    this.setState({ clubName: clubName })
  }

  handleMouseOver = (club) => {
    let clubName
    let clubProfile = ''

    switch (club) {
    case '-LjW7UI7e0uUikYrw-Vj':
      clubName = 'ThinkLead Ecosystem'
      clubProfile = 'Creating Personal Leadership for any individual leader across the globe'
      break
    case '-Mci2tVbY406VWKWa5Ov':
      clubName = 'The Book Every Leader Needs to Read'
      clubProfile = 'Pages & pages of mentorship'
      break
    case '-MHRD2gH8B-4umEza1eG':
      clubName = 'ActionSA South Africa'
      clubProfile = ''
      break
    case '-M4LtrI17CY5H1Krhi5J':
      clubName = 'Towerstone South Africa'
      clubProfile = ''
      break
    case '-Lr8gaCje_jeM5RVUauP':
      clubName = 'Texas USA Accountability Cop'
      clubProfile = ''
      break
    case '-LoacuJOqQP7sZU7_sFQ':
      clubName = 'GLI in Canada'
      clubProfile = ''
      break
    case '-MZwJp46RoDtehNEGly3':
      clubName = 'Leadership Express Nigeria'
      clubProfile = 'Creating Personal Leadership for leaders in Nigeria & beyond'
      break
    case '-MRZC7xU_69moSco5_sX':
      clubName = 'ThinkLead Power Sector'
      clubProfile = 'Creating Personal Leadership inside the Power Sector'
      break
    case '-NRbTwxbqFLepk62Eq6M':
      clubName = 'Adrian Groenewald'
      clubProfile = 'Creating Personal Leadership'
      break  
    default:
      clubName = 'Public Ecosystem'
      clubProfile = ''
    }
    let params = {
      type: SHOW_TOAST,
      open: true,
      variant: 'success',
      message: clubProfile,
      pos: 'bottom'
    }
    this.props.showToast(params)

     //this.setState({selectClub: e.target.value});
     this.setState({clubName: clubName});
  }

  render(props) {
    const {
      firstName,
      lastName,
      email,
      password,
      showPassword,
      agreeTCs,
      directJoin
    } = this.state

    const isInvalid =
      password.trim().length < 7 ||
      password === '' ||
      email === '' ||
      firstName === '' ||
      lastName === '' ||
      !agreeTCs

    const { classes, showprogress } = this.props

    if (window.location.href.includes('harmony')) {
      this.state.isHarmony = true
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth='md' >
          {/* <Typography component='div' style={{ backgroundColor: '#cfe8fc', height: '100vh' }} /> */}
          <Grid container>
            <Grid item xs={12} sm={5} md={5} className={classes.main}>
              <Hidden smDown>
                <img src='./images/thinkleadEcosystem.jpg' style={{ width: '100%', height: 'auto' }} />
              </Hidden>
            </Grid>
            {
              this.state.showLogin ?
                <Grid item xs={12} sm={5} md={5} className={classes.main}>
                  <Media queries={{ small: '(max-width: 599px)' }}>
                    {matches =>
                      matches.small ?
                        <img src={ThinkLeadLogo} style={{ width: '100%', height: 'auto' }} />
                        : null
                    }
                  </Media>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    value={this.state.username}
                    placeholder='Email'
                    fullWidth='true'
                    onChange={this.handleChange('username')}
                    style={{ backgroundColor: '#fff', borderRadius: 5 }}
                  />
                  <TextField
                    id='filled-adornment-password'
                    variant='outlined'
                    margin='dense'
                    placeholder='Password'
                    value={this.state.userpassword}
                    type={this.state.showPassword ? 'text' : 'password'}
                    autoComplete='false'
                    fullWidth='true'
                    onChange={this.handleChange('userpassword')}
                    onKeyDown={this.handlePressEnter}
                    style={{ backgroundColor: '#fff', borderRadius: 5 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='Toggle password visibility'
                            onClick={this.handleClickShowPassword}
                          >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility /> }
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Grid container direction='row'>
                    <Grid item sm={3} xl={3} xs={3} >
                      <Button
                        variant='contained'
                        onClick={this.signInEmailx}
                        className= {classes.button}
                      >
                     Login
                      </Button>
                    </Grid>
                    <Grid item sm={8} xl={8} xs={8} >
                      {
                        APP_TYPE === 'PUBLIC' ? (
                          <Button
                            variant='contained'
                            onClick={this.signInGoogle}
                            className= {classes.button}
                            startIcon={<GoogleIcon />}
                          >
                       Login with Google
                          </Button>
                        ) : (null)
                      }
                    </Grid>
                    <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
                      <Grid item sm={5} xl={5} xs={12}
                        style={{ textAlign: 'left', marginTop: '20px' }}
                      >
                        <span
                          className={classes.createAccount}
                          style={{}}
                          onClick={this.handleToggleLoginSignUp}>
                       Create Account
                        </span>
                      </Grid>
                      <Grid item sm={5} xl={5} xs={12}
                        style={{ textAlign: 'left', marginTop: '20px' }}
                      >
                        <ForgotPassword />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                :
                APP_TYPE === 'PUBLIC' ?
                  <Grid item xs={12} sm={5} md={5} className={classes.main}>
                    <Media queries={{ small: '(max-width: 599px)' }}>
                      {matches =>
                        matches.small ?
                          <img src={ThinkLeadLogo} style={{ width: '100%', height: 'auto' }} />
                          : null
                      }
                    </Media>
                    <TextField
                      variant='outlined'
                      // value={this.state.firstName}
                      placeholder='First Name'
                      fullWidth='true'
                      onChange={this.handleChange('firstName')}
                      style={{ backgroundColor: '#fff', borderRadius: 5 }}
                      value={this.state.firstName}
                      // onChange={event => this.handleChange('firstName', event)}
                      inputProps={{
                        name: 'firstName',
                        id: 'firstName'
                      }}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                    <TextField
                      variant='outlined'
                      margin='dense'
                      placeholder='Last Name'
                      // value={this.state.lastName}
                      fullWidth='true'
                      onChange={this.handleChange('lastName')}
                      style={{ backgroundColor: '#fff', borderRadius: 5 }}
                      value={this.state.lastName}
                      // onChange={e => this.handleChange(e, 'lastName')}
                      inputProps={{
                        name: 'lastName',
                        id: 'lastName'
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                    <TextField
                      variant='outlined'
                      margin='dense'
                      placeholder='Email'
                      autoComplete='false'
                      fullWidth='true'
                      onChange={this.handleChange('email')}
                      style={{ backgroundColor: '#fff', borderRadius: 5 }}
                      value={this.state.email}
                      // onChange={e => this.handleChange(e, 'email')}
                      inputProps={{
                        name: 'email',
                        id: 'email'
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                    <TextField
                      id='filled-adornment-password'
                      variant='outlined'
                      margin='dense'
                      placeholder='Password'
                      value={this.state.password}
                      type={this.state.showPassword ? 'text' : 'password'}
                      autoComplete='false'
                      fullWidth='true'
                      onChange={this.handleChange('password')}
                      style={{ backgroundColor: '#fff', borderRadius: 5 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='Toggle password visibility'
                              onClick={this.handleClickShowPassword}
                            >
                              {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {/* <form noValidate autoComplete='off'> */}
                    {directJoin ? 
                    <div />
                    :
                    // <form >
                    //   <FormControl fullWidth>
                    //   <span 
                    //   style={{ backgroundColor: '#000000', color: "#fefefe"}}
                    //   >Select Your ThinkLead Executive Club</span>
                    //     <Select
                    //       onChange={e => this.handleClubChange(e, 'select')}
                    //       style={{ backgroundColor: '#fff', color: "#fefefe", borderRadius: 5 }}
                    //       value={this.state.selectClub} 
                    //       labelId="demo-controlled-open-select-label"
                    //       id="demo-controlled-open-select"
                    //       label="Select Your ThinkLead Executive Club"
                    //     >
                    //      <MenuItem value='' disabled>
                    //         <span style={{ color: "#000000" }}>{this.state.clubName}</span>
                    //      </MenuItem>
                    //       <MenuItem value='-NRbTwxbqFLepk62Eq6M'>Me-Vision Academy</MenuItem>
                    //       {/* 
                    //         <MenuItem value={'-LjW7UI7e0uUikYrw-Vj'} onMouseOver={() => this.handleMouseOver('-LjW7UI7e0uUikYrw-Vj')} >ThinkLead Public Ecosystem</MenuItem>
                    //       <MenuItem value={'-MHRD2gH8B-4umEza1eG'} >ActionSA in South Africa</MenuItem>
                    //       <MenuItem value={'-M4LtrI17CY5H1Krhi5J'} >Towerstone South Africa</MenuItem>
                    //       <MenuItem value={'-Lr8gaCje_jeM5RVUauP'} >Texas USA Accountability Cop</MenuItem>
                    //       <MenuItem value={'-LoacuJOqQP7sZU7_sFQ'} >GLI in Canada</MenuItem>
                    //       <MenuItem value={'-MZwJp46RoDtehNEGly3'} >Leadership Express Nigeria</MenuItem>
                    //       <MenuItem value={'-MRZC7xU_69moSco5_sX'} >ThinkLead Power Sector</MenuItem> 
                    //       <MenuItem value={'-NRbTwxbqFLepk62Eq6M'}>Adriaan Groenewald</MenuItem>
                    //       <MenuItem value={'-NVZ5YC5BVUhSb-BhyX7'}>Dave Boreham</MenuItem>
                    //       <MenuItem value={'-NV-bhSOa3T8iQKaYMNZ'}>Me-Vision Academy</MenuItem>
                    //       <MenuItem value={'-NTUaz-CD0gKI2jagykx'}>Johan Bester</MenuItem>
                    //       <MenuItem value={'-LjW7UI7e0uUikYrw-Vj'}>ThinkLead Ecosystem</MenuItem>  */}
                    //     </Select>
                    //   </FormControl>
                    // </form>
                    <form>
                    <FormControl fullWidth>
                      <InputLabel id="club-select-label" style={{ color: "#000000" }}>
                        Select Your ThinkLead Executive Club
                      </InputLabel>
                      <Select
                        onChange={this.handleClubChange}
                        style={{ backgroundColor: '#fff', color: "#000000", borderRadius: 5 }}
                        value={this.state.selectClub || ''}
                        labelId="club-select-label"
                        id="club-select"
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          <span style={{ color: "#000000" }}>{this.state.clubName}</span>
                        </MenuItem>
                        <MenuItem value={'-NRbTwxbqFLepk62Eq6M'}>Me-Vision Academy</MenuItem>
                      </Select>
                    </FormControl>
                  </form>
                      }
                    <br />
                    <Checkbox
                      checked={agreeTCs}
                      value='agreeTCs'
                      color='primary'
                      onChange={this.handleChecked('agreeTCs')}
                      style={{ borderColor: '#fff', backgroundColor: '#fff' }}
                    />

                    <span
                      onClick={this.handleViewTermsAndConditions}
                      style={{ marginTop: '15px', cursor: 'pointer', padding: '3px', color: '#fff' }}>
                      I agree to the ThinkLead Terms and Conditions
                    </span>

                    <Grid container direction='row' justify='center' alignItems='center'>
                      <Grid item sm={4} xl={4} xs={4}>
                        <Button
                          variant='contained'
                          disabled={!this.state.selectClub}
                          onClick={this.SignUpWithEmail}
                          // onClick={this.handleSubmitUserx}
                          className={classes.button}
                        >
                      Sign Up
                        </Button>
                      </Grid>
                      <Grid item sm={8} xl={8} xs={8} >
                        {
                          APP_TYPE === 'PUBLIC' ? (
                            <Button
                              variant='contained'
                              disabled={!this.state.selectClub}
                              onClick={this.signInGoogle}
                              className= {classes.button}
                              startIcon={<GoogleIcon />}
                            >
                       Sign Up with Google
                            </Button>
                          ) : (null)
                        }
                      </Grid>
                    </Grid>
                    <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
                      <Grid item sm={12} xl={12} xs={12}
                        style={{ textAlign: 'left', marginTop: '20px' }}
                      >
                        <span
                          className={classes.createAccount}
                          style={{}}
                          onClick={this.handleToggleLoginSignUp}>
                       Already have an Account? Log In
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                  : null
            }

          </Grid>
          <Dialog
            open={this.state.openTandC}
            onClose={this.handleCloseTandC}
            aria-labelledby='scroll-dialog-title'>
            <DialogTitle id='scroll-dialog-title'>Terms and Conditions</DialogTitle>
            <DialogContent dividers={this.state.scroll === 'paper'}>
            <div style={{ display: 'block' }}>

            <h2>1. Me-Vision Academy Ecosystem (MVAE) Conditions</h2>
<p>MVAE is an Exclusive place for current and future Successful Leaders who have bought into the vision of transforming leadership standards globally by leading thousands – eventually millions - towards their Best Selves; by transforming leadership standards – leading self, others, organisation, society - on all levels, in all sectors and in all countries. This exclusive community wants to change the current negative trajectory of leadership standards, emotional and financial (economic) wellbeing. </p>
<p>As is the case on other platforms, on MVAE you may LEAD with a message of any kind - informative, inspirational, announcements, news, breaking news, anything, as you would on other platforms. However, inside MVAE we truly realise that every LEAD is influential to a lesser or larger degree, impacting society in some way, collectively and individually. Therefore, on MVAE we appeal to our Best Self, to THINK before we LEAD, because every LEAD impacts how we THINK.</p>
<p>People join MVAE and…</p>
    <ul>
      <li>LEAD on whatever subject matter they choose - to make their voice heard;</li>
      <li>LEAD with news or a breaking news story;</li>
      <li>LEAD with anything interesting;</li>
      <li>LEAD to express their best self - through knowledge, wisdom and experience;</li>
      <li>LEAD to positively impact the world around them;</li>
      <li>LEAD to grow and develop as a human being and successful leader;</li>
      <li>LEAD to be rewarded with Lc (LEAD currency) for every LEAD, resulting in future benefits and more influence as a successful leader;
</li>
      <li>LEAD to grow their personal or leadership brand, resulting in “leads” for their business, service or cause.</li>
      <li>And so much more now and in the future…</li>
      </ul>

<p>MVAE is also built to become a global community of Successful Leaders who believe the globe is in desperate need of greater, more mature and authentic leadership. Members inside this ecosystem are honoured to be involved and believe they are always learning from one another, contributing in a positive manner and striving to make the world a better place for future generations.</p> 
<h3>MVAE:</h3>
<p>Is a digital place where participants earn LEAD currency (Lc) as they participate. 
  Leaders join and when they - or someone on their behalf - pledge a monthly amount in dollars, 
  the platform converts that amount into LEAD. At this stage 1xLc = $1. 
  A portion of the converted Lc is given back to patrons, MVAE to manage costs and a 
  communal fund from which participants earn LEAD for most of their activities inside the ecosystem. 
  MVA management reserves the right to tweak and manage the ratio of how dollars converted into 
  LEAD are distributed. All of us acknowledge that this is new and pioneering. Hence many changes will 
  occur. In essence we are all grateful that we are managing to replace a simple points system 
  - some gamification - with earning LEAD instead. In short the ecosystem is aiming to grow a 
  wealth of leadership and leaders, while attempting to grow leadership wealth. We acknowledge 
  that whenever LEAD moves inside this platform, leadership has improved, we have added to the 
  improvement of a leader or the collective community. </p>
  <ul>
<li>Members of this community acknowledge that because they are earning LEAD through participation, they will at some future date be able to extract value from it outside MVAE - that is the goal. However, at this stage we are in phase one of our currency evolution. During this phase a participant is able to measure the value of their LEAD generation in dollars. In stage two, when we start building the LEAD value outside of MVAE, participants will be able to cash in.  </li>

<li>Members also commit to refrain from flooding the system with “earning activities”, simply to earn and not with the authentic purpose of developing themselves and fellow members or MVAE. You agree that the MVAE team may audit your earning actions to ascertain whether it makes sense and are reasonably done with the right intentions.</li>

<li>Successful Leaders - award recipients - inside the ecosystem acknowledge that they can only earn LEAD when they are investors, in other words they themselves have invested by pledging a monthly amount for the support of our vision and currency system. MVAE team withholds the right to decide when there is sufficient currency in the ecosystem to sustain bringing future leaders or leaders with potential from under resourced areas on board to participate, grow and earn, without pledging.  </li>

</ul>
<p>Is a magnet / growing library for all things leadership – attracting all leadership content, conversation and inspiration – available on your phone, tablet and laptop.</p>
<p>Is an Exclusive Space that displays openly on LeaderTrack a Global Leader Board ranked according to earned LEAD and feedback ratings, globally, per sector, country and positional level. Leaders inside MVAE agree to being displayed on the Leader Board, seen by all MVAE members. The world needs to know where to find quality Leaders and Influencers, per sector, country and level. Leaders have the option of switching their visible leadership ratings on and off.</p>
<p>Is an Exclusive Ecosystem that is built to potentially pioneer the “professionalisation” of leadership with Lc for participation in the platform and leadership improvement activities. Lc not only equates to earning while participating but can also be compared with CPD (Continuous Professional Development) Points that professionals in other professions need to earn in order to remain relevant and active in their professions. 
  Participants understand that they are at all times compiling a portfolio of evidence of all participation and Lc earned, which can be printed and shared with anyone relevant.</p>
<p>Is a productive communication platform for leaders of organisations and society at large. We recognise that effective communication, real time, is one of the greatest challenges of leadership.</p>
<p>Is a web based digital platform for society, organisations and individuals.</p>
<p>Is a web based digital platform for all content within the community guidelines and all things leadership.</p>

<p>A Platform where members may follow and unfollow any member, thereby receiving or cancelling notifications of their posts.</p>
<p>Is a platform for organisations to empower leaders with leadership content, real-time feedback, real-time analysis, real-time personal continuous development and clearer, real time, productive communication from Top Leaders.</p>
<p>Members agree that anyone may at any time click on their profile picture and rate their leadership, including the sharing of qualitative feedback on competencies decided by their organisation or these four universal competencies - leading self, leading others, leading organisation (non-people issues) and leading society (outside the workplace).</p>
<h3>MVAE Co-Owners:</h3>
<p>MVAE is also working on the opportunity for members to open their own MVAE to help mobilise Successful Leaders across the world in order to transform leadership standards on all levels - including technical, functional level leadership - in all sectors and all countries. They will recruit other Partners and Successful Leaders, for which they are rewarded financially by way of residual commission for as long as their recruits remain paying members of MVAE. More will be shared as this opportunity evolves</p>
<p>You might not like everything you see, read, listen to or watch inside MVAE. If you think content is inappropriate – based on the terms of this agreement - use the feedback function to report it for review by our MVAE team. They carefully review content on a day to day basis to determine whether there’s a violation of our Community Guidelines.</p>
<p>Again, MVAE is a global community of responsible Successful Leaders where members LEAD with any subject matter of their choice. MVAE is also a home for all leadership content, empowerment, the successful leading of organisations across the globe, showcasing great leadership, as well as successful leadership of self on all levels from specialists and experts to CEO’s of organisations, even Presidents of countries. The following is not allowed:</p>
<ul>
      <li><b>Nudity or sexual content: </b>Pornography or sexually explicit content is not acceptable. If this describes your contribution in any way, even if it's a video of yourself, don't share it on MVAE. Sexually explicit content like pornography is not allowed. Videos containing fetish content will be removed. In most cases, violent, graphic, or humiliating fetishes will not be shown on MVAE.</li>
      <li><b>Hateful content:</b> We do not accept content that intends to incite violence or encourage dangerous or illegal activities that have an inherent risk of serious physical harm or death. All content that incites others to commit acts of violence is strictly prohibited on MVAE. If your contribution in any way asks others to commit an act of violence or threatens people with serious acts of violence, it will be removed from the platform.</li>
      <li><b>Privacy: </b>If someone has posted your personal information or posted your content without your consent, you can request removal of this content. However, kindly request the removal of the content from the uploader directly first and if that doesn’t work out contact us.</li>
      <li><b>Threats: </b>Things like predatory behaviour, stalking, threats, harassment, intimidation, invading privacy, revealing other people's personal information, attempting to destroy someone’s reputation and inciting others to commit violent acts or to violate the Terms of Use are not acceptable. Anyone caught doing these things may be permanently banned from MVAE.</li>
      <li><b>Vulgar Language:</b> We don’t subscribe to this. Use of sexually explicit language or excessive profanity in your posts or associated metadata may lead to it being deleted without notification.</li>
      </ul>
      <p>Despite the above, you understand and acknowledge that in using the platform, the odd slip up may occur and you therefore stand the risk of being exposed to content that is factually inaccurate, offensive, indecent, or otherwise objectionable to you. You agree to waive, and hereby do waive, any legal or equitable rights or remedies you have or may have against MVAE with respect to any such Content.</p>
<h2>2. Your personal relationship with MVAE</h2>
<ul>
      <li>Your use of MVAE and any MVAE products, channels, software, data feeds and services, is subject to the terms of a legal agreement between you and MVAE. "MVAE" means Me-Vision Academy Ecosystem (a service by ThinkLead Pty Ltd), whose principal place of business is based at 2932 Korhaan Str, Marloth Park, Mpumalanga, South Africa.</li>
      <li>The Terms form a legally binding agreement between you and MVAE in relation to your use of the Service. It is important that you take the time to read them carefully.</li>
      <li>The Terms apply to all members of the Service, including members who are also contributors of Content on the Service. "Content" includes the text, articles, podcasts, audios, videos, ebooks, graphics, photos, sounds, webinars, music, audio-visual combinations, interactive features, leadership and life courses and other materials you may view on, access through or contribute to the Service.</li>
      </ul>
<h2>3. Accepting the Terms</h2>
<ul>
      <li>In order to use the Service, you must firstly agree to the Terms. You may not use the Service if you do not accept the Terms.</li>
      <li>You can accept the Terms by simply using the Service. You understand and agree that MVAE will treat your use of the Service as acceptance of the Terms from that point onwards.</li>
      <li>You may not use the Service and may not accept the Terms if:<ol>
           <li>you are not of legal age to form a binding contract with MVAE, or</li>
           <li>you are a person who is either barred or otherwise legally prohibited from receiving or using the Service under the laws of the country in which you are resident or from which you access or use the Service.</li>
           </ol></li></ul>
<h2>4. Changes to the Terms</h2>
<ul>
      <li>MVAE reserves the right to make changes on the Terms from time to time, for example to address changes to the law or regulatory changes or changes to functionality offered through the Service. Therefore you are required to look at the Terms regularly to check for such changes. The modified version of the Terms (the "Modified Terms") will be made available within the Service. If you do not agree to the Modified Terms you must stop using the Service. Your continued use of the Service after the date the Modified Terms are posted will constitute your acceptance of the Modified Terms.</li>
      </ul>
      <h2>5. MVAE Accounts</h2>
<ul>
      <li>In order to access some features of the Ecosystem or other elements of the Service, you will have to create a MVAE account. When creating your account, you must provide accurate and complete information. It is important that you must keep your MVAE account password secure and confidential.</li>
      <li>You must notify MVAE immediately of any breach of security or unauthorised use of your MVAE account as soon as you become aware of such breach or unauthorised use.</li>
      <li>You agree that you will be solely responsible (to MVAE, and to others) for all activity that occurs under your MVAE account.</li>
      <li>MVAE hereby grants you permission to access and use the Service, subject to the following express conditions, and you agree that your failure to adhere to any of these conditions shall constitute a breach of these Terms on your part:
      <ol>
           <li>you agree not to distribute any part of or parts of the platform or the Service, including but not limited to any Content, in any medium without MVAE 's prior written authorisation, unless MVAE makes available the means for such distribution through functionality offered by the Service;</li>
           <li>you agree not to alter or modify any part of the Platform or any of the Service;</li>
           <li>you agree not to (or attempt to) circumvent, disable or otherwise interfere with any security related features of the Service or features that (i) prevent or restrict use or copying of Content or (ii) enforce limitations on use of the Service or the content accessible via the Service;</li>
           <li>you agree not to collect or harvest any personal data of any user of the Platform or any Service (and agree that this shall be deemed to include MVAE account names);</li>
           <li>you agree not to use the Platform or the Services (including the comments and email features in the Platform) for the solicitation of business in the course of trade or in connection with a commercial enterprise;</li>
           <li>you agree not to solicit, for commercial purposes, any members of the Platform with respect to their Content;</li>
           <li>you shall not copy, reproduce, distribute, transmit, broadcast, display, sell, license, or otherwise exploit any Content for any other purposes without the prior written consent of MVAE or the respective licensors of the Content.</li>
           </ol>
      </li>
      <li>MVAE is constantly innovating in order to provide the best possible experience for its members. You acknowledge and agree that the form and nature of the Service which MVAE provides may change from time to time without prior notice to you.</li>
      <li>As part of this continuing innovation, you acknowledge and agree that MVAE may stop (permanently or temporarily) providing the Service (or any features within the Service) to you or to members generally at MVAE 's sole discretion, without prior notice to you. You may stop using the Service at any time, when your payment terms have expired, following which you do not need to specifically inform MVAE when you stop using the Service.</li>
      </ul>
      <h2>6. Links from MVAE</h2>
      <ul>
      <li>The Service may include hyperlinks to other web sites that are not owned or controlled by MVAE. MVAE has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party Apps.</li>
      <li>You acknowledge and agree that MVAE is not responsible for the availability of any such external sites or resources, and does not endorse any advertising, products or other materials on or available from such web sites or resources.</li>
      <li>You acknowledge and agree that MVAE is not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, products or other materials on, or available from, such web sites or resources.</li>
      <li>MVAE encourages you to be aware when you leave the Service and to read the terms and conditions and privacy policy of each other Platform that you visit.</li>
      </ul>
<h2>7. Ending your relationship with MVAE</h2>
<p>The Terms will continue to apply until terminated by either you or MVAE as set out below.</p>
<ul>
      <li>If you want to terminate your legal agreement with MVAE, you may do so by (a) notifying MVAE at any time (b) closing your MVAE account (c) not renewing your monthly membership fee. Your notice could be sent, in writing, to MVAE’s address info@mevisionacademy.com. We will then delete all your information from the database.</li>
      <li>MVAE may at any time terminate its legal agreement with you if:
      <ol>
           <li>you have breached any provision of the Terms (or have acted in manner which clearly shows that you do not intend to, or are unable to comply with the provisions of the Terms); or</li>
           <li>MVAE is required to do so by law (for example, where the provision of the Service to you is, or becomes, unlawful); or
           </li></ol>
           </li>
      <li>MVAE may terminate its legal agreement with you if:
      <ol>
           <li>MVAE is transitioning to no longer providing the Service to members in the country in which you are resident or from which you use the Service; or</li>
           <li>The provision of the Service to you by MVAE is, in MVAE 's opinion, no longer commercially viable, and in the case of each of the above, where possible, give reasonable notice of such termination.</li>
           <li>You are not a paying member and have been inactive inside the Club for longer than 6 months. 
           </li></ol></li>
      <li>When these Terms come to an end, all of the legal rights, obligations and liabilities that you and MVAE have benefited from, been subject to (or which have accrued over time whilst the Terms have been in force) or which are expressed to continue indefinitely, shall be unaffected by this cessation.</li>
      </ul>              
      <h2>8. Exclusion of Warranties</h2>
                                   <ul>
      <li>The Service is provided "as is" and MVAE makes no warranty or representation to you with respect to it.</li>
      <li>In particular MVAE does not represent or warrant to you that:
         <ol>
           <li>your use of the Service will meet your requirements;</li>
           <li>your use of the Service will be uninterrupted, timely, secure or free from error;</li>
           <li>any information obtained by you as a result of your use of the Service will be accurate or reliable; and</li>
           <li>that defects in the operation or functionality of any software provided to you as part of the Service will be corrected.
           </li></ol></li></ul>
<h2>9. Limitation of Liability</h2>
<h3>MVAE shall not be liable to you for:</h3>
<ol>
           <li>any indirect or consequential losses which may be incurred by you. This shall include any loss of profit (whether incurred directly or indirectly), any loss of goodwill or business reputation, or any loss of data suffered by you;</li>
           <li>any loss or damage which may be incurred by you as a result of:
            <ol>
               <li>any reliance placed by you on the completeness, accuracy or existence of any advertising, or as a result of any relationship or transaction between you and any advertiser or sponsor whose advertising appears on the Service;</li>
               <li>any changes which MVAE may make to the Service, or for any permanent or temporary cessation in the provision of the Service (or any features within the Service);</li>
               <li>the deletion of, corruption of, or failure to store, any Content and other communications data maintained or transmitted by or through your use of the Service;</li>
               <li>your failure to provide MVAE with accurate account information; or</li>
               <li>your failure to keep your password or MVAE account details secure and confidential.
               </li></ol></li></ol>
<h2>10. General Legal Terms</h2>
<ul>
      <li>The Terms constitute the whole legal agreement between you and MVAE and govern your use of the Service and completely replace any prior agreements between you and MVAE in relation to the Service.</li>
      <li>You agree that MVAE may provide you with notices, including those regarding changes to functionality and the Terms, by email, regular mail, or postings on the Service.</li>
      <li>You agree that if MVAE does not exercise or enforce any legal right or remedy which is contained in the Terms (or which MVAE has the benefit of under any applicable law), this will not be taken to be a formal waiver of MVAE’s rights and that those rights or remedies will still be available to MVAE.</li>
      <li>If any court of law, having the jurisdiction to decide on this matter, rules that any provision of these Terms is invalid, then that provision will be removed from the Terms without affecting the rest of the Terms. The remaining provisions of the Terms will continue to be valid and enforceable.</li>
      </ul>
<h2>11. Global Contributors for MVAE – inside and outside organisations</h2>
<ol>
       <li>If you are an Organisation you acknowledge that MVAE can also function as a leadership communications tool. For members / followers / employees to receive messages from their Top Leader/s or other Contributors they must be a follower. Your organisation therefore acknowledges that MVAE has built a system that allows for all members inside your organisation to automatically follow Global Contributors (ex CEO) of the organisation, so that they receive notifications of any posts. Members or followers may choose to unfollow any Contributor.</li>
       <li>As a MVAE Contributor you may submit Content to the Service, including videos, audios, articles, voicemails, pictures and thoughts. You understand that MVAE does not guarantee any confidentiality with respect to any Content submitted.</li>
       <li>As a Global Contributor you may choose to submit any content either internally (for organisational use and viewing only) or globally (for any members worldwide to use and view).</li>
       <li>As Global Contributor you are solely responsible for your own Content published internally (in organisations) and your messages and Content published globally. As a Global Contributor you affirm, represent, and warrant that you own or have the necessary licenses, rights, consents, and permissions to publish Content you submit; and you license to MVAE all patent, trademark, trade secret, copyright or other proprietary rights in and to such Content for global publication on the Service pursuant to these Terms of Service.</li>
       <li>For clarity, you retain all of your ownership rights of your organisations internal Content.
         However, as Global Contributor as you submit Content to MVAE for global consumption, 
         you hereby grant MVAE a worldwide, non-exclusive, royalty-free, sublicensable and 
         transferable license to use, reproduce, distribute, prepare derivative works of, display, 
         and perform the Content in connection with the Service and MVAE 's (and its successors' and 
         affiliates') business, including without limitation for promoting and redistributing part or 
         all of the Service (and derivative works thereof) in any media formats and through any media 
         channels. You also hereby grant each user of the Service a non-exclusive license to access 
         your Content through the Service, and to use, reproduce, distribute, display and perform 
         such Content as permitted through the functionality of the Service and under these 
         Terms of Service. The above licenses granted by you in all Content you submit globally to 
         the Service terminate within a commercially reasonable time after you remove or delete your 
         content from the Service. You understand and agree, however, that MVAE may retain, but not 
         display, distribute, or perform, server copies of your content that have been removed or 
         deleted. The above licenses granted by you in user comments you submit are perpetual and 
         irrevocable.</li>   
         <li>Content you as a Global Contributor submit globally to the Service should not contain third party copyrighted material, or material that is subject to other third party proprietary rights, unless you have permission from the rightful owner of the material or you are otherwise legally entitled to post the material and to grant MVAE all of the license rights granted herein.</li>
       <li>MVAE does not endorse any Content submitted to the Service by any user or other licensor, or any opinion, recommendation, or advice expressed therein, and MVAE expressly disclaims any and all liability in connection with Content. MVAE does not permit copyright infringing activities and infringement of intellectual property rights on the Service, and MVAE will remove all Content if properly notified that such Content infringes on another's intellectual property rights. MVAE reserves the right to remove Content without prior notice.</li>
       <li>As Global or other Contributor you understand and agree that you are solely responsible for your Content and the consequences of posting or publishing it. MVAE does not endorse any Content or any opinion, recommendation, or advice expressed therein, and MVAE expressly disclaims any and all liability in connection with Content.</li>
       </ol>


              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseTandC} color='primary'>
            Close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.openPolicy}
            onClose={this.handleClosePolicy}
            aria-labelledby='scroll-dialog-title'
          >
            <DialogTitle id='scroll-dialog-title'>Cookie Policy</DialogTitle>
            <DialogContent dividers={this.state.scroll === 'paper'}>
              <DialogContentText>
                <p>This website uses cookies to improve the user experience and ensure that it is functioning effectively, and on occasion also to provide marketing information or advertise online, either directly or through third party vendors.</p>

                <p>This Cookie Policy is part of 'ThinkLead' and/or its affiliates (hereafter referred as "ThinkLead", or "we") Privacy Policy. For more information about ThinkLead, and how it protects your information, please see the Privacy Policy.</p>

                <p>To provide you with a more personalized and responsive service, ThinkLead needs to remember and store information about how you use this website. This is done using small text files called cookies. Cookies contain small amounts of information and are downloaded to your computer or other device by a server for this website. Your web browser then sends these cookies back to this website on each subsequent visit so that it can recognize you and remember things like your user preferences. You can find more detailed information about cookies and how they work at https://www.aboutcookies.org.</p>

                <p>Whenever you use this website, information may be collected using cookies and other technologies. By using this website you agree to ThinkLead's use of cookies as described in this Cookie Policy and also to the use of cookies on the other country, regional or practice specific websites contained in ThinkLead.app that you may visit, as described in their accompanying cookie policy.</p>

                <p>What cookies does ThinkLead use and why?</p>

                <p>Following cookies may be used at ThinkLead:</p>

                <ul>
                  <li>Essential cookies</li>
                  <li>Performance cookies</li>
                  <li>Functionality cookies</li>
                  <li>Behaviorally Targeted Advertising cookies</li>
                  <li>Compliance, Security Authentication Cookies</li>
                  <li>Notification Cookies</li>
                </ul>

                <p>ThinkLead or its service providers also use analytic services to understand how effective ThinkLead's content is, what interests ThinkLead's users have, and to improve how this website works. In addition, ThinkLead uses web beacons, or tracking pixels to count visitor numbers and performance cookies to track how many individual users access this website and how often. This information is used for statistical purposes only and it is not ThinkLead's intention to use such information to personally identify any user. However, if you have registered and signed into this website, ThinkLead may combine this information with information from its web analytic services and cookies to analyze how you use this website in more detail.</p>

                <p>Please contact ThinkLead, through any of the means highlighted in our Privacy Policy, if you would like more detailed information on the cookies it uses.</p>

                <h3>How to Control Cookies?</h3>
                <p>By using this website, you agree that ThinkLead can place cookies on your computer or device as explained above. However, you can control and manage cookies in various ways. Please bear in mind that removing or blocking cookies can impact user experience and parts of this website may no longer be fully accessible.</p>

                <h3>Social Buttons and/or External Web Services</h3>
                <p>The website contains 'social buttons' that enable users to share or bookmark web pages. These are buttons for third party social media sites and these sites may log information about your activities on the Internet including on this website. ThinkLead sometimes also uses external web services on this website to display content within the web pages of this website, for example to display images, show videos or run polls. As with the social buttons, ThinkLead cannot prevent these sites, or external domains, from collecting information on your use of this embedded content. Please review the respective terms of use and privacy policies/statements/notices of these sites, or external domains to understand exactly how they use your information and to find out how to opt-out, or delete, such information.</p>

                <h3>Amendment to the policy</h3>
                <p>Further, ThinkLead may modify or amend this Cookie Policy from time to time at its discretion. When ThinkLead makes change to this policy, it will amend the revision date at the top of this page, and such modified or amended Cookie Policy shall be effective to you and your information as of that revision date. ThinkLead encourages you to periodically review this Cookie Policy to be informed about how it is using cookies.</p>

                <p>Most browsers will allow you to see what cookies you have and delete them on an individual basis or block cookies from particular or all websites. Be aware that any preference you have set will be lost if you delete all cookies, including your preference to opt-out from cookies as this itself requires an opt-out cookie to have been set. For more information on how to modify your browser settings to block or filter cookies, see https://www.aboutcookies.org or http://www.cookiecentral.com/faq</p>

                <p>You can opt-out of having your anonymized browsing activity within websites recorded by analytics cookies. ThinkLead use the following service providers and you can learn more about their privacy policies and how to opt-out of their cookies by clicking on following links:
               Google Analytics: http://www.google.com/analytics/learn/privacy.html</p>

                <p>A local shared object is like most other browser cookies except that it can store additional types of information. These cookies cannot be controlled using the mechanisms listed above. Some parts of this website may use these types of cookies to store user preferences for media player functionality and without them some video content may not play properly. </p>

                <h3>Notification Cookies</h3>
                <p>These cookies are used to improve your use of the site by allowing notices of information and options. For example, ThinkLead may use the cookie to allow us to stop showing you the signup notification if you have already seen it.</p>

                <h3>Compliance, Security Authentication Cookies</h3>
                <p>These cookies are used to prevent fraud, protect your data from unauthorized parties and to comply with legal requirements. For example, these cookies will be used to determine if you are logged in.</p>

                <h3>Compliance, Security Authentication Cookies</h3>
                <p>These cookies are used to prevent fraud, protect your data from unauthorized parties and to comply with legal requirements. For example, these cookies will be used to determine if you are logged in.</p>

                <h3>Functionality cookies</h3>
                <p>We use functionality cookies to allow us to remember your preferences. For example, cookies recall your customization preferences, such as which regional edition of the website you want to see when you log in.</p>

                <h3>Performance cookies</h3>
                <p>We utilize other cookies to analyze how our visitors use our websites and to monitor website performance. This allows us to provide a high quality experience by customizing our offering and quickly identifying and fixing any issues that arise. For example, we might use performance cookies to keep track of which pages are most popular, which method of linking between pages is most effective, and to determine why some pages are receiving error messages. We might also use these cookies to highlight articles or site services that we think will be of interest to you based on your usage of the website.</p>

                <h3>Essential cookies</h3>
                <p>Some cookies are essential for the operation of our website. For example, some cookies allow us to identify subscribers and ensure they can access the subscription only pages. If a subscriber opts to disable these cookies, the user will not be able to access all of the content that a subscription entitles them to.</p>

                <p>ThinkLead and/or its affiliates ("ThinkLead," the "Company," "we," "us," and "our,") respect your privacy and is committed to protecting it through its compliance with its privacy policies. This policy describes:</p>

                <p>the types of information that ThinkLead may collect from you when you access or use its websites, applications and other online services (collectively, referred as "Services"); and
              its practices for collecting, using, maintaining, protecting and disclosing that information.</p>

                <p>This policy applies only to the information ThinkLead collects through its Services, in email, text and other electronic communications sent through or in connection with its Services.</p>

                <p>This policy DOES NOT apply to information that you provide to, or that is collected by, any third-party, such as restaurants at which you make reservations and/or pay through ThinkLead's Services and social networks that you use in connection with its Services. ThinkLead encourages you to consult directly with such third-parties about their privacy practices</p>.

                <p>Please read this policy carefully to understand ThinkLead's policies and practices regarding your information and how ThinkLead will treat it. By accessing or using its Services and/or registering for an account with ThinkLead, you agree to this privacy policy and you are consenting to ThinkLead's collection, use, disclosure, retention, and protection of your personal information as described here. If you do not provide the information ThinkLead requires, ThinkLead may not be able to provide all of its Services to you.</p>

                <p>If you reside in a country within the European Union/European Economic Area (EAA), United States of America, any other part of the world, ThinkLead will be the controller of your personal data provided to, or collected by or for, or processed in connection with our Services;</p>

                <p>Your data controller is responsible for the collection, use, disclosure, retention, and protection of your personal information in accordance with its privacy standards as well as any applicable national laws. Your data controller may transfer data to other members of ThinkLead as described in this Privacy Policy. ThinkLead may process and retain your personal information and/or on the servers of its third parties (in or outside India), having contractual relationships with ThinkLead.</p>

                <p>This policy may change from time to time, your continued use of ThinkLead's Services after it makes any change is deemed to be acceptance of those changes, so please check the policy periodically for updates.</p>

                <h3>EU General Data Protection Regulation (GDPR) has provided the below rights to the EU residents:</h3>

                <ul>
                  <li>Right to information - including contact details to the DPO, the purposes for processing Personal Information and the rights of the individual.</li>
                  <li>Right to access the Personal Information that are processed</li>
                  <li>Right to erasure (”Right to be forgotten”)</li>
                  <li>Right to rectification</li>
                  <li>Right to restriction of processing</li>
                  <li>Right to data portability of the Personal Information supplied to ThinkLead by the EU resident</li>
                  <li>Right to object (opt-out) to processing (unless ThinkLead otherwise has compelling legitimate grounds)</li>
                </ul>

                <p>EU residents can exercise these rights by raising a request directly at support@thinklead.co.za or contact us at the information provided in CONTACT US section.
                </p>

                <h3>Contact us</h3>
                <p>If you have any queries relating to the processing/ usage of information provided by you or ThinkLead's Privacy Policy, you may email the Data Protection Officer (DPO) at support@thinklead.co.za or write to us at the following address.</p>


                <h3>The information we collect and how we use it</h3>
                <p>ThinkLead ("ThinkLead," the "Company," "we," "us," and "our”) collects several types of information from and about users of our Services, including:</p>

                <p>Your Personal Information("PI") - Personal Information is the information that can be associated with a specific person and could be used to identify that specific person whether from that data, or from the data and other information that we have, or is likely to have access to. We do not consider personal information to include information that has been made anonymous or aggregated so that it can no longer be used to identify a specific person, whether in combination with other information or otherwise.
            Information about your internet connection, the equipment you use to access our Services and your usage details.</p>

                <h3>We collect this information:</h3>
                <p>directly from you when you provide it to us; and/or
              automatically as you navigate through our Services (information collected automatically may include usage details, IP addresses and information collected through cookies, web beacons and other tracking technologies).</p>

                <h3>Information You Provide to Us</h3>
                <p>The information we collect on or through our Services may include:</p>

                <p>Your account information: Your full name, email address, postal code, password and other information you may provide with your account, such as your gender, mobile phone number and website. Your profile picture that will be publicly displayed as part of your account profile. You may optionally provide us with this information through third-party sign-in services such as Facebook and Google Plus. In such cases, we fetch and store whatever information is made available to us by you through these sign-in services.
            Your preferences: Your preferences and settings such as time zone and language.
            Your content: Information you provide through our Services, including your reviews, photographs, comments, lists, followers, the users you follow, current and prior restaurant reservation details, food ordering details and history, favorite restaurants, special restaurant requests, contact information of people you add to, or notify of, your restaurant reservations through our Services, names, and other information you provide on our Services, and other information in your account profile.
            Your searches and other activities: The search terms you have looked up and results you selected.
            Your browsing information: How long you used our Services and which features you used; the ads you clicked on.
            Your communications: Communications between you and other users or merchants through our Services; your participation in a survey, poll, sweepstakes, contest or promotion scheme; your request for certain features (e.g., newsletters, updates or other products); your communication with us about employment opportunities posted to the services.
            Your transactional information: If you make reservations or purchases through our Services, we may collect and store information about you to process your requests and automatically complete forms for future transactions, including (but not limited to) your phone number, address, email, billing information and credit or payment card information. This information may be shared with third-parties which assist in processing and fulfilling your requests, including PCI compliant payment gateway processors. When you submit credit or payment card information, we encrypt the information using industry standard technologies, as further described below under "Payment Card Information." If you write reviews about businesses with which you conduct transactions through our Services, we may publicly display information that you transacted with those businesses.
            Your Public Posts: You also may provide information (such as ratings, reviews, tips, photos, comments, likes, bookmarks, friends, lists, etc.) to be published or displayed (hereinafter, "posted") on publicly accessible areas of our Services, or transmitted to other users of our Services or third-parties (collectively, "User Contributions"). Your User Contributions are posted on and transmitted to others at your own risk. Although we limit access to certain pages, you may set certain privacy settings for such information by logging into your account profile. Please be aware that no security measures are perfect or impenetrable (see "Security" section below). Additionally, we cannot control the actions of other users of our Services with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons. We may display this information on the Services, share it with businesses, and further distribute it to a wider audience through third party sites and services. You should be careful about revealing any sensitive details about yourself in such postings.
             We use the information you provide to us to enhance the functionality and improve the quality of our Services, and to personalize your experience while using our Services. We also use this information to display relevant advertising, provide support to you, communicate with you, and comply with our legal obligations.</p>

                <h3>Information about Your Messages</h3>
                <p>If you exchange messages with others through the Services, we may store them in order to process and deliver them, allow you to manage them, and investigate possible violations of our Terms of Service and wrongdoing in connection with the Services. If you send information from the Services to your mobile device via SMS text message, we may log your phone number, phone carrier, and the date and time that the message was processed. Carriers may charge recipients for texts that they receive</p>.

                <h3>Information We Collect Through Automatic Data Collection Technologies</h3>
                <p>We may automatically collect certain information about the computer or devices (including mobile devices) you use to access the Services, and about your use of the Services, even if you use the Services without registering or logging in.
            Usage information: Details of your use of our Services, including traffic data, location data, logs and other communication data and the resources that you access and use on or through our Services.
            Computer and device information: Information about your computer, Internet connection and mobile device, including your IP address, operating systems, platforms, browser type, other browsing information (connection, speed, connection type etc.), device type, device's unique device identifier, mobile network information and the device's telephone number.
            Stored information and files: Our applications also may access metadata and other information associated with other files stored on your mobile device. This may include, for example, photographs, audio and video clips, personal contacts and address book information.
            Location information: Our applications collect real-time information about the location of your device, as permitted by you.
            Last URL visited: The URL of the last web page you visited before visiting our websites.
            Mobile device IDs: Unique mobile device identifier (e.g. IDFA or other device IDs on Apple devices like the iPhone and iPad), if you're using our Services on a mobile device, we may use mobile device IDs (the unique identifier assigned to a device by the manufacturer), instead of cookies, to recognize you. We may do this to store your preferences and track your use of our applications. Unlike cookies, mobile device IDs cannot be deleted. Advertising companies may use device IDs to track your use of our applications, track the number of advertisements displayed, measure advertising performance and display advertisements that are more relevant to you. Analytics companies may use mobile device IDs to track your usage of our applications.
            Your preferences: Your preferences and settings such as time zone and language.
            Your activity on the Services: Information about your activity on the Services, such as your search queries, comments, domain names, search results selected, number of clicks, pages viewed and the order of those pages, how long you visited our Services, the date and time you used the Services, error logs, and other similar information.
            Mobile status: For mobile application users, the online or offline status of your application.
            Applications: If you use the ThinkLead application, ThinkLead may collect information about the presence and/ or absence and/ or details pertaining to other applications on your mobile phone. The applications we gather information for, may vary across categories including, without limitation, shopping, fashion, food and travel. This will help us understand you and your preferences better and enable ThinkLead to provide you with a personalized experience.</p>

                <h3>Precise Location Information and How to Opt Out</h3>
                <p>When you use one of our location-enabled services (for example, when you access Services from a mobile device), we may collect and process information about your mobile device's GPS location (including the latitude, longitude or altitude of your mobile device) and the time the location information is recorded to customize the Services with location-based information and features (for example, to inform you about restaurants in your area or applicable promotions). Some of these services require your personal data for the feature to work and we may associate location data with your device ID and other information we hold about you. We keep this data for no longer than is reasonably necessary for providing services to you. If you wish to use the particular feature, you will be asked to consent to your data being used for this purpose. You can withdraw your consent at any time by disabling the GPS or other location-tracking functions on your device, provided your device allows you to do this. See your device manufacturer's instructions for further details.</p>

                <h3>Cookies and Other Electronic Tools</h3>
                <p>We, and third parties with whom we partner, may use cookies, pixel tags, web beacons, mobile device IDs, "flash cookies" and similar files or technologies to collect and store information in respect to your use of the Services and third party websites. A cookie is a small text file that is stored on your computer that enables us to recognize you (for example, as a registered user) when you visit our website, store your preferences and settings, enhance your experience by delivering content and advertising specific to your interests, perform research and analytics, track your use of our Services, and assist with security and administrative functions. Cookies may be persistent or stored only during an individual session. To understand more about cookies, click here https://www.aboutcookies.org. A pixel tag (also called a web beacon or clear GIF) is a tiny graphic with a unique identifier, embedded invisibly on a webpage (or an online ad or email), and is used to count or track things like activity on a webpage or ad impressions or clicks, as well as to access cookies stored on users' computers. </p>

                <p>Most browsers are set to automatically allow cookies. Please note it may be possible to disable some (but not all) cookies through your device or browser settings, but doing so may interfere with certain functionality on the Services. Major browsers provide users with various options when it comes to cookies. Users can usually set their browsers to block all third-party cookies (which are those set by third-party companies collecting information on websites operated by other companies), block all cookies (including first-party cookies such as the ones ThinkLead uses to collect search activity information about its users), or block specific cookies. To modify your cookie settings, please visit your browser's help settings. You will need to opt out on each browser and each device you use to access the Services. Flash cookies operate differently than browser cookies and cannot be removed or blocked via web browser settings. By using our Services with your browser set to accept cookies you are consenting to our use of cookies in the manner described in this section. For more information please read our Cookie Policy.</p>

                <p>Third parties whose products or services are accessible or advertised through the Services, including social media services, may also use cookies or similar tools, and we advise you to check their privacy policies for information about their cookies and other practices. We do not control the practices of such partners and their privacy policies govern their interactions with you.</p>

                <h3>Information from Third Parties</h3>
                <p>We may collect, process and store your user ID associated with any social media account (such as your Facebook and Google account) that you use to sign into the Services or connect with or use with the Services. When you sign in to your account with your social media account information, or otherwise connect to your social media account with the Services, you consent to our collection, storage, and use, in accordance with this Privacy Policy, of the information that you make available to us through the social media interface. This could include, without limitation, any information that you have made public through your social media account, information that the social media service shares with us, or information that is disclosed during the sign-in process. Please see your social media provider's privacy policy and help center for more information about how they share information when you choose to connect your account.</p>

                <p>We may also obtain information about you from third parties such as partners, marketers, third-party websites, and researchers, and combine that information with information which we collect from or about you.</p>

                <h3>Anonymous or De-Identified Data</h3>
                <p>We may anonymize and/or de-identify information collected from you through the Services or via other means, including via the use of third-party web analytic tools as described below. As a result, our use and disclosure of aggregated and/or de-identified information is not restricted by this Privacy Policy, and it may be used and disclosed to others without limitation.</p>

                <h3>How we use the information we collect</h3>
                <p>We use the information we collect from and about you for a variety of purposes, including to:</p>

                <h3>Process and respond to your queries</h3>
                <p>Understand our users (what they do on our Services, what features they like, how they use them, etc.), improve the content and features of our Services (such as by personalizing content to your interests), process and complete your transactions, and make special offers.
            Administer our Services and diagnose technical problems.
            Send you communications that you have requested or that may be of interest to you by way of emails, or courier, or registered post, or telephone calls, or any other mode of communication. We may also share your preferences or the Services availed by you with your network followers on ThinkLead for marketing and other promotional activities of our Services.
            Send you questions from other users that you may be able to answer if you have registered with ThinkLead.
            Enable us to show you ads that are relevant to you.
            Generate and review reports and data about, and to conduct research on, our user base and Service usage patterns.
            Administer contests and sweepstakes.
            Provide you with customer support.
            Provide you with policies about your account.
            Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.
            Notify you about changes to our Services.
            Allow you to participate in interactive features offered through our Services.
            In any other way we may describe when you provide the information.
            For any other purpose with your consent.
            We may also use your information to contact you about our own and third-party goods and services that may be of interest to you. If you do not want us to use your information in this way, please check the relevant box located on the form on which we collect your data and/or adjust your user preferences in your account profile.</p>

                <p>We may use the information we have collected from you to enable us to display advertisements to our advertisers'/service providers' target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.</p>

                <h3>How we share the information we collect</h3>

                <p>We may disclose personal information that we collect or you provide, as described in this privacy policy, in the following ways:</p>

                <h3>General Information Disclosures</h3>

                <p>To contractors, advertisers/service providers and other third-parties whom we use to support our business (e.g. dinner reservations and food delivery) and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</p>

                <p>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of ThinkLead's assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information held by ThinkLead about the users of our Services are among the assets transferred.</p>

                <p />To third-parties to market their products or services to you if you have consented to receive the promotional updates. We contractually require these third-parties to keep personal information confidential and use it only for the purposes for which we disclose it to them.
            To fulfill the purpose for which you provide it.
                <p>For any other purpose disclosed by us when you provide the information.
            Service Providers. We may share your information with outside vendors that we use for a variety of purposes, such as to send you communications via emails, messages or tele-call to inform you about our products that may be of interest to you, push notifications to your mobile device on our behalf, provide voice recognition services to process your spoken queries and questions, help us analyze use of our Services, and process and collect payments. Some of our products, services and databases are hosted by third party hosting services providers. We also may use vendors for other projects, such as conducting surveys or organizing sweepstakes for us. We may share information about you with these vendors only to enable them to perform their services.
            Legal Purposes. We may share your information when we believe in good faith that such sharing is reasonably necessary in order to investigate, prevent, or take action regarding possible illegal activities or to comply with legal process. We may also share your information to investigate and address threats or potential threats to the physical safety of any person, to investigate and address violations of this Privacy Policy or the Terms of Service, or to investigate and address violations of the rights of third parties and/or to protect the rights, property and safety of ThinkLead, our employees, users, or the public. This may involve the sharing of your information with law enforcement, government agencies, courts, and/or other organizations on account of legal request such as subpoena, court order or government demand to comply with the law.
            Social Networks. If you interact with social media features on our Services, such as the Facebook Like button, or use your social media credentials to log-in or post content, these features may collect information about your use of the Services, as well as post information about your activities on the social media service. Your interactions with social media companies are governed by their privacy policies.</p>

                <p>To enforce or apply our Terms of Use and other agreements, including for billing and collection purposes.</p>

                <p>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of ThinkLead, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
               Consent. We may share your information in any other circumstances where we have your consent.</p>

                <h3>Payment Card Information</h3>
                <p>To use certain of our Services, such as to make reservations at certain restaurants and to make payments to certain restaurants, we may require credit or debit card account information. By submitting your credit or debit card account information through our Services, you expressly consent to the sharing of your information with restaurants, third-party payment processors, and other third-party service providers (including but not limited to vendors who provide fraud detection services to us and other third parties), and you further agree to the following terms:</p>

                <p>When you use a credit or debit card to secure a reservation through our Sites, we provide your credit or debit card account information (including card number and expiration date) to our third-party payment service providers and the applicable restaurant.
            When you initially provide your credit or debit card account information through our Services in order to use our restaurant payment services, we provide your credit or debit card account information to our third-party payment service providers. As explained in our Terms of Use, these third parties may store your credit or debit card account information so you can use our restaurant payment services through our Services in the future.
            For information about the security of your credit or debit card account information, see the "Security" section below.</p>

                <h3>Analytics and tailored advertising</h3>
                <p>To help us better understand your use of the Services, we may use third-party web analytics on our Services, such as Google Analytics. These service providers use the sort of technology described in the "Automatically-Collected Information" Section above. The information collected by this technology will be disclosed to or collected directly by these service providers, who use the information to evaluate our users' use of the Services. We also use Google Analytics as described in the following section. To prevent Google Analytics from collecting or using your information, you may install the Google Analytics Opt-Out Browser add-on.</p>

                <h3>Tailored Advertising</h3>
                <p>Third parties whose products or services are accessible or advertised via the Services may also use cookies or similar technologies to collect information about your use of the Services. </p>

                <p>This is done in order to help them inform, optimize, and serve ads based on past visits to our website and other sites and
              Report how our ad impressions, other uses of ad services, and interactions with these ad impressions and ad services are related to visits to our website. We also allow other third parties (e.g., ad networks and ad servers such as Google Analytics, OpenX, Pubmatic, DoubleClick and others) to serve tailored ads to you on the Services, and to access their own cookies or similar technologies on your computer, mobile phone, or other device you use to access the Services. We neither have access to, nor does this Privacy Policy govern, the use of cookies or other tracking technologies that may be placed by such third parties. These parties may permit you to opt out of ad targeting. If you are interested in more information about tailored browser advertising and how you can generally control cookies from being put on your computer to deliver tailored advertising (i.e., not just for the Services), you may visit the Network Advertising Initiative's Consumer Opt-Out Link, and/or the Digital Advertising Alliance's Consumer Opt-Out Link to opt-out of receiving tailored advertising from companies that participate in those programs. To opt out of Google Analytics for Display Advertising or customize Google Display Network ads, you can visit the Google Ads Settings page. Please note that to the extent advertising technology is integrated into the Services, you may still receive ads even if you opt-out of tailored advertising. In that case, the ads will just not be tailored to your interests. Also, we do not control any of the above opt-out links and are not responsible for any choices you make using these mechanisms or the continued availability or accuracy of these mechanisms.</p>

                <p>When accessing the Services from a mobile application you may also receive tailored in-application advertisements. Each operating system: iOS, Android and Windows Phone provides its own instructions on how to prevent the delivery of tailored in-application advertisements. You may review the support materials and/or the privacy settings for the respective operating systems in order to opt-out of tailored in-application advertisements. For any other devices and/or operating systems, please visit the privacy settings for the applicable device or operating system or contact the applicable platform operator.</p>

                <h3>Choices about how we use and disclose your information</h3>
                <p>We strive to provide you with choices regarding the personal information you provide to us. You can set your browser or mobile device to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe's website. If you disable or refuse cookies, please note that some parts of our Services may then be inaccessible or not function properly. We do not share your personal information with any advertising agency.</p>

                <h3>Communication choices</h3>
                <p>When you sign up for an account, you are opting in to receive emails from other ThinkLead users, businesses, and ThinkLead itself. You can log in to manage your email preferences and follow the "unsubscribe" instructions in commercial email messages, but note that you cannot opt out of receiving certain administrative policy, service policy, or legal policy from ThinkLead.</p>

                <h3>Reviewing, changing or deleting information</h3>
                <p>If you would like to review, change or delete personal information we have collected from you, or permanently delete your account, please use the "Contact Us" link at the bottom of every page (also located here), or contact the ThinkLead Support.</p>

                <p>If you delete your User Contributions from our websites, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other users of our websites. Proper access and use of information provided on our websites, including User Contributions, is governed by our Terms of Use</p>

                <h3>Accessing & correcting your personal information</h3>
                <p>We will take reasonable steps to accurately record the personal information that you provide to us and any subsequent updates.</p>

                <p>We encourage you to review, update, and correct the personal information that we maintain about you, and you may request that we delete personal information about you that is inaccurate, incomplete, or irrelevant for legitimate purposes, or are being processed in a way which infringes any applicable legal requirement.</p>

                <p>Your right to review, update, correct, and delete your personal information may be limited, subject to the law of your jurisdiction:</p>

                <p>If your requests are abusive or unreasonably excessive,
            Where the rights or safety of another person or persons would be encroached upon, or
            If the information or material you request relates to existing or anticipated legal proceedings between you and us, or providing access to you would prejudice negotiations between us or an investigation of possible unlawful activity. Your right to review, update, correct, and delete your information is subject to our records retention policies and applicable law, including any statutory retention requirements.</p>

                <h3>Security: How we protect your information</h3>
                <p>We have implemented appropriate physical, electronic, and managerial procedures to safeguard and help prevent unauthorized access to your information and to maintain data security. These safeguards take into account the sensitivity of the information that we collect, process and store and the current state of technology. We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. The third party service providers with respect to payment gateway and payment processing are all validated as compliant with the payment card industry standard (generally referred to as PCI compliant service providers).</p>

                <p>We assume no liability or responsibility for disclosure of your information due to errors in transmission, unauthorized third-party access, or other causes beyond our control. You play an important role in keeping your personal information secure. You should not share your user name, password, or other security information for your ThinkLead account with anyone. If we receive instructions using your user name and password, we will consider that you have authorized the instructions.</p>

                <h3>Third party links and services</h3>
                <p>The Services may contain links to third-party websites. Your use of these features may result in the collection, processing or sharing of information about you, depending on the feature. Please be aware that we are not responsible for the content or privacy practices of other websites or services which may be linked on our services. We do not endorse or make any representations about third-party websites or services. Our Privacy Policy does not cover the information you choose to provide to or that is collected by these third parties. We strongly encourage you to read such third parties' privacy policies.</p>

                <h3>EU privacy/data protection rights</h3>
                <p>EU General Data Protection Regulation (GDPR) has provided the below rights to the EU residents:</p>

                <p>Right to information - including contact details to the DPO, the purposes for processing Personal Information and the rights of the individual.
            Right to access the Personal Information that are processed
            Right to erasure (”Right to be forgotten”)
            Right to rectification
            Right to restriction of processing
            Right to data portability of the Personal Information supplied to ThinkLead by the EU resident
            Right to object (opt-out) to processing (unless ThinkLead otherwise has compelling legitimate grounds)
            EU residents can exercise these rights by raising a request directly at support@thinklead.co.za
                </p>

                <h3>Data retention and account termination</h3>
                <p>You can close your account by visiting your profile settings page on our website. We will remove your public posts from view and/or dissociate them from your account profile, but we may retain information about you for the purposes authorized under this Privacy Policy unless prohibited by law. Thereafter, we will either delete your personal information or de-identify it so that it is anonymous and not attributed to your identity. For example, we may retain information to prevent, investigate, or identify possible wrongdoing in connection with the Service or to comply with legal obligations.</p>

                <h3>Changes to this privacy policy</h3>
                <p>We reserve the right to amend this Privacy Policy from time to time to reflect changes in the law, our data collection and use practices, the features of our services, or advances in technology. Please check this page periodically for changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is used. If we make any material changes to this Privacy Policy, we will post the changes here. Please review the changes carefully. Your continued use of the Services following the posting of changes to this Privacy Policy will constitute your consent and acceptance of those changes.</p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClosePolicy} color='primary'>
            Close
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </React.Fragment>
    )
  }
}

// ===============
const mapDispatchToProps = dispatch => {
  return {
    signInEmail: creds => dispatch(signInWithEmailAndPassword(creds)),
    SignUpWithEmail: params => dispatch(signUpNewUsers(params)),
    // SignUpWithEmail: params => dispatch(handleSubmitUser(params)),
    checkUserIs: params => dispatch(checkUserIs(params)),
    createCompanyUser: params => dispatch(createCompanyUser(params)),
   // signGoogle: () => dispatch(signInWithGoogle()),
    // signInWithGoogle: () => dispatch(signInWithGoogle()),
    signGoogle: params => dispatch(signInWithGoogle(params)),
    showLoader: params => dispatch(showLoader(params)),
    hideLoader: params => dispatch(hideLoader(params)),
    resetPassword: params => dispatch(resetUserPassword(params)),
    showToast: params => dispatch(showToast(params))
  }
}

// ===============
const BodyContentStyled = withStyles(styles)(BodyContent)
export default connect(
  null,
  mapDispatchToProps
)(BodyContentStyled)
