/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable no-inline-comments */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { getCompanyValues, deleteCompanyValue } from '../../../store/actions/pldpActions'
import {
  ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanelActions, Grid, Typography, Button, Modal, Dialog,
  DialogActions, DialogContent, DialogTitle
} from '@material-ui/core'
import { Link } from "react-router-dom";

import TextField from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import AccountBalance from '@material-ui/icons/AccountBalance'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { VALUES_NAME, SHOW_TOAST, GET_O } from '../../../store/actions/types'
import { LIGHTGREY, THEMECOLOR } from '../../../utils/color_utils'
import { RadioGroup, Radio } from 'react-radio-group'
import Task from './Task'
import MyTasks from './MyTasks'
import TasksListView from './TasksListView'
import { fetchMyPLDPTasks, fetchAllMyPLDPTasks, updateNewPLDPTask, completeTask } from '../../../store/actions/pldpActions'
// import PrintTasks from './PrintTasks';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { addMorePoints } from '../../../store/actions/freshCardActions'
import {
  CgetOpinions
} from '../../../store/actions/opinionCActions'
import {
  GgetOpinions
} from '../../../store/actions/opinionG_Actions'
import {
  getOpinions
} from '../../../store/actions/opinionActions'
import GenTutorial from '../../../pages/PointsSurveys/GenTutorial'
// import { ThreeSixty } from '@material-ui/icons'
import wiseman from '../../../pages/eximg/wiseman3.jpg'
import firebase from '../../../store/firebase'
import { showToast } from '../../../store/actions/toastAction'
import ChatGPT from '../../../components/Dialogs/ChatGPT'
import ChatGPTfree from '../../../components/Dialogs/ChatGPTfree'
import ChatGPTHistory from '../../../components/Dialogs/ChatGPTHistory'
import { PHOTO_PROFILE } from '../../../utils/photo_utils'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
import {
  Avatar
} from '@material-ui/core'

const tableM = {
  color: "#515A5A",
  fontSize: 12,
  border: "0px",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 2,
  width: isMobile ? '20%' : '20%',
  maxWidth: isMobile ? '20%' : '20%',
}
const tableW = {
  color: "#515A5A",
  fontSize: 12,
  border: "0px",
  backgroundColor: '#FFFFFF',
  padding: 1,
  borderRadius: 2
}
const tableMhh = {
  color: "#515A5A",
  fontSize: 12,
  border: "2px",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 2,
  width: '25%'
}
const tableWh = {
  color: "#515A5A",
  fontSize: 12,
  border: "0px",
  backgroundColor: '#FFFFFF',
  padding: 1,
  borderRadius: 2,
  width: '25%'
}
const tableC = {
  color: "#ffffff",
  fontSize: 12,
  border: "0px",
  padding: 1,
  borderRadius: 2,
  fontweight: 'bold',
  backgroundColor: '#515A5A',
  width: isMobile ? '40%' : '40%',
  maxWidth: isMobile ? '40%' : '40%',
}
const tableCa = {
  color: "#ffffff",
  fontSize: 12,
  border: "0px",
  padding: 1,
  borderRadius: 2,
  fontweight: 'bold',
  backgroundColor: '#515A5A',
  width: isMobile ? '20%' : '20%',
  maxWidth: isMobile ? '20%' : '20%',
}
const tableCc = {
  color: "#372F0C",
  fontSize: 20,
  border: "0px",
  padding: 1,
  borderRadius: 2,
  fontweight: 'bold',
  backgroundColor: '#ffffff',
  width: isMobile ? '35%' : '6%',
  maxWidth: isMobile ? '35%' : '10%',
}
const tableCcc = { 
  color: "#B69C26",
  bgColor: "#cccccc",
  fontSize: 14,
  align: 'left',
  textAlign: 'left',
  // marginRight: '300px',
  // marginRight: '400px',
  marginLeft: '20px',
  alignItems: 'left',
  justifyContent: 'left',
  alignContent: 'left',

  display: 'flex',
  alignContent: 'left',
  border: 1,
  padding: 1,
  borderRadius: 2,
  fontweight: 'bold',
  backgroundColor: '#ffffff',
  width: isMobile ? '90%' : '90%',
  maxWidth: isMobile ? '90%' : '90%',
}
const tableSage = {
  color: "#372F0C",
  fontSize: 18,
  border: "0px",
  padding: 6,
  borderRadius: 6,
  fontweight: 'bold',
  backgroundColor: '#ffffff',
  width: isMobile ? '65%' : '90%',
  maxWidth: isMobile ? '65%' : '94%',
  cursor: 'pointer'
}
const tableMh = {
  color: "#515A5A",
  fontSize: 15,
  border: "2px",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 2,
  width: isMobile ? '40%' : '40%',
  maxWidth: isMobile ? '40%' : '40%',
}
const tableS = {
  color: "#515A5A",
  fontSize: 10,
  border: "0px",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 2
}
const tableH = {
  color: "#515A5A",
  fontSize: 12,
  border: "0px",
  backgroundColor: '#F2F3F4',
  padding: 2,
  borderRadius: 2
}
const tableHself = {
  color: "#796716",
  fontSize: 12,
  border: "0px",
  backgroundColor: '#D4C8DA',
  padding: 2,
  borderRadius: 2
}
const tableAI = {
  color: "#515A5A",
  fontSize: 15,
  border: "0px",
  backgroundColor: '#F2F3F4',
  padding: 3,
  borderRadius: 2
}

// const inputStyles = {
//   borderColor: 'red', // Change this to the desired color
//   borderWidth: '2px', // You can also change the width of the border
// };

Array.prototype.groupBy = function (prop) {
  return this.reduce(function (groups, item) {
    const val = item[prop]
    groups[val] = groups[val] || []
    groups[val].push(item)
    return groups
  }, {})
}

function includedCompetencies(openedTasks, tasks) {
  let competenciesFound = []
  Object.keys(tasks).map(id => {
    if (tasks[id] && (openedTasks === tasks[id].active)) {
      const a = competenciesFound.indexOf(tasks[id].moveAction)
      competenciesFound.push(tasks[id].moveAction)
    }
  })
  return competenciesFound
}

function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: 0 }}>
      {props.children}
    </Typography>
  )
}

function rand() {
  return Math.round(Math.random() * 20) - 10
}
const top = 50 + rand()
const left = 50 + rand()
let groupTasks

const styles = theme => ({
  // root: {
  //   marginTop: 0,
  //   padding: '10px',
  //   flexGrow: 1
  // },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
    // border: `4px solid ${THEMECOLOR}`
    border: `4px solid #eeeeee`
  },
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#935c02', // Change this to the desired hover color
        fontSize: '12px',
        // width: '600px',
        // maxWidth: '600px',
        color: '#64540B'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#7C6B1D', // Change this to the desired focus color
        fontSize: '12px',
        // width: '600px',
        // maxWidth: '600px',
        color: '#64540B'
      },
    },
  },
  panel: {
    marginBottom: '10px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: 'none'
    // "&:hover": {
    //   boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19)"
    // }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '6px'
  },
  title: {
    borderBottom: `1px solid ${LIGHTGREY}`,
    fontSize: '15px',
    minHeight: '0px',
    width: 'auto',
    // color: '#fff',
    padding: '5px 10px',
    borderRadius: 5
    // backgroundColor: '#0085a5'
  },
  unopendtitle: {
    borderBottom: `1px solid ${LIGHTGREY}`,
    fontSize: '15px',
    minHeight: '0px',
    width: 'auto',
    color: '#e5e4e2',
    padding: '5px 10px',
    borderRadius: 5
    // backgroundColor: '#0085a5'
  },
  modal: {
    outline: 'none',
    border: 'none'
  },
  paper: {
    position: 'absolute',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    outline: 'none'
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  divStyle: {
    margin: '20px',
    padding: '10px 0',
    border: '1px solid #ccc!important',
    [theme.breakpoints.down('sm')]: {
      margin: '4px',
      padding: '0px 6px'
    }
  },
  dialogHeight: {
    // height: `calc(100% - 20px)`
    height: 'auto'
  },
  unopendList: {
    width: '100%',
    padding: '20px 10px 20px 20px',
    border: '1px solid #e5e4e2',
    margin: '10px',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  addIconstyles: {
    backgroundColor: THEMECOLOR,
    color: '#000',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textField: {
		// width: '600px',
    // maxWidth: '600px',
    size: '15px !important',
    color: '#594B0B !important',
    borderColor: '#935c02 !important'
	},
  textFieldLabel: {
		// width: '600px',
    // maxWidth: '600px',
    size: '15px !important',
    color: '#935c02 !important'
	}
})

class Competencies extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editText: '',
      editID: '',
      filterby: 'open',
      open: false,
      openConfirm: false,
      addTask: false,
      editTask: false,
      active: false,
      taskOpened: {},
      companyValue: '',
      editText: '',
      openComplete: 'complete',
      tabsvalue: 0,
      openedTasks: true,
      mainCompetencies: [],
      leadershipTasks: [],
      slave: 0,
      slaveparticipants: 0,
      
      simple: 0,
      ssimple: 0,
      simpleparticipants: 0,
      simpleopinion: "",

      valueA: 0,
      valueAparticipants: 0,
      valueAopinion: "",

      valueB: 0,
      valueBopinion: "",
      valueBparticipants: 0,

      valueC: 0,
      valueCopinion: "",
      valueCparticipants: 0,

      valueD: 0,
      valueDopinion: "",
      valueDparticipants: 0,

      valueE: 0,
      valueEopinion: "",
      valueEparticipants: 0,

      valueF: 0,
      valueFopinion: "",
      valueFparticipants: 0,

      valueG: 0,
      valueGopinion: "",
      valueGparticipants: 0,

      valueH: 0,
      valueHopinion: "",
      valueHparticipants: 0,

      svalueA: 0,
      svalueB: 0,
      svalueC: 0,
      svalueD: 0,
      svalueE: 0,
      svalueF: 0,
      svalueG: 0,
      svalueH: 0,

      allopinions: '',
      one_count: 0,
      two_count: 0,
      three_count: 0,
      four_count: 0,
      five_count: 0,
      six_count: 0,
      seven_count: 0,
      eight_count: 0,
      nine_count: 0,
      ten_count: 0,

      one_arr: [],
      two_arr: [],
      three_arr: [],
      four_arr: [],
      five_arr: [],
      six_arr: [],
      seven_arr: [],
      eight_arr: [],
      nine_arr:  [],
      ten_arr: [], 
      eleven_arr: [], 

      sone_count: 0,
      stwo_count: 0,
      sthree_count: 0,
      sfour_count: 0,
      sfive_count: 0,
      ssix_count: 0,
      sseven_count: 0,
      seight_count: 0,
      snine_count: 0,
      sten_count: 0,
      sone_arr: [],
      stwo_arr: [],
      sthree_arr: [],
      sfour_arr: [],
      sfive_arr: [],
      ssix_arr: [],
      sseven_arr: [],
      seight_arr: [],
      snine_arr: [],
      sten_arr: [],

      lastAnswer: '',
      alist: [],
      myemail: '',
      gtpquestion: '',
      maxLengthThought: 0,
      showFreeQ: true,
      showing: 'none'
     // opinions_state: {}
    }
    // if (this.props.userdata) {
    //   this.putSOpinions();
    // }
 
  }
  
     getAllOpinions(userID){
       if (userID) {
         this.findOpinionsC(userID)
       }
      // this.props.getOpinions({ // loads the opinions of leaders recorded in state
      //   leaderID: this.props.userdata.userID
      // })
    }

   componentDidMount() {
    let { opinions, companyValues } = this.props
    // this.fetchData()
    console.log('green teststate-ledain : ', this.props.opinions)
    //console.log('green ledain')
    
  //    if (this.props.userdata.userID) {
  //    console.log('statsstate 1 : ', this.props) 
  //     this.props.getOpinions({ //loads the opinions of leaders recorded in state
  //      type: GET_O,
  //      leaderID: this.props.userdata.userID
  //    })
  //  } 
  
  var alist = []
  if(companyValues) {
  Object.keys(companyValues).map(id => {
    const labelId = `${companyValues[id].valueID}`
    const labelName = `${companyValues[id].valueDesc}`
    let tasksCount = 0;
  
    if (groupTasks[labelId]) {
      ///////////////////////////////////////
       var tlist = groupTasks[labelId]
      var i;
   
      for (i = 0; i < tlist.length; i++) {
        if (tlist[i].title) {
          alist.push(tlist[i].title)
       }}
  
    }
  
    return null
  })
  this.dismantleArray(alist)
  }
  
  if (this.props.userdata.userID) {
    console.log('green teststate i1 : ', this.props.userdata.email)
    //this.getAllOpinions(this.props.userdata.userID)
    this.setState({ myemail: this.props.userdata.email})
    console.log('green teststate i2 : ', this.props)
   
  //   this.props.GgetOpinions({ // loads the opinions of leaders recorded in state
  //     leaderID: this.props.userdata.companyID
  //   })
  //   this.props.getOpinions({ // loads the opinions of leaders recorded in state
  //     leaderID: this.props.userdata.userID
  //   })
   }

    if (this.props.opinions) { 
     // this.getAllOpinions()
      //this.findOpinionsC()
        console.log('green teststate 1 : ', this.props)
        this.fetchData()
        console.log('green teststate 2 : ', this.props.opinions)
        console.log('statsstate 2 : ', this.props.userdata.photoURL )
        this.putOpinions();
        this.putSOpinions();
        if (this.props.opinions && Object.keys(this.props.opinions).length > 0) {
          this.makeAIopinions();
         }
    }
  
   console.log('green teststate 3 : ', this.props.opinions)
    // this.insertPollCount();
    // this.HandleSeparateTasksAndCompetencies()
  }

  componentDidUpdate(prevProps, prevState) {
   // if (prevProps.opinions === this.props.opinions) {
    if (this.props.opinions) {} else {
      // if (this.props.userdata.userID) { 
      //   //this.findOpinionsC()
      //   console.log('green teststate 1 : ', this.props.opinions)
      //   this.fetchData()
      //   console.log('green teststate 2 : ', this.props.opinions)
      //   console.log('statsstate 2 : ', this.props.userdata.photoURL )
      //   this.putOpinions();
      //   this.putSOpinions();
      //   if (this.props.opinions && Object.keys(this.props.opinions).length > 0) {
      //     this.makeAIopinions();
      //    }
      //  }
    }
  }

  findOpinionsC = (userID) => {
    const promise = this.findOpinionsD(userID)
    promise.then(this.onFulfilled, this.onRejected)
  }

  findOpinionsD = (userIDx) => {
    console.log('In findOpinionsD 1: ', this.props)
    return new Promise((resolve, reject) => {
      setTimeout(() => {
       try {
              this.props.getOpinions({ //loads the opinions of leaders recorded in state
                  type: GET_O,
                	leaderID: userIDx
	              })
                console.log('In findOpinionsD 6: ', this.props)
        }
       catch (e) {
        console.log('Error in findOpinionsD: ', e)
      }
      }, 6000)
    })
  }

   makeAIopinions = () => {
    let { opinions } = this.props

      var simpleopinion = '';
      var valueAopinion = '';
      var valueBopinion = ''; 
      var valueCopinion = '';
      var valueDopinion = '';
      var valueEopinion = '';
      var valueFopinion = '';
      var valueGopinion = '';
      var valueHopinion = '';

      var n;
      n = 0;
      console.log('simpleopinion 1 : ', opinions)

      opinions && Object.keys(opinions).map((opinionsID, index) => {
        if (opinions[index].leaderID!==opinions[index].slaveID) {
          n = n + 1;
          if (opinions[index].simpleopinion) {simpleopinion = this.trimOpinion(opinions[index].simpleopinion) + ', ' + this.trimOpinion(simpleopinion) + ', '}
          if (opinions[index].valueAopinion) {valueAopinion = this.trimOpinion(opinions[index].valueAopinion) + ', ' + this.trimOpinion(valueAopinion) + ', '}
          if (opinions[index].valueBopinion) {valueBopinion = this.trimOpinion(opinions[index].valueBopinion) + ', ' + this.trimOpinion(valueBopinion) + ', '}
          if (opinions[index].valueCopinion) {valueCopinion = this.trimOpinion(opinions[index].valueCopinion) + ', ' + this.trimOpinion(valueCopinion) + ', '}
          if (opinions[index].valueDopinion) {valueDopinion = this.trimOpinion(opinions[index].valueDopinion) + ', ' + this.trimOpinion(valueDopinion) + ', '}
          if (opinions[index].valueEopinion) {valueEopinion = this.trimOpinion(opinions[index].valueEopinion) + ', ' + this.trimOpinion(valueEopinion) + ', '}
          if (opinions[index].valueFopinion) {valueFopinion = this.trimOpinion(opinions[index].valueFopinion) + ', ' + this.trimOpinion(valueFopinion) + ', '}
          if (opinions[index].valueGopinion) {valueGopinion = this.trimOpinion(opinions[index].valueGopinion) + ', ' + this.trimOpinion(valueGopinion) + ', '}
          if (opinions[index].valueHopinion) {valueHopinion = this.trimOpinion(opinions[index].valueHopinion) + ', ' + this.trimOpinion(valueHopinion) + ', '}

          console.log('simpleopinion 2 : ', simpleopinion)
         }
       })

     this.setState({
      simpleopinion: simpleopinion ? 'People say the following about me regarding me in general: ' + this.trimOpinion(simpleopinion) + '. ': '',
      valueAopinion: valueAopinion ? 'People say the following regarding my skills in Strategic Leadership: ' +  this.trimOpinion(valueAopinion) + '. ': '',
      valueBopinion: valueBopinion ? 'People say the following about me being inclusive: ' +  this.trimOpinion(valueBopinion) + '. ': '',
      valueCopinion: valueCopinion ? 'People say the following about my ability to inspire: ' +  this.trimOpinion(valueCopinion) + '. ': '',
      valueDopinion: valueDopinion ? 'People say the following about my Ethical Leadership skills: ' +  this.trimOpinion(valueDopinion) + '. ': '',
      valueEopinion: valueEopinion ? 'People say the following about my Accountability: ' +  this.trimOpinion(valueEopinion) + '. ': '',
      valueEopinion: valueFopinion ? 'People say the following about my Innovation skills: ' +  this.trimOpinion(valueFopinion) + '. ': '',
      valueEopinion: valueGopinion ? 'People say the following about my Steadfastness: ' +  this.trimOpinion(valueGopinion) + '. ': '',
      valueEopinion: valueHopinion ? 'People say the following about my Balance: ' +  this.trimOpinion(valueHopinion) + '. ': '',
      allopinions: simpleopinion + valueAopinion + valueBopinion + valueCopinion + valueDopinion + valueEopinion + valueFopinion + valueGopinion + valueHopinion + '. Considering what people who work with me are saying about me, I have a question and could you please address the answer to me in second person, without using my first name. Here is the question: '
    })
   }

   dismantleArray = (arr) => {
    let list = null;
    console.log('dismantleArray 1 : ', arr)
    for (let i = arr.length - 1; i >= 0; i--) {
        list = list + ', ' + arr[i];
    }
    console.log('dismantleArray 2 : ', list)
    this.setState({
      alist: list
    })
   // return list;
   }

   trimOpinion = (strIn) => {
    var i
    i = 0
    var stropinion = strIn;
    for (; i < 6; i++) {
    
    console.log('valueAopinion slice 1: ', stropinion.slice(-1))
      if (
        stropinion.slice(-1) === ' ' || 
        stropinion.slice(-1) === ',' || 
        stropinion.slice(-1) === '.'  
      )
      {
        stropinion = stropinion.substring(0, stropinion.length - 1)
      }
      console.log('valueAopinion slice 2: ', stropinion)
    }
    return stropinion;
   }

  componentDidUpdate() { 
    console.log('greenstats : ', this.props.opinions)
  }

  componentWillMount() {
    // this.HandleSeparateTasksAndCompetencies()
  }

//   static getDerivedStateFromProps(props, state) { 
//     console.log('statsstate 3 : ', props)
//     this.putOpinions();
//     this.putSOpinions();
//     return {
//        points: 200 // update state with this
//     }
//  } 

  fetchData = () => {
    this.props.fetchCompanyValues(this.props.userdata.companyID)
    this.props.fetchMyPLDPTasks(this.props.userdata.userID, this.state.openedTasks)
    this.props.fetchAllMyPLDPTasks({ userID: this.props.userdata.userID })
  };

  handleClose = () => {
    this.setState({
      editText: '',
      editID: '',
      open: false,
      openConfirm: false,
      addTask: false,
      editTask: false,
      taskOpened: {},
      companyValue: '',
      openComplete: this.state.active ? 'open' : 'complete'

    })
  };

  addAction = () => {
    this.setState({
      ...this.state,
      open: true,
      addTask: true,
      editTask: false
    })
  }

  handleOnSaveTask = (newTask, updateOnSave) => {
    this.props.updateNewPLDPTask(newTask, updateOnSave)

    this.handleClose()
  };

  handleFilteredTasks = (val) => {
    // this.setState({ filterby: val })
     console.log("active ", val)
    if (val.trim() === 'open') {
      this.setState({
        ...this.state,
        filterby: val,
        tabsvalue: 0,
        openedTasks: true
      })
      this.props.fetchData(true)
    } else if (val.trim() === 'complete') {
      this.setState({
        ...this.state,
        filterby: val,
        tabsvalue: 0,
        openedTasks: false
      })
      this.props.fetchData(false)
    } else if (val.trim() === 'feedback') {
      this.setState({
        ...this.state,
        filterby: val,
        tabsvalue: 2,
        openedTasks: false
      })
      this.props.fetchData(false)
    }
    else {
      this.setState({
        ...this.state,
        filterby: val,
        tabsvalue: 1
      })
    }
  }

  editTask(id, name, task) {
    this.setState({
      openConfirm: false,
      open: true,
      addTask: false,
      editTask: true,
      companyValue: id,
      taskOpened: task
    })
  }

  onMarkComplete(id, name, task, active) {
    this.setState({
      open: false,
      openConfirm: true,
      addTask: false,
      editTask: true,
      companyValue: id,
      taskOpened: task,
      editText: name,
      active: active,
      openComplete: active ? 'open' : 'complete'
    })
  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * 
  charactersLength));
    }
    return result;
  }

  completedTask = () => {
    let taskParams = {
      task: this.state.taskOpened,
      edit: true,
      active: this.state.active
    }
    console.log('green plfp completeTask 5 : ', taskParams.task.moveActionDesc);
    let action = taskParams.task.moveActionDesc.substr(0, 9);

    let timeNow = new Date().getTime();
    let timePast = (timeNow - taskParams.task.dateRegistered)/(86400000)
    console.log('pink completeTask 3: ', timePast)
    
    var pass = false;
    
    switch (taskParams.active) {
      case true: //re-open task
        pass = true;
        break;
      case false: //complete task
        if (timePast > 29 || action === "Activity:") {pass=true}
        break;
    }

    if (pass===true) {
      setTimeout(() => {
        this.props.completeTask(taskParams)
      }, 3000)

      if (this.state.filterby === 'open') {
        this.props.fetchData(true)
      } else {
        this.props.fetchData(false)
      }

    // this.props.fetchData(this.state.active ? false : true);
    //if (this.props.userdata.execmember === true) {
      let bpoints
      if (this.state.active === true) {
        bpoints = -10
      } else {
        bpoints = 20
      }
      // ////ADD POINTS///////////////////////////////
      console.log('green plfp points : ', this.state)
      let pointsParams = {
        uid: this.props.userdata.uid,
        userID: this.props.userdata.userID,
        pointsCurrent: this.props.userdata.points,
        pointsAdded: bpoints,
        ypointsCurrent: this.props.userdata.pointsy,
        trailID: this.makeid(10),
        companyName: this.props.userdata.companyName,
        firstName: this.props.userdata.firstName,
        lastName: this.props.userdata.lastName,
        title: this.state.editText,
        actionType: bpoints > 0 ? 'PLFP Task completed' : "PLFP Task re-opened",
        actionDesc: this.state.taskOpened.moveActionDesc + '/' + this.state.taskOpened.subcompetency + ' Task'
      }
      if (this.props.userdata.execmember) {
         this.props.addMorePoints(pointsParams)
      }
     
    }
    else
    {
      this.props.showToast({
         type: 'SHOW_TOAST',
         open: true,
         variant: 'success',
         message: 'Tasks can only be ticked as completed after 30 days, to ensure integrity of changing behaviours and adopting new habits.'
       })
    }
    this.handleClose()
  }

  getTasksCount = (tasks) => {
    let i; let open_tasks = 0; let closed_tasks = 0
    for (i = 0; i < tasks.length; i++) {
      if (tasks[i].active) {
        // console.log("labelName: ", labelName);
        // console.log("active: ", tasks[i]);
        open_tasks++
      } else {
        closed_tasks++
      }
    }

    if (this.state.filterby === 'open') {
      return open_tasks
    }
    if (this.state.filterby === 'feedback') {
      return null
    }
    return closed_tasks
  }

  xputSOpinions = () => {
    let { opinions } = this.props
    console.log('scores final x ', opinions);

    var simple_count = 0;
    var slave_count = 0;
    var slave_all_count = 0;
    var simple_all_count = 0;
    
    var sone = [0];//0;
    var stwo = [0];//0;
    var sthree = [0];//0;
    var sfour = [0];//0;
    var sfive = [0];//0;
    var ssix = [0];//0;
    var sseven = [0];//0;
    var seight = [0];//0;
    var snine = [0];//0;
    var sten = [0];//0;

    var sone_sub = 0;
    var stwo_sub = 0;
    var sthree_sub = 0;
    var sfour_sub = 0;
    var sfive_sub = 0;
    var ssix_sub = 0;
    var sseven_sub = 0;
    var seight_sub = 0;
    var snine_sub = 0;
    var sten_sub = 0;

    var sone_count = 0;
    var stwo_count = 0;
    var sthree_count = 0;
    var sfour_count = 0;
    var sfive_count = 0;
    var ssix_count = 0;
    var sseven_count = 0;
    var seight_count = 0;
    var snine_count = 0;
    var sten_count = 0;

    if (opinions) { Object.keys(opinions).map((opinionsID, index) => {
      console.log('inside putOpinions 2', opinions[index]);
      if (opinions[index].slave) { 
        slave_all_count = slave_all_count + 1;
        slave_count = opinions[index].slave 
        console.log('green slave_count : ', slave_count);
        switch(slave_count) {
          case 1:
            //one = one + 1;
            sone_count = sone_count + 1;
            if (opinions[index].simple) { 
              sone_sub = sone[0]
              sone[0] = sone_sub + opinions[index].simple 
            }
            break;
          case 2:
            //two = two + 1;
            stwo_count = stwo_count + 1;
            if (opinions[index].simple) { 
              stwo_sub = stwo[0]
              stwo[0] = stwo_sub + opinions[index].simple 
            }
            break;
          case 3:
            //three = three + 1;
            sthree_count = sthree_count + 1;
            if (opinions[index].simple) { 
              sthree_sub = sthree[0]
              sthree[0] = sthree_sub + opinions[index].simple 
            }           
            break;
          case 4:
            //four = four + 1;
            sfour_count = sfour_count + 1;
            if (opinions[index].simple) { 
              sfour_sub = sfour[0]
              sfour[0] = sfour_sub + opinions[index].simple 
            } 
            break;
          case 5:
            //five = five + 1;
            sfive_count = sfive_count + 1;
            if (opinions[index].simple) { 
              sfive_sub = sfive[0]
              sfive[0] = sfive_sub + opinions[index].simple 
            }
            break;
          case 6:
           // six = six + 1;
            ssix_count = ssix_count + 1;
            if (opinions[index].simple) { 
              ssix_sub = ssix[0]
              ssix[0] = ssix_sub + opinions[index].simple 
            }
            break;
          case 7:
            //seven = seven + 1;
            sseven_count = sseven_count + 1;
            if (opinions[index].simple) { 
              sseven_sub = sseven[0]
              sseven[0] = sseven_sub + opinions[index].simple 
            }
            break;
          case 8:
           // eight = eight + 1;
            seight_count = seight_count + 1;
            if (opinions[index].simple) { 
              seight_sub = seight[0]
              seight[0] = seight_sub + opinions[index].simple 
            }
            break;
          case 9:
            //nine = nine + 1;
            snine_count = snine_count + 1;
            if (opinions[index].simple) { 
              snine_sub = snine[0]
              snine[0] = snine_sub + opinions[index].simple 
            }
            break;
          case 10:
            //ten = ten + 1;
            sten_count = sten_count + 1;
            if (opinions[index].simple) { 
              sten_sub = sten[0]
              sten[0] = sten_sub + opinions[index].simple 
            }
            break;
          default:
            // code block
        }
      }
      if (opinions[index].simple && (opinions[index].slave !== 11)) { 
        simple_all_count = simple_all_count + 1;
        simple_count = simple_count + opinions[index].simple 
      } 
    })}

     //////////////////////////////////////////////////////////////////
     var islave = slave_all_count > 0 ? slave_count / slave_all_count : 0;
     var isimple = simple_all_count > 0 ? simple_count / simple_all_count : 0;
     //////////////////////////////////////////////////////////////////

    if (opinions) {
     this.setState({
      slave: islave.toFixed(2),
      //simple: isimple.toFixed(2),
     // simpleparticipants: simple_all_count,
      sone_count: sone_count,
      stwo_count: stwo_count,
      sthree_count: sthree_count,
      sfour_count: sfour_count,
      sfive_count: sfive_count,
      ssix_count: ssix_count,
      sseven_count: sseven_count,
      seight_count: seight_count,
      snine_count: snine_count,
      sten_count: sten_count,
      sone_arr: [sone[0]],
      stwo_arr: [stwo[0]],
      sthree_arr: [sthree[0]],
      sfour_arr: [sfour[0]],
      sfive_arr: [sfive[0]],
      ssix_arr: [ssix[0]],
      sseven_arr: [sseven[0]],
      seight_arr: [seight[0],],
      snine_arr: [snine[0],],
      sten_arr: [sten[0]]
     })};

     //insertPollCount into user and users
     const updates = {}

     var finalScore = isimple
     console.log('scores final 1 : ', isimple)
     console.log('scores final 2 : ', finalScore)
     //finalScore = finalScore.toFixed(2);
     console.log('scores final 3 ', finalScore)

     if(finalScore) {
      updates['/users/' + this.props.userdata.userID + '/sfeedback'] = finalScore.toFixed(2);
      updates['/user/' + this.props.userdata.uid + '/sfeedback' ] = finalScore.toFixed(2);
     }
 
     // server: create group - send data to firebase
     firebase.database.ref().update(
       updates,
       function (error) {
         if (error) {
           console.log('success : ');
         } else {
          console.log('err : ', error);
         }
       })
  }

  putSOpinions = () => {
    let { opinions } = this.props
    console.log('putSOpinions 1 ', opinions);

    var simple_count = 0;
    var mysimple;
    // var slave_count = 0;
    // var slave_all_count = 0;
    var simple_all_count = 0;
    
    if (opinions) { Object.keys(opinions).map((opinionsID, index) => {
      console.log('putSOpinions 2', opinions[index]);

      if (opinions[index].simple && (opinions[index].simple > 0) && (opinions[index].slave !== 11)) { 
        simple_all_count = simple_all_count + 1;
        simple_count = simple_count + opinions[index].simple 
        console.log('putSOpinions 3', simple_all_count);
      } 
      if (opinions[index].simple && (opinions[index].simple > 0) && (opinions[index].slave === 11)) { 
        mysimple = opinions[index].simple 
      } 

    })}

     //////////////////////////////////////////////////////////////////
     var isimple = simple_all_count > 0 ? simple_count / simple_all_count : 0;
     //////////////////////////////////////////////////////////////////

    if (opinions) {
     this.setState({
      simple: isimple.toFixed(2),
      ssimple: mysimple,
      simpleparticipants: simple_all_count,
     })};

     //insertPollCount into user and users
     const updates = {}

     var finalScore = isimple
     console.log('putSOpinions 4', finalScore);
     console.log('scoress final 1 : ', isimple)
     console.log('scoress final 2 : ', simple_all_count)

     if(finalScore) {
      updates['/users/' + this.props.userdata.userID + '/sfeedback'] = finalScore.toFixed(2);
      updates['/user/' + this.props.userdata.uid + '/sfeedback' ] = finalScore.toFixed(2);
     }
 
     // server: create group - send data to firebase
     firebase.database.ref().update(
       updates,
       function (error) {
         if (error) {
           console.log('success : ');
         } else {
          console.log('err : ', error);
         }
       })
  }

  handleChange = event => {
    this.setState({
      gtpquestion: event.target.value,
     // showFreeQ: false
    })
  };
  clearQuestion = event => {
    this.setState({
      gtpquestion: ''
    })
    console.log('inside clearQuestion')
  }

  putOpinions = () => {
    let { opinions } = this.props
    console.log('scores final x ', opinions);

    var slave_count = 0;
    var valueA_count = 0;
    var valueB_count = 0;
    var valueC_count = 0;
    var valueD_count = 0;
    var valueE_count = 0;
    var valueF_count = 0;
    var valueG_count = 0;
    var valueH_count = 0;

    var slave_all_count = 0;
    var valueA_all_count = 0;
    var valueB_all_count = 0;
    var valueC_all_count = 0;
    var valueD_all_count = 0;
    var valueE_all_count = 0;
    var valueF_all_count = 0;
    var valueG_all_count = 0;
    var valueH_all_count = 0;

    var one = [0,0,0,0,0,0,0,0];//0;
    var two = [0,0,0,0,0,0,0,0];//0;
    var three = [0,0,0,0,0,0,0,0];//0;
    var four = [0,0,0,0,0,0,0,0];//0;
    var five = [0,0,0,0,0,0,0,0];//0;
    var six = [0,0,0,0,0,0,0,0];//0;
    var seven = [0,0,0,0,0,0,0,0];//0;
    var eight = [0,0,0,0,0,0,0,0];//0;
    var nine = [0,0,0,0,0,0,0,0];//0;
    var ten = [0,0,0,0,0,0,0,0];//0;
    var eleven = [0,0,0,0,0,0,0,0];//0;

    var one_sub = 0;
    var two_sub = 0;
    var three_sub = 0;
    var four_sub = 0;
    var five_sub = 0;
    var six_sub = 0;
    var seven_sub = 0;
    var eight_sub = 0;
    var nine_sub = 0;
    var ten_sub = 0;

    var one_count = 0;
    var two_count = 0;
    var three_count = 0;
    var four_count = 0;
    var five_count = 0;
    var six_count = 0;
    var seven_count = 0;
    var eight_count = 0;
    var nine_count = 0;
    var ten_count = 0;

    if (opinions) { Object.keys(opinions).map((opinionsID, index) => {
      console.log('inside putOpinions 2', opinions[index]);
      if (opinions[index].slave) { 
        slave_all_count = slave_all_count + 1;
        slave_count = opinions[index].slave 
        console.log('green slave_count : ', slave_count);
        switch(slave_count) {
          case 1:
            //one = one + 1;
            one_count = one_count + 1;
            if (opinions[index].valueA) { 
              one_sub = one[0]
              one[0] = one_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              one_sub = one[1]
              one[1] = one_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              one_sub = one[2]
              one[2] = one_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) {
              one_sub = one[3] 
              one[3] = one_sub + opinions[index].valueD 
            } 
            if (opinions[index].valueE) {
              one_sub = one[4] 
              one[4] = one_sub + opinions[index].valueE 
            }
            if (opinions[index].valueF) {
              one_sub = one[5] 
              one[5] = one_sub + opinions[index].valueF 
            }
            if (opinions[index].valueG) {
              one_sub = one[6] 
              one[6] = one_sub + opinions[index].valueG 
            }
            if (opinions[index].valueH) {
              one_sub = one[7] 
              one[7] = one_sub + opinions[index].valueH
            } 
            break;
          case 2:
            //two = two + 1;
            two_count = two_count + 1;
            if (opinions[index].valueA) { 
              two_sub = two[0]
              two[0] = two_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              two_sub = two[1]
              two[1] = two_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              two_sub = two[2]
              two[2] = two_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              two_sub = two[3]
              two[3] = two_sub +  opinions[index].valueD 
            } 
            if (opinions[index].valueE) { 
              two_sub = two[4]
              two[4] = two_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              two_sub = two[5]
              two[5] = two_sub +  opinions[index].valueF 
            }
            if (opinions[index].valueG) { 
              two_sub = two[6]
              two[6] = two_sub +  opinions[index].valueG 
            }
            if (opinions[index].valueH) { 
              two_sub = two[7]
              two[7] = two_sub +  opinions[index].valueH 
            }
            break;
          case 3:
            //three = three + 1;
            three_count = three_count + 1;
            if (opinions[index].valueA) { 
              three_sub = three[0]
              three[0] = three_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              three_sub = three[1]
              three[1] = three_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              three_sub = three[2]
              three[2] = three_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              three_sub = three[3]
              three[3] = three_sub +  opinions[index].valueD 
            }  
            if (opinions[index].valueE) { 
              three_sub = three[4]
              three[4] = three_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              three_sub = three[5]
              three[5] = three_sub +  opinions[index].valueF 
            } 
            if (opinions[index].valueG) { 
              three_sub = three[6]
              three[6] = three_sub +  opinions[index].valueG 
            } 
            if (opinions[index].valueH) { 
              three_sub = three[7]
              three[7] = three_sub +  opinions[index].valueH 
            }           
            break;
          case 4:
            //four = four + 1;
            four_count = four_count + 1;
            if (opinions[index].valueA) { 
              four_sub = four[0]
              four[0] = four_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              four_sub = four[1]
              four[1] = four_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              four_sub = four[2]
              four[2] = four_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              four_sub = four[3]
              four[3] = four_sub +  opinions[index].valueD 
            } 
            if (opinions[index].valueE) { 
              four_sub = four[4]
              four[4] = four_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              four_sub = four[5]
              four[5] = four_sub +  opinions[index].valueF
            }  
            if (opinions[index].valueG) { 
              four_sub = four[6]
              four[6] = four_sub +  opinions[index].valueG 
            }  
            if (opinions[index].valueH) { 
              four_sub = four[7]
              four[7] = four_sub +  opinions[index].valueH 
            }    
            break;
          case 5:
            //five = five + 1;
            five_count = five_count + 1;
            if (opinions[index].valueA) { 
              five_sub = five[0]
              five[0] = five_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              five_sub = five[1]
              five[1] = five_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              five_sub = five[2]
              five[2] = five_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              five_sub = five[3]
              five[3] = five_sub +  opinions[index].valueD 
            } 
            if (opinions[index].valueE) { 
              five_sub = five[4]
              five[4] = five_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              five_sub = five[5]
              five[5] = five_sub +  opinions[index].valueF 
            } 
            if (opinions[index].valueG_all_count) { 
              five_sub = five[6]
              five[6] = five_sub +  opinions[index].valueG
            } 
            if (opinions[index].valueH) { 
              five_sub = five[7]
              five[7] = five_sub +  opinions[index].valueH 
            } 
            break;
          case 6:
           // six = six + 1;
            six_count = six_count + 1;
            if (opinions[index].valueA) { 
              six_sub = six[0]
              six[0] = six_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              six_sub = six[1]
              six[1] = six_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              six_sub = six[2]
              six[2] = six_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              six_sub = six[3]
              six[3] = six_sub +  opinions[index].valueD 
            }
            if (opinions[index].valueE) { 
              six_sub = six[4]
              six[4] = six_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              six_sub = six[5]
              six[5] = six_sub +  opinions[index].valueF 
            } 
            if (opinions[index].valueG) { 
              six_sub = six[6]
              six[6] = six_sub +  opinions[index].valueG 
            } 
            if (opinions[index].valueH) { 
              six_sub = six[7]
              six[7] = six_sub +  opinions[index].valueH 
            }  
            break;
          case 7:
            //seven = seven + 1;
            seven_count = seven_count + 1;
            if (opinions[index].valueA) { 
              seven_sub = seven[0]
              seven[0] = seven_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              seven_sub = seven[1]
              seven[1] = seven_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              seven_sub = seven[2]
              seven[2] = seven_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              seven_sub = seven[3]
              seven[3] = seven_sub +  opinions[index].valueD 
            } 
            if (opinions[index].valueE) { 
              seven_sub = seven[4]
              seven[4] = seven_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              seven_sub = seven[5]
              seven[5] = seven_sub +  opinions[index].valueF
            }
            if (opinions[index].valueG) { 
              seven_sub = seven[6]
              seven[6] = seven_sub +  opinions[index].valueG 
            }
            if (opinions[index].valueH) { 
              seven_sub = seven[7]
              seven[7] = seven_sub +  opinions[index].valueH 
            }
            break;
          case 8:
           // eight = eight + 1;
            eight_count = eight_count + 1;
            if (opinions[index].valueA) { 
              eight_sub = eight[0]
              eight[0] = eight_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              eight_sub = eight[1]
              eight[1] = eight_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              eight_sub = eight[2]
              eight[2] = eight_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              eight_sub = eight[3]
              eight[3] = eight_sub +  opinions[index].valueD 
            } 
            if (opinions[index].valueE) { 
              eight_sub = eight[4]
              eight[4] = eight_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              eight_sub = eight[5]
              eight[5] = eight_sub +  opinions[index].valueF 
            }
            if (opinions[index].valueG) { 
              eight_sub = eight[6]
              eight[6] = eight_sub +  opinions[index].valueG 
            }
            if (opinions[index].valueH) { 
              eight_sub = eight[7]
              eight[7] = eight_sub +  opinions[index].valueH 
            }
            break;
          case 9:
            //nine = nine + 1;
            nine_count = nine_count + 1;
            if (opinions[index].valueA) { 
              nine_sub = nine[0]
              nine[0] = nine_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              nine_sub = nine[1]
              nine[1] = nine_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              nine_sub = nine[2]
              nine[2] = nine_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              nine_sub = nine[3]
              nine[3] = nine_sub +  opinions[index].valueD 
            } 
            if (opinions[index].valueE) { 
              nine_sub = nine[4]
              nine[4] = nine_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              nine_sub = nine[5]
              nine[5] = nine_sub +  opinions[index].valueF 
            }
            if (opinions[index].valueG) { 
              nine_sub = nine[6]
              nine[6] = nine_sub +  opinions[index].valueG 
            }
            if (opinions[index].valueH) { 
              nine_sub = nine[7]
              nine[7] = nine_sub +  opinions[index].valueH 
            }
            break;
          case 10:
            //ten = ten + 1;
            ten_count = ten_count + 1;
            if (opinions[index].valueA) { 
              ten_sub = ten[0]
              ten[0] = ten_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              ten_sub = ten[1]
              ten[1] = ten_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              ten_sub = ten[2]
              ten[2] = ten_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              ten_sub = ten[3]
              ten[3] = ten_sub +  opinions[index].valueD 
            } 
            if (opinions[index].valueE) { 
              ten_sub = ten[4]
              ten[4] = ten_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              ten_sub = ten[5]
              ten[5] = ten_sub +  opinions[index].valueF
            } 
            if (opinions[index].valueG) { 
              ten_sub = ten[6]
              ten[6] = ten_sub +  opinions[index].valueG 
            } 
            if (opinions[index].valueH) { 
              ten_sub = ten[7]
              ten[7] = ten_sub +  opinions[index].valueH 
            } 
            break;
          case 11: //user evaluated themselfves
              if (opinions[index].valueA) { 
                eleven[0] = opinions[index].valueA 
              }
              if (opinions[index].valueB) { 
                eleven[1] = opinions[index].valueB 
              }
              if (opinions[index].valueC) { 
                eleven[2] = opinions[index].valueC 
              }   
              if (opinions[index].valueD) { 
                eleven[3] = opinions[index].valueD 
              } 
              if (opinions[index].valueE) { 
                eleven[4] = opinions[index].valueE 
              } 
              if (opinions[index].valueF) { 
                eleven[5] = opinions[index].valueF
              } 
              if (opinions[index].valueG) { 
                eleven[6] = opinions[index].valueG 
              } 
              if (opinions[index].valueH) { 
                eleven[7] = opinions[index].valueH 
              } 
              break;          
          default:
            // code block
        }
      }
     if (opinions[index].slave !== 11) { //dont count my own eval
      if (opinions[index].valueA) { 
        valueA_all_count = valueA_all_count + 1;
        valueA_count = valueA_count + opinions[index].valueA 
      }
      if (opinions[index].valueB) { 
        valueB_all_count = valueB_all_count + 1;
        valueB_count = valueB_count + opinions[index].valueB 
      }
      if (opinions[index].valueC) { 
        valueC_all_count = valueC_all_count + 1;
        valueC_count = valueC_count + opinions[index].valueC 
      }   
      if (opinions[index].valueD) { 
        valueD_all_count = valueD_all_count + 1;
        valueD_count = valueD_count + opinions[index].valueD 
      }  
      if (opinions[index].valueE) { 
        valueE_all_count = valueE_all_count + 1;
        valueE_count = valueE_count + opinions[index].valueE 
      } 
      if (opinions[index].valueF) { 
        valueF_all_count = valueF_all_count + 1;
        valueF_count = valueF_count + opinions[index].valueF 
      } 
      if (opinions[index].valueG) { 
        valueG_all_count = valueG_all_count + 1;
        valueG_count = valueG_count + opinions[index].valueG
      } 
      if (opinions[index].valueH) { 
        valueH_all_count = valueH_all_count + 1;
        valueH_count = valueH_count + opinions[index].valueH 
      } 
    }  
    })}

     //////////////////////////////////////////////////////////////////
     var islave = slave_all_count > 0 ? slave_count / slave_all_count : 0;
     var ivalueA = valueA_all_count > 0 ? valueA_count / valueA_all_count : 0;
     var ivalueB = valueB_all_count > 0 ? valueB_count / valueB_all_count : 0;
     var ivalueC = valueC_all_count > 0 ? valueC_count / valueC_all_count : 0;
     var ivalueD = valueD_all_count > 0 ? valueD_count / valueD_all_count : 0;
     var ivalueE = valueE_all_count > 0 ? valueE_count / valueE_all_count : 0;
     var ivalueF = valueF_all_count > 0 ? valueF_count / valueF_all_count : 0;
     var ivalueG = valueG_all_count > 0 ? valueG_count / valueG_all_count : 0;
     var ivalueH = valueH_all_count > 0 ? valueH_count / valueH_all_count : 0;

     //////////////////////////////////////////////////////////////////

    if (opinions) {
     this.setState({
      slave: islave.toFixed(2),
      valueA: ivalueA.toFixed(2),
      valueB: ivalueB.toFixed(2),
      valueC: ivalueC.toFixed(2),
      valueD: ivalueD.toFixed(2),
      valueE: ivalueE.toFixed(2),
      valueF: ivalueF.toFixed(2),
      valueG: ivalueG.toFixed(2),
      valueH: ivalueH.toFixed(2),

      svalueA: eleven[0],
      svalueB: eleven[1],
      svalueC: eleven[2],
      svalueD: eleven[3],
      svalueE: eleven[4],
      svalueF: eleven[5],
      svalueG: eleven[6],
      svalueH: eleven[7], 

      valueAparticipants: valueA_all_count,
      slaveparticipants: slave_all_count,
      valueBparticipants: valueB_all_count,
      valueCparticipants: valueC_all_count,
      valueDparticipants: valueD_all_count,
      valueEparticipants: valueE_all_count,
      valueFparticipants: valueF_all_count,
      valueGparticipants: valueG_all_count,
      valueHparticipants: valueH_all_count,
      one_count: one_count,
      two_count: two_count,
      three_count: three_count,
      four_count: four_count,
      five_count: five_count,
      six_count: six_count,
      seven_count: seven_count,
      eight_count: eight_count,
      nine_count: nine_count,
      ten_count: ten_count,
      one_arr: [one[0],one[1],one[2],one[3],one[4],one[5],one[6],one[7]],
      two_arr: [two[0],two[1],two[2],two[3],two[4],two[5],two[6],two[7]],
      three_arr: [three[0],three[1],three[2],three[3],three[4],three[5],three[6],three[7]],
      four_arr: [four[0],four[1],four[2],four[3],four[4],four[5],four[6],four[7]],
      five_arr: [five[0],five[1],five[2],five[3],five[4],five[5],five[6],five[7]],
      six_arr: [six[0],six[1],six[2],six[3],six[4],six[5],six[6],six[7]],
      seven_arr: [seven[0],seven[1],seven[2],seven[3],seven[4],seven[5],seven[6],seven[7]],
      eight_arr: [eight[0],eight[1],eight[2],eight[3],eight[4],eight[5],eight[6],eight[7]],
      nine_arr: [nine[0],nine[1],nine[2],nine[3],nine[4],nine[5],nine[6],nine[7]],
      ten_arr: [ten[0],ten[1],ten[2],ten[3],ten[4],ten[5],ten[6],ten[7]]
     })};

     //insertPollCount into user and users
     const updates = {}

     var finalScore = (ivalueA + ivalueB + ivalueC + ivalueD + ivalueE + ivalueF + ivalueG + ivalueH)/8
     console.log('scores final 1 : ', ivalueA + ' ' + ivalueB + ' ' + ivalueC + ' ' + ivalueD + ' ' + ivalueE + ' ' + ivalueF + ' ' + ivalueG + ' ' + ivalueH)
    
     console.log('scores-final-finalScore 4 : ', finalScore)
     //finalScore = finalScore.toFixed(2);
     console.log('scores final 3 ', finalScore)

     if(finalScore) {
      updates['/users/' + this.props.userdata.userID + '/feedback'] = finalScore.toFixed(2);
      updates['/user/' + this.props.userdata.uid + '/feedback' ] = finalScore.toFixed(2);
     }
 
     // server: create group - send data to firebase
     firebase.database.ref().update(
       updates,
       function (error) {
         if (error) {
           console.log('success : ');
         } else {
          console.log('err : ', error);
         }
       })
  }

  insertPollCount = () => {
    const updates = {}
    var finalScore = (this.state.valueA + this.state.valueB + this.state.valueC + this.state.valueD + this.state.valueE + this.state.valueF + this.state.valueG + this.state.valueH)/8
     finalScore = parseInt(finalScore);
    updates['/users/' + this.props.userdata.userID + '/feedback'] = finalScore;
    
    updates['/user/' + this.props.userdata.uid + '/feedback' ] = finalScore;

    // server: create group - send data to firebase
    firebase.database.ref().update(
      updates,
      function (error) {
        if (error) {
          console.log('success : ');
        } else {
         console.log('err : ', error);
        }
      })
  }

   getOpinion = (props) => {
    for (let x in props) {
      return props[x].valueA
    }
   }

   roundOff = (nr) => {
    var fnr = 0;
    fnr = nr.toFixed(0);
    return fnr;
   }

   filterZZZ = (comLable) => {
    let comLablex = comLable.toString()
    let myTruncatedString = comLablex.substring(0, 4)
    let finalstring = comLablex

    if (myTruncatedString === 'ZZZ_') {
      finalstring = comLablex.substring(4, comLablex.length)
    }
    if (myTruncatedString === 'AAA_') {
      finalstring = comLablex.substring(4, comLablex.length)
    }
    return finalstring
  };

  lastResp = (ans) => {
    this.setState({lastAnswer: ans})
  }


  betaDesc = () => {
    if (this.state.showing==='none') {
       this.setState({ showing: 'block' })
    }
    else
    {
      this.setState({ showing: 'none' })
    }
    console.log('betaDesc showing : ', this.state.showing);
  }

  render() {
    const { classes, companyValues, userdata, pldpTasks, pldpAllMyTasks, opinions, showing } = this.props
    console.log('[Tcompany values list]', companyValues)
    console.log('[Tcompany values type]', typeof companyValues)
    console.log('green leaderboard feedback : ', this.props.userdata.execmember);
    console.log('ledain props : ', userdata)

    const { tabsvalue, openedTasks } = this.state
    groupTasks = Object.values(pldpTasks).groupBy('moveAction')
    delete groupTasks.undefined
    const competenciesFound = includedCompetencies(openedTasks, pldpAllMyTasks)

    var alist = []
    return (
      <div className={classes.root}>
        <Grid container spacing={2} className={classes.header}>
          <Grid item sm={10} xs={6}>
            <Typography variant='subtitle2' gutterBottom>
              <div style={{ fontSize: 16, alignSelf: 'center' }}>
                <AccountBalance style={{ fontSize: 35, padding: 10 }}/>
             { userdata ?
                <Link to={{
                  pathname: '/Poe',
                  state: {
                    Everyone: userdata.userID
                  }
                }}
              >              
                <Button
                  size='small'
                  color='#000000'
                >
                  <font style={{
                    margin:"12px",
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    color: 'rgba(0,0,0,1)',
                    fontSize: '12px'
                  }}><b>&nbsp;&nbsp;Portfolio of Evidence of points</b></font>
                </Button>
                </Link>
                : null}
                
              </div>
            </Typography>
          </Grid>
          <Grid item sm={2} xs={6} style={{ textAlign: 'center' }}>

            <Button
              variant='contained'
              onClick={this.addAction}
              // color='primary'
              startIcon={<AddIcon />}
              className={classes.addIconstyles}
            >
               Add
            </Button>
            {/* </Button> */}
          </Grid>
          <Grid>
            <GenTutorial
               // url={'https://youtu.be/rsn6Vec3fQA'}
                url={'https://youtu.be/s0WvGR94oLQ'}
              />
          </Grid>

          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <RadioGroup
                name='filteredtasks'
                selectedValue={this.state.filterby}
                onChange={this.handleFilteredTasks}
              >
                <Radio value='open' /> Active
                <Radio value='complete' style={{ marginLeft: '8px' }} /> Completed
                <Radio value='feedback' style={{ marginLeft: '8px' }} /> Feedback
                <Radio value='all' style={{ marginLeft: '8px' }} /> All (POE)
              </RadioGroup>
            </div>
          </Grid>
        </Grid>
        {tabsvalue === 0 && (
          <TabContainer>
            <Grid container spacing={2}>
              {companyValues &&

                  Object.keys(companyValues).map(id => {
                    const labelId = `${companyValues[id].valueID}`
                    const labelName = `${companyValues[id].valueDesc}`
                    let tasksCount = 0


                    console.log('[companyValues]', companyValues[id])
                    if (groupTasks[labelId]) {
                      console.log('[Task info]', groupTasks[labelId])
                      console.log('[Task info type]', typeof groupTasks[labelId])
                      tasksCount = this.getTasksCount(groupTasks[labelId], labelName)
                    }

                    if (labelName !== 'undefined' && tasksCount > 0) {
                      return (
                        <Grid item xs={12} sm={12} lg={12}>
                          <ExpansionPanel expanded className={classes.panel}>
                            <ExpansionPanelSummary style={{ alignItems: 'center' }}>
 
                              <Typography
                                gutterBottom
                                variant='h6'
                                className={classes.title}

                              >

                                {labelName}
                                <Typography variant='caption' style={{ padding: '0px 15px', color: '#7393B3' }}>
                                  {labelName === 'Strategic Leadership' || labelName === 'Inclusivity' || labelName === 'Inspiration' || labelName === 'Ethical Leadership' || labelName === 'Accountability' || labelName === 'Innovation' || labelName === 'Steadfastness' || labelName === 'Balance' ? 'Leadership Competency' : 'Leadership Task'}
                                </Typography>
                              </Typography>

                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails
                              style={{
                                padding: '8px 8px 24px'
                              }}
                            >

                              <MyTasks
                                groupTasks={groupTasks[labelId]}
                                compid={companyValues[id].valueID}
                                editTask={this.editTask.bind(this)}
                                completeTask={this.onMarkComplete.bind(this)}

                              />
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </Grid>
                      )
                    }
                    return null
                  })
              }

          {companyValues && Object.keys(companyValues).map(id => {
            const labelId = `${companyValues[id].valueID}`
            const labelName = `${companyValues[id].valueDesc}`
            let tasksCount = 0;

            // var alist = []

            console.log('[companyValues]', companyValues[id])
            if (groupTasks[labelId]) {
              ///////////////////////////////////////
              var tlist = groupTasks[labelId]

              console.log('[Task info]', groupTasks[labelId])
              console.log('[Task info Title]', groupTasks[labelId])
              console.log('[Task info type]', typeof groupTasks[labelId])
              tasksCount = this.getTasksCount(groupTasks[labelId], labelName)
            }

            if (labelName !== 'undefined' && tasksCount === 0) {
              return (
                <Grid item xs={12} sm={12} lg={12}>
                  <div className={classes.unopendList}>
                    <Typography
                      gutterBottom
                      variant='h6'
                      className={classes.unopendtitle}
                    >

                      {labelName}
                      <Typography variant='caption' style={{ padding: '0px 15px', color: '#e5e4e2' }}>
                        {labelName === 'Strategic Leadership' || labelName === 'Inclusivity' || labelName === 'Inspiration' || labelName === 'Ethical Leadership' || labelName === 'Accountability' || labelName === 'Innovation' || labelName === 'Steadfastness' || labelName === 'Balance' ? 'Leadership Competency' : 'Leadership Task'} 
                      </Typography>
                    </Typography>
                  </div>
                </Grid>
              )
            }

            return null
          })
          }

            </Grid>
          </TabContainer>
        )}
        <a name="feedback"></a>
        {tabsvalue === 1 && (
          <TabContainer>
            <TasksListView
              pldpAllMyTasks={pldpAllMyTasks}
              competenciesFound={competenciesFound}
              companyValues={companyValues}
              userdata={userdata}
              filterZZZ={this.filterZZZ}
              editTask={this.editTask.bind(this)}
              completeTask={this.onMarkComplete.bind(this)} />
          </TabContainer>
        )}

        {(tabsvalue === 2 || tabsvalue === 1 ) && (this.props.userdata.execmember) && (
           (
            <TabContainer>
              <div>&nbsp; </div>
              <div><b>Feedback Stats and Opinions</b></div>
              <div>
              <MDBContainer>  
                    <MDBRow> 
                      <MDBCol style={tableC} >
                      <b> Opinion</b>
                      </MDBCol>          
                      <MDBCol style={tableCa} >
                      <b> Mean Score</b>
                      </MDBCol> 
                      <MDBCol style={tableCa} >
                      <b> Participants</b>
                      </MDBCol> 
                      <MDBCol style={tableCa} >
                      <b> My Own Score</b>
                      </MDBCol> 
                    </MDBRow>  
                      {/* //////////////////////////////////////////////////////////////// */}

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                      <b>Realtime Feedback score:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                        { this.state.simple } 
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.simpleparticipants }
                      </MDBCol>  
                      <MDBCol style={tableM} colspan='5'>
                      { this.state.ssimple }
                      </MDBCol> 
                    </MDBRow>  
             
                    <MDBRow> 
                      <MDBCol style={tableMh} >
                      <b>Strategic Leadership:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueA }
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.valueAparticipants}
                      </MDBCol>  
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueA }
                      </MDBCol>                                          
                    </MDBRow>  

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> Inclusivity :</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueB}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.valueBparticipants}
                      </MDBCol>  
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueB}
                      </MDBCol>                                         
                    </MDBRow>  

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> Inspiration:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueC}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.valueCparticipants}
                      </MDBCol>  
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueC}
                      </MDBCol> 
                    </MDBRow>  
                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> Ethical Leadership:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueD}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='9'>
                       { this.state.valueDparticipants}
                      </MDBCol>  
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueD}
                      </MDBCol>                                         
                    </MDBRow>  
 
                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> Accountability:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueE}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.valueEparticipants}
                      </MDBCol> 
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueE}
                      </MDBCol>                                          
                    </MDBRow>  
                    
                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> Innovation:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueF}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.valueFparticipants}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueF}
                      </MDBCol>                                        
                    </MDBRow>  

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> Steadfastness:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueG}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.valueGparticipants}
                      </MDBCol>  
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueG}
                      </MDBCol>                                         
                    </MDBRow>  

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> Balance:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueH}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.valueHparticipants}
                      </MDBCol>    
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueH}
                      </MDBCol>                                       
                    </MDBRow> 

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                      &nbsp;
                      </MDBCol> 
                      <MDBCol style={tableM} >
                      &nbsp;
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                      &nbsp;
                      </MDBCol>    
                      <MDBCol style={tableM} colspan='5'>
                      &nbsp;
                      </MDBCol>                                       
                    </MDBRow> 

                    <MDBRow> 
                  {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Realtime Feedback: </i></b>
                    :null}
                    </MDBRow>                     
                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                      opinions[index].simpleopinion && opinions[index].leaderID!==opinions[index].slaveID ?
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].simpleopinion }
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol >
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow> 
                      </React.Fragment>    
                     :
                     <React.Fragment>  
                     <MDBRow>
                      <MDBCol style={tableHself}>
                      { opinions[index].simpleopinion }
                     </MDBCol>
                     </MDBRow>    
                      <MDBRow>
                      <MDBCol >
                        &nbsp;&nbsp;
                     </MDBCol>
                     </MDBRow> 
                     </React.Fragment>   
                     ) 
                   })}                                       
                    {/* /////////////////////////////////////////////////////////////////// */}
                    <React.Fragment>  
                       <MDBRow>
                       <MDBCol>
                       {/* <ChatGPT
                          ask={this.state.simpleopinion} 
                        /> */}
                   
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>                     
 
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Strategic Leadership: </i></b>
                    :null}
                    </MDBRow>                     
                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                      opinions[index].valueAopinion && opinions[index].leaderID!==opinions[index].slaveID ?
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].valueAopinion }
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol >
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow> 
                      </React.Fragment>   
                      :
                      <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableHself}>
                      { opinions[index].valueAopinion }
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol >
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow> 
                      </React.Fragment>    
                     ) 
                   })} 
                   {/* /////////////////////////////////////////////////////////////////// */}
                   <React.Fragment>  
                       <MDBRow>
                       <MDBCol>

                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>   

                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Inclusivity: </i></b>
                    :null}
                    </MDBRow>                     
                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                      opinions[index].valueBopinion && opinions[index].leaderID!==opinions[index].slaveID ?
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].valueBopinion }
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                            
                     </React.Fragment>    
                    :
                    <React.Fragment>  
                    <MDBRow>
                     <MDBCol style={tableHself}>
                    { opinions[index].valueBopinion }
                    </MDBCol>
                    </MDBRow>    
                     <MDBRow>
                     <MDBCol >
                       &nbsp;&nbsp;
                    </MDBCol>
                    </MDBRow> 
                    </React.Fragment>  
                    ) 
                   })}
                   {/* /////////////////////////////////////////////////////////////////// */}
                   <React.Fragment>  
                       <MDBRow>
                       <MDBCol>    
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>   


                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Inspiration: </i></b>
                    :null}
                    </MDBRow>                     
                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                      opinions[index].valueCopinion && opinions[index].leaderID!==opinions[index].slaveID ?
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].valueCopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     :
                     <React.Fragment>  
                     <MDBRow>
                      <MDBCol style={tableHself}>
                     { opinions[index].valueCopinion }
                     </MDBCol>
                     </MDBRow>    
                      <MDBRow>
                      <MDBCol >
                        &nbsp;&nbsp;
                     </MDBCol>
                     </MDBRow> 
                     </React.Fragment>  
                     ) 
                   })}

                   {/* /////////////////////////////////////////////////////////////////// */}
                   <React.Fragment>  
                       <MDBRow>
                       <MDBCol>
        
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>   

                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Ethical Leadership</i></b>
                    :null}
                    </MDBRow>    

                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                      opinions[index].valueDopinion && opinions[index].leaderID!==opinions[index].slaveID ?
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].valueDopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     :
                     <React.Fragment>  
                     <MDBRow>
                      <MDBCol style={tableHself}>
                     { opinions[index].valueDopinion }
                     </MDBCol>
                     </MDBRow>    
                      <MDBRow>
                      <MDBCol >
                        &nbsp;&nbsp;
                     </MDBCol>
                     </MDBRow> 
                     </React.Fragment>  
                     ) 
                   })}
                   {/* /////////////////////////////////////////////////////////////////// */}
                   <React.Fragment>  
                       <MDBRow>
                       <MDBCol>
        
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>   

                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Accountability</i></b>
                    :null}
                    </MDBRow>    
                    
                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                      opinions[index].valueEopinion && opinions[index].leaderID!==opinions[index].slaveID ?
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].valueEopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     :
                     <React.Fragment>  
                     <MDBRow>
                      <MDBCol style={tableHself}>
                     { opinions[index].valueEopinion }
                     </MDBCol>
                     </MDBRow>    
                      <MDBRow>
                      <MDBCol >
                        &nbsp;&nbsp;
                     </MDBCol>
                     </MDBRow> 
                     </React.Fragment>  
                     ) 
                   })}
                   {/* /////////////////////////////////////////////////////////////////// */}
                   <React.Fragment>  
                       <MDBRow>
                       <MDBCol>
        
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>   

                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Innovation</i></b>
                    :null}
                    </MDBRow>    

                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                      opinions[index].valueFopinion && opinions[index].leaderID!==opinions[index].slaveID ?
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].valueFopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     :
                     <React.Fragment>  
                     <MDBRow>
                      <MDBCol style={tableHself}>
                     { opinions[index].valueFopinion }
                     </MDBCol>
                     </MDBRow>    
                      <MDBRow>
                      <MDBCol >
                        &nbsp;&nbsp;
                     </MDBCol>
                     </MDBRow> 
                     </React.Fragment>  
                     ) 
                   })}
                   {/* /////////////////////////////////////////////////////////////////// */}
                   <React.Fragment>  
                       <MDBRow>
                       <MDBCol>
        
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>   

                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Steadfastness</i></b>
                    :null}
                    </MDBRow>    

                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                      opinions[index].valueGopinion && opinions[index].leaderID!==opinions[index].slaveID ?
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].valueGopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     :
                     <React.Fragment>  
                     <MDBRow>
                      <MDBCol style={tableHself}>
                     { opinions[index].valueGopinion }
                     </MDBCol>
                     </MDBRow>    
                      <MDBRow>
                      <MDBCol >
                        &nbsp;&nbsp;
                     </MDBCol>
                     </MDBRow> 
                     </React.Fragment>  
                     ) 
                   })}
                   {/* /////////////////////////////////////////////////////////////////// */}
                   <React.Fragment>  
                       <MDBRow>
                       <MDBCol>
        
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>   

                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Balance</i></b>
                    :null}
                    </MDBRow>    

                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                      opinions[index].valueHopinion && opinions[index].leaderID!==opinions[index].slaveID ?
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].valueHopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     :
                     <React.Fragment>  
                     <MDBRow>
                      <MDBCol style={tableHself}>
                     { opinions[index].valueHopinion }
                     </MDBCol>
                     </MDBRow>    
                      <MDBRow>
                      <MDBCol >
                        &nbsp;&nbsp;
                     </MDBCol>
                     </MDBRow> 
                     </React.Fragment>  
                     ) 
                   })}
                   {/* /////////////////////////////////////////////////////////////////// */}
                    <React.Fragment>  
                       <MDBRow>
                       <MDBCol>
                   
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>   

                   
            {/* ////////////////////////////////////////////////////////////////////////// */}
                   <MDBRow>   
                     <b><i>Statistics Breakdown</i></b>
                    </MDBRow>  

                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                       <b>Participant Categories ({this.state.slaveparticipants})</b>
                      </MDBCol>   
                      <MDBCol style={tableM} >
                       <b>Nr</b>
                      </MDBCol> 

                      <MDBCol style={tableM} >
                       <b>Strategic Leadership</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>Inclusivity</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>Inspiration</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>Ethical Leadership</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>Accountability</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>Innovation</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>Steadfastness</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       <b>Balance</b>
                      </MDBCol> 

                      <MDBCol style={tableM} >
                       <b>Tot %</b>
                      </MDBCol>                                         
                    </MDBRow> 



                    <MDBRow> 
                      <MDBCol style={tableWh} colspan='2' size="3">
                      I report to the leader directly
                      </MDBCol>   
                      <MDBCol style={tableW} >
                       {this.state.one_count}
                      </MDBCol> 


                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[0]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[1]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[2]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[3]/this.state.one_count) }
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[4]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[5]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[6]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.one_arr[7]/this.state.one_count) }
                      </MDBCol> 

                      <MDBCol style={tableW} >
                       {this.roundOff((this.state.one_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol>                      

                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                      The leader reports to me
                      </MDBCol>   
                      <MDBCol style={tableM} >
                       {this.state.two_count}
                      </MDBCol>  
                       

                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[0]/this.state.two_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[1]/this.state.two_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[2]/this.state.two_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[3]/this.state.two_count) }
                      </MDBCol>                      
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[4]/this.state.two_count) }
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[5]/this.state.two_count) }
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[6]/this.state.two_count) }
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.two_arr[7]/this.state.two_count) }
                      </MDBCol>  

                      <MDBCol style={tableM} >
                       {this.roundOff((this.state.two_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol> 
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableWh} colspan='2' size="3">
                      I work with the leader as a peer, colleague
                      </MDBCol>   
                      <MDBCol style={tableW} >
                       {this.state.three_count}
                      </MDBCol>    
                       

                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[0]/this.state.three_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[1]/this.state.three_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[2]/this.state.three_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[3]/this.state.three_count)}
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[4]/this.state.three_count)}
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[5]/this.state.three_count)}
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[6]/this.state.three_count)}
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.three_arr[7]/this.state.three_count)}
                      </MDBCol> 

                      <MDBCol style={tableW} >
                       {this.roundOff((this.state.three_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol> 
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                      I am an employee/member of the organisation
                      </MDBCol>   
                      <MDBCol style={tableM} >
                       {this.state.four_count}
                      </MDBCol> 
                      

                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[0]/this.state.four_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[1]/this.state.four_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[2]/this.state.four_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[3]/this.state.four_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[4]/this.state.four_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[5]/this.state.four_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[6]/this.state.four_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.four_arr[7]/this.state.four_count)}
                      </MDBCol> 

                      <MDBCol style={tableM} >
                       {this.roundOff((this.state.four_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol>  
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableWh} colspan='2' size="3">
                      I am merely a concerned citizen / distant shareholder
                      </MDBCol>   
                      <MDBCol style={tableW} >
                       {this.state.five_count}
                      </MDBCol>  
                        

                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[0]/this.state.five_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[1]/this.state.five_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[2]/this.state.five_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[3]/this.state.five_count)}
                      </MDBCol>                       
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[4]/this.state.five_count)}
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[5]/this.state.five_count)}
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[6]/this.state.five_count)}
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.five_arr[7]/this.state.five_count)}
                      </MDBCol> 

                      <MDBCol style={tableW} >
                       {this.roundOff((this.state.five_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol> 
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                      I am an outside supplier/client to this organisation that the leader leads
                      </MDBCol>   
                      <MDBCol style={tableM} >
                       {this.state.six_count}
                      </MDBCol>
                        

                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[0]/this.state.six_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[1]/this.state.six_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[2]/this.state.six_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[3]/this.state.six_count)}
                      </MDBCol>      
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[4]/this.state.six_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[5]/this.state.six_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[6]/this.state.six_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.six_arr[7]/this.state.six_count)}
                      </MDBCol> 

                      <MDBCol style={tableM} >
                       {this.roundOff((this.state.six_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol> 

                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableWh} colspan='2' size="3">
                      I work with the leader from an outside community
                      </MDBCol>   
                      <MDBCol style={tableW} >
                       {this.state.seven_count}
                      </MDBCol>  

                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[0]/this.state.seven_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[1]/this.state.seven_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[2]/this.state.seven_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[3]/this.state.seven_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[4]/this.state.seven_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[5]/this.state.seven_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[6]/this.state.seven_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.seven_arr[7]/this.state.seven_count)}
                      </MDBCol>  

                      <MDBCol style={tableW} >
                       {this.roundOff((this.state.seven_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol>  
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                      The leader is my direct coach/captain
                      </MDBCol>   
                      <MDBCol style={tableM} >
                       {this.state.eight_count}
                      </MDBCol> 
                        

                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[0]/this.state.eight_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[1]/this.state.eight_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[2]/this.state.eight_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[3]/this.state.eight_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[4]/this.state.eight_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[5]/this.state.eight_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[6]/this.state.eight_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.eight_arr[7]/this.state.eight_count)}
                      </MDBCol>  

                      <MDBCol style={tableM} >
                       {this.roundOff((this.state.eight_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol>  
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableWh} colspan='2' size="3">
                      I am an active student of the leaders work
                      </MDBCol>   
                      <MDBCol style={tableW} >
                       {this.state.nine_count}
                      </MDBCol>  
                        

                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.nine_arr[0]/this.state.nine_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.nine_arr[1]/this.state.nine_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.nine_arr[2]/this.state.nine_count) }
                      </MDBCol> 
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.nine_arr[3]/this.state.nine_count)}
                      </MDBCol>  
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.nine_arr[4]/this.state.nine_count)}
                      </MDBCol>
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.nine_arr[5]/this.state.nine_count)}
                      </MDBCol>
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.nine_arr[6]/this.state.nine_count)}
                      </MDBCol>
                      <MDBCol style={tableW} >
                       {this.roundOff(this.state.nine_arr[7]/this.state.nine_count)}
                      </MDBCol>

                      <MDBCol style={tableW} >
                       {this.roundOff((this.state.nine_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol> 

                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                      Other
                      </MDBCol>   
                      <MDBCol style={tableM} >
                       {this.state.ten_count}
                      </MDBCol> 
                      

                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[0]/this.state.ten_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[1]/this.state.ten_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[2]/this.state.ten_count) }
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[3]/this.state.ten_count)}
                      </MDBCol>  
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[4]/this.state.ten_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[5]/this.state.ten_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[6]/this.state.ten_count)}
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       {this.roundOff(this.state.ten_arr[7]/this.state.ten_count)}
                      </MDBCol> 

                      <MDBCol style={tableM} >
                       {this.roundOff((this.state.ten_count / this.state.slaveparticipants) * 100) } %
                      </MDBCol>  
                    </MDBRow> 
                    <MDBRow> 
                    </MDBRow> 
                    <MDBRow> 
                    &nbsp;&nbsp;
                    </MDBRow> 
                    {/* <MDBRow> 
                    <MDBCol style={{
                      display: this.state.showing,
                        color: "#B69C26",
                        bgColor: "#cccccc",
                        fontSize: 14,
                        align: 'left',
                        textAlign: 'left',
                        marginLeft: '20px',
                        alignItems: 'left',
                        justifyContent: 'left',
                        alignContent: 'left',

                        //display: 'flex',
                        alignContent: 'left',
                        border: 1,
                        padding: 1,
                        borderRadius: 2,
                        fontweight: 'bold',
                        backgroundColor: '#ffffff',
                        width: isMobile ? '90%' : '90%',
                        maxWidth: isMobile ? '90%' : '90%'}} 
                        colspan='2' 
                        // onClick={() => this.betaDesc()}
                        >
                      
                        Welcome to the cutting edge of innovation within Me-Vision Academy. 
                        As we introduce Aeon, your confidence Sage, we invite you to join us on this 
                        extraordinary journey of refinement and growth. We acknowledge that as we push 
                        the boundaries of technology and interpret our platform with AI, there may be 
                        occasional glitches. However, we embrace these challenges as opportunities for 
                        improvement and invite you to participate in shaping this incredible offering. 
                        Your involvement will not only allow us to fine-tune our system but also 
                        demonstrate your commitment to innovation and your willingness to explore 
                        uncharted territories. Together, let's embrace the risks and pave the way for 
                        an even more exceptional Me-Vision Academy experience.
                        
                    </MDBCol> 
                    </MDBRow>  */}
                    <MDBRow> 
                    <MDBCol style={tableCcc} colspan='2'>
                    &nbsp;&nbsp;
                    </MDBCol> 
                    </MDBRow> 
                    <MDBRow> 
                    <MDBCol style={tableCc} >
                      <Avatar
                        className={classes.bigAvatar}
                        aria-label='Photo'
                        src={wiseman}
                        // sx={{ width: '38px', height: '38px' }}
                      />
                    </MDBCol>   
                    <MDBCol style={tableSage} onClick={() => this.betaDesc()}>   
                     <b><i>Consult Aeon - Your Confidence Sage </i></b>
                     <span 
                    style={{
                      fontSize: 7, 
                      verticalAlign: isMobile ? 'top': 'bottom'}}
                      >beta</span>
                    </MDBCol>  
                    </MDBRow> 

                    <MDBRow> 
                    <MDBCol style={{
                      display: this.state.showing,
                        color: "#B69C26",
                        bgColor: "#cccccc",
                        fontSize: 14,
                        align: 'left',
                        textAlign: 'left',
                        marginLeft: '20px',
                        alignItems: 'left',
                        justifyContent: 'left',
                        alignContent: 'left',

                        //display: 'flex',
                        alignContent: 'left',
                        border: 1,
                        padding: 1,
                        borderRadius: 2,
                        fontweight: 'bold',
                        backgroundColor: '#ffffff',
                        width: isMobile ? '90%' : '90%',
                        maxWidth: isMobile ? '90%' : '90%'}} 
                        colspan='2' 
                        // onClick={() => this.betaDesc()}
                        >
                      
                        Welcome to the cutting edge of innovation within Me-Vision Academy. 
                        As we introduce Aeon, your confidence Sage, we invite you to join us on this 
                        extraordinary journey of refinement and growth. We acknowledge that as we push 
                        the boundaries of technology and interpret our platform with AI, there may be 
                        occasional glitches. However, we embrace these challenges as opportunities for 
                        improvement and invite you to participate in shaping this incredible offering. 
                        Your involvement will not only allow us to fine-tune our system but also 
                        demonstrate your commitment to innovation and your willingness to explore 
                        uncharted territories. Together, let's embrace the risks and pave the way for 
                        an even more exceptional Me-Vision Academy experience.
                        
                    </MDBCol> 
                    </MDBRow> 

                    <MDBRow>   
                    &nbsp;&nbsp;
                    </MDBRow>  
                <MDBRow> 
                <MDBCol style={tableAI} >


{/* Portfolio of Evidence questions:
Based on data captured in my Portfolio of Evidence, what kind of leader am I likely becoming? 
If what value could I be to my current or any future organisation? */}

               {/* <ChatGPT
                ask={this.state.alist + 'Based on these tasks that I am focusing on every day, what does it indicate about me as a leader?'} 
                email={this.state.myemail}
                formalQuestion={'Based on these tasks that I am focusing on every day, what does it indicate about me as a leader?'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                /> */}
                <ChatGPT
                ask={this.state.allopinions + 'Based on these opinions, what does it indicate about me as a leader?'} 
                email={this.state.myemail}
                formalQuestion={'Based on my real-time feedback from colleagues, what does it indicate about me as a leader?'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'What development areas would you recommend, in order to move towards my Best Self?'} 
                email={this.state.myemail}
                formalQuestion={'What development areas would you recommend, in order to move towards my Best Self?'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'What are my key strengths based on real-time feedback? '} 
                email={this.state.myemail}
                formalQuestion={'What are my key strengths based on real-time feedback? '}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'How would you suggest I leverage my strengths to become the best leader I can be?'} 
                email={this.state.myemail}
                formalQuestion = {'How would you suggest I leverage my strengths to become the best leader I can be?'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'Please suggest a most effective development path going forward?'} 
                email={this.state.myemail}
                formalQuestion = {'Please suggest a most effective development path going forward?'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'Based on recommendations for a development path, what would be some feasible SMART Goals I could consider?'} 
                email={this.state.myemail}
                formalQuestion = {'Based on recommendations for a development path, what would be some feasible SMART Goals I could consider?'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'Please suggest specific online certificates and diplomas I can participate in, preferably for free.'} 
                email={this.state.myemail}
                formalQuestion = {'Please suggest specific online certificates and diplomas I can participate in, preferably for free.'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'Please suggest relevant podcasts, videos and articles out there that would enhance my real-time journey.'} 
                email={this.state.myemail}
                formalQuestion = {'Please suggest relevant podcasts, videos and articles out there that would enhance my real-time journey.'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'What value could I be to my current or any future organisation?'} 
                email={this.state.myemail}
                formalQuestion = {'What value could I be to my current or any future organisation?'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
               />
                </MDBCol>  
                </MDBRow> 
                <MDBRow> 
               <MDBCol>
               &nbsp; &nbsp; 
               </MDBCol> 
               </MDBRow> 

                <MDBRow> 
                <MDBCol style={tableSage}>   
                     <b><i>Type your question to Aeon: </i></b>
                </MDBCol>  
               </MDBRow> 
                
                <MDBRow> 
               <MDBCol>  

               <TextField
                  //label='Type your question to Aeon:'
                  variant='outlined'
                  multiline
                  key="Type your question to Aeon:"
                 // disableUnderline
                 // id="fullWidth"
                  id="Type your question to Aeon:"
                 // id="demo-helper-text-aligned-no-helper"
                  fullWidth
                  rows={2}
                  //InputLabelProps={{ shrink: true }}
                  //inputProps={{ maxLength: 280 }}
                  aria-describedby='thought'
                  value={this.state.gtpquestion}
                  helperText={''} //{this.state.maxLengthThought}
                  //className={classes.textField}
                  onChange={e => this.handleChange(e, 'Type your question to Aeon:')}
                  inputProps={{
                    className: classes.textField,
                  }}
                  //className={classes.root}
                  //margin='normal'
                  InputLabelProps={{
                    shrink: true,
                    className: classes.textFieldLabel
                  }}
                />
               <ChatGPTfree
                ask={this.state.allopinions + this.state.gtpquestion} 
                //showFreeQ={this.state.showFreeQ}
                clearQuestion={this.clearQuestion}
                email={this.state.myemail}
                formalQuestion={this.state.gtpquestion}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
               />
               </MDBCol>  
                </MDBRow> 
                
                 <MDBRow> 
                 <MDBCol>
                 &nbsp; &nbsp; 
                 {/* <ChatGPTHistory
                    ask={'Show the history of my questions.'} 
                    //showFreeQ={this.state.showFreeQ}
                    clearQuestion={this.clearQuestion}
                    email={this.state.myemail}
                    formalQuestion={'Show the history of my questions.'}
                    photoURL={wiseman}
                    lastResp={this.lastResp}
                    lastAnswer={this.state.lastAnswer}
                 /> */}
                 </MDBCol>  
                </MDBRow> 

               {/* </MDBCol>  
                </MDBRow>  */}
                </MDBContainer>                 
                </div>
                </TabContainer>
          )
        )}
        {this.props.userdata.execmember ? null : <div align='center'><b>Please PLEDGE in order to access all functions of Me-Vision Academy, including development plan, detailed feedback, real-time points system and more.</b></div>}
        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={false}
          className={classes.modal}
        >
          <div style={modalStyle} className={classes.paper}>
            {this.state.addTask ? (
              <Task
                hasTask={false}
                updateOnSave={this.state.editTask}
                companyValue={this.state}
                OnSaveTask={this.handleOnSaveTask}
                title='Add Action'
                journalUser={userdata}
                onClose={this.handleClose} />
            ) : (
              <Task
                hasTask={true}
                task={this.state.taskOpened}
                companyValue={this.state}
                onBackClick={this.handleOnBackClick}
                OnSaveTask={this.handleOnSaveTask}
                updateOnSave={this.state.editTask}
                title='Edit Action'
                journalUser={userdata}
                onClose={this.handleClose}
              />
            )}

          </div>
        </Modal>
        <Modal
          aria-labelledby='confirmation-modal'
          aria-describedby='confirmation-modal-description'
          open={this.state.openConfirm}
          className={classes.modal}
        >
          <div style={isMobile ? modalStyle3 : modalStyle2} className={classes.paper}>
            <Typography variant='h6' gutterBottom>Please Confirm</Typography>
            <Typography variant='body' gutterBottom>Mark {this.state.editText} as {this.state.openComplete}?</Typography>
           <div>
              <ExpansionPanelActions>
                <Button onClick={this.handleClose} size='small'>Cancel</Button>
                <Button onClick={this.completedTask} size='small' color='primary'>{this.state.active ? 'Re-Open' : 'Complete'}</Button>
              </ExpansionPanelActions>
            </div>
          </div>
        </Modal>

        <Dialog
          open={this.state.open}
          aria-labelledby='form-dialog-title'
          fullWidth
          maxWidth='md'
          classes={{ paper: classes.dialogHeight }}
        >
          <DialogContent className={classes.divStyle}>
            {this.state.addTask ? (
              <Task
                hasTask={false}
                updateOnSave={this.state.editTask}
                companyValue={this.state}
                OnSaveTask={this.handleOnSaveTask}
                title='Add Action/ Goals/ Learnings / Impact'
                journalUser={userdata}
                onClose={this.handleClose} />
            ) : (
              <Task
                hasTask={true}
                task={this.state.taskOpened}
                companyValue={this.state}
                onBackClick={this.handleOnBackClick}
                OnSaveTask={this.handleOnSaveTask}
                updateOnSave={this.state.editTask}
                title='Edit Action/ Goals/ Learnings / Impact'
                journalUser={userdata}
                onClose={this.handleClose}
              />
            )}
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

Competencies.propTypes = {
  fetchCompanyValues: PropTypes.func.isRequired,
  userdata: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

const modalStyle = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`
}
const modalStyle2 = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`,
  padding: '16px 12px 0px'
}
const modalStyle3 = {
  top: '50px', //`${top}%`,
  left: '0px', // left: `${left}%`,
  //transform: `translate(-${top}%, -${left}%)`,
  padding: '16px 12px 0px'
}
const mapStateToProps = state => {
  return {
    classes: PropTypes.object.isRequired,
    userdata: state.userDialog.userDetails[0],
    pldpTasks: state.pldp.pldpTasks,
    companyValues: state.pldp.companyValues,
    pldpAllMyTasks: state.pldp.pldpAllMyTasks,
    opinions: state.opinions.opinion
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCompanyValues: params => dispatch(getCompanyValues(params)),
    deleteCompanyValue: params => dispatch(deleteCompanyValue(params)),
    fetchMyPLDPTasks: (userid, active) => dispatch(fetchMyPLDPTasks(userid, active)),
    fetchAllMyPLDPTasks: params => dispatch(fetchAllMyPLDPTasks(params)),
    updateNewPLDPTask: (task, edit) => dispatch(updateNewPLDPTask(task, edit)),
    completeTask: params => dispatch(completeTask(params)),
    addMorePoints: params => dispatch(addMorePoints(params)),
    CgetOpinions: params => dispatch(CgetOpinions(params)),
    GgetOpinions: params => dispatch(GgetOpinions(params)),
    getOpinions: params => dispatch(getOpinions(params)),
    showToast: params => dispatch(showToast(params)),
  }
}

const CompetenciesStyled = withStyles(styles)(Competencies)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetenciesStyled)
