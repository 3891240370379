import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ThoughtsView from '../components/Views/ThoughtsView'
import { connect } from 'react-redux'
import { fetchThoughts, getTrendingLeads } from '../store/actions/thoughtsActions'
import { fetchUserData } from '../store/actions/userActions'
import {
  ILEAD_THOUGHTS,
  APP_TYPE,
  PUBLIC_COMPANY_ID,
  SET_SEEN_NOTIFICATIONS,
  ILEAD_LEADS,
  ILEAD_LEADS2,
  ILEAD_LEADS2x,
  ILEAD_LEADS5x,
  ILEAD_LEADS6x,
  ILEAD_LEADS3,
  ILEAD_LEADS4
} from '../store/actions/types'
import { deletePostAttachment } from './../store/actions/attachmentActions'

class Ilead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: window.innerHeight,
      minData: 12
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll() {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight
    const body = document.body
    const html = document.documentElement
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    const windowBottom = Math.round(windowHeight + window.pageYOffset)
    if (windowBottom >= docHeight) {
      this.setState({
        minData: this.state.minData + 12
      })
      this.fetchData()
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    console.log('ilead....', this.props)
    this.fetchData()
  }

  // shouldComponentUpdate(nextProps, newState) {
  //   return this.props.thoughts === nextProps.thoughts; // use === to compare types too!
  //  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  componentWillMount = () => {
  //  this.fetchData()
  };

  fetchData = async () => {
    let equalTo = ''
    equalTo =
      APP_TYPE === 'CORPORATE'
        ? this.props.userdata.companyID + '_ilead_approved'
        : PUBLIC_COMPANY_ID + '_approved'

    let params = {
      type: ILEAD_THOUGHTS,
      companyID: this.props.userdata.companyID,
      orderChild: 'publish_status',
      equalTo: equalTo,
      minData: this.state.minData
    }
    
   this.props.fetchThoughts(params)

    let params2 = {
      type: ILEAD_LEADS5x, //ILEAD_LEADS, ILEAD_LEADS2,  ILEAD_LEADS3, ILEAD_LEADS2x, ILEAD_LEADS5x
      companyID: this.props.userdata.companyID,
      orderChild: 'publish_status',
      equalTo: equalTo,
      minData: this.state.minData
    }

    //await this.props.fetchThoughts(params2)

    console.log('thoughtsView....')
    //this.loadLeads(equalTo)
    //this.props.getTrendingLeads(params2)

    console.log('greenfetchThoughts : ',equalTo );

    this.props.fetchUserData({
      type: SET_SEEN_NOTIFICATIONS,
      userID: this.props.userdata.userID,
      path: this.props.match.path,
      unseenotifications: this.props.unseenotifications.unseenotifications
    })
  };

  loadLeads = (equalTo) => {
    // this.setState({ loadingLeaders: true })
    // this.props.loadingAction({
    //   leaderboard_loading: 1,
    //   type: LEADERBOARD_LOADING
    // })
    console.log('ileads_leads2 0 : ', equalTo);
    const promise = this.getLeads(equalTo)
    promise.then(this.onFulfilled, this.onRejected)
  }

  getLeads = (equalTo) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
       // if (this.state.success) {
          let params2 = {
            type: ILEAD_LEADS5x, //ILEAD_LEADS, ILEAD_LEADS2,  ILEAD_LEADS3, ILEAD_LEADS2x, ILEAD_LEADS5x
            companyID: this.props.userdata.companyID,
            orderChild: 'publish_status',
            equalTo: equalTo,
            minData: this.state.minData
          }
          console.log('ileads_leads2 1 : ', params2);
          this.props.fetchThoughts(params2)
          //this.props.getTrendingLeads(params2)
        // } else {
        //   reject('Failed to the Leads')
        //}
      }, 2000)
    })
  }
  // shouldComponentUpdate(nextProps) {
  //   // Rendering the component only if 
  //   // passed props value is changed
  
  //   if (nextProps.thoughts !== this.props.thoughts) {
  //      console.log('greenthoughts ilead 2 : ', nextProps.thoughts)
  //      console.log('greenthoughts ilead 2a : ', this.props.thoughts)
  //      return true;
  //   } else {
  //     //return false;
  //      console.log('greenthoughts ilead 3 : ', nextProps.thoughts)
  //      console.log('greenthoughts ilead 3a : ', this.props.thoughts)
  //      return false;
  //   }
  // }

  // shouldComponentUpdate(nextProps, nextState) {  
  //  if (this.props.thoughts !== nextProps.thoughts) {  
  //   return true;  
  //   }  
  //   return false;  
  // } 

  render() {
    const { thoughts, userdata, topleads } = this.props
    console.log('greenthoughts ilead 1 : ', thoughts)
    return (
      <div>
        {userdata && thoughts ? (
          <React.Fragment>
            <ThoughtsView
              thoughts={thoughts}
              topleads={this.props.topleads}
              currentUser={userdata}
              {...this.props}
              openFileViewer={this.props.openFileViewer}
            />
          </React.Fragment>
        ) : null}
      </div>
    )
  }
}

Ilead.propTypes = {
  fetchThoughts: PropTypes.func.isRequired,
  thoughts: PropTypes.array
}

const mapStateToProps = state => {
  return {
    thoughts: state.thoughts.ilead,
    topleads: state.thoughts.topleads,
    userdata: state.userDialog.userDetails[0],
    unseenotifications: state.userdata.unseenotifications
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchThoughts: params => dispatch(fetchThoughts(params)),
    deletePostAttachment: params => dispatch(deletePostAttachment(params)),
    fetchUserData: params => dispatch(fetchUserData(params)),
    getTrendingLeads: params => dispatch(getTrendingLeads(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ilead)
