import React from 'react'
import ReactDOM from 'react-dom'
import MUIDataTable from 'mui-datatables'
import {
  createTheme,
  MuiThemeProvider,
  getMuiTheme,
  withStyles
} from '@material-ui/core/styles'
// import "./styles.css";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import applyMiddleware from 'redux'
// import thunk from 'redux-thunk'
import {
  getChat
} from '../../store/actions/companyAdminActions'

import {
  Avatar
} from '@material-ui/core'
import { PHOTO_PROFILE } from '../../utils/photo_utils'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Grid
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {
  SHOW_TOAST, GET_AI
} from '../../store/actions/types'
import { Container, Row, Col } from 'react-grid-system'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { showToast } from '../../store/actions/toastAction'

const tableA = {
  color: "#615831",
  fontSize: 14,
  border: "0px",
  padding: 1,
  borderRadius: 2,
  fontweight: 'bold',
  backgroundColor: '#eeeeee',
  width: isMobile ? '100%' : '100%',
  maxWidth: isMobile ? '100%' : '100%',
}
const tableB = {
  color: "#615831",
  size: 20,
  border: "0px",
  padding: 1,
  borderRadius: 2,
  fontweight: 'bold',
  backgroundColor: '#eeeeee',
  width: isMobile ? '100%' : '100%',
  maxWidth: isMobile ? '100%' : '100%'
}
const tableC = {
  color: "#372F0C",
  fontSize: 10,
  border: "0px",
  padding: 1,
  borderRadius: 2,
  fontweight: 'bold',
  backgroundColor: '#eeeeee',
  width: isMobile ? '10%' : '5%',
  maxWidth: isMobile ? '10%' : '5%',
}
const tableD = {
  color: "#372F0C",
  fontSize: isMobile ? 14 : 16,
  border: "0px",
  padding: 5,
  borderRadius: 2,
  fontweight: 500,
  backgroundColor: '#eeeeee',
  width: isMobile ? '90%' : '95%',
  maxWidth: isMobile ? '90%' : '95%',
}
class ChatGPT extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    ask: this.props.ask,
    answ: '',
    showAnswer: false,
    loading: '',
    qcolor: '#372D04',
    qlock: false
  };

  componentDidMount() {
    console.log('green this.props.GPT 1 : ', this.props)

    // this.props.getChat({ // this.props.users
    //   // type: GET_AI,
    //   ask: this.props.ask
    // })
    // this.findAnswerC()
  }

  // getReply = () => {
  //   this.setState({ loading: true })
  //   console.log('green getReply 1 : ', this.state.answ.length)
  //   console.log('green getReply 2 : ', this.props.lastAnswer.length)

  //   if (this.props.lastAnswer!==this.state.answ) {
  //     this.props.getChat({ // this.props.users
  //       // type: GET_AI,
  //       ask: this.props.ask
  //     })
  //     this.findAnswerC()
  //   } 
  //   else{
  //     if (this.state.answ) { //both have a value and it is the same
  //       //do nothing
  //     }
  //     else { //both are nothing
  //       this.props.getChat({ // this.props.users
  //         // type: GET_AI,
  //         ask: this.props.ask
  //       })
  //       this.findAnswerC()
  //     }
  //   }
  // }

  getReply = () => {
    this.setState({ loading: 'Loading...' })
      this.props.getChat({ // this.props.users
        // type: GET_AI,
        ask: this.props.ask,
        email: this.props.email
      })
      this.setState({
        qcolor: '#8B771B',
        qlock: true
      })
      this.findAnswerC()
  }

  findAnswerC = (prompt) => {
    const promise = this.findAnswerD(prompt)
    promise.then(this.onFulfilled, this.onRejected)
  }

  findAnswerD = (prompt) => {
  const { lastResp } = this.props
    return new Promise((resolve, reject) => {
      setTimeout(() => {
       try {
        if (this.props.chatGPTAnwser) {
          //var gptAnsw = JSON.stringify(this.props.chatGPTAnwser);
          let receivedData = { ...this.props.chatGPTAnwser }
          receivedData = Object.assign({}, this.props.chatGPTAnwser)
          if (receivedData[1][1] !== undefined) {
           var A = receivedData[1][1] //JSON.stringify(receivedData[1][1])
           A = A.replace(/\\n/g," ");
          }
          else {
            A = 'no answer'
          }
 
          this.setState({ 
            answ: A,
           // showAnswer: true
          })
          // lastResp(A)

          console.log('green getReply 1 : ', A)
          console.log('green getReply 2 : ', this.props.lastAnswer)

          if (A && this.props.lastAnswer!=A) {
            this.setState({ 
              showAnswer: true,
              loading: 'Loading...'
            })
          }
          else{
            if (A.length===0 && this.props.lastAnswer.length===0) {
              this.setState({ 
                showAnswer: true,
                loading: 'Loading...'
              })
            }
            else {
              this.setState({ 
                showAnswer: false,
                loading: 'Please click on the question again...'
              })      
            }
          }
          lastResp(A);

          console.log('green this.props.GPT 3b : ', this.props.chatGPTAnwser);
          console.log('green this.props.GPT 3c : ', A);
        }
      } catch (e) {
        console.log('Error in chatgpt function findAnswerD: ', e)
        this.setState({ answ: 'no answer' })
      }
      }, 10000)
    })
  }

  //  static getDerivedStateFromProps(nextProps, prevState) {
  //   // do things with nextProps.someProp and prevState.cachedSomeProp
  //   if (this.props.chatGPTAnwser) {
  //     //var gptAnsw = JSON.stringify(this.props.chatGPTAnwser);
  //     let receivedData = { ...this.props.chatGPTAnwser }
  //     receivedData = Object.assign({}, this.props.chatGPTAnwser)

  //     //this.setState({ answ: receivedData[1][1] })
  //      console.log('green this.props.GPT 3b : ', receivedData[1][1]);
  //   }
  //   return {
  //     gtpAnswer: this.props.receivedData[1][1],
  //     // ... other derived state properties
  //   };
  // }
  mouseOver = () => {
   // document.getElementById("formalQuestion").style.color = "red";
   // console.log("ooo In");
    this.setState({
      qcolor: '#8B771B'
    })
  }

  mouseOut = () => {
    if (this.state.qlock===false) {
    this.setState({
      qcolor: '#372D04'
    })
  }
   // console.log("ooo Out");
  }
  render() {
    // let { users } = this.props
    //  const getAIRes = (props) => {
    //   for (let x in props) {
    //     return props[x].data
    //   }
    // }
    console.log('green this.props.GPT 3 : ', this.props.gtpAnswer);

    return (
      <React.Fragment>
        {
          this.state.showAnswer ?
          <React.Fragment>
               <MDBContainer>  
               <MDBRow> 
               <MDBCol style={tableB} colspan='2' id='formalQuestion'>
                <b><i>{this.props.formalQuestion}</i></b>
               </MDBCol> 
                </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableC} >
                      <Avatar
                        aria-label='Photo'
                      // className={classes.avatar}
                       // src={PHOTO_PROFILE('./eximg/wiseman.jpg')}
                        src={PHOTO_PROFILE(this.props.photoURL)}
                        style={{ cursor: 'pointer' }}
                        height={isMobile ? 5 : 8}
                        width={isMobile ? 5 : 8}
                      />
                      </MDBCol>          
                      <MDBCol style={tableD} >
                      {this.state.answ}
                      </MDBCol> 
                    </MDBRow>   
                    </MDBContainer>          
          </React.Fragment>
             : 
            <div
               onMouseOver={() => this.mouseOver()}
               onMouseOut={() => this.mouseOut()}
              style={{
                  background: 'none!important',
                  border: 'none',
                  padding: '0!important',
                  /*optional*/
                  fontFamily: 'arial, sans-serif',
                  color: this.state.qcolor,
                  textDecoration: 'underline',
                  cursor: 'pointer',
              }}
              variant='contained'
              color='primary'
              type='submit' 
              onClick={() => { this.getReply() }}
            >
              {/* <b>{this.props.formalQuestion}</b>&nbsp; &nbsp;{this.state.loading ? <i>Thinking...</i> :null} */}
              <b>{this.props.formalQuestion}</b>&nbsp; &nbsp;{this.state.loading}
            </div>
             }
          {/* </div> */}
          
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    // userdata: state.userDialog.userDetails[0],
    // followingData: state.userdata.followingButton,
    // user_exists: state.users.userexists,
    chatGPTAnwser: state.gptanswer.gptanswer
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getChat: params => dispatch(getChat(params)),
    showToast: params => dispatch(showToast(params)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChatGPT)
