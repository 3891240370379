/**
  Note: Please update versiversionnon per servter - to indicate new deployment updserverURL: 'https://glp-test-f6590.appspot.com',tes
  APP_TYPE_SERVER ==> public_test OR corporate_test OR public_live OR corporate_live
**/

const APP_TYPE_SERVER = 'public_live'

const config = {
  public_test: {
    APP_TYPE: 'PUBLIC',
    SERVER_TYPE: 'TEST',
    FB_URL: 'https://us-central1-thinklead-b3299.cloudfunctions.net/',
    BASE_API_URL: 'https://us-central1-thinklead-b3299.cloudfunctions.net/api',
    BASE_URL: 'https://us-central1-thinklead-b3299.cloudfunctions.net',
    DB_CONFIG: 'public_test',
    graphQLURL: 'https://us-central1-leadershipplatform-158316.cloudfunctions.net/api/graphql',
    serverURL: 'https://thinklead-b3299.uc.r.appspot.com/',
    version: '2.2',
    IS_HARMONY: false
  },
  corporate_test: {
    APP_TYPE: 'CORPORATE',
    SERVER_TYPE: 'TEST',
    FB_URL: 'https://us-central1-glp-test-f6590.cloudfunctions.net',
    BASE_API_URL: 'https://us-central1-glp-test-f6590.cloudfunctions.net/api',
    BASE_URL: 'https://us-central1-glp-test-f6590.cloudfunctions.net',
    DB_CONFIG: 'test',
    serverURL: 'https://glp-test-f6590.appspot.com',
    version: '1.0',
    IS_HARMONY: true
  },
  public_live: {
    APP_TYPE: 'PUBLIC',
    SERVER_TYPE: 'LIVE',
    FB_URL: 'https://us-central1-leadershipplatform-158316.cloudfunctions.net/',
    BASE_API_URL:
      'https://us-central1-leadershipplatform-158316.cloudfunctions.net/api',
    BASE_URL:
      'https://us-central1-leadershipplatform-158316.cloudfunctions.net',
    DB_CONFIG: 'live',
    serverURL: 'https://leadershipplatform-158316.appspot.com',
    version: '2.0',
    IS_HARMONY: false
  },
  corporate_live: {
    APP_TYPE: 'CORPORATE',
    SERVER_TYPE: 'LIVE',
    FB_URL: 'https://us-central1-harmony-thinklead.cloudfunctions.net/',
    BASE_URL:
      'https://us-central1-harmony-thinklead.cloudfunctions.net',
    BASE_API_URL:
      'https://us-central1-harmony-thinklead.cloudfunctions.net/api',
    DB_CONFIG: 'live',
    serverURL: 'https://glp-corporate.appspot.com/',
    version: '2.2',
    IS_HARMONY: true
  }
}

export const app_config = config[APP_TYPE_SERVER]
