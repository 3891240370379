import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import { AppBar, Toolbar, IconButton, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import glpLogo from '../assets/glp-logo.png';

const styles = (theme) => ({
	root: {
		padding: '20px',
		flexGrow: 1
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary
	},
	paperContainer: {
		padding: '20px',
		marginTop: 20
	},
	grow: {
		flexGrow: 1
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20
	},
	colorPrimary: {
		color: '#ffffff',
		backgroundColor: '#d51317'
	}
});

class TermsConditions extends React.Component {
	state = {
		expanded: null
	};

	handleChange = (panel) => (event, expanded) => {
		this.setState({
			expanded: expanded ? panel : false
		});
	};

	render() {
		const { classes } = this.props;
		const { expanded } = this.state;
		return (
			<React.Fragment className={classes.root}>
				<Paper className={classes.paperContainer}>
					{/* <div className="w3-row w3-center">
						<img src={glpLogo} alt="Global Leadership Platform" />
					</div> */}
          
          <div id='chapter1'>
          <h2>1. Me-Vision Academy Ecosystem (MVAE) Conditions</h2>
<p>MVAE is an Exclusive place for current and future Successful Leaders who have bought into the vision of transforming leadership standards globally by leading thousands – eventually millions - towards their Best Selves; by transforming leadership standards – leading self, others, organisation, society - on all levels, in all sectors and in all countries. This exclusive community wants to change the current negative trajectory of leadership standards, emotional and financial (economic) wellbeing. </p>
<p>As is the case on other platforms, on MVAE you may LEAD with a message of any kind - informative, inspirational, announcements, news, breaking news, anything, as you would on other platforms. However, inside MVAE we truly realise that every LEAD is influential to a lesser or larger degree, impacting society in some way, collectively and individually. Therefore, on MVAE we appeal to our Best Self, to THINK before we LEAD, because every LEAD impacts how we THINK.</p>
<p>People join MVAE and…</p>
    <ul>
      <li>LEAD on whatever subject matter they choose - to make their voice heard;</li>
      <li>LEAD with news or a breaking news story;</li>
      <li>LEAD with anything interesting;</li>
      <li>LEAD to express their best self - through knowledge, wisdom and experience;</li>
      <li>LEAD to positively impact the world around them;</li>
      <li>LEAD to grow and develop as a human being and successful leader;</li>
      <li>LEAD to be rewarded with Lc (LEAD currency) for every LEAD, resulting in future benefits and more influence as a successful leader;
</li>
      <li>LEAD to grow their personal or leadership brand, resulting in “leads” for their business, service or cause.</li>
      <li>And so much more now and in the future…</li>
      </ul>

<p>MVAE is also built to become a global community of Successful Leaders who believe the globe is in desperate need of greater, more mature and authentic leadership. Members inside this ecosystem are honoured to be involved and believe they are always learning from one another, contributing in a positive manner and striving to make the world a better place for future generations.</p> 
<h3>MVAE:</h3>
<p>Is a digital place where participants earn LEAD currency (Lc) as they participate. 
  Leaders join and when they - or someone on their behalf - pledge a monthly amount in dollars, 
  the platform converts that amount into LEAD. At this stage 1xLc = $1. 
  A portion of the converted Lc is given back to patrons, MVAE to manage costs and a 
  communal fund from which participants earn LEAD for most of their activities inside the ecosystem. 
  MVA management reserves the right to tweak and manage the ratio of how dollars converted into 
  LEAD are distributed. All of us acknowledge that this is new and pioneering. Hence many changes will 
  occur. In essence we are all grateful that we are managing to replace a simple points system 
  - some gamification - with earning LEAD instead. In short the ecosystem is aiming to grow a 
  wealth of leadership and leaders, while attempting to grow leadership wealth. We acknowledge 
  that whenever LEAD moves inside this platform, leadership has improved, we have added to the 
  improvement of a leader or the collective community. </p>
  <ul>
<li>Members of this community acknowledge that because they are earning LEAD through participation, they will at some future date be able to extract value from it outside MVAE - that is the goal. However, at this stage we are in phase one of our currency evolution. During this phase a participant is able to measure the value of their LEAD generation in dollars. In stage two, when we start building the LEAD value outside of MVAE, participants will be able to cash in.  </li>

<li>Members also commit to refrain from flooding the system with “earning activities”, simply to earn and not with the authentic purpose of developing themselves and fellow members or MVAE. You agree that the MVAE team may audit your earning actions to ascertain whether it makes sense and are reasonably done with the right intentions.</li>

<li>Successful Leaders - award recipients - inside the ecosystem acknowledge that they can only earn LEAD when they are investors, in other words they themselves have invested by pledging a monthly amount for the support of our vision and currency system. MVAE team withholds the right to decide when there is sufficient currency in the ecosystem to sustain bringing future leaders or leaders with potential from under resourced areas on board to participate, grow and earn, without pledging.  </li>

</ul>
<p>Is a magnet / growing library for all things leadership – attracting all leadership content, conversation and inspiration – available on your phone, tablet and laptop.</p>
<p>Is an Exclusive Space that displays openly on LeaderTrack a Global Leader Board ranked according to earned LEAD and feedback ratings, globally, per sector, country and positional level. Leaders inside MVAE agree to being displayed on the Leader Board, seen by all MVAE members. The world needs to know where to find quality Leaders and Influencers, per sector, country and level. Leaders have the option of switching their visible leadership ratings on and off.</p>
<p>Is an Exclusive Ecosystem that is built to potentially pioneer the “professionalisation” of leadership with Lc for participation in the platform and leadership improvement activities. Lc not only equates to earning while participating but can also be compared with CPD (Continuous Professional Development) Points that professionals in other professions need to earn in order to remain relevant and active in their professions. 
  Participants understand that they are at all times compiling a portfolio of evidence of all participation and Lc earned, which can be printed and shared with anyone relevant.</p>
<p>Is a productive communication platform for leaders of organisations and society at large. We recognise that effective communication, real time, is one of the greatest challenges of leadership.</p>
<p>Is a web based digital platform for society, organisations and individuals.</p>
<p>Is a web based digital platform for all content within the community guidelines and all things leadership.</p>

<p>A Platform where members may follow and unfollow any member, thereby receiving or cancelling notifications of their posts.</p>
<p>Is a platform for organisations to empower leaders with leadership content, real-time feedback, real-time analysis, real-time personal continuous development and clearer, real time, productive communication from Top Leaders.</p>
<p>Members agree that anyone may at any time click on their profile picture and rate their leadership, including the sharing of qualitative feedback on competencies decided by their organisation or these four universal competencies - leading self, leading others, leading organisation (non-people issues) and leading society (outside the workplace).</p>
<h3>MVAE Co-Owners:</h3>
<p>MVAE is also working on the opportunity for members to open their own MVAE to help mobilise Successful Leaders across the world in order to transform leadership standards on all levels - including technical, functional level leadership - in all sectors and all countries. They will recruit other Partners and Successful Leaders, for which they are rewarded financially by way of residual commission for as long as their recruits remain paying members of MVAE. More will be shared as this opportunity evolves</p>
<p>You might not like everything you see, read, listen to or watch inside MVAE. If you think content is inappropriate – based on the terms of this agreement - use the feedback function to report it for review by our MVAE team. They carefully review content on a day to day basis to determine whether there’s a violation of our Community Guidelines.</p>
<p>Again, MVAE is a global community of responsible Successful Leaders where members LEAD with any subject matter of their choice. MVAE is also a home for all leadership content, empowerment, the successful leading of organisations across the globe, showcasing great leadership, as well as successful leadership of self on all levels from specialists and experts to CEO’s of organisations, even Presidents of countries. The following is not allowed:</p>
<ul>
      <li><b>Nudity or sexual content: </b>Pornography or sexually explicit content is not acceptable. If this describes your contribution in any way, even if it's a video of yourself, don't share it on MVAE. Sexually explicit content like pornography is not allowed. Videos containing fetish content will be removed. In most cases, violent, graphic, or humiliating fetishes will not be shown on MVAE.</li>
      <li><b>Hateful content:</b> We do not accept content that intends to incite violence or encourage dangerous or illegal activities that have an inherent risk of serious physical harm or death. All content that incites others to commit acts of violence is strictly prohibited on MVAE. If your contribution in any way asks others to commit an act of violence or threatens people with serious acts of violence, it will be removed from the platform.</li>
      <li><b>Privacy: </b>If someone has posted your personal information or posted your content without your consent, you can request removal of this content. However, kindly request the removal of the content from the uploader directly first and if that doesn’t work out contact us.</li>
      <li><b>Threats: </b>Things like predatory behaviour, stalking, threats, harassment, intimidation, invading privacy, revealing other people's personal information, attempting to destroy someone’s reputation and inciting others to commit violent acts or to violate the Terms of Use are not acceptable. Anyone caught doing these things may be permanently banned from MVAE.</li>
      <li><b>Vulgar Language:</b> We don’t subscribe to this. Use of sexually explicit language or excessive profanity in your posts or associated metadata may lead to it being deleted without notification.</li>
      </ul>
      <p>Despite the above, you understand and acknowledge that in using the platform, the odd slip up may occur and you therefore stand the risk of being exposed to content that is factually inaccurate, offensive, indecent, or otherwise objectionable to you. You agree to waive, and hereby do waive, any legal or equitable rights or remedies you have or may have against MVAE with respect to any such Content.</p>
<h2>2. Your personal relationship with MVAE</h2>
<ul>
      <li>Your use of MVAE and any MVAE products, channels, software, data feeds and services, is subject to the terms of a legal agreement between you and MVAE. "MVAE" means Me-Vision Academy Ecosystem (a service by ThinkLead Pty Ltd), whose principal place of business is based at 2932 Korhaan Str, Marloth Park, Mpumalanga, South Africa.</li>
      <li>The Terms form a legally binding agreement between you and MVAE in relation to your use of the Service. It is important that you take the time to read them carefully.</li>
      <li>The Terms apply to all members of the Service, including members who are also contributors of Content on the Service. "Content" includes the text, articles, podcasts, audios, videos, ebooks, graphics, photos, sounds, webinars, music, audio-visual combinations, interactive features, leadership and life courses and other materials you may view on, access through or contribute to the Service.</li>
      </ul>
<h2>3. Accepting the Terms</h2>
<ul>
      <li>In order to use the Service, you must firstly agree to the Terms. You may not use the Service if you do not accept the Terms.</li>
      <li>You can accept the Terms by simply using the Service. You understand and agree that MVAE will treat your use of the Service as acceptance of the Terms from that point onwards.</li>
      <li>You may not use the Service and may not accept the Terms if:<ol>
           <li>you are not of legal age to form a binding contract with MVAE, or</li>
           <li>you are a person who is either barred or otherwise legally prohibited from receiving or using the Service under the laws of the country in which you are resident or from which you access or use the Service.</li>
           </ol></li></ul>
<h2>4. Changes to the Terms</h2>
<ul>
      <li>MVAE reserves the right to make changes on the Terms from time to time, for example to address changes to the law or regulatory changes or changes to functionality offered through the Service. Therefore you are required to look at the Terms regularly to check for such changes. The modified version of the Terms (the "Modified Terms") will be made available within the Service. If you do not agree to the Modified Terms you must stop using the Service. Your continued use of the Service after the date the Modified Terms are posted will constitute your acceptance of the Modified Terms.</li>
      </ul>
      <h2>5. MVAE Accounts</h2>
<ul>
      <li>In order to access some features of the Ecosystem or other elements of the Service, you will have to create a MVAE account. When creating your account, you must provide accurate and complete information. It is important that you must keep your MVAE account password secure and confidential.</li>
      <li>You must notify MVAE immediately of any breach of security or unauthorised use of your MVAE account as soon as you become aware of such breach or unauthorised use.</li>
      <li>You agree that you will be solely responsible (to MVAE, and to others) for all activity that occurs under your MVAE account.</li>
      <li>MVAE hereby grants you permission to access and use the Service, subject to the following express conditions, and you agree that your failure to adhere to any of these conditions shall constitute a breach of these Terms on your part:
      <ol>
           <li>you agree not to distribute any part of or parts of the platform or the Service, including but not limited to any Content, in any medium without MVAE 's prior written authorisation, unless MVAE makes available the means for such distribution through functionality offered by the Service;</li>
           <li>you agree not to alter or modify any part of the Platform or any of the Service;</li>
           <li>you agree not to (or attempt to) circumvent, disable or otherwise interfere with any security related features of the Service or features that (i) prevent or restrict use or copying of Content or (ii) enforce limitations on use of the Service or the content accessible via the Service;</li>
           <li>you agree not to collect or harvest any personal data of any user of the Platform or any Service (and agree that this shall be deemed to include MVAE account names);</li>
           <li>you agree not to use the Platform or the Services (including the comments and email features in the Platform) for the solicitation of business in the course of trade or in connection with a commercial enterprise;</li>
           <li>you agree not to solicit, for commercial purposes, any members of the Platform with respect to their Content;</li>
           <li>you shall not copy, reproduce, distribute, transmit, broadcast, display, sell, license, or otherwise exploit any Content for any other purposes without the prior written consent of MVAE or the respective licensors of the Content.</li>
           </ol>
      </li>
      <li>MVAE is constantly innovating in order to provide the best possible experience for its members. You acknowledge and agree that the form and nature of the Service which MVAE provides may change from time to time without prior notice to you.</li>
      <li>As part of this continuing innovation, you acknowledge and agree that MVAE may stop (permanently or temporarily) providing the Service (or any features within the Service) to you or to members generally at MVAE 's sole discretion, without prior notice to you. You may stop using the Service at any time, when your payment terms have expired, following which you do not need to specifically inform MVAE when you stop using the Service.</li>
      </ul>
      <h2>6. Links from MVAE</h2>
      <ul>
      <li>The Service may include hyperlinks to other web sites that are not owned or controlled by MVAE. MVAE has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party Apps.</li>
      <li>You acknowledge and agree that MVAE is not responsible for the availability of any such external sites or resources, and does not endorse any advertising, products or other materials on or available from such web sites or resources.</li>
      <li>You acknowledge and agree that MVAE is not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, products or other materials on, or available from, such web sites or resources.</li>
      <li>MVAE encourages you to be aware when you leave the Service and to read the terms and conditions and privacy policy of each other Platform that you visit.</li>
      </ul>
<h2>7. Ending your relationship with MVAE</h2>
<p>The Terms will continue to apply until terminated by either you or MVAE as set out below.</p>
<ul>
      <li>If you want to terminate your legal agreement with MVAE, you may do so by (a) notifying MVAE at any time (b) closing your MVAE account (c) not renewing your monthly membership fee. Your notice could be sent, in writing, to MVAE’s address info@mevisionacademy.com. We will then delete all your information from the database.</li>
      <li>MVAE may at any time terminate its legal agreement with you if:
      <ol>
           <li>you have breached any provision of the Terms (or have acted in manner which clearly shows that you do not intend to, or are unable to comply with the provisions of the Terms); or</li>
           <li>MVAE is required to do so by law (for example, where the provision of the Service to you is, or becomes, unlawful); or
           </li></ol>
           </li>
      <li>MVAE may terminate its legal agreement with you if:
      <ol>
           <li>MVAE is transitioning to no longer providing the Service to members in the country in which you are resident or from which you use the Service; or</li>
           <li>The provision of the Service to you by MVAE is, in MVAE 's opinion, no longer commercially viable, and in the case of each of the above, where possible, give reasonable notice of such termination.</li>
           <li>You are not a paying member and have been inactive inside the Club for longer than 6 months. 
           </li></ol></li>
      <li>When these Terms come to an end, all of the legal rights, obligations and liabilities that you and MVAE have benefited from, been subject to (or which have accrued over time whilst the Terms have been in force) or which are expressed to continue indefinitely, shall be unaffected by this cessation.</li>
      </ul>              
      <h2>8. Exclusion of Warranties</h2>
                                   <ul>
      <li>The Service is provided "as is" and MVAE makes no warranty or representation to you with respect to it.</li>
      <li>In particular MVAE does not represent or warrant to you that:
         <ol>
           <li>your use of the Service will meet your requirements;</li>
           <li>your use of the Service will be uninterrupted, timely, secure or free from error;</li>
           <li>any information obtained by you as a result of your use of the Service will be accurate or reliable; and</li>
           <li>that defects in the operation or functionality of any software provided to you as part of the Service will be corrected.
           </li></ol></li></ul>
<h2>9. Limitation of Liability</h2>
<h3>MVAE shall not be liable to you for:</h3>
<ol>
           <li>any indirect or consequential losses which may be incurred by you. This shall include any loss of profit (whether incurred directly or indirectly), any loss of goodwill or business reputation, or any loss of data suffered by you;</li>
           <li>any loss or damage which may be incurred by you as a result of:
            <ol>
               <li>any reliance placed by you on the completeness, accuracy or existence of any advertising, or as a result of any relationship or transaction between you and any advertiser or sponsor whose advertising appears on the Service;</li>
               <li>any changes which MVAE may make to the Service, or for any permanent or temporary cessation in the provision of the Service (or any features within the Service);</li>
               <li>the deletion of, corruption of, or failure to store, any Content and other communications data maintained or transmitted by or through your use of the Service;</li>
               <li>your failure to provide MVAE with accurate account information; or</li>
               <li>your failure to keep your password or MVAE account details secure and confidential.
               </li></ol></li></ol>
<h2>10. General Legal Terms</h2>
<ul>
      <li>The Terms constitute the whole legal agreement between you and MVAE and govern your use of the Service and completely replace any prior agreements between you and MVAE in relation to the Service.</li>
      <li>You agree that MVAE may provide you with notices, including those regarding changes to functionality and the Terms, by email, regular mail, or postings on the Service.</li>
      <li>You agree that if MVAE does not exercise or enforce any legal right or remedy which is contained in the Terms (or which MVAE has the benefit of under any applicable law), this will not be taken to be a formal waiver of MVAE’s rights and that those rights or remedies will still be available to MVAE.</li>
      <li>If any court of law, having the jurisdiction to decide on this matter, rules that any provision of these Terms is invalid, then that provision will be removed from the Terms without affecting the rest of the Terms. The remaining provisions of the Terms will continue to be valid and enforceable.</li>
      </ul>
<h2>11. Global Contributors for MVAE – inside and outside organisations</h2>
<ol>
       <li>If you are an Organisation you acknowledge that MVAE can also function as a leadership communications tool. For members / followers / employees to receive messages from their Top Leader/s or other Contributors they must be a follower. Your organisation therefore acknowledges that MVAE has built a system that allows for all members inside your organisation to automatically follow Global Contributors (ex CEO) of the organisation, so that they receive notifications of any posts. Members or followers may choose to unfollow any Contributor.</li>
       <li>As a MVAE Contributor you may submit Content to the Service, including videos, audios, articles, voicemails, pictures and thoughts. You understand that MVAE does not guarantee any confidentiality with respect to any Content submitted.</li>
       <li>As a Global Contributor you may choose to submit any content either internally (for organisational use and viewing only) or globally (for any members worldwide to use and view).</li>
       <li>As Global Contributor you are solely responsible for your own Content published internally (in organisations) and your messages and Content published globally. As a Global Contributor you affirm, represent, and warrant that you own or have the necessary licenses, rights, consents, and permissions to publish Content you submit; and you license to MVAE all patent, trademark, trade secret, copyright or other proprietary rights in and to such Content for global publication on the Service pursuant to these Terms of Service.</li>
       <li>For clarity, you retain all of your ownership rights of your organisations internal Content.
         However, as Global Contributor as you submit Content to MVAE for global consumption, 
         you hereby grant MVAE a worldwide, non-exclusive, royalty-free, sublicensable and 
         transferable license to use, reproduce, distribute, prepare derivative works of, display, 
         and perform the Content in connection with the Service and MVAE 's (and its successors' and 
         affiliates') business, including without limitation for promoting and redistributing part or 
         all of the Service (and derivative works thereof) in any media formats and through any media 
         channels. You also hereby grant each user of the Service a non-exclusive license to access 
         your Content through the Service, and to use, reproduce, distribute, display and perform 
         such Content as permitted through the functionality of the Service and under these 
         Terms of Service. The above licenses granted by you in all Content you submit globally to 
         the Service terminate within a commercially reasonable time after you remove or delete your 
         content from the Service. You understand and agree, however, that MVAE may retain, but not 
         display, distribute, or perform, server copies of your content that have been removed or 
         deleted. The above licenses granted by you in user comments you submit are perpetual and 
         irrevocable.</li>   
         <li>Content you as a Global Contributor submit globally to the Service should not contain third party copyrighted material, or material that is subject to other third party proprietary rights, unless you have permission from the rightful owner of the material or you are otherwise legally entitled to post the material and to grant MVAE all of the license rights granted herein.</li>
       <li>MVAE does not endorse any Content submitted to the Service by any user or other licensor, or any opinion, recommendation, or advice expressed therein, and MVAE expressly disclaims any and all liability in connection with Content. MVAE does not permit copyright infringing activities and infringement of intellectual property rights on the Service, and MVAE will remove all Content if properly notified that such Content infringes on another's intellectual property rights. MVAE reserves the right to remove Content without prior notice.</li>
       <li>As Global or other Contributor you understand and agree that you are solely responsible for your Content and the consequences of posting or publishing it. MVAE does not endorse any Content or any opinion, recommendation, or advice expressed therein, and MVAE expressly disclaims any and all liability in connection with Content.</li>
       </ol>


              </div>
				</Paper>
			</React.Fragment>
		);
	}
}

// TermsConditions.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

export default withStyles(styles)(TermsConditions);
