import React from "react";
import "./Index.css";
import { Container, Row, Col } from 'react-grid-system';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Grid,
  FormHelperText,
  Button,
  FormLabel
} from "@material-ui/core";
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import TextField from "@material-ui/core/TextField";
import { Hidden } from '@material-ui/core';
import DialogActions from "@material-ui/core/DialogActions";
import { GREY, RED, LIGHTBLUE, LIGHTGREY, MAROONHOVER } from "../../utils/color_utils";
import SendIcon from "@material-ui/icons/Send";
import { showToast } from "../../store/actions/toastAction";
import { createSurvey } from "../../store/actions/SurveyActions";
import { addMorePoints } from '../../store/actions/freshCardActions'
import { updateSurvey } from "../../store/actions/surveyUpdate";
import { testHistoryActions } from "../../store/actions/testHistoryActions";
import { confirmAlert } from 'react-confirm-alert';
// import { surveyStats } from "../../store/actions/surveyStatsAction";
// import { simpleAction } from "../../store/actions/simpleAction";
// import { surveyTestsDisplay } from "../../store/actions/surveyTestsDisplayAction";
//import firebase from "../../store/firebase";
import { connect } from "react-redux";
import {
  SURVEY_LIST,
  UPDATE_SURVEY,
  SURVEY_STATS_LIST,
  SIMPLE_VALUES,
  SURVEY_TESTS_LIST,
  SHOW_TOAST,
  TEST_HISTORY
} from "../../store/actions/types";
import { array } from "prop-types";
import survey from './img/surveypic.jpg';
import './sidebar.css'; 
import Tooltip from '@material-ui/core/Tooltip';
//import ReactRadioGroup from 'react-simple-radio-button';

const sendBtn = {
  backgroundColor: LIGHTBLUE,
  color: "#fff"
};
const sendBtn2 = {
  backgroundColor: RED,
  color: "#fff",
  cursor:'pointer'
};
const table = {
    color: "#515A5A",
    fontSize: 14,
    border: "1px solid #CAC9C8",
    backgroundColor: '#F2F3F4',
    padding: 1,
    borderRadius: 7
}
const tableh = {
  color: "#515A5A",
  fontSize: 14,
  border: "1px solid #707B7C",
  backgroundColor: '#F2F3F4',
  padding: 10,
  borderRadius: 15
}
const tableM = {
  color: "#515A5A",
  fontSize: 18,
  border: "1px solid #707B7C",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 15
}

const buttonStyle = {
    backgroundColor: RED,
    color: "#fff",
    width: "120px",
    height: "48px",
    border: "10px solid",
    borderColor: "#fff",
    borderRadius: "15px",
    textDecoration: "none"
  };
  
  const hoverbuttonStyle = {
    backgroundColor: MAROONHOVER,
    color: "#fff",
    width: "120px",
    height: "48px",
    border: "10px solid",
    borderColor: "#fff",
    borderRadius: "15px",
    textDecoration: "none"
  };

const styles = {
  mlist: {
    width: 250,
  },
  survs: {
    width: 1500,
  },
  row2: {
  },
  col1: {
    backgroundColor: 'yellow',
  },
  col2: {
    backgroundColor: 'brown',
  },
  col: {
    padding: '1rem',
    backgroundColor: '#33b5e5',
    border: '2px solid #fff',
    color: '#fff',
    textAlign: 'center'
    }
};
function handleChange() {
    console.log('in select function:');
  }
  function swapStuff(str) {
    var c, cc;
      c = str.replace(/\./g, (i => _ => ' ?'[i = 1 - i])(1));
      cc = c.replace(/\,/g, (i => _ => ' ?'[i = 1 - i])(1));
      cc = cc.replace(/\’/g, (i => _ => '?'[i = 1 - i])(1));
      cc = cc.replace(/\?/g, (i => _ => ' ?'[i = 1 - i])(1));
      
     // cc = cc.replace(/.$/," ");
  
    return cc;
   }
  
  class CreateaSurvey extends React.Component {
      constructor (props) {   
        super(props);
       // this.props.letgoPic(false);
       this.submitPost = this.submitPost.bind(this);
      }
  
      state = {
        stitle: this.props.title,
        companyid: this.props.userdata.companyID,
        Qnumber: 0,
        sQuestion: '',
        sAnswer1: '',
        sAnswer2: '',
        sAnswer3: '',
        sAnswer4: '',
        sAnswer5: '',
       // sAnswer6: '',
        QandA1: [],
        QandA2: [],
        QandA3: [],
        QandA4: [],
        QandA5: [],
        QandA6: [],
        QandA7: [],
        QandA8: [],
        QandA9: [],
        QandA10: [],
        QandA11: [],
        QandA12: [],
        typeofsurvey: "T",
        defaultsurvey: "Select Survey type",
        focus: true,
        submitLev: 1,
        ownMaterial: true,
        startPublish: false
      }
  
    selectSurveyType = (event, name) => {

    }
   
    makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
      }
      return result;
    }

    checkAnswers = (qqNum) => {
      if (qqNum === 0) { answerIn = true }
      else
      {
      var answerIn = false;
      if (
        this.state.sAnswer5 !== this.state.sAnswer1 &&
        this.state.sAnswer5 !== this.state.sAnswer2 &&
        this.state.sAnswer5 !== this.state.sAnswer3 &&
        this.state.sAnswer5 !== this.state.sAnswer4 
       )
       {
        answerIn = false;
       }
       else
       {
        answerIn = true;
       }
       if (
        this.state.sAnswer1 === '' || 
        this.state.sAnswer2 === '' || 
        this.state.sAnswer3 === '' || 
        this.state.sAnswer4 === '' || 
        this.state.sAnswer5 === ''   
       ) 
       {
        answerIn = false;
       }
      }
       return answerIn;
     }

    stop = (nrIn, val) => {
      var body = {
        thoughtID: this.props.thoughtID,
        type: SURVEY_LIST,
        title: this.state.stitle,
        companyID: this.state.companyid,
        typeofSurvey: this.state.typeofsurvey,
        size: this.state.Qnumber,
        QandA1: (nrIn===1 ? val:this.state.QandA1),
        QandA2: (nrIn===2 ? val:this.state.QandA2),
        QandA3: (nrIn===3 ? val:this.state.QandA3),
        QandA4: (nrIn===4 ? val:this.state.QandA4),
        QandA5: (nrIn===5 ? val:this.state.QandA5),
        QandA6: (nrIn===6 ? val:this.state.QandA6),
        QandA7: (nrIn===7 ? val:this.state.QandA7),
        QandA8: (nrIn===8 ? val:this.state.QandA8),
        QandA9: (nrIn===9 ? val:this.state.QandA9),
        QandA10: (nrIn===10 ? val:this.state.QandA10),
        QandA11: (nrIn===11 ? val:this.state.QandA11),
        QandA12: (nrIn===12 ? val:this.state.QandA12),
        ownMaterial: this.state.ownMaterial,
        dailyThoughtType: this.props.dailyThoughtType,
        groupid: this.props.groupid
      };
      console.log('blue stop: ', body);
      
      this.props.createSurvey(body);
  
      this.setState({stitle: "", typeofsurvey: 0, Qnumber: 1000});

      // ADD POINTS
      if (this.state.ownMaterial==="true") {
        var params = {
          uid: this.props.userdata.uid,
          userID: this.props.userdata.userID,
          pointsCurrent: this.props.userdata.points,
          pointsAdded: 20,
          ypointsCurrent: this.props.userdata.pointsy,
          trailID: this.makeid(10),
          companyName: this.props.userdata.companyName,
          firstName: this.props.userdata.firstName,
          lastName: this.props.userdata.lastName,
          title: this.state.title,
          actionType: 'Forum Test Published' + ' (own material)',
          actionDesc: this.state.title + ' Test'
        }
        console.log('blue stop ownMaterial 1: ', body);
      }
      else
      {
        var params = {
          uid: this.props.userdata.uid,
          userID: this.props.userdata.userID,
          pointsCurrent: this.props.userdata.points,
          ypointsCurrent: this.props.userdata.pointsy,
          pointsAdded: 20,
          trailID: this.makeid(10),
          companyName: this.props.userdata.companyName,
          firstName: this.props.userdata.firstName,
          lastName: this.props.userdata.lastName,
          title: this.state.title,
          actionType: 'Forum Test Published' + ' (own material)',
          actionDesc: this.state.title + ' Test'
        }
      }
     if (this.props.userdata.execmember===true) {
         this.props.addMorePoints(params);
     }
    this.props.getSurveyStage(this.props.thoughtID);
    this.props.resetMakeTest(this.props.thoughtID, this.props.companyID, this.props.companyID);
     console.log('inside showLead function A ', this.props)
    if (this.props.showLeadb) {
      this.props.showLeadb(this.props, 'something');
      }	
      return <div>-------</div>
    }
  
    handleChangev = (name, value) => {
      if(value==null) {
         return;
      }
      this.setState({
        [name]: value
      });
      }     

      handleNameChange = event => {
        this.setState({ sAnswer1: event.target.value });
      };

      handleChangex = (val) => {
        this.setState({
          sAnswer1: val
        });
      };

      setVal = (txtIn, vv) => {
        this.setState({
          [txtIn]: vv,
        });
      };

    changeDetails = e => {
      const { name, value, checked, maxLength } = e.target;
      const { Details, formErrors } = this.state;
      let formObj = {};
      if (name === "sAnswer1") {
        // handle the change event of sAnswer1
          // push selected value in list
          this.setState({
            [name]: this.inputRef.value,
          });
        // handle change event except language field
        // this.setState({
        //   [name]: value
        // });
      }
    };
    // confirmPublish = () => {
    //   console.log('inside confirmPublish');
    //   confirmAlert({
    //     title: 'Confirm Test Publication',
    //     message: 'Are you sure that you want to publish this test?',
    //     buttons: [
    //       {
    //         label: 'Yes',
    //         onClick: () => alert('Click Yes')
    //       },
    //       {
    //         label: 'No',
    //         onClick: () => alert('Click No')
    //       }
    //     ]
    //   });
    // };

    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
  
      if (/android/i.test(userAgent)) {
          return "Android";
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
  
      return "unknown";
  }

    submitPost = (desc) => {
      console.log('in submitPost');
      console.log('green this.stat.Qnumber: ', this.state.Qnumber );
      var pass = true;
      var limit = true;

    if (desc===1 && this.state.Qnumber===7) {  //reached limit of 8 questions
     // console.log('green getMobileOperatingSystem: ', this.getMobileOperatingSystem);

      if(this.getMobileOperatingSystem==='iOS') {
          desc = 2;
          console.log('Thing was saved to the database.');
          pass = true;
      }
      else
      {
          if (window.confirm('Seven questions is your limit. Do you want to save your Test now?')) {
          // Save it!
          desc = 2;
          console.log('Thing was saved to the database.');
          pass = true;
           
          } else {
          // Do nothing!
          console.log('Thing was not saved to the database.');
          pass = false;
           
          }
        } 
      }

      // if (desc===1 && this.state.Qnumber===7) {  //reached limit of 8 questions
      //   if (window.confirm('Seven questions is your limit. Do you want to save your Test now?')) {
      //   // Save it!
      //   desc = 2;
      //   console.log('Thing was saved to the database.');
      //   pass = true;
         
      //   } else {
      //   // Do nothing!
      //   console.log('Thing was not saved to the database.');
      //   pass = false;
         
      //   }
      // }   

            ///////////GO BACK///////GO BACK/////////////////////////////////////
            console.log('GREEN CHANGE: ', desc);
            var qq;
          if (desc===3) {                                           ///going back
            qq = this.state.Qnumber - 1
            console.log('GREEN CHANGE back: ', this.state);
            switch (qq) {
              case 1:
                params = {
                  type: TEST_HISTORY,
                  q: this.state.QandA1.question,
                  a1: this.state.QandA1.a1,
                  a2: this.state.QandA1.a2,
                  a3: this.state.QandA1.a3,
                  a4: this.state.QandA1.a4,
                  a5: this.state.QandA1.a5
                }
                this.props.testHistoryActions(params);

                console.log('GREEN CHANGE params: ', params);
                 console.log('this.sAnswer1.value: ', this.state.sAnswer1);
                break;
              case 2:
                  params = {
                    type: TEST_HISTORY,
                    q: this.state.QandA2.question,
                    a1: this.state.QandA2.a1,
                    a2: this.state.QandA2.a2,
                    a3: this.state.QandA2.a3,
                    a4: this.state.QandA2.a4,
                    a5: this.state.QandA2.a5
                  }
                  this.props.testHistoryActions(params);
                  break;
               case 3:
                  params = {
                    type: TEST_HISTORY,
                    q: this.state.QandA3.question,
                    a1: this.state.QandA3.a1,
                    a2: this.state.QandA3.a2,
                    a3: this.state.QandA3.a3,
                    a4: this.state.QandA3.a4,
                    a5: this.state.QandA3.a5
                  }
                  this.props.testHistoryActions(params);
                  break;    
               case 4:
                  params = {
                    type: TEST_HISTORY,
                    q: this.state.QandA4.question,
                    a1: this.state.QandA4.a1,
                    a2: this.state.QandA4.a2,
                    a3: this.state.QandA4.a3,
                    a4: this.state.QandA4.a4,
                    a5: this.state.QandA4.a5
                  }
                  this.props.testHistoryActions(params);
                  break;   
               case 5:
                    params = {
                      type: TEST_HISTORY,
                      q: this.state.QandA5.question,
                      a1: this.state.QandA5.a1,
                      a2: this.state.QandA5.a2,
                      a3: this.state.QandA5.a3,
                      a4: this.state.QandA5.a4,
                      a5: this.state.QandA5.a5
                    }
                    this.props.testHistoryActions(params);
                    break;                               
              default:
              }
            }
              if (desc===1) {                                      ///  navigating forward
                qq = this.state.Qnumber + 1
                console.log('GREEN CHANGE forward: ', this.state);
                switch (qq) {
                  case 1:
                    params = {
                      type: TEST_HISTORY,
                      q: this.state.QandA1.question,
                      a1: this.state.QandA1.a1,
                      a2: this.state.QandA1.a2,
                      a3: this.state.QandA1.a3,
                      a4: this.state.QandA1.a4,
                      a5: this.state.QandA1.a5
                    }
                    this.props.testHistoryActions(params);
    
                    console.log('GREEN CHANGE params: ', params);
                     console.log('this.sAnswer1.value: ', this.state.sAnswer1);
                    break;
                  case 2:
                      params = {
                        type: TEST_HISTORY,
                        q: this.state.QandA2.question,
                        a1: this.state.QandA2.a1,
                        a2: this.state.QandA2.a2,
                        a3: this.state.QandA2.a3,
                        a4: this.state.QandA2.a4,
                        a5: this.state.QandA2.a5
                      }
                      this.props.testHistoryActions(params);
                      break;
                   case 3:
                      params = {
                        type: TEST_HISTORY,
                        q: this.state.QandA3.question,
                        a1: this.state.QandA3.a1,
                        a2: this.state.QandA3.a2,
                        a3: this.state.QandA3.a3,
                        a4: this.state.QandA3.a4,
                        a5: this.state.QandA3.a5
                      }
                      this.props.testHistoryActions(params);
                      break;    
                   case 4:
                      params = {
                        type: TEST_HISTORY,
                        q: this.state.QandA4.question,
                        a1: this.state.QandA4.a1,
                        a2: this.state.QandA4.a2,
                        a3: this.state.QandA4.a3,
                        a4: this.state.QandA4.a4,
                        a5: this.state.QandA4.a5
                      }
                      this.props.testHistoryActions(params);
                      break;   
                   case 5:
                        params = {
                          type: TEST_HISTORY,
                          q: this.state.QandA5.question,
                          a1: this.state.QandA5.a1,
                          a2: this.state.QandA5.a2,
                          a3: this.state.QandA5.a3,
                          a4: this.state.QandA5.a4,
                          a5: this.state.QandA5.a5
                        }
                        this.props.testHistoryActions(params);
                        break;                               
                  default:
                  }  
                }       
            //}
            //////////////////////////////////////////////////////////////////////

      if (desc===2) {
        if (this.checkAnswers(this.state.Qnumber) === false) {
          //back out
          var params = {
            type: SHOW_TOAST,
            open: true,
            variant: 'warning',
            message: 'You did not fill in all the fields, or your correct answer in the last text field does NOT CORRESPOND with any of the other fields 1 to 4. Please try again.'
          }
          this.props.showToast(params)
          return
        }
        if (window.confirm('Are you sure you want to publish this test? Confirming will be final, and your test will then be published.')) {
        // Save it!
        console.log('Thing was saved to the database.');
        pass = true;
        } else {
        // Do nothing!
        console.log('Thing was not saved to the database.');
        pass = false;
        }
      }
      if (pass===true) {
      if (this.checkAnswers(this.state.Qnumber) === false && desc===1) {
        //back out
        console.log('green this.state.Qnumber: ', this.state.Qnumber);
        var params = {
          type: SHOW_TOAST,
          open: true,
          variant: 'warning',
          message: 'You did not fill in all the fields, or your correct answer in the last text field does NOT CORRESPOND with any of the other fields 1 to 4. Please try again.'
        }
        this.props.showToast(params)
        return
      }
      var arrAnswer1 = [];
      var arrAnswer2 = [];
      var arrAnswer3 = [];
      var arrAnswer4 = [];
      var arrAnswer5 = [];
      var arrAnswer6 = []; 
      var arrAnswer7 = [];
      var arrAnswer8 = [];
      var arrAnswer9 = [];
      var arrAnswer10 = [];
      var arrAnswer11 = [];
      var arrAnswer12 = [];
      var arrQandA = [];
  
     console.log('submitPost props: ',  this.props);
     if (desc===2) {
      var qq = this.state.Qnumber + 1;
     }
  
     this.setState({Qnumber: qq});
     var arrQandA = []; //{}; //new Array();
     var arrAnswer = []; //{};
     var Qnum = this.state.Qnumber;
     var lastQ;
  
     //var Qlimit = this.state.Qnumber;
  
    //  if (desc === 2) {
    //    Qnum = 12
  
    //   }
    console.log('arrAnswer2 Qnum: ', Qnum);
    console.log('arrAnswer2 desc: ', desc);

     switch (Qnum) {
      case 1000:
        console.log('inside case 1000');
        break;
      case 0: // survey basic details
        this.setState({
          title: this.state.stitle,
          companyID: this.state.companyid 
        })
        break;
      case 1: // build arrays for all other cases
      arrAnswer1 = {
        "question": this.state.sQuestion,
        "a1": this.state.sAnswer1,
        "a2": this.state.sAnswer2,
        "a3": this.state.sAnswer3,
        "a4": this.state.sAnswer4,
        "a5": this.state.sAnswer5
      };
      this.setState({QandA1: arrAnswer1});
      if ( desc === 2 && Qnum === 1 ) {
        this.stop(1, arrAnswer1)
      }
        break;
      case 2: // build arrays for all other cases
      arrAnswer2 = {
        "question": this.state.sQuestion,
        "a1": this.state.sAnswer1,
        "a2": this.state.sAnswer2,
        "a3": this.state.sAnswer3,
        "a4": this.state.sAnswer4,
        "a5": this.state.sAnswer5
      };
      this.setState({QandA2: arrAnswer2});

      if ( desc === 2 && Qnum === 2 ) {
        this.stop(2, arrAnswer2)
      }
        break;
      case 3: // build arrays for all other cases
      arrAnswer3 = {
        "question": this.state.sQuestion,
        "a1": this.state.sAnswer1,
        "a2": this.state.sAnswer2,
        "a3": this.state.sAnswer3,
        "a4": this.state.sAnswer4,
        "a5": this.state.sAnswer5
      };
      if ( desc === 2 && Qnum === 3 ) {
        this.stop(3, arrAnswer3)
      }
      this.setState({QandA3: arrAnswer3});
        break; 
      case 4: // build arrays for all other cases
      arrAnswer4 = {
        "question": this.state.sQuestion,
        "a1": this.state.sAnswer1,
        "a2": this.state.sAnswer2,
        "a3": this.state.sAnswer3,
        "a4": this.state.sAnswer4,
        "a5": this.state.sAnswer5
      };
      this.setState({QandA4: arrAnswer4});
      if ( desc === 2 && Qnum === 4 ) {
        this.stop(4, arrAnswer4)
      }
        break;  
     case 5: // build arrays for all other cases
      arrAnswer5 = {
        "question": this.state.sQuestion,
        "a1": this.state.sAnswer1,
        "a2": this.state.sAnswer2,
        "a3": this.state.sAnswer3,
        "a4": this.state.sAnswer4,
        "a5": this.state.sAnswer5
      };
      this.setState({QandA5: arrAnswer5});
      if ( desc === 2 && Qnum === 5 ) {
        this.stop(5, arrAnswer5)
      }
        break;    
     case 6: // build arrays for all other cases
        arrAnswer6 = {
          "question": this.state.sQuestion,
          "a1": this.state.sAnswer1,
          "a2": this.state.sAnswer2,
          "a3": this.state.sAnswer3,
          "a4": this.state.sAnswer4,
          "a5": this.state.sAnswer5
        };
        this.setState({QandA6: arrAnswer6});
        if ( desc === 2 && Qnum === 6 ) {
          this.stop(6, arrAnswer6)
        }
          break;      
     case 7: // build arrays for all other cases
          arrAnswer7 = {
            "question": this.state.sQuestion,
            "a1": this.state.sAnswer1,
            "a2": this.state.sAnswer2,
            "a3": this.state.sAnswer3,
            "a4": this.state.sAnswer4,
            "a5": this.state.sAnswer5
          };
          this.setState({QandA7: arrAnswer7});
          if ( desc === 2 && Qnum === 7 ) {
            this.stop(7, arrAnswer7)
          }
            break;       
     case 8: // build arrays for all other cases
            arrAnswer8 = {
              "question": this.state.sQuestion,
              "a1": this.state.sAnswer1,
              "a2": this.state.sAnswer2,
              "a3": this.state.sAnswer3,
              "a4": this.state.sAnswer4,
              "a5": this.state.sAnswer5
            };
            this.setState({QandA8: arrAnswer8});
            if ( desc === 2 && Qnum === 8 ) {
              this.stop(8, arrAnswer8)
            }
              break; 
     case 9: // build arrays for all other cases
            arrAnswer9 = {
              "question": this.state.sQuestion,
              "a1": this.state.sAnswer1,
              "a2": this.state.sAnswer2,
              "a3": this.state.sAnswer3,
              "a4": this.state.sAnswer4,
              "a5": this.state.sAnswer5
            };
            this.setState({QandA9: arrAnswer9});
            if ( desc === 2 && Qnum === 9 ) {
              this.stop(9, arrAnswer9)
            }
              break; 
      case 10: // build arrays for all other cases
            arrAnswer10 = {
              "question": this.state.sQuestion,
              "a1": this.state.sAnswer1,
              "a2": this.state.sAnswer2,
              "a3": this.state.sAnswer3,
              "a4": this.state.sAnswer4,
              "a5": this.state.sAnswer5
            };
            this.setState({QandA10: arrAnswer10});
            if ( desc === 2 && Qnum === 10 ) {
              this.stop(10, arrAnswer10)
            }
              break; 
       case 11: // build arrays for all other cases
              arrAnswer11 = {
                "question": this.state.sQuestion,
                "a1": this.state.sAnswer1,
                "a2": this.state.sAnswer2,
                "a3": this.state.sAnswer3,
                "a4": this.state.sAnswer4,
                "a5": this.state.sAnswer5
              };
              this.setState({QandA11: arrAnswer11});
              if ( desc === 2 && Qnum === 11 ) {
                this.stop(11, arrAnswer11)
              }
                break;              
      case 12: // last question, submit all 
        arrAnswer12 = {
          "question": this.state.c,
          "a1": this.state.sAnswer1,
          "a2": this.state.sAnswer2,
          "a3": this.state.sAnswer3,
          "a4": this.state.sAnswer4,
          "a5": this.state.sAnswer5
        };
        this.setState({QandA12: arrAnswer12});
        if ( desc === 2 && Qnum === 12 ) {
          this.stop(12, arrAnswer12)
        }
        // lastQ = "QandA" + this.state.Qnumber
        // this.setState({[lastQ]: arrAnswer12});
        // if (this.state.Qnumber != 12) {arrAnswer12=[]}
  
        // var body = {
        //   type: SURVEY_LIST,
        //   title: this.state.stitle,
        //   companyID: this.state.companyid,
        //   typeofSurvey: this.state.typeofsurvey,
        //   size: this.state.Qnumber,
        //   QandA1: this.state.QandA1,
        //   QandA2: this.state.QandA2,
        //   QandA3: this.state.QandA3,
        //   QandA4: this.state.QandA4,
        //   QandA5: this.state.QandA5,
        //   QandA6: this.state.QandA6,
        //   QandA7: this.state.QandA7,
        //   QandA8: this.state.QandA8,
        //   QandA9: this.state.QandA9,
        //   QandA10: this.state.QandA10,
        //   QandA11: this.state.QandA11,
        //   QandA12: arrAnswer12
        // };
  
        // console.log('body: ', body);
        // this.props.props.createSurvey(body);
  
        // this.setState({stitle: "", typeofsurvey: 0, Qnumber: 1000});
        // this.props.letgoPic(true);
        break;
  
      default: // build arrays for all other cases
     }
    //  this.setState({
    //   sQuestion: this.state.sQuestion ? this.state.sQuestion : '',
    //   sAnswer1: this.state.sAnswer1 ? this.state.sAnswer1 : '',
    //   sAnswer2: this.state.sAnswer2 ? this.state.sAnswer2 : '',
    //   sAnswer3: this.state.sAnswer3 ? this.state.sAnswer3 : '',
    //   sAnswer4: this.state.sAnswer4 ? this.state.sAnswer4 : '',
    //   sAnswer5: this.state.sAnswer5 ? this.state.sAnswer5 : '',
    //  });

    //  this.setState({
    //   sQuestion: this.state.arrAnswer1.question ? this.state.arrAnswer1.question : '',
    //   sAnswer1: this.state.arrAnswer1.a1 ? this.state.arrAnswer1.a1 : '',
    //   sAnswer2: this.state.arrAnswer2.a2 ? this.state.arrAnswer2.a2 : '',
    //   sAnswer3: this.state.arrAnswer3.a3 ? this.state.arrAnswer3.a3 : '',
    //   sAnswer4: this.state.arrAnswer4.a4 ? this.state.arrAnswer4.a4 : '',
    //   sAnswer5: this.state.arrAnswer5.a5 ? this.state.arrAnswer5.a5 : '',
    //  });
    console.log('GREENy arrAnswer2 qq: ', qq);
    
    switch (qq) {
      case 1:
        console.log('GREENy this.state.QandA1: ', this.state.QandA1);
        this.setState({
          sQuestion: this.state.QandA1.question ? this.state.QandA1.question : '',
          sAnswer1: this.state.QandA1.a1 ? this.state.QandA1.a1 : '',
          sAnswer2: this.state.QandA1.a2 ? this.state.QandA1.a2 : '',
          sAnswer3: this.state.QandA1.a3 ? this.state.QandA1.a3 : '',
          sAnswer4: this.state.QandA1.a4 ? this.state.QandA1.a4 : '',
          sAnswer5: this.state.QandA1.a5 ? this.state.QandA1.a5 : '',
         });
        break;
      case 2:
        console.log('GREENy this.state.QandA2: ', this.state.QandA2);
        this.setState({
          sQuestion: this.state.QandA2.question ? this.state.QandA2.question : '',
          sAnswer1: this.state.QandA2.a1 ? this.state.QandA2.a1 : '',
          sAnswer2: this.state.QandA2.a2 ? this.state.QandA2.a2 : '',
          sAnswer3: this.state.QandA2.a3 ? this.state.QandA2.a3 : '',
          sAnswer4: this.state.QandA2.a4 ? this.state.QandA2.a4 : '',
          sAnswer5: this.state.QandA2.a5 ? this.state.QandA2.a5 : '',
         });
        break;
      case 3:
        console.log('GREENy this.state.QandA3: ', this.state.QandA3);
        this.setState({
          sQuestion: this.state.QandA3.question ? this.state.QandA3.question : '',
          sAnswer1: this.state.QandA3.a1 ? this.state.QandA3.a1 : '',
          sAnswer2: this.state.QandA3.a2 ? this.state.QandA3.a2 : '',
          sAnswer3: this.state.QandA3.a3 ? this.state.QandA3.a3 : '',
          sAnswer4: this.state.QandA3.a4 ? this.state.QandA3.a4 : '',
          sAnswer5: this.state.QandA3.a5 ? this.state.QandA3.a5 : '',
         });
        break;  
      case 4:
        console.log('GREENy this.state.QandA4: ', this.state.QandA4);
        this.setState({
          sQuestion: this.state.QandA4.question ? this.state.QandA4.question : '',
          sAnswer1: this.state.QandA4.a1 ? this.state.QandA4.a1 : '',
          sAnswer2: this.state.QandA4.a2 ? this.state.QandA4.a2 : '',
          sAnswer3: this.state.QandA4.a3 ? this.state.QandA4.a3 : '',
          sAnswer4: this.state.QandA4.a4 ? this.state.QandA4.a4 : '',
          sAnswer5: this.state.QandA4.a5 ? this.state.QandA4.a5 : '',
         });
        break;   
      case 5:
        this.setState({
          sQuestion: this.state.QandA5.question ? this.state.QandA5.question : '',
          sAnswer1: this.state.QandA5.a1 ? this.state.QandA5.a1 : '',
          sAnswer2: this.state.QandA5.a2 ? this.state.QandA5.a2 : '',
          sAnswer3: this.state.QandA5.a3 ? this.state.QandA5.a3 : '',
          sAnswer4: this.state.QandA5.a4 ? this.state.QandA5.a4 : '',
          sAnswer5: this.state.QandA5.a5 ? this.state.QandA5.a5 : '',
          });
        break;   
      case 6:
        this.setState({
          sQuestion: this.state.QandA6.question ? this.state.QandA6.question : '',
          sAnswer1: this.state.QandA6.a1 ? this.state.QandA6.a1 : '',
          sAnswer2: this.state.QandA6.a2 ? this.state.QandA6.a2 : '',
          sAnswer3: this.state.QandA6.a3 ? this.state.QandA6.a3 : '',
          sAnswer4: this.state.QandA6.a4 ? this.state.QandA6.a4 : '',
          sAnswer5: this.state.QandA6.a5 ? this.state.QandA6.a5 : '',
          });
        break;   
      case 7:
        this.setState({
          sQuestion: this.state.QandA7.question ? this.state.QandA7.question : '',
          sAnswer1: this.state.QandA7.a1 ? this.state.QandA7.a1 : '',
          sAnswer2: this.state.QandA7.a2 ? this.state.QandA7.a2 : '',
          sAnswer3: this.state.QandA7.a3 ? this.state.QandA7.a3 : '',
          sAnswer4: this.state.QandA7.a4 ? this.state.QandA7.a4 : '',
          sAnswer5: this.state.QandA7.a5 ? this.state.QandA7.a5 : '',
          });
        break;   
      case 8:
        this.setState({
          sQuestion: this.state.QandA8.question ? this.state.QandA8.question : '',
          sAnswer1: this.state.QandA8.a1 ? this.state.QandA8.a1 : '',
          sAnswer2: this.state.QandA8.a2 ? this.state.QandA8.a2 : '',
          sAnswer3: this.state.QandA8.a3 ? this.state.QandA8.a3 : '',
          sAnswer4: this.state.QandA8.a4 ? this.state.QandA8.a4 : '',
          sAnswer5: this.state.QandA8.a5 ? this.state.QandA8.a5 : '',
          });
        break;  
      case 9:
        this.setState({
          sQuestion: this.state.QandA9.question ? this.state.QandA9.question : '',
          sAnswer1: this.state.QandA9.a1 ? this.state.QandA9.a1 : '',
          sAnswer2: this.state.QandA9.a2 ? this.state.QandA9.a2 : '',
          sAnswer3: this.state.QandA9.a3 ? this.state.QandA9.a3 : '',
          sAnswer4: this.state.QandA9.a4 ? this.state.QandA9.a4 : '',
          sAnswer5: this.state.QandA9.a5 ? this.state.QandA9.a5 : '',
          });
        break;   
      case 10:
        this.setState({
          sQuestion: this.state.QandA10.question ? this.state.QandA10.question : '',
          sAnswer1: this.state.QandA10.a1 ? this.state.QandA10.a1 : '',
          sAnswer2: this.state.QandA10.a2 ? this.state.QandA10.a2 : '',
          sAnswer3: this.state.QandA10.a3 ? this.state.QandA10.a3 : '',
          sAnswer4: this.state.QandA10.a4 ? this.state.QandA10.a4 : '',
          sAnswer5: this.state.QandA10.a5 ? this.state.QandA10.a5 : '',
          });
        break;  
      case 11:
        this.setState({
          sQuestion: this.state.QandA11.question ? this.state.QandA11.question : '',
          sAnswer1: this.state.QandA11.a1 ? this.state.QandA11.a1 : '',
          sAnswer2: this.state.QandA11.a2 ? this.state.QandA11.a2 : '',
          sAnswer3: this.state.QandA11.a3 ? this.state.QandA11.a3 : '',
          sAnswer4: this.state.QandA11.a4 ? this.state.QandA11.a4 : '',
          sAnswer5: this.state.QandA11.a5 ? this.state.QandA11.a5 : '',
          });
        break;                                  
    }
    // this.setState({
    //   sQuestion: '',
    //   sAnswer1: '',
    //   sAnswer2: '',
    //   sAnswer3: '',
    //   sAnswer4: '',
    //   sAnswer5: '',
    //  });
    }
  }

    componentDidMount() {
     this.setState({defaultsurvey: "Select Survey Type"});
    }
    shouldComponentUpdate(nextProps, nextState) {
      return true;
    }
    handleSelection(){
      console.log('green handleSelection');
    }

    // toggleOff(n) {
    //   const { ownMaterial, startPublish } = this.state
    //   console.log('green ownMaterial: ', this.state.ownMaterial);
    //   this.setState({
    //     ownMaterial: n,
    //     startPublish: true
    //   })
    // };

    // onOptionSelect = (value) => {
    //   console.log('green Selected value=', value)
    // }

    handleRadioChange = e => {
      const { name, value } = e.target;
      console.log('green radio option: ', value)
      this.setState({
        ownMaterial: value,
        startPublish: true
      });
    };

    render() {
      const { handleClosex, testHistory } = this.props
      console.log('CreateaSurvey props:', this.props);
  
      const longText = `Please enter the CORRECT ANSWER to your set question. This answer must correspond to just ONE of
      your supplied answers in the above 4 options.`;
       

    return (
      // <div className="bod">
      <div style={{
        marginLeft: '30px',
        marginTop: '15px',
        height: '600px',
        top: '-15px',
        width: '90%',
        maxWidth: '90%'
       }}>
         
        <Container>
        { this.state.Qnumber===0 ?
        <React.Fragment>
        <h5>Dear publisher,</h5>
        <p>
Set up a test related to your publication, for which you will earn an additional 30 points (if orriginal material). The test can have between three and seven questions. 
Each question has four possible answers of which only one will be the correct answer. 
The correct answer will be the fifth and last answer you fill in for every question.  
Once a test is published, you can’t modify or change it. 
If you want to modify or change your test, you would have to re-publish your publication.
</p>
         <Row>
          <Col sm={12}>
            <div>Is this Publication your own material?</div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>


          {/* <div onChange={this.toggleOff(true)}>
              <input type="radio" value="own" name="own"/> Yes &nbsp;&nbsp;
              <input type="radio" value="notown" name="own"/> No
          </div> */}
                  {/* <ReactRadioGroup 
                    defaultSelected = 'Option 1'
                    onChange={this.onOptionSelect}
                    options={['Option 1','Option 2']} 
                  /> */}

                  {/* <RadioGroup
                    aria-label='a'
                    name='a'
                    value={0}
                    style={{ flexDirection: 'row', marginBottom: '10px' }}
                  >
                        <FormControlLabel
                          value='own'
                          control={<Radio color='primary' />}
                          label='Yes my own publication'
                          labelPlacement='end'
                          onClick={() => this.toggleOff(true)}
                        />
                      <FormControlLabel
                        value='postGlobally'
                        control={<Radio color='primary' />}
                        label='Not my own material'
                        labelPlacement='end'
                        onClick={() => this.toggleOff(false)}
                      />
                  </RadioGroup> */}
                  <div className="radio-buttons">
                        Yes &nbsp;&nbsp;
                        <input
                          id="true"
                          value="true"
                          name="platform"
                          type="radio"
                          onChange={this.handleRadioChange}
                        />
                        &nbsp;&nbsp; No &nbsp;&nbsp;
                        <input
                          id="false"
                          value="false"
                          name="platform"
                          type="radio"
                          onChange={this.handleRadioChange}
                        />

                      </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TextField
                 // autoFocus={true}
                  id="title"
                  label="Title"
                  multiline
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 200, readOnly: true }}
                  aria-describedby="surveyTitle"
                  // onChange={event => {
                  //   const { value } = event.target;
                  //   this.setState({stitle: swapStuff(value)})
                  // }}
                  //value={swapStuff(this.state.stitle)}
                  value={this.props.title}
                />
          </Col>
        </Row>
  
        <Row>
          <Col sm={12}>
          {/* <FormControl style={{minWidth: 160}}>  */}
          {/* <InputLabel shrink htmlFor="age-native-label-placeholder">
            Enter Type of Survey
          </InputLabel> */}
           {/* <Select
              label="Type of Survey"
              onChange={event => {
                const { value } = event.target;
                this.setState({typeofsurvey: value})
                console.log('typeofsurvey: ', this.state.typeofsurvey);
              }}
              value={this.state.typeofsurvey}
              displayEmpty
              input={<Input name="survey" id="survey" value={this.state.typeofsurvey} />}
            >
              <MenuItem value={this.state.typeofsurvey}>
                <em>{this.state.defaultsurvey}</em>
              </MenuItem>
              <MenuItem value='P'>
                <em>Poll</em>
              </MenuItem>
              <MenuItem value='T'>
                Test
              </MenuItem>
            </Select> */}
            {/* </FormControl> */}
          </Col>
          </Row>
          </React.Fragment> 
          :
           this.state.Qnumber < 13 ?
          <React.Fragment> 
          <h4><div style={{ width:'600px'}}>Test Builder</div></h4>         
          {/* <p><font color="#ffffff">..............................................................................</font></p> */}
      <Row>
          <Col sm={12}>
            <TextField
                //autoFocus={this.state.focus}
                id="sQuestion"
                label={"Enter Question "+this.state.Qnumber}
                multiline
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 200 }}
                aria-describedby="surveyTitle"
                onChange={event => {
                  const { value } = event.target;
                  this.setState({sQuestion: swapStuff(value)})
                }}
                //value={swapStuff(this.state.sQuestion)}
                value={ this.state.sQuestion ? swapStuff(this.state.sQuestion) : testHistory.q ? testHistory.q: ""}
              />
          </Col>
        </Row>  
         <Row>
         </Row>
         <Row>
          <Col sm={10}>
            <TextField
                // autoFocus
                //autoComplete
                id="sAnswer1"
                name="sAnswer1"
                //inputRef={(input) => this.sAnswer1 = input }
                ref="sAnswer1"
                label="Enter Answer 1"
                multiline
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 200 }}
                aria-describedby="answer"
                onChange={event => {
                  const { value } = event.target;
                  this.setState({sAnswer1: swapStuff(value)}) 
                }}
                //value={ this.state.submitLev===3 ? testHistory.a1 : swapStuff(this.state.sAnswer1)}
                //value={swapStuff(this.state.sAnswer1)}
                 value={ this.state.sAnswer1 ? swapStuff(this.state.sAnswer1) : testHistory.a1 ? testHistory.a1: ""}
              />
          </Col>
        </Row> 
        <Row>
          <Col sm={10}>
            <TextField
                // autoFocus
                id="sAnswer2"
                label="Enter Answer 2"
                multiline
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 200 }}
                aria-describedby="answer"
                onChange={event => {
                  const { value } = event.target;
                  this.setState({sAnswer2: swapStuff(value)})
                }}
                //value={swapStuff(this.state.sAnswer2)}
                value={ this.state.sAnswer2 ? swapStuff(this.state.sAnswer2) : testHistory.a2 ? testHistory.a2: ""}
                />
          </Col>
        </Row> 
        <Row>
          <Col sm={10}>
            <TextField
                // autoFocus
                id="sAnswer3"
                label="Enter Answer 3"
                multiline
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 200 }}
                aria-describedby="answer"
                onChange={event => {
                  const { value } = event.target;
                  this.setState({sAnswer3: swapStuff(value)})
                }}
                //value={swapStuff(this.state.sAnswer3)}
                value={ this.state.sAnswer3 ? swapStuff(this.state.sAnswer3) : testHistory.a3 ? testHistory.a3: ""}
              />
          </Col>
        </Row> 
        <Row>
          <Col sm={10}>
            <TextField
                // autoFocus
                id="sAnswer4"
                label="Enter Answer 4"
                multiline
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 200 }}
                aria-describedby="answer"
                onChange={event => {
                  const { value } = event.target;
                  this.setState({sAnswer4: swapStuff(value)})
                }}
                //value={swapStuff(this.state.sAnswer4)}
                value={ this.state.sAnswer4 ? swapStuff(this.state.sAnswer4) : testHistory.a4 ? testHistory.a4: ""}
              />
          </Col>
        </Row> 
  
    { this.state.typeofsurvey==="T"?
        <Row>
          <Col sm={10}>
          <Tooltip title={longText}>
            <TextField
                // autoFocus
                id="sAnswer5"
                label="Enter Correct Answer"
                multiline
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 200 }}
                aria-describedby="answer"
                onChange={event => {
                  const { value } = event.target;
                  this.setState({sAnswer5: swapStuff(value)})
                }}
                //value={swapStuff(this.state.sAnswer5)}
                value={ this.state.sAnswer5 ? swapStuff(this.state.sAnswer5) : testHistory.a5 ? testHistory.a5: ""}
              />
              </Tooltip>
          </Col>
        </Row> 
       :
       <Row>
       <Col sm={10}>
       <input type="hidden"
             id="sAnswer5"
             aria-describedby="answer"
             value="no answer"
           />
       </Col>
     </Row> 
       }
        </React.Fragment> 
         :null
          // OR 
          // PUT IN SOME NICE MESSAGE
          }
          { this.state.Qnumber < 13 ?
          <Row>
           <Col sm={8}></Col>
          <Col sm={8}>
          <FormControl>
           
            <DialogActions>
          
              {this.state.Qnumber < 12 && this.state.Qnumber > 1? 
                <Button
                //variant="contained"
                onClick={() => this.submitPost(3)}
                style={sendBtn}
              >
                <SendIcon/>
                BACK
              </Button>
              :null}
              {this.state.Qnumber < 12 && this.state.startPublish===true?  
                <Button
                  //variant="contained"
                  onClick={() => this.submitPost(1)}
                  style={sendBtn}
                >
                  <SendIcon/>
                  CONTINUE
                </Button>
               :null}
               
               {this.state.Qnumber > 0?
                <Button
                  onClick={() => this.submitPost(2)}
                  //autoFocus={true}
                  type="submit"
                 //onClick={() => this.confirmPublish()}
                  style={sendBtn2}
                >
                  <SendIcon/>
                  END
                </Button>
                :null}
              </DialogActions>
            </FormControl>
          </Col>
        </Row>
        :
        <React.Fragment>
        <div>Your Test has been submitted successfully.</div>
         <p align='center' onClick={() => handleClosex()}> Close</p>
         </React.Fragment>
        }
      </Container>
      </div>
    );
    }
   }
   
  const mapStateToProps = state => {
    return {
      userdata: state.userDialog.userDetails[0],
      update_Survey: state.updateSurvey.update_Survey,
      testHistory: state.testHistory
      // testHistoryq: state.testHistory.q,
      // testHistorya1: state.testHistory.a1,
      // testHistorya2: state.testHistory.a2,
      // testHistorya3: state.testHistory.a3,
      // testHistorya4: state.testHistory.a4,
      // testHistorya5: state.testHistory.a5
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      showToast: params => dispatch(showToast(params)),
      createSurvey: params => dispatch(createSurvey(params)),
      updateSurvey: params => dispatch(updateSurvey(params)),
      addMorePoints: params => dispatch(addMorePoints(params)),
      testHistoryActions: params => dispatch(testHistoryActions(params)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(CreateaSurvey);