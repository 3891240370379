/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable no-inline-comments */

import React, { Component } from 'react'
//import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { getCompanyValues, deleteCompanyValue } from '../../../store/actions/pldpActions'
import {
  ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanelActions, Grid, Typography, Button, Modal, Dialog,
  DialogActions, DialogContent, DialogTitle
} from '@material-ui/core'
import { Link } from "react-router-dom";

import TextField from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import AccountBalance from '@material-ui/icons/AccountBalance'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { VALUES_NAME, SHOW_TOAST, GET_O } from '../../../store/actions/types'
import { LIGHTGREY, THEMECOLOR } from '../../../utils/color_utils'
import { RadioGroup, Radio } from 'react-radio-group'
import Task from './Task'
import MyTasks from './MyTasks'
import TasksListView from './TasksListView'
import { fetchMyPLDPTasks, fetchAllMyPLDPTasks, updateNewPLDPTask, completeTask } from '../../../store/actions/pldpActions'
// import PrintTasks from './PrintTasks';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { addMorePoints } from '../../../store/actions/freshCardActions'
import {
  CgetOpinions
} from '../../../store/actions/opinionCActions'
import {
  GgetOpinions
} from '../../../store/actions/opinionG_Actions'
import {
  getOpinions
} from '../../../store/actions/opinionActions'
import GenTutorial from '../../PointsSurveys/GenTutorial'
// import { ThreeSixty } from '@material-ui/icons'
import wiseman from '../../../pages/eximg/wiseman3.jpg'
import firebase from '../../../store/firebase'
import { showToast } from '../../../store/actions/toastAction'
import ChatGPT from '../../../components/Dialogs/ChatGPT'
import ChatGPTfree from '../../../components/Dialogs/ChatGPTfree'
import ChatGPTHistory from '../../../components/Dialogs/ChatGPTHistory'
import { PHOTO_PROFILE } from '../../../utils/photo_utils'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
import {
  Avatar
} from '@material-ui/core'
import generalEval from '../../../components/UserProfile/data/generalEval.json'
//import OpenIcon from '@material-ui/icons/LockOpen'
//import CloseIcon from '@material-ui/icons/CloseSharp'
import OpenIcon from '@material-ui/icons/ExpandMore'
import CloseIcon from '@material-ui/icons/ExpandLess'
import {useRef} from 'react';
import Combining from './charts'

const tableM = {
  color: "#515A5A",
  fontSize: 12,
  border: "1",
  backgroundColor: '#F2F3F4',
  width: '20%',
  maxWidth: '20%',
  padding: 1,
  borderRadius: 2
}
const tableMj = {
  color: "#515A5A",
  fontSize: 12,
  border: "1",
  backgroundColor: '#F2F3F4',
  width: '20%',
  maxWidth: '20%',
  padding: 1,
  borderRadius: 2
}
// const tableX = {
//   color: "#515A5A",
//   fontSize: 12,
//   border: "1",
//   backgroundColor: '#F2F3F4',
//   width: '7%',
//   maxWidth: '7%',
//   // width: 60/`${ this.state.countHeadings }%`,
//   // maxWidth: 60/`${ this.state.countHeadings }%`,
//   padding: 1,
//   borderRadius: 2
// }
const tableXi = {
  color: "#515A5A",
  fontSize: 12,
  border: "1",
  backgroundColor: '#F2F3F4',
  width: '5%',
  maxWidth: '5%',
  padding: 1,
  borderRadius: 2
}
const tableHself = {
  color: "#796716",
  fontSize: 12,
  border: "1",
  backgroundColor: '#D4C8DA',
  padding: 2,
  borderRadius: 2
}
const tableHselfhide = {
  display: "none",
  fontSize: 12,
  border: "0px"
}
const tableCa = {
  color: "#ffffff",
  fontSize: 12,
  border: "1",
  padding: 1,
  borderRadius: 2,
  fontweight: 'bold',
  backgroundColor: '#515A5A',
  width: isMobile ? '20%' : '20%',
  maxWidth: isMobile ? '20%' : '20%',
}
const tableMi = {
  display: "none",
  border: "0px",
  padding: 1,
  borderRadius: 2
}
const tableWi = {
  display: "none",
  border: "0px",
  padding: 1,
  borderRadius: 2
}
const tableW = {
  color: "#515A5A",
  fontSize: 12,
  border: "0px",
  backgroundColor: '#FFFFFF',
  padding: 1,
  width: '7%',
  maxWidth: '7%',
  borderRadius: 2
}
const tableWj = {
  color: "#515A5A",
  fontSize: 12,
  border: "0px",
  backgroundColor: '#FFFFFF',
  padding: 1,
  width: '5%',
  maxWidth: '5%',
  borderRadius: 2
}
const tableMhh = {
  color: "#515A5A",
  fontSize: 12,
  border: "2px",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 2,
  width: '30%'
}
const tableWh = {
  color: "#515A5A",
  fontSize: 12,
  border: "1px",
  backgroundColor: '#FFFFFF',
  padding: 1,
  borderRadius: 2,
  width: '30%',
  maxWidth: '30%',
}
const tableC = {
  color: "#ffffff",
  fontSize: 12,
  border: "1",
  padding: 1,
  width: '40%',
  maxWidth: '40%',
  borderRadius: 2,
  fontweight: 'bold',
  backgroundColor: '#515A5A'
}
const tableC1 = {
  color: "#ffffff",
  fontSize: 12,
  border: "1",
  padding: 1,
  width: '20%',
  maxWidth: '20%',
  borderRadius: 2,
  fontweight: 'bold',
  backgroundColor: '#515A5A'
}
const tableC2 = {
  color: "#ffffff",
  fontSize: 12,
  border: "1",
  padding: 1,
  width: '20%',
  maxWidth: '20%',
  borderRadius: 2,
  fontweight: 'bold',
  backgroundColor: '#515A5A'
}
const tableCc = {
  color: "#372F0C",
  fontSize: 20,
  border: "0px",
  padding: 1,
  borderRadius: 2,
  fontweight: 'bold',
  backgroundColor: '#ffffff',
  width: isMobile ? '35%' : '6%',
  maxWidth: isMobile ? '35%' : '10%',
}
const tableCcc = { 
  color: "#B69C26",
  bgColor: "#cccccc",
  fontSize: 14,
  align: 'left',
  textAlign: 'left',
  // marginRight: '300px',
  // marginRight: '400px',
  marginLeft: '20px',
  alignItems: 'left',
  justifyContent: 'left',
  alignContent: 'left',

  display: 'flex',
  alignContent: 'left',
  border: 1,
  padding: 1,
  borderRadius: 2,
  fontweight: 'bold',
  backgroundColor: '#ffffff',
  width: isMobile ? '90%' : '90%',
  maxWidth: isMobile ? '90%' : '90%',
}
const tableSage = {
  color: "#372F0C",
  fontSize: 18,
  border: "0px",
  padding: 6,
  borderRadius: 6,
  fontweight: 'bold',
  backgroundColor: '#ffffff',
  width: isMobile ? '65%' : '90%',
  maxWidth: isMobile ? '65%' : '94%',
  cursor: 'pointer'
}
const tableMh = {
  color: "#515A5A",
  fontSize: 15,
  border: "1",
  backgroundColor: '#F2F3F4',
  width: '40%',
  maxWidth: '40%',
  padding: 1,
  borderRadius: 2
}
const tableS = {
  color: "#515A5A",
  fontSize: 10,
  border: "0px",
  width: '18%',
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 2
}
const tableH = {
  color: "#515A5A",
  fontSize: 12,
  border: "0px",
  backgroundColor: '#F2F3F4',
  padding: 2,
  borderRadius: 2
}
const tableBlank = {
  //
}
const tableAI = {
  color: "#515A5A",
  fontSize: 15,
  border: "0px",
  backgroundColor: '#F2F3F4',
  padding: 3,
  borderRadius: 2
}

// const inputStyles = {
//   borderColor: 'red', // Change this to the desired color
//   borderWidth: '2px', // You can also change the width of the border
// };

Array.prototype.groupBy = function (prop) {
  return this.reduce(function (groups, item) {
    const val = item[prop]
    groups[val] = groups[val] || []
    groups[val].push(item)
    return groups
  }, {})
}

function includedCompetencies(openedTasks, tasks) {
  let competenciesFound = []
  Object.keys(tasks).map(id => {
    if (tasks[id] && (openedTasks === tasks[id].active)) {
      const a = competenciesFound.indexOf(tasks[id].moveAction)
      competenciesFound.push(tasks[id].moveAction)
    }
  })
  return competenciesFound
}

function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: 0 }}>
      {props.children}
    </Typography>
  )
}

function rand() {
  return Math.round(Math.random() * 20) - 10
}
const top = 50 + rand()
const left = 50 + rand()
let groupTasks

const styles = theme => ({
  // root: {
  //   marginTop: 0,
  //   padding: '10px',
  //   flexGrow: 1
  // },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
    // border: `4px solid ${THEMECOLOR}`
    border: `4px solid #eeeeee`
  },
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#935c02', // Change this to the desired hover color
        fontSize: '12px',
        // width: '600px',
        // maxWidth: '600px',
        color: '#64540B'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#7C6B1D', // Change this to the desired focus color
        fontSize: '12px',
        // width: '600px',
        // maxWidth: '600px',
        color: '#64540B'
      },
    },
  },
  panel: {
    marginBottom: '10px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: 'none'
    // "&:hover": {
    //   boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19)"
    // }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '6px'
  },
  title: {
    borderBottom: `1px solid ${LIGHTGREY}`,
    fontSize: '15px',
    minHeight: '0px',
    width: 'auto',
    // color: '#fff',
    padding: '5px 10px',
    borderRadius: 5
    // backgroundColor: '#0085a5'
  },
  unopendtitle: {
    borderBottom: `1px solid ${LIGHTGREY}`,
    fontSize: '15px',
    minHeight: '0px',
    width: 'auto',
    color: '#e5e4e2',
    padding: '5px 10px',
    borderRadius: 5
    // backgroundColor: '#0085a5'
  },
  modal: {
    outline: 'none',
    border: 'none'
  },
  paper: {
    position: 'absolute',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    outline: 'none'
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  divStyle: {
    margin: '20px',
    padding: '10px 0',
    border: '1px solid #ccc!important',
    [theme.breakpoints.down('sm')]: {
      margin: '4px',
      padding: '0px 6px'
    }
  },
  dialogHeight: {
    // height: `calc(100% - 20px)`
    height: 'auto'
  },
  unopendList: {
    width: '100%',
    padding: '20px 10px 20px 20px',
    border: '1px solid #e5e4e2',
    margin: '10px',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  addIconstyles: {
    backgroundColor: THEMECOLOR,
    color: '#000',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textField: {
		// width: '600px',
    // maxWidth: '600px',
    size: '15px !important',
    color: '#594B0B !important',
    borderColor: '#935c02 !important'
	},
  textFieldLabel: {
		// width: '600px',
    // maxWidth: '600px',
    size: '15px !important',
    color: '#935c02 !important'
	}
})

class Competencies extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editText: '',
      editID: '',
      filterby: 'open',
      open: false,
      openConfirm: false,
      addTask: false,
      editTask: false,
      active: false,
      taskOpened: {},
      companyValue: '',
      editText: '',
      openComplete: 'complete',
      tabsvalue: 0,
      openedTasks: true,
      mainCompetencies: [],
      leadershipTasks: [],
      slave: 0,
      slaveparticipants: 0,
      
      simple: 0,
      ssimple: 0,
      simpleparticipants: 0,
      simpleopinion: "",

      valueA: 0,
      valueAparticipants: 0,
      valueAopinion: "",

      valueB: 0,
      valueBopinion: "",
      valueBparticipants: 0,

      valueC: 0,
      valueCopinion: "",
      valueCparticipants: 0,

      valueD: 0,
      valueDopinion: "",
      valueDparticipants: 0,

      valueE: 0,
      valueEopinion: "",
      valueEparticipants: 0,

      valueF: 0,
      valueFopinion: "",
      valueFparticipants: 0,

      valueG: 0,
      valueGopinion: "",
      valueGparticipants: 0,

      valueH: 0,
      valueHopinion: "",
      valueHparticipants: 0,

      svalueA: 0,
      svalueB: 0,
      svalueC: 0,
      svalueD: 0,
      svalueE: 0,
      svalueF: 0,
      svalueG: 0,
      svalueH: 0,

      allopinions: '',
      one_count: 0,
      two_count: 0,
      three_count: 0,
      four_count: 0,
      five_count: 0,
      six_count: 0,
      seven_count: 0,
      eight_count: 0,
      nine_count: 0,
      ten_count: 0,

      one_arr: [],
      two_arr: [],
      three_arr: [],
      four_arr: [],
      five_arr: [],
      six_arr: [],
      seven_arr: [],
      eight_arr: [],
      nine_arr:  [],
      ten_arr: [], 

      sone_count: 0,
      stwo_count: 0,
      sthree_count: 0,
      sfour_count: 0,
      sfive_count: 0,
      ssix_count: 0,
      sseven_count: 0,
      seight_count: 0,
      snine_count: 0,
      sten_count: 0,
      sone_arr: [],
      stwo_arr: [],
      sthree_arr: [],
      sfour_arr: [],
      sfive_arr: [],
      ssix_arr: [],
      sseven_arr: [],
      seight_arr: [],
      snine_arr: [],
      sten_arr: [],

      lastAnswer: '',
      alist: [],
      myemail: '',
      gtpquestion: '',
      maxLengthThought: 0,
      showFreeQ: true,
      showing: 'none',
     descA: "", 
     headingA: "",
     descB: "", 
     headingB: "",
     descC: "", 
     headingC: "",
     descD: "", 
     headingD: "",
     descE: "", 
     headingE: "",
     descF: "", 
     headingF: "",
     descG: "", 
     headingG: "",
     descH: "", 
     headingH: "",

     countHeadings: 0,
     commentRTime: [],
     commentHistoryA: [],
     commentHistoryB: [],
     commentHistoryC: [],
     commentHistoryD: [],
     commentHistoryE: [],
     commentHistoryF: [],
     commentHistoryG: [],
     commentHistoryH: [],

     commentRTimeSelf: [],
     commentHistoryAself: [],
     commentHistoryBself: [],
     commentHistoryCself: [],
     commentHistoryDself: [],
     commentHistoryEself: [],
     commentHistoryFself: [],
     commentHistoryGself: [],
     commentHistoryHself: [],
     shownA: '',
     shownB: '',
     shownC: '',
     shownD: '',
     shownE: '',
     shownF: '',
     shownG: '',
     shownH: '',
     shownRs: '',
     shownAs: '',
     shownBs: '',
     shownCs: '',
     shownDs: '',
     shownEs: '',
     shownFs: '',
     shownGs: '',
     shownHs: '',
     slaveIDarray:[],
     evalPeer: 0,
     evalBoss: 0,
     evalSlave: 0,
     evalSelf: 0
    }

    // if (this.props.userdata) {
    //   this.putSOpinions();
    // }
   
  }
  
     getAllOpinions(userID){
       if (userID) {
         this.findOpinionsC(userID)
       }
      // this.props.getOpinions({ // loads the opinions of leaders recorded in state
      //   leaderID: this.props.userdata.userID
      // })
    }

   async componentDidMount() {
    let { opinions, companyValues } = this.props
    // this.fetchData()
    console.log('green teststate-index : ', this.props.opinions)
    console.log('green generalx')
    console.log('green date : ', new Date())
    console.log('green-opinions : ', opinions)
    //sdate: new Date()

    await this.fillHeadings()

  //    if (this.props.userdata.userID) {
  //    console.log('statsstate 1 : ', this.props) 
  //     this.props.getOpinions({ //loads the opinions of leaders recorded in state
  //      type: GET_O,
  //      leaderID: this.props.userdata.userID
  //    })
  //  } 

  console.log('scores-final-finalScore 7 a : ', this.state.countHeadings)

  var alist = []
  if(companyValues) {
  Object.keys(companyValues).map(id => {
    const labelId = `${companyValues[id].valueID}`
    const labelName = `${companyValues[id].valueDesc}`
    let tasksCount = 0;
  
    if (groupTasks[labelId]) {
      ///////////////////////////////////////
       var tlist = groupTasks[labelId]
      var i;
   
      for (i = 0; i < tlist.length; i++) {
        if (tlist[i].title) {
          alist.push(tlist[i].title)
       }}
  
    }
  
    return null
  })
  this.dismantleArray(alist)
  }

  // if (this.props.opinions && Object.keys(this.props.opinions).length > 0) {
  //   await this.makeAIopinions();
  //  }

  console.log('scores-final-finalScore 7 b : ', this.state.countHeadings)

  if (this.props.userdata.userID) {
    console.log('green teststate i1 : ', this.props.userdata.email)
    //this.getAllOpinions(this.props.userdata.userID)
    this.setState({ myemail: this.props.userdata.email})
    console.log('green teststate i2 : ', this.props)
   
   }
  console.log('scores-final-finalScore 7 c : ', this.state.countHeadings)

    if (this.props.opinions && this.state.countHeadings) { 
        this.fetchData()

        console.log('scores-final-finalScore 7 d : ', this.state.countHeadings)

        if (this.state.countHeadings) {} else {
          await this.fillHeadings()
        }
        this.putOpinions();
        this.putSOpinions();

       if (this.props.opinions && Object.keys(this.props.opinions).length > 0) {
         await this.makeAIopinions();
        }

       this.printHistory();  
      
    }

   console.log('green teststate 3 : ', this.props.opinions)
    // this.insertPollCount();
    // this.HandleSeparateTasksAndCompetencies()
  }

  componentDidUpdate(prevProps, prevState) {
   // if (prevProps.opinions === this.props.opinions) {
    if (this.props.opinions) {} else {
      // if (this.props.userdata.userID) { 
      //   //this.findOpinionsC()
      //   console.log('green teststate 1 : ', this.props.opinions)
      //   this.fetchData()
      //   console.log('green teststate 2 : ', this.props.opinions)
      //   console.log('statsstate 2 : ', this.props.userdata.photoURL )
      //   this.putOpinions();
      //   this.putSOpinions();
      //   if (this.props.opinions && Object.keys(this.props.opinions).length > 0) {
      //     this.makeAIopinions();
      //    }
      //  }
    }
  }

  fillHeadings = () => {
    var n = 0
    console.log('fillHeadingsall 1: ', this.props.userdata.companyID)
    generalEval.surveyData.map((surveyData) => {
      if ((surveyData.companyID===this.props.userdata.companyID) && (surveyData.type==="general")) {
        console.log('fillHeadingsall 2 : ', surveyData.companyID)
        if (surveyData.title) {
          n = n + 1
          switch(n) {
            case 1:
              this.setState({
                descA: surveyData.desc, 
                headingA: surveyData.title
              }) 
              break;
            case 2:
              this.setState({
                descB: surveyData.desc, 
                headingB: surveyData.title
              }) 
              break;
            case 3:
              this.setState({
                descC: surveyData.desc, 
                headingC: surveyData.title
              }) 
              break;
            case 4:
              this.setState({
                descD: surveyData.desc, 
                headingD: surveyData.title
              }) 
              break;
            case 5:
              this.setState({
                descE: surveyData.desc, 
                headingE: surveyData.title
              }) 
              break;
            case 6:
              this.setState({
                descF: surveyData.desc, 
                headingF: surveyData.title
              }) 
              break;
            case 7:
              this.setState({
                descG: surveyData.desc, 
                headingG: surveyData.title
              }) 
              break;
            case 8:
              this.setState({
                descH: surveyData.desc, 
                headingH: surveyData.title
              }) 
              break;
            default:
          }
         // n = n + 1;  
         // console.log('fillHeadings : ', n)       
        }
      }
    })
    console.log('scores-final-finalScore 7 x : ', n)

     this.setState({
        countHeadings: n
      }) 
  }
  
  findOpinionsC = (userID) => {
    const promise = this.findOpinionsD(userID)
    promise.then(this.onFulfilled, this.onRejected)
  }

  findOpinionsD = (userIDx) => {
    console.log('In findOpinionsD 1: ', this.props)
    return new Promise((resolve, reject) => {
      setTimeout(() => {
       try {
              this.props.getOpinions({ //loads the opinions of leaders recorded in state
                  type: GET_O,
                	leaderID: userIDx
	              })
                console.log('In findOpinionsD 6: ', this.props)
        }
       catch (e) {
        console.log('Error in findOpinionsD: ', e)
      }
      }, 6000)
    })
  }

   makeAIopinions = async() => {
    let { opinions } = this.props
    const { 
      descA, 
      headingA, 
      descB, 
      headingB,
      descC, 
      headingC,
      descD, 
      headingD,
      descE, 
      headingE,
      descF, 
      headingF,
      descG, 
      headingG,
      descH, 
      headingH
       } = this.state;

      var simpleopinion = '';
      var valueAopinion = '';
      var valueBopinion = ''; 
      var valueCopinion = '';
      var valueDopinion = '';
      var valueEopinion = '';
      var valueFopinion = '';
      var valueGopinion = '';
      var valueHopinion = '';

      var simpleopinionB = '';
      var valueAopinionB = '';
      var valueBopinionB = ''; 
      var valueCopinionB = '';
      var valueDopinionB = '';
      var valueEopinionB = '';
      var valueFopinionB = '';
      var valueGopinionB = '';
      var valueHopinionB = '';

      var allOpinionsA = '';
      var allOpinionsB = '';
      var str;
      var str2;
      var slaveLock = false;

      var n;
      n = 0;
      var found;
      var slaveIDarrayp = []
     // slaveIDarray.splice(0,a.length);
     slaveIDarrayp.length = 0;
      while (slaveIDarrayp.length > 0) {
        slaveIDarrayp.pop();
       }

      opinions && Object.keys(opinions).map((opinionsID, index) => {
     
      // if (opinions[index].slave !== 11) {
        found = slaveIDarrayp.includes(opinions[index].slaveID);
      // }       

       
        console.log('green-valueAopinion111 : ', found);

       if (found===false) {
        if (opinions[index].slave && (this.props.userdata.userID === opinions[index].leaderID) && (opinions[index].slave !== 11)) {
          n = n + 1;
          // if (opinions[index].simpleopinion) {simpleopinion = this.trimOpinion(opinions[index].simpleopinion) + ', ' + this.trimOpinion(simpleopinion) + ', ';}
          // if (opinions[index].valueAopinion) {valueAopinion = this.trimOpinion(opinions[index].valueAopinion) + ', ' + this.trimOpinion(valueAopinion) + ', ';}
          // if (opinions[index].valueBopinion) {valueBopinion = this.trimOpinion(opinions[index].valueBopinion) + ', ' + this.trimOpinion(valueBopinion) + ', ';}
          // if (opinions[index].valueCopinion) {valueCopinion = this.trimOpinion(opinions[index].valueCopinion) + ', ' + this.trimOpinion(valueCopinion) + ', ';}
          // if (opinions[index].valueDopinion) {valueDopinion = this.trimOpinion(opinions[index].valueDopinion) + ', ' + this.trimOpinion(valueDopinion) + ', ';}
          // if (opinions[index].valueEopinion) {valueEopinion = this.trimOpinion(opinions[index].valueEopinion) + ', ' + this.trimOpinion(valueEopinion) + ', ';}
          // if (opinions[index].valueFopinion) {valueFopinion = this.trimOpinion(opinions[index].valueFopinion) + ', ' + this.trimOpinion(valueFopinion) + ', ';}
          // if (opinions[index].valueGopinion) {valueGopinion = this.trimOpinion(opinions[index].valueGopinion) + ', ' + this.trimOpinion(valueGopinion) + ', ';}
          // if (opinions[index].valueHopinion) {valueHopinion = this.trimOpinion(opinions[index].valueHopinion) + ', ' + this.trimOpinion(valueHopinion) + ', ';}
         
          if (opinions[index].simpleopinion) {simpleopinion = this.trimOpinion(opinions[index].simpleopinion) + ', ' ;}
          if (opinions[index].valueAopinion) {valueAopinion = this.trimOpinion(opinions[index].valueAopinion) + ', ' ;}
          if (opinions[index].valueBopinion) {valueBopinion = this.trimOpinion(opinions[index].valueBopinion) + ', ' ;}
          if (opinions[index].valueCopinion) {valueCopinion = this.trimOpinion(opinions[index].valueCopinion) + ', ' ;}
          if (opinions[index].valueDopinion) {valueDopinion = this.trimOpinion(opinions[index].valueDopinion) + ', ' ;}
          if (opinions[index].valueEopinion) {valueEopinion = this.trimOpinion(opinions[index].valueEopinion) + ', ' ;}
          if (opinions[index].valueFopinion) {valueFopinion = this.trimOpinion(opinions[index].valueFopinion) + ', ' ;}
          if (opinions[index].valueGopinion) {valueGopinion = this.trimOpinion(opinions[index].valueGopinion) + ', ' ;}
          if (opinions[index].valueHopinion) {valueHopinion = this.trimOpinion(opinions[index].valueHopinion) + ', ' ;}
         

          console.log('green-valueAopinion 2 : ', found + '.....' + opinions[index].slaveID + '......' + opinions[index].slave)

          slaveIDarrayp.push(opinions[index].slaveID) //all slaveID s connected with this leader
         }
       }
        
      })
     console.log('green-valueAopinionA : ', valueAopinion);
     console.log('green-valueAopinionB : ', valueBopinion);
     console.log('green-valueAopinionC : ', valueCopinion);
     console.log('green-valueAopinionD : ', valueDopinion);
     console.log('green-valueAopinionE : ', valueEopinion);

      if (simpleopinion) {simpleopinionB = 'People say this about me regarding me in general: ' + this.trimOpinion(simpleopinion) + '. '}
      if (valueAopinion) {valueAopinionB = 'People say the following regarding my ' + headingA + ' skills: ' +  this.trimOpinion(valueAopinion) + '. '}
      if (valueBopinion) {valueBopinionB = 'People say the following about my ' + headingB + ' skills: ' +  this.trimOpinion(valueBopinion) + '. '}
      if (valueCopinion) {valueCopinionB = 'People say the following about my ' + headingC + ' skills: ' +  this.trimOpinion(valueCopinion) + '. '}
      if (valueDopinion) {valueDopinionB = 'People say the following about my ' + headingD + ' skills: ' +  this.trimOpinion(valueDopinion) + '. '}
      if (valueEopinion) {valueEopinionB = 'People say the following about my ' + headingE + ' skills: ' +  this.trimOpinion(valueEopinion) + '. '}
      if (valueFopinion) {valueFopinionB = 'People say the following about my ' + headingF + ' skills: ' +  this.trimOpinion(valueFopinion) + '. '}
      if (valueGopinion) {valueGopinionB = 'People say the following about my ' + headingG + ' skills: ' +  this.trimOpinion(valueGopinion) + '. '}
      if (valueHopinion) {valueHopinionB = 'People say the following about my ' + headingH + ' skills: ' +  this.trimOpinion(valueHopinion) + '. '}

      str = valueAopinionB + valueBopinionB + valueCopinionB + valueDopinionB + valueEopinionB + valueFopinionB + valueGopinionB + valueHopinionB
      str2 = str.trim();
      allOpinionsA = str2.substring(0, str2.length - 1);
      allOpinionsB = allOpinionsA + ' . So considering what people who work with me are saying about me, I have a question and could you please address the answer to me in second person, without using my first name. Here is the question: '
      console.log('allOpinionsB : ', allOpinionsB);

    await this.setState({
      slaveIDarray: slaveIDarrayp,
      allopinions: allOpinionsB
    })
    this.count_evaluations(slaveIDarrayp)
   }

   dismantleArray = (arr) => {
    let list = null;
    console.log('dismantleArray 1 : ', arr)
    for (let i = arr.length - 1; i >= 0; i--) {
        list = list + ', ' + arr[i];
    }
    console.log('dismantleArray 2 : ', list)
    this.setState({
      alist: list
    })
   // return list;
   }

   trimOpinion = (strIn) => {
    var i
    i = 0
    var stropinion = strIn;
    for (; i < 6; i++) {
    
    console.log('valueAopinion slice 1: ', stropinion.slice(-1))

      if (
        stropinion.slice(-1) === ' ' || 
        stropinion.slice(-1) === ',' || 
        stropinion.slice(-1) === '.'  
      )
      {
        stropinion = stropinion.substring(0, stropinion.length - 1)
      }
      console.log('valueAopinion slice 2: ', stropinion)
    }
    return stropinion;
   }

  componentDidUpdate() { 
    console.log('greenstats : ', this.props.opinions)
  }

  componentWillMount() {
    // this.HandleSeparateTasksAndCompetencies()
  }

//   static getDerivedStateFromProps(props, state) { 
//     console.log('statsstate 3 : ', props)
//     this.putOpinions();
//     this.putSOpinions();
//     return {
//        points: 200 // update state with this
//     }
//  } 

  fetchData = () => {
    this.props.fetchCompanyValues(this.props.userdata.companyID)
    this.props.fetchMyPLDPTasks(this.props.userdata.userID, this.state.openedTasks)
    this.props.fetchAllMyPLDPTasks({ userID: this.props.userdata.userID })
  };

  handleClose = () => {
    this.setState({
      editText: '',
      editID: '',
      open: false,
      openConfirm: false,
      addTask: false,
      editTask: false,
      taskOpened: {},
      companyValue: '',
      openComplete: this.state.active ? 'open' : 'complete'

    })
  };

  addAction = () => {
    this.setState({
      ...this.state,
      open: true,
      addTask: true,
      editTask: false
    })
  }

  handleOnSaveTask = (newTask, updateOnSave) => {
    this.props.updateNewPLDPTask(newTask, updateOnSave)

    this.handleClose()
  };

  handleFilteredTasks = (val) => {
    // this.setState({ filterby: val })
     console.log("active ", val)
    if (val.trim() === 'open') {
      this.setState({
        ...this.state,
        filterby: val,
        tabsvalue: 0,
        openedTasks: true
      })
      this.props.fetchData(true)
    } else if (val.trim() === 'complete') {
      this.setState({
        ...this.state,
        filterby: val,
        tabsvalue: 0,
        openedTasks: false
      })
      this.props.fetchData(false)
    } else if (val.trim() === 'feedback') {
      this.setState({
        ...this.state,
        filterby: val,
        tabsvalue: 2,
        openedTasks: false
      })
      this.props.fetchData(false)
    }
    else {
      this.setState({
        ...this.state,
        filterby: val,
        tabsvalue: 1
      })
    }
  }

  editTask(id, name, task) {
    this.setState({
      openConfirm: false,
      open: true,
      addTask: false,
      editTask: true,
      companyValue: id,
      taskOpened: task
    })
  }

  onMarkComplete(id, name, task, active) {
    this.setState({
      open: false,
      openConfirm: true,
      addTask: false,
      editTask: true,
      companyValue: id,
      taskOpened: task,
      editText: name,
      active: active,
      openComplete: active ? 'open' : 'complete'
    })
  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * 
  charactersLength));
    }
    return result;
  }

  completedTask = () => {
    let taskParams = {
      task: this.state.taskOpened,
      edit: true,
      active: this.state.active
    }
    console.log('green plfp completeTask 5 : ', taskParams.task.moveActionDesc);
    let action = taskParams.task.moveActionDesc.substr(0, 9);

    let timeNow = new Date().getTime();
    let timePast = (timeNow - taskParams.task.dateRegistered)/(86400000)
    console.log('pink completeTask 3: ', timePast)
    
    var pass = false;
    
    switch (taskParams.active) {
      case true: //re-open task
        pass = true;
        break;
      case false: //complete task
        if (timePast > 29 || action === "Activity:") {pass=true}
        break;
    }

    if (pass===true) {
      setTimeout(() => {
        this.props.completeTask(taskParams)
      }, 3000)

      if (this.state.filterby === 'open') {
        this.props.fetchData(true)
      } else {
        this.props.fetchData(false)
      }

    // this.props.fetchData(this.state.active ? false : true);
    //if (this.props.userdata.execmember === true) {
      let bpoints
      if (this.state.active === true) {
        bpoints = -10
      } else {
        bpoints = 20
      }
      // ////ADD POINTS///////////////////////////////
      console.log('green plfp points : ', this.state)
      let pointsParams = {
        uid: this.props.userdata.uid,
        userID: this.props.userdata.userID,
        pointsCurrent: this.props.userdata.points,
        pointsAdded: bpoints,
        ypointsCurrent: this.props.userdata.pointsy,
        trailID: this.makeid(10),
        companyName: this.props.userdata.companyName,
        firstName: this.props.userdata.firstName,
        lastName: this.props.userdata.lastName,
        title: this.state.editText,
        actionType: bpoints > 0 ? 'PLFP Task completed' : "PLFP Task re-opened",
        actionDesc: this.state.taskOpened.moveActionDesc + '/' + this.state.taskOpened.subcompetency + ' Task'
      }
      if (this.props.userdata.execmember) {
         this.props.addMorePoints(pointsParams)
      }
     
    }
    else
    {
      this.props.showToast({
         type: 'SHOW_TOAST',
         open: true,
         variant: 'success',
         message: 'Tasks can only be ticked as completed after 30 days, to ensure integrity of changing behaviours and adopting new habits.'
       })
    }
    this.handleClose()
  }

  getTasksCount = (tasks) => {
    let i; let open_tasks = 0; let closed_tasks = 0
    for (i = 0; i < tasks.length; i++) {
      if (tasks[i].active) {
        // console.log("labelName: ", labelName);
        // console.log("active: ", tasks[i]);
        open_tasks++
      } else {
        closed_tasks++
      }
    }

    if (this.state.filterby === 'open') {
      return open_tasks
    }
    if (this.state.filterby === 'feedback') {
      return null
    }
    return closed_tasks
  }

  // xputSOpinions = () => {
  //   let { opinions } = this.props
  //   console.log('scores final x ', opinions);

  //   var simple_count = 0;
  //   var slave_count = 0;
  //   var slave_all_count = 0;
  //   var simple_all_count = 0;
    
  //   var sone = [0];//0;
  //   var stwo = [0];//0;
  //   var sthree = [0];//0;
  //   var sfour = [0];//0;
  //   var sfive = [0];//0;
  //   var ssix = [0];//0;
  //   var sseven = [0];//0;
  //   var seight = [0];//0;
  //   var snine = [0];//0;
  //   var sten = [0];//0;

  //   var sone_sub = 0;
  //   var stwo_sub = 0;
  //   var sthree_sub = 0;
  //   var sfour_sub = 0;
  //   var sfive_sub = 0;
  //   var ssix_sub = 0;
  //   var sseven_sub = 0;
  //   var seight_sub = 0;
  //   var snine_sub = 0;
  //   var sten_sub = 0;

  //   var sone_count = 0;
  //   var stwo_count = 0;
  //   var sthree_count = 0;
  //   var sfour_count = 0;
  //   var sfive_count = 0;
  //   var ssix_count = 0;
  //   var sseven_count = 0;
  //   var seight_count = 0;
  //   var snine_count = 0;
  //   var sten_count = 0;

  //   if (opinions) { Object.keys(opinions).map((opinionsID, index) => {
  //     console.log('inside putOpinions 2', opinions[index]);
  //     if (opinions[index].slave) { 
  //       slave_all_count = slave_all_count + 1;
  //       slave_count = opinions[index].slave 
  //       console.log('green slave_count : ', slave_count);
  //       console.log('green slave_countx : ', slave_count);

  //       switch(slave_count) {
  //         case 1:
  //           //one = one + 1;
  //           sone_count = sone_count + 1;
  //           if (opinions[index].simple) { 
  //             sone_sub = sone[0]
  //             sone[0] = sone_sub + opinions[index].simple 
  //           }
  //           break;
  //         case 2:
  //           //two = two + 1;
  //           stwo_count = stwo_count + 1;
  //           if (opinions[index].simple) { 
  //             stwo_sub = stwo[0]
  //             stwo[0] = stwo_sub + opinions[index].simple 
  //           }
  //           break;
  //         case 3:
  //           //three = three + 1;
  //           sthree_count = sthree_count + 1;
  //           if (opinions[index].simple) { 
  //             sthree_sub = sthree[0]
  //             sthree[0] = sthree_sub + opinions[index].simple 
  //           }           
  //           break;
  //         case 4:
  //           //four = four + 1;
  //           sfour_count = sfour_count + 1;
  //           if (opinions[index].simple) { 
  //             sfour_sub = sfour[0]
  //             sfour[0] = sfour_sub + opinions[index].simple 
  //           } 
  //           break;
  //         case 5:
  //           //five = five + 1;
  //           sfive_count = sfive_count + 1;
  //           if (opinions[index].simple) { 
  //             sfive_sub = sfive[0]
  //             sfive[0] = sfive_sub + opinions[index].simple 
  //           }
  //           break;
  //         case 6:
  //          // six = six + 1;
  //           ssix_count = ssix_count + 1;
  //           if (opinions[index].simple) { 
  //             ssix_sub = ssix[0]
  //             ssix[0] = ssix_sub + opinions[index].simple 
  //           }
  //           break;
  //         case 7:
  //           //seven = seven + 1;
  //           sseven_count = sseven_count + 1;
  //           if (opinions[index].simple) { 
  //             sseven_sub = sseven[0]
  //             sseven[0] = sseven_sub + opinions[index].simple 
  //           }
  //           break;
  //         case 8:
  //          // eight = eight + 1;
  //           seight_count = seight_count + 1;
  //           if (opinions[index].simple) { 
  //             seight_sub = seight[0]
  //             seight[0] = seight_sub + opinions[index].simple 
  //           }
  //           break;
  //         case 9:
  //           //nine = nine + 1;
  //           snine_count = snine_count + 1;
  //           if (opinions[index].simple) { 
  //             snine_sub = snine[0]
  //             snine[0] = snine_sub + opinions[index].simple 
  //           }
  //           break;
  //         case 10:
  //           //ten = ten + 1;
  //           sten_count = sten_count + 1;
  //           if (opinions[index].simple) { 
  //             sten_sub = sten[0]
  //             sten[0] = sten_sub + opinions[index].simple 
  //           }
  //           break;
  //         default:
  //           // code block
  //       }
  //     }
  //     if (opinions[index].simple) { 
  //       simple_all_count = simple_all_count + 1;
  //       simple_count = simple_count + opinions[index].simple 
  //     } 
  //   })}

  //    //////////////////////////////////////////////////////////////////
  //    var islave = slave_all_count > 0 ? slave_count / slave_all_count : 0;
  //    var isimple = simple_all_count > 0 ? simple_count / simple_all_count : 0;
  //    //////////////////////////////////////////////////////////////////

  //   if (opinions) {
  //    this.setState({
  //     slave: islave.toFixed(2),
  //     //simple: isimple.toFixed(2),
  //    // simpleparticipants: simple_all_count,
  //     sone_count: sone_count,
  //     stwo_count: stwo_count,
  //     sthree_count: sthree_count,
  //     sfour_count: sfour_count,
  //     sfive_count: sfive_count,
  //     ssix_count: ssix_count,
  //     sseven_count: sseven_count,
  //     seight_count: seight_count,
  //     snine_count: snine_count,
  //     sten_count: sten_count,
  //     sone_arr: [sone[0]],
  //     stwo_arr: [stwo[0]],
  //     sthree_arr: [sthree[0]],
  //     sfour_arr: [sfour[0]],
  //     sfive_arr: [sfive[0]],
  //     ssix_arr: [ssix[0]],
  //     sseven_arr: [sseven[0]],
  //     seight_arr: [seight[0]],
  //     snine_arr: [snine[0]],
  //     sten_arr: [sten[0]]
  //    })};

  //    //insertPollCount into user and users
  //    const updates = {}

  //    var finalScore = isimple
  //    console.log('scores final 1 : ', isimple)
  //    console.log('scores final 2 : ', finalScore)
  //    //finalScore = finalScore.toFixed(2);
  //    console.log('scores final 3 ', finalScore)

  //    if(finalScore) {
  //     updates['/users/' + this.props.userdata.userID + '/sfeedback'] = finalScore.toFixed(2);
  //     updates['/user/' + this.props.userdata.uid + '/sfeedback' ] = finalScore.toFixed(2);
  //    }

  //    firebase.database.ref().update(
  //      updates,
  //      function (error) {
  //        if (error) {
  //          console.log('success : ');
  //        } else {
  //         console.log('err : ', error);
  //        }
  //      })
  // }

  // putSsOpinions = () => {
  //   let { opinions } = this.props
  //   console.log('putSOpinions 1 ', opinions);
  //   var daLock = false;
  //   var mydaLock = false; 
  //   var sslave1 = [];
  //   var sslave2;

  //   var simple_count = 0;
  //   var mysimple;
  //   // var slave_count = 0;
  //   // var slave_all_count = 0;
  //   var simple_all_count = 0;
    
  //   if (opinions) { Object.keys(opinions).map((opinionsID, index) => {
  //     console.log('putSOpinions 2', opinions[index]);

  //     // sslave1 = opinions[index].slave
  //     // if (sslave2) {
  //     //   daLock = false;
  //     // } else { 
  //     //   sslave2 = sslave1
  //     //   daLock = true;
  //     // }
     
  //      daLock = sslave1.includes(opinions[index].slave)
      
  //     if (opinions[index].simple && (opinions[index].simple > 0) && (opinions[index].slave !== 11) && (daLock = false)) { 
  //       simple_all_count = simple_all_count + 1;
  //       simple_count = simple_count + opinions[index].simple 
  //       console.log('putSOpinions 3', simple_all_count);
  //        sslave1.push('opinions[index].slave');
  //     } 

  //     // mysslave1 = opinions[index].slave
  //     // if (mysslave2) {
  //     //   mydaLock = false;
  //     // } else { 
  //     //   mysslave2 = sslave1
  //     //   mydaLock = true;
  //     // }
  //     mydaLock = sslave2.includes(opinions[index].slave)
  //     if (opinions[index].simple && (opinions[index].simple > 0) && (opinions[index].slave === 11) && (mydaLock = false)) { 
  //       lockDate1 = opinions[index].sdate
  //       mysimple = opinions[index].simple //opinionsTrail
  //       sslave2.push('opinions[index].slave');
  //     } 

  //   })}

  //    //////////////////////////////////////////////////////////////////
  //    var isimple = simple_all_count > 0 ? simple_count / simple_all_count : 0;
  //    //////////////////////////////////////////////////////////////////

  //   if (opinions) {
  //    this.setState({
  //     simple: isimple.toFixed(2),
  //     ssimple: mysimple,
  //     simpleparticipants: simple_all_count,
  //    })};

  //    //insertPollCount into user and users
  //    const updates = {}

  //    var finalScore = isimple
  //    console.log('putSOpinions 4', finalScore);
  //    console.log('scoress final 1 : ', isimple)
  //    console.log('scoress final 2 : ', simple_all_count)

  //    if(finalScore) {
  //     updates['/users/' + this.props.userdata.userID + '/sfeedback'] = finalScore.toFixed(2);
  //     updates['/user/' + this.props.userdata.uid + '/sfeedback' ] = finalScore.toFixed(2);
  //    }
 
  //    // server: create group - send data to firebase
  //    firebase.database.ref().update(
  //      updates,
  //      function (error) {
  //        if (error) {
  //          console.log('success : ');
  //        } else {
  //         console.log('err : ', error);
  //        }
  //      })
  // }
  
  putSOpinions = () => {
    let { opinions } = this.props
    console.log('putSOpinions-S ', opinions);

    const sslave1 = ["aa","bb"];
    const sslave2 = ["aa","bb"];

    var simple_count = 0;
    var mysimple;
    var simple_all_count = 0;
    
    if (opinions) { Object.keys(opinions).map((opinionsID, index) => {
      console.log('putSOpinions 2', opinions[index]);

      //daLock = sslave1.includes(opinions[index].slave);
      
      const daLock = sslave1.indexOf(opinions[index].slaveID);
      console.log('daLock3', daLock);

      if (opinions[index].simple && (opinions[index].simple > 0) && (this.props.userdata.userID===opinions[index].leaderID) && (opinions[index].type === 2) && (opinions[index].slave !== 11) && (daLock === -1)) { 
        simple_all_count = simple_all_count + 1;
        simple_count = simple_count + opinions[index].simple 
        sslave1.push(opinions[index].slaveID);
        console.log('simpleparticipants x : ', opinions[index].simple);
        console.log('simpleparticipants y : ', opinions[index].simpleopinion);
      } 
   
       const mydaLock = sslave2.indexOf(opinions[index].slaveID);

      if (opinions[index].simple && (opinions[index].simple > 0) && (this.props.userdata.userID===opinions[index].leaderID) && (opinions[index].type === 4) && (opinions[index].slave === 11) && (mydaLock === -1)) { 
        mysimple = opinions[index].simple //opinionsTrail
       // simple_count = simple_count + opinions[index].simple 
        sslave2.push(opinions[index].slaveID);
      } 
    })}

     //////////////////////////////////////////////////////////////////
     var isimple = simple_all_count > 0 ? simple_count / simple_all_count : 0;
     //////////////////////////////////////////////////////////////////
     console.log('simpleparticipants simple : ', isimple);
     console.log('simpleparticipants ssimple : ', mysimple);
     console.log('simpleparticipants simpleparticipants : ', simple_all_count);

    if (opinions) {
     this.setState({
      simple: isimple.toFixed(2),
      ssimple: mysimple,
      simpleparticipants: simple_all_count,
     })};

     //insertPollCount into user and users
     const updates = {}

     var finalScore = isimple
     console.log('putSOpinions 5', finalScore);
     console.log('scoress final 1 : ', isimple)
     console.log('scoress final 2 : ', simple_all_count)

     if (finalScore) {
      updates['/users/' + this.props.userdata.userID + '/sfeedback'] = finalScore.toFixed(2);
      updates['/user/' + this.props.userdata.uid + '/sfeedback' ] = finalScore.toFixed(2);
     }
 
     // server: create group - send data to firebase
     firebase.database.ref().update(
       updates,
       function (error) {
         if (error) {
           console.log('success : ');
         } else {
          console.log('err : ', error);
         }
       })
  }

  handleChange = event => {
    this.setState({
      gtpquestion: event.target.value,
     // showFreeQ: false
    })
  };
  clearQuestion = event => {
    this.setState({
      gtpquestion: ''
    })
    console.log('inside clearQuestion')
  }

  putOpinions = () => {
    let { opinions } = this.props
    console.log('scores-final-x 1 ', this.props);

    var slave_count = 0;
    var valueA_count = 0;
    var valueB_count = 0;
    var valueC_count = 0;
    var valueD_count = 0;
    var valueE_count = 0;
    var valueF_count = 0;
    var valueG_count = 0;
    var valueH_count = 0;

    var slave_all_count = 0;
    var valueA_all_count = 0;
    var valueB_all_count = 0;
    var valueC_all_count = 0;
    var valueD_all_count = 0;
    var valueE_all_count = 0;
    var valueF_all_count = 0;
    var valueG_all_count = 0;
    var valueH_all_count = 0;

    var one = [0,0,0,0,0,0,0,0];//0;
    var two = [0,0,0,0,0,0,0,0];//0;
    var three = [0,0,0,0,0,0,0,0];//0;
    var four = [0,0,0,0,0,0,0,0];//0;
    var five = [0,0,0,0,0,0,0,0];//0;
    var six = [0,0,0,0,0,0,0,0];//0;
    var seven = [0,0,0,0,0,0,0,0];//0;
    var eight = [0,0,0,0,0,0,0,0];//0;
    var nine = [0,0,0,0,0,0,0,0];//0;
    var ten = [0,0,0,0,0,0,0,0];//0;
    var eleven = [0,0,0,0,0,0,0,0];//0;

    var one_sub = 0;
    var two_sub = 0;
    var three_sub = 0;
    var four_sub = 0;
    var five_sub = 0;
    var six_sub = 0;
    var seven_sub = 0;
    var eight_sub = 0;
    var nine_sub = 0;
    var ten_sub = 0;

    var one_count = 0;
    var two_count = 0;
    var three_count = 0;
    var four_count = 0;
    var five_count = 0;
    var six_count = 0;
    var seven_count = 0;
    var eight_count = 0;
    var nine_count = 0;
    var ten_count = 0;

    const sslave = ["aa","bb"];
    const sslaves = ["aa","bb"];

    const sslave1 = ["aa","bb"];
    const sslave2 = ["aa","bb"];
    const sslave3 = ["aa","bb"];
    const sslave4 = ["aa","bb"];
    const sslave5 = ["aa","bb"];
    const sslave6 = ["aa","bb"];
    const sslave7 = ["aa","bb"];
    const sslave8 = ["aa","bb"];

    if (opinions) { 
      console.log('scores-final-x 2 ', opinions);

      Object.keys(opinions).map((opinionsID, index) => {
      console.log('inside putOpinions 2', opinions[index]);
      const daLock = sslave.indexOf(opinions[index].slaveID);   //company values assesement
  
   if (opinions[index].slave && opinions[index].valueA && (opinions[index].leaderID===this.props.userdata.userID) ) { 
    slave_count = opinions[index].slave

   // if (daLock === -1 && opinions[index].type===1) {
    if (daLock === -1) {
      sslave.push(opinions[index].slaveID) 
      if ((opinions[index].slave!==11)) { 
       slave_all_count = slave_all_count + 1
      };

      console.log('green-slavexxxxx : ', opinions[index].leaderID)
      console.log('green-slave_count-2 : ', slave_count + ' -- ' +  opinions[index].leaderID + ' --- ' + opinions[index].slaveID);

      console.log('greenslavecount : ', opinions[index].valueA + ' --- ' + opinions[index].firstName)

        switch(parseInt(slave_count)) {
          case 1:
            //one = one + 1;
            one_count = one_count + 1;
            if (opinions[index].valueA) { 
              one_sub = one[0]
              one[0] = one_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              one_sub = one[1]
              one[1] = one_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              one_sub = one[2]
              one[2] = one_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) {
              one_sub = one[3] 
              one[3] = one_sub + opinions[index].valueD 
            } 
            if (opinions[index].valueE) {
              one_sub = one[4] 
              one[4] = one_sub + opinions[index].valueE 
            }
            if (opinions[index].valueF) {
              one_sub = one[5] 
              one[5] = one_sub + opinions[index].valueF 
            }
            if (opinions[index].valueG) {
              one_sub = one[6] 
              one[6] = one_sub + opinions[index].valueG 
            }
            if (opinions[index].valueH) {
              one_sub = one[7] 
              one[7] = one_sub + opinions[index].valueH
            } 
            // sslave1.push(opinions[index].slave);
            break;
          case 2:
            //two = two + 1;
            two_count = two_count + 1;
            if (opinions[index].valueA) { 
              two_sub = two[0]
              two[0] = two_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              two_sub = two[1]
              two[1] = two_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              two_sub = two[2]
              two[2] = two_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              two_sub = two[3]
              two[3] = two_sub +  opinions[index].valueD 
            } 
            if (opinions[index].valueE) { 
              two_sub = two[4]
              two[4] = two_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              two_sub = two[5]
              two[5] = two_sub +  opinions[index].valueF 
            }
            if (opinions[index].valueG) { 
              two_sub = two[6]
              two[6] = two_sub +  opinions[index].valueG 
            }
            if (opinions[index].valueH) { 
              two_sub = two[7]
              two[7] = two_sub +  opinions[index].valueH 
            }
            break;
          case 3:
            //three = three + 1;
            three_count = three_count + 1;
            if (opinions[index].valueA) { 
              three_sub = three[0]
              three[0] = three_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              three_sub = three[1]
              three[1] = three_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              three_sub = three[2]
              three[2] = three_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              three_sub = three[3]
              three[3] = three_sub +  opinions[index].valueD 
            }  
            if (opinions[index].valueE) { 
              three_sub = three[4]
              three[4] = three_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              three_sub = three[5]
              three[5] = three_sub +  opinions[index].valueF 
            } 
            if (opinions[index].valueG) { 
              three_sub = three[6]
              three[6] = three_sub +  opinions[index].valueG 
            } 
            if (opinions[index].valueH) { 
              three_sub = three[7]
              three[7] = three_sub +  opinions[index].valueH 
            }           
            break;
          case 4:
            //four = four + 1;
            four_count = four_count + 1;
            if (opinions[index].valueA) { 
              four_sub = four[0]
              four[0] = four_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              four_sub = four[1]
              four[1] = four_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              four_sub = four[2]
              four[2] = four_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              four_sub = four[3]
              four[3] = four_sub +  opinions[index].valueD 
            } 
            if (opinions[index].valueE) { 
              four_sub = four[4]
              four[4] = four_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              four_sub = four[5]
              four[5] = four_sub +  opinions[index].valueF
            }  
            if (opinions[index].valueG) { 
              four_sub = four[6]
              four[6] = four_sub +  opinions[index].valueG 
            }  
            if (opinions[index].valueH) { 
              four_sub = four[7]
              four[7] = four_sub +  opinions[index].valueH 
            }    
            break;
          case 5:
            //five = five + 1;
            five_count = five_count + 1;
            if (opinions[index].valueA) { 
              five_sub = five[0]
              five[0] = five_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              five_sub = five[1]
              five[1] = five_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              five_sub = five[2]
              five[2] = five_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              five_sub = five[3]
              five[3] = five_sub +  opinions[index].valueD 
            } 
            if (opinions[index].valueE) { 
              five_sub = five[4]
              five[4] = five_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              five_sub = five[5]
              five[5] = five_sub +  opinions[index].valueF 
            } 
            if (opinions[index].valueG_all_count) { 
              five_sub = five[6]
              five[6] = five_sub +  opinions[index].valueG
            } 
            if (opinions[index].valueH) { 
              five_sub = five[7]
              five[7] = five_sub +  opinions[index].valueH 
            } 
            break;
          case 6:
           // six = six + 1;
            six_count = six_count + 1;
            if (opinions[index].valueA) { 
              six_sub = six[0]
              six[0] = six_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              six_sub = six[1]
              six[1] = six_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              six_sub = six[2]
              six[2] = six_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              six_sub = six[3]
              six[3] = six_sub +  opinions[index].valueD 
            }
            if (opinions[index].valueE) { 
              six_sub = six[4]
              six[4] = six_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              six_sub = six[5]
              six[5] = six_sub +  opinions[index].valueF 
            } 
            if (opinions[index].valueG) { 
              six_sub = six[6]
              six[6] = six_sub +  opinions[index].valueG 
            } 
            if (opinions[index].valueH) { 
              six_sub = six[7]
              six[7] = six_sub +  opinions[index].valueH 
            }  
            break;
          case 7:
            //seven = seven + 1;
            seven_count = seven_count + 1;
            if (opinions[index].valueA) { 
              seven_sub = seven[0]
              seven[0] = seven_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              seven_sub = seven[1]
              seven[1] = seven_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              seven_sub = seven[2]
              seven[2] = seven_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              seven_sub = seven[3]
              seven[3] = seven_sub +  opinions[index].valueD 
            } 
            if (opinions[index].valueE) { 
              seven_sub = seven[4]
              seven[4] = seven_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              seven_sub = seven[5]
              seven[5] = seven_sub +  opinions[index].valueF
            }
            if (opinions[index].valueG) { 
              seven_sub = seven[6]
              seven[6] = seven_sub +  opinions[index].valueG 
            }
            if (opinions[index].valueH) { 
              seven_sub = seven[7]
              seven[7] = seven_sub +  opinions[index].valueH 
            }
            break;
          case 8:
           // eight = eight + 1;
            eight_count = eight_count + 1;
            if (opinions[index].valueA) { 
              eight_sub = eight[0]
              eight[0] = eight_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              eight_sub = eight[1]
              eight[1] = eight_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              eight_sub = eight[2]
              eight[2] = eight_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              eight_sub = eight[3]
              eight[3] = eight_sub +  opinions[index].valueD 
            } 
            if (opinions[index].valueE) { 
              eight_sub = eight[4]
              eight[4] = eight_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              eight_sub = eight[5]
              eight[5] = eight_sub +  opinions[index].valueF 
            }
            if (opinions[index].valueG) { 
              eight_sub = eight[6]
              eight[6] = eight_sub +  opinions[index].valueG 
            }
            if (opinions[index].valueH) { 
              eight_sub = eight[7]
              eight[7] = eight_sub +  opinions[index].valueH 
            }
            break;
          case 9:
            //nine = nine + 1;
            nine_count = nine_count + 1;
            if (opinions[index].valueA) { 
              nine_sub = nine[0]
              nine[0] = nine_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              nine_sub = nine[1]
              nine[1] = nine_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              nine_sub = nine[2]
              nine[2] = nine_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              nine_sub = nine[3]
              nine[3] = nine_sub +  opinions[index].valueD 
            } 
            if (opinions[index].valueE) { 
              nine_sub = nine[4]
              nine[4] = nine_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              nine_sub = nine[5]
              nine[5] = nine_sub +  opinions[index].valueF 
            }
            if (opinions[index].valueG) { 
              nine_sub = nine[6]
              nine[6] = nine_sub +  opinions[index].valueG 
            }
            if (opinions[index].valueH) { 
              nine_sub = nine[7]
              nine[7] = nine_sub +  opinions[index].valueH 
            }
            break;
          case 10:
            //ten = ten + 1;
            ten_count = ten_count + 1;
            if (opinions[index].valueA) { 
              ten_sub = ten[0]
              ten[0] = ten_sub + opinions[index].valueA 
            }
            if (opinions[index].valueB) { 
              ten_sub = ten[1]
              ten[1] = ten_sub + opinions[index].valueB 
            }
            if (opinions[index].valueC) { 
              ten_sub = ten[2]
              ten[2] = ten_sub + opinions[index].valueC 
            }   
            if (opinions[index].valueD) { 
              ten_sub = ten[3]
              ten[3] = ten_sub +  opinions[index].valueD 
            } 
            if (opinions[index].valueE) { 
              ten_sub = ten[4]
              ten[4] = ten_sub +  opinions[index].valueE 
            } 
            if (opinions[index].valueF) { 
              ten_sub = ten[5]
              ten[5] = ten_sub +  opinions[index].valueF
            } 
            if (opinions[index].valueG) { 
              ten_sub = ten[6]
              ten[6] = ten_sub +  opinions[index].valueG 
            } 
            if (opinions[index].valueH) { 
              ten_sub = ten[7]
              ten[7] = ten_sub +  opinions[index].valueH 
            } 
            break;
        case 11:
            //if (daLocks===-1){
              console.log('green-slave_count11 3 : ', opinions[index].valueA);
              if (opinions[index].valueA) { 
                eleven[0] = opinions[index].valueA 
              }
              if (opinions[index].valueB) { 
                eleven[1] = opinions[index].valueB 
              }
              if (opinions[index].valueC) { 
                eleven[2] = opinions[index].valueC 
              }   
              if (opinions[index].valueD) { 
                eleven[3] = opinions[index].valueD 
              } 
              if (opinions[index].valueE) { 
                eleven[4] = opinions[index].valueE 
              } 
              if (opinions[index].valueF) { 
                eleven[5] = opinions[index].valueF
              } 
              if (opinions[index].valueG) { 
                eleven[6] = opinions[index].valueG 
              } 
              if (opinions[index].valueH) { 
                eleven[7] = opinions[index].valueH 
              } 
           // }
            break;
        default:
            // code block
        }
    //}

    console.log('green-slave_count-3 : ', slave_count + ' -- ' + opinions[index].slaveID + ' -- ' + opinions[index].valueA);

   if (slave_count!==11 ) {
    if (opinions[index].valueA) { 
        valueA_all_count = valueA_all_count + 1;
        valueA_count = valueA_count + opinions[index].valueA 
      }
      if (opinions[index].valueB) { 
        valueB_all_count = valueB_all_count + 1;
        valueB_count = valueB_count + opinions[index].valueB 
      }
      if (opinions[index].valueC) { 
        valueC_all_count = valueC_all_count + 1;
        valueC_count = valueC_count + opinions[index].valueC 
      }   
      if (opinions[index].valueD) { 
        valueD_all_count = valueD_all_count + 1;
        valueD_count = valueD_count + opinions[index].valueD 
      }  
      if (opinions[index].valueE) { 
        valueE_all_count = valueE_all_count + 1;
        valueE_count = valueE_count + opinions[index].valueE 
      } 
      if (opinions[index].valueF) { 
        valueF_all_count = valueF_all_count + 1;
        valueF_count = valueF_count + opinions[index].valueF 
      } 
      if (opinions[index].valueG) { 
        valueG_all_count = valueG_all_count + 1;
        valueG_count = valueG_count + opinions[index].valueG
      } 
      if (opinions[index].valueH) { 
        valueH_all_count = valueH_all_count + 1;
        valueH_count = valueH_count + opinions[index].valueH 
      } 
    }
    //  sslave.push(opinions[index].leaderID);
        // if ((opinions[index].slave===11) && (daLocks === -1)) { sslaves.push(opinions[index].slaveID) };

        // if ((opinions[index].slave!==11) && (daLock === -1)) { 
        //  sslave.push(opinions[index].slaveID) 
        //  slave_all_count = slave_all_count + 1
        // };
      }
    // }
    }
   })}

     //////////////////////////////////////////////////////////////////
     var islave = slave_all_count > 0 ? slave_count / slave_all_count : 0;
     var ivalueA = valueA_all_count > 0 ? valueA_count / valueA_all_count : 0;
     var ivalueB = valueB_all_count > 0 ? valueB_count / valueB_all_count : 0;
     var ivalueC = valueC_all_count > 0 ? valueC_count / valueC_all_count : 0;
     var ivalueD = valueD_all_count > 0 ? valueD_count / valueD_all_count : 0;
     var ivalueE = valueE_all_count > 0 ? valueE_count / valueE_all_count : 0;
     var ivalueF = valueF_all_count > 0 ? valueF_count / valueF_all_count : 0;
     var ivalueG = valueG_all_count > 0 ? valueG_count / valueG_all_count : 0;
     var ivalueH = valueH_all_count > 0 ? valueH_count / valueH_all_count : 0;

     //////////////////////////////////////////////////////////////////
     console.log('greeneleven[0] : ', eleven[0])
     
    if (opinions) {
     this.setState({
      slave: islave.toFixed(2),
      valueA: ivalueA.toFixed(2),
      valueB: ivalueB.toFixed(2),
      valueC: ivalueC.toFixed(2),
      valueD: ivalueD.toFixed(2),
      valueE: ivalueE.toFixed(2),
      valueF: ivalueF.toFixed(2),
      valueG: ivalueG.toFixed(2),
      valueH: ivalueH.toFixed(2),
      
      svalueA: eleven[0],
      svalueB: eleven[1],
      svalueC: eleven[2],
      svalueD: eleven[3],
      svalueE: eleven[4],
      svalueF: eleven[5],
      svalueG: eleven[6],
      svalueH: eleven[7], 

      slaveparticipants: slave_all_count,
      valueAparticipants: valueA_all_count,
      valueBparticipants: valueB_all_count,
      valueCparticipants: valueC_all_count,
      valueDparticipants: valueD_all_count,
      valueEparticipants: valueE_all_count,
      valueFparticipants: valueF_all_count,
      valueGparticipants: valueG_all_count,
      valueHparticipants: valueH_all_count,
      one_count: one_count,
      two_count: two_count,
      three_count: three_count,
      four_count: four_count,
      five_count: five_count,
      six_count: six_count,
      seven_count: seven_count,
      eight_count: eight_count,
      nine_count: nine_count,
      ten_count: ten_count,
      one_arr: [one[0],one[1],one[2],one[3],one[4],one[5],one[6],one[7]],
      two_arr: [two[0],two[1],two[2],two[3],two[4],two[5],two[6],two[7]],
      three_arr: [three[0],three[1],three[2],three[3],three[4],three[5],three[6],three[7]],
      four_arr: [four[0],four[1],four[2],four[3],four[4],four[5],four[6],four[7]],
      five_arr: [five[0],five[1],five[2],five[3],five[4],five[5],five[6],five[7]],
      six_arr: [six[0],six[1],six[2],six[3],six[4],six[5],six[6],six[7]],
      seven_arr: [seven[0],seven[1],seven[2],seven[3],seven[4],seven[5],seven[6],seven[7]],
      eight_arr: [eight[0],eight[1],eight[2],eight[3],eight[4],eight[5],eight[6],eight[7]],
      nine_arr: [nine[0],nine[1],nine[2],nine[3],nine[4],nine[5],nine[6],nine[7]],
      ten_arr: [ten[0],ten[1],ten[2],ten[3],ten[4],ten[5],ten[6],ten[7]]
     })};

     //insertPollCount into user and users
     const updates = {}

     var finalScore = (ivalueA + ivalueB + ivalueC + ivalueD + ivalueE + ivalueF + ivalueG + ivalueH) / this.state.countHeadings
     console.log('scores final 1 : ', ivalueA + ' ' + ivalueB + ' ' + ivalueC + ' ' + ivalueD + ' ' + ivalueE + ' ' + ivalueF + ' ' + ivalueG + ' ' + ivalueH)
     console.log('scores-final-finalScore 5 : ', finalScore)
     
     console.log('green slave_count 5 : ', eleven[0])
     console.log('green slave_count 6 : ', this.state.countHeadings)
     //finalScore = finalScore.toFixed(2);
     console.log('scores final 3 ', finalScore)

     console.log('scores final finalScore 1 ', this.state.countHeadings)
     //console.log('scores final finalScore 2 ', finalScore)
     console.log('scores final slave_all_count 1 ', this.state.valueA )
     
    //  if (this.state.valueA > 3) {
    //     console.log('scores final slave_all_count 3 ', this.state.valueA)
    //    if(finalScore) {
    //     updates['/users/' + this.props.userdata.userID + '/feedback'] = finalScore.toFixed(2);
    //     updates['/user/' + this.props.userdata.uid + '/feedback' ] = finalScore.toFixed(2);
    //   }
    //  }
    // else {
    //     console.log('scores final slave_all_count 4 ', this.state.valueA)
    //   if(finalScore) {
    //     updates['/users/' + this.props.userdata.userID + '/feedback'] = 0;
    //     updates['/user/' + this.props.userdata.uid + '/feedback' ] = 0;
    //   }
    // }
     // server: create group - send data to firebase

      if (finalScore) {
      //  if(
      //   (this.state.evalBoss > 0)
      //   && (this.state.evalSlave > 0)
      //   && (this.state.evalPeer > 0)
      //   && (this.state.evalSelf > 0)
      //  )
      //   {
      //     updates['/users/' + this.props.userdata.userID + '/eval360'] = true;
      //     updates['/user/' + this.props.userdata.uid + '/eval360' ] = true;
      //   }
        updates['/users/' + this.props.userdata.userID + '/feedback'] = finalScore.toFixed(2);
        updates['/user/' + this.props.userdata.uid + '/feedback'] = finalScore.toFixed(2);
       };

     firebase.database.ref().update(
       updates,
       function (error) {
         if (error) {
           console.log('success : ');
         } else {
          console.log('err : ', error);
         }
       })
    }

  // putOpinions_31Aug = () => {
  //   let { opinions } = this.props
  //   console.log('scores final x ', opinions);

  //   var slave_count = 0;
  //   var valueA_count = 0;
  //   var valueB_count = 0;
  //   var valueC_count = 0;
  //   var valueD_count = 0;
  //   var valueE_count = 0;
  //   var valueF_count = 0;
  //   var valueG_count = 0;
  //   var valueH_count = 0;

  //   var slave_all_count = 0;
  //   var valueA_all_count = 0;
  //   var valueB_all_count = 0;
  //   var valueC_all_count = 0;
  //   var valueD_all_count = 0;
  //   var valueE_all_count = 0;
  //   var valueF_all_count = 0;
  //   var valueG_all_count = 0;
  //   var valueH_all_count = 0;

  //   var one = [0,0,0,0,0,0,0,0];//0;
  //   var two = [0,0,0,0,0,0,0,0];//0;
  //   var three = [0,0,0,0,0,0,0,0];//0;
  //   var four = [0,0,0,0,0,0,0,0];//0;
  //   var five = [0,0,0,0,0,0,0,0];//0;
  //   var six = [0,0,0,0,0,0,0,0];//0;
  //   var seven = [0,0,0,0,0,0,0,0];//0;
  //   var eight = [0,0,0,0,0,0,0,0];//0;
  //   var nine = [0,0,0,0,0,0,0,0];//0;
  //   var ten = [0,0,0,0,0,0,0,0];//0;
  //   var eleven = [0,0,0,0,0,0,0,0];//0;

  //   var one_sub = 0;
  //   var two_sub = 0;
  //   var three_sub = 0;
  //   var four_sub = 0;
  //   var five_sub = 0;
  //   var six_sub = 0;
  //   var seven_sub = 0;
  //   var eight_sub = 0;
  //   var nine_sub = 0;
  //   var ten_sub = 0;

  //   var one_count = 0;
  //   var two_count = 0;
  //   var three_count = 0;
  //   var four_count = 0;
  //   var five_count = 0;
  //   var six_count = 0;
  //   var seven_count = 0;
  //   var eight_count = 0;
  //   var nine_count = 0;
  //   var ten_count = 0;

  //   if (opinions) { Object.keys(opinions).map((opinionsID, index) => {
  //     console.log('inside putOpinions 2', opinions[index]);
  //     if (opinions[index].slave) { 
  //       slave_all_count = slave_all_count + 1;
  //       slave_count = opinions[index].slave 
  //       console.log('green slave_count : ', slave_count);
  //       switch(slave_count) {
  //         case 1:
  //           //one = one + 1;
  //           one_count = one_count + 1;
  //           if (opinions[index].valueA) { 
  //             one_sub = one[0]
  //             one[0] = one_sub + opinions[index].valueA 
  //           }
  //           if (opinions[index].valueB) { 
  //             one_sub = one[1]
  //             one[1] = one_sub + opinions[index].valueB 
  //           }
  //           if (opinions[index].valueC) { 
  //             one_sub = one[2]
  //             one[2] = one_sub + opinions[index].valueC 
  //           }   
  //           if (opinions[index].valueD) {
  //             one_sub = one[3] 
  //             one[3] = one_sub + opinions[index].valueD 
  //           } 
  //           if (opinions[index].valueE) {
  //             one_sub = one[4] 
  //             one[4] = one_sub + opinions[index].valueE 
  //           }
  //           if (opinions[index].valueF) {
  //             one_sub = one[5] 
  //             one[5] = one_sub + opinions[index].valueF 
  //           }
  //           if (opinions[index].valueG) {
  //             one_sub = one[6] 
  //             one[6] = one_sub + opinions[index].valueG 
  //           }
  //           if (opinions[index].valueH) {
  //             one_sub = one[7] 
  //             one[7] = one_sub + opinions[index].valueH
  //           } 
  //           break;
  //         case 2:
  //           //two = two + 1;
  //           two_count = two_count + 1;
  //           if (opinions[index].valueA) { 
  //             two_sub = two[0]
  //             two[0] = two_sub + opinions[index].valueA 
  //           }
  //           if (opinions[index].valueB) { 
  //             two_sub = two[1]
  //             two[1] = two_sub + opinions[index].valueB 
  //           }
  //           if (opinions[index].valueC) { 
  //             two_sub = two[2]
  //             two[2] = two_sub + opinions[index].valueC 
  //           }   
  //           if (opinions[index].valueD) { 
  //             two_sub = two[3]
  //             two[3] = two_sub +  opinions[index].valueD 
  //           } 
  //           if (opinions[index].valueE) { 
  //             two_sub = two[4]
  //             two[4] = two_sub +  opinions[index].valueE 
  //           } 
  //           if (opinions[index].valueF) { 
  //             two_sub = two[5]
  //             two[5] = two_sub +  opinions[index].valueF 
  //           }
  //           if (opinions[index].valueG) { 
  //             two_sub = two[6]
  //             two[6] = two_sub +  opinions[index].valueG 
  //           }
  //           if (opinions[index].valueH) { 
  //             two_sub = two[7]
  //             two[7] = two_sub +  opinions[index].valueH 
  //           }
  //           break;
  //         case 3:
  //           //three = three + 1;
  //           three_count = three_count + 1;
  //           if (opinions[index].valueA) { 
  //             three_sub = three[0]
  //             three[0] = three_sub + opinions[index].valueA 
  //           }
  //           if (opinions[index].valueB) { 
  //             three_sub = three[1]
  //             three[1] = three_sub + opinions[index].valueB 
  //           }
  //           if (opinions[index].valueC) { 
  //             three_sub = three[2]
  //             three[2] = three_sub + opinions[index].valueC 
  //           }   
  //           if (opinions[index].valueD) { 
  //             three_sub = three[3]
  //             three[3] = three_sub +  opinions[index].valueD 
  //           }  
  //           if (opinions[index].valueE) { 
  //             three_sub = three[4]
  //             three[4] = three_sub +  opinions[index].valueE 
  //           } 
  //           if (opinions[index].valueF) { 
  //             three_sub = three[5]
  //             three[5] = three_sub +  opinions[index].valueF 
  //           } 
  //           if (opinions[index].valueG) { 
  //             three_sub = three[6]
  //             three[6] = three_sub +  opinions[index].valueG 
  //           } 
  //           if (opinions[index].valueH) { 
  //             three_sub = three[7]
  //             three[7] = three_sub +  opinions[index].valueH 
  //           }           
  //           break;
  //         case 4:
  //           //four = four + 1;
  //           four_count = four_count + 1;
  //           if (opinions[index].valueA) { 
  //             four_sub = four[0]
  //             four[0] = four_sub + opinions[index].valueA 
  //           }
  //           if (opinions[index].valueB) { 
  //             four_sub = four[1]
  //             four[1] = four_sub + opinions[index].valueB 
  //           }
  //           if (opinions[index].valueC) { 
  //             four_sub = four[2]
  //             four[2] = four_sub + opinions[index].valueC 
  //           }   
  //           if (opinions[index].valueD) { 
  //             four_sub = four[3]
  //             four[3] = four_sub +  opinions[index].valueD 
  //           } 
  //           if (opinions[index].valueE) { 
  //             four_sub = four[4]
  //             four[4] = four_sub +  opinions[index].valueE 
  //           } 
  //           if (opinions[index].valueF) { 
  //             four_sub = four[5]
  //             four[5] = four_sub +  opinions[index].valueF
  //           }  
  //           if (opinions[index].valueG) { 
  //             four_sub = four[6]
  //             four[6] = four_sub +  opinions[index].valueG 
  //           }  
  //           if (opinions[index].valueH) { 
  //             four_sub = four[7]
  //             four[7] = four_sub +  opinions[index].valueH 
  //           }    
  //           break;
  //         case 5:
  //           //five = five + 1;
  //           five_count = five_count + 1;
  //           if (opinions[index].valueA) { 
  //             five_sub = five[0]
  //             five[0] = five_sub + opinions[index].valueA 
  //           }
  //           if (opinions[index].valueB) { 
  //             five_sub = five[1]
  //             five[1] = five_sub + opinions[index].valueB 
  //           }
  //           if (opinions[index].valueC) { 
  //             five_sub = five[2]
  //             five[2] = five_sub + opinions[index].valueC 
  //           }   
  //           if (opinions[index].valueD) { 
  //             five_sub = five[3]
  //             five[3] = five_sub +  opinions[index].valueD 
  //           } 
  //           if (opinions[index].valueE) { 
  //             five_sub = five[4]
  //             five[4] = five_sub +  opinions[index].valueE 
  //           } 
  //           if (opinions[index].valueF) { 
  //             five_sub = five[5]
  //             five[5] = five_sub +  opinions[index].valueF 
  //           } 
  //           if (opinions[index].valueG_all_count) { 
  //             five_sub = five[6]
  //             five[6] = five_sub +  opinions[index].valueG
  //           } 
  //           if (opinions[index].valueH) { 
  //             five_sub = five[7]
  //             five[7] = five_sub +  opinions[index].valueH 
  //           } 
  //           break;
  //         case 6:
  //          // six = six + 1;
  //           six_count = six_count + 1;
  //           if (opinions[index].valueA) { 
  //             six_sub = six[0]
  //             six[0] = six_sub + opinions[index].valueA 
  //           }
  //           if (opinions[index].valueB) { 
  //             six_sub = six[1]
  //             six[1] = six_sub + opinions[index].valueB 
  //           }
  //           if (opinions[index].valueC) { 
  //             six_sub = six[2]
  //             six[2] = six_sub + opinions[index].valueC 
  //           }   
  //           if (opinions[index].valueD) { 
  //             six_sub = six[3]
  //             six[3] = six_sub +  opinions[index].valueD 
  //           }
  //           if (opinions[index].valueE) { 
  //             six_sub = six[4]
  //             six[4] = six_sub +  opinions[index].valueE 
  //           } 
  //           if (opinions[index].valueF) { 
  //             six_sub = six[5]
  //             six[5] = six_sub +  opinions[index].valueF 
  //           } 
  //           if (opinions[index].valueG) { 
  //             six_sub = six[6]
  //             six[6] = six_sub +  opinions[index].valueG 
  //           } 
  //           if (opinions[index].valueH) { 
  //             six_sub = six[7]
  //             six[7] = six_sub +  opinions[index].valueH 
  //           }  
  //           break;
  //         case 7:
  //           //seven = seven + 1;
  //           seven_count = seven_count + 1;
  //           if (opinions[index].valueA) { 
  //             seven_sub = seven[0]
  //             seven[0] = seven_sub + opinions[index].valueA 
  //           }
  //           if (opinions[index].valueB) { 
  //             seven_sub = seven[1]
  //             seven[1] = seven_sub + opinions[index].valueB 
  //           }
  //           if (opinions[index].valueC) { 
  //             seven_sub = seven[2]
  //             seven[2] = seven_sub + opinions[index].valueC 
  //           }   
  //           if (opinions[index].valueD) { 
  //             seven_sub = seven[3]
  //             seven[3] = seven_sub +  opinions[index].valueD 
  //           } 
  //           if (opinions[index].valueE) { 
  //             seven_sub = seven[4]
  //             seven[4] = seven_sub +  opinions[index].valueE 
  //           } 
  //           if (opinions[index].valueF) { 
  //             seven_sub = seven[5]
  //             seven[5] = seven_sub +  opinions[index].valueF
  //           }
  //           if (opinions[index].valueG) { 
  //             seven_sub = seven[6]
  //             seven[6] = seven_sub +  opinions[index].valueG 
  //           }
  //           if (opinions[index].valueH) { 
  //             seven_sub = seven[7]
  //             seven[7] = seven_sub +  opinions[index].valueH 
  //           }
  //           break;
  //         case 8:
  //          // eight = eight + 1;
  //           eight_count = eight_count + 1;
  //           if (opinions[index].valueA) { 
  //             eight_sub = eight[0]
  //             eight[0] = eight_sub + opinions[index].valueA 
  //           }
  //           if (opinions[index].valueB) { 
  //             eight_sub = eight[1]
  //             eight[1] = eight_sub + opinions[index].valueB 
  //           }
  //           if (opinions[index].valueC) { 
  //             eight_sub = eight[2]
  //             eight[2] = eight_sub + opinions[index].valueC 
  //           }   
  //           if (opinions[index].valueD) { 
  //             eight_sub = eight[3]
  //             eight[3] = eight_sub +  opinions[index].valueD 
  //           } 
  //           if (opinions[index].valueE) { 
  //             eight_sub = eight[4]
  //             eight[4] = eight_sub +  opinions[index].valueE 
  //           } 
  //           if (opinions[index].valueF) { 
  //             eight_sub = eight[5]
  //             eight[5] = eight_sub +  opinions[index].valueF 
  //           }
  //           if (opinions[index].valueG) { 
  //             eight_sub = eight[6]
  //             eight[6] = eight_sub +  opinions[index].valueG 
  //           }
  //           if (opinions[index].valueH) { 
  //             eight_sub = eight[7]
  //             eight[7] = eight_sub +  opinions[index].valueH 
  //           }
  //           break;
  //         case 9:
  //           //nine = nine + 1;
  //           nine_count = nine_count + 1;
  //           if (opinions[index].valueA) { 
  //             nine_sub = nine[0]
  //             nine[0] = nine_sub + opinions[index].valueA 
  //           }
  //           if (opinions[index].valueB) { 
  //             nine_sub = nine[1]
  //             nine[1] = nine_sub + opinions[index].valueB 
  //           }
  //           if (opinions[index].valueC) { 
  //             nine_sub = nine[2]
  //             nine[2] = nine_sub + opinions[index].valueC 
  //           }   
  //           if (opinions[index].valueD) { 
  //             nine_sub = nine[3]
  //             nine[3] = nine_sub +  opinions[index].valueD 
  //           } 
  //           if (opinions[index].valueE) { 
  //             nine_sub = nine[4]
  //             nine[4] = nine_sub +  opinions[index].valueE 
  //           } 
  //           if (opinions[index].valueF) { 
  //             nine_sub = nine[5]
  //             nine[5] = nine_sub +  opinions[index].valueF 
  //           }
  //           if (opinions[index].valueG) { 
  //             nine_sub = nine[6]
  //             nine[6] = nine_sub +  opinions[index].valueG 
  //           }
  //           if (opinions[index].valueH) { 
  //             nine_sub = nine[7]
  //             nine[7] = nine_sub +  opinions[index].valueH 
  //           }
  //           break;
  //         case 10:
  //           //ten = ten + 1;
  //           ten_count = ten_count + 1;
  //           if (opinions[index].valueA) { 
  //             ten_sub = ten[0]
  //             ten[0] = ten_sub + opinions[index].valueA 
  //           }
  //           if (opinions[index].valueB) { 
  //             ten_sub = ten[1]
  //             ten[1] = ten_sub + opinions[index].valueB 
  //           }
  //           if (opinions[index].valueC) { 
  //             ten_sub = ten[2]
  //             ten[2] = ten_sub + opinions[index].valueC 
  //           }   
  //           if (opinions[index].valueD) { 
  //             ten_sub = ten[3]
  //             ten[3] = ten_sub +  opinions[index].valueD 
  //           } 
  //           if (opinions[index].valueE) { 
  //             ten_sub = ten[4]
  //             ten[4] = ten_sub +  opinions[index].valueE 
  //           } 
  //           if (opinions[index].valueF) { 
  //             ten_sub = ten[5]
  //             ten[5] = ten_sub +  opinions[index].valueF
  //           } 
  //           if (opinions[index].valueG) { 
  //             ten_sub = ten[6]
  //             ten[6] = ten_sub +  opinions[index].valueG 
  //           } 
  //           if (opinions[index].valueH) { 
  //             ten_sub = ten[7]
  //             ten[7] = ten_sub +  opinions[index].valueH 
  //           } 
  //           break;
  //         case 11: //user evaluated themselfves
  //          console.log('green show own scores 1 : ', opinions[index].valueA )
  //             if (opinions[index].valueA) { 
  //               eleven[0] = opinions[index].valueA 
  //             }
  //             if (opinions[index].valueB) { 
  //               eleven[1] = opinions[index].valueB 
  //             }
  //             if (opinions[index].valueC) { 
  //               eleven[2] = opinions[index].valueC 
  //             }   
  //             if (opinions[index].valueD) { 
  //               eleven[3] = opinions[index].valueD 
  //             } 
  //             if (opinions[index].valueE) { 
  //               eleven[4] = opinions[index].valueE 
  //             } 
  //             if (opinions[index].valueF) { 
  //               eleven[5] = opinions[index].valueF
  //             } 
  //             if (opinions[index].valueG) { 
  //               eleven[6] = opinions[index].valueG 
  //             } 
  //             if (opinions[index].valueH) { 
  //               eleven[7] = opinions[index].valueH 
  //             } 
  //             break;          
  //         default:
  //           // code block
  //       }
  //     }
  //     console.log('green show own scores 2 : ',  eleven[0]);

  //    if (opinions[index].slave !== 11) { //dont count my own eval
  //     if (opinions[index].valueA) { 
  //       valueA_all_count = valueA_all_count + 1;
  //       valueA_count = valueA_count + opinions[index].valueA 
  //     }
  //     if (opinions[index].valueB) { 
  //       valueB_all_count = valueB_all_count + 1;
  //       valueB_count = valueB_count + opinions[index].valueB 
  //     }
  //     if (opinions[index].valueC) { 
  //       valueC_all_count = valueC_all_count + 1;
  //       valueC_count = valueC_count + opinions[index].valueC 
  //     }   
  //     if (opinions[index].valueD) { 
  //       valueD_all_count = valueD_all_count + 1;
  //       valueD_count = valueD_count + opinions[index].valueD 
  //     }  
  //     if (opinions[index].valueE) { 
  //       valueE_all_count = valueE_all_count + 1;
  //       valueE_count = valueE_count + opinions[index].valueE 
  //     } 
  //     if (opinions[index].valueF) { 
  //       valueF_all_count = valueF_all_count + 1;
  //       valueF_count = valueF_count + opinions[index].valueF 
  //     } 
  //     if (opinions[index].valueG) { 
  //       valueG_all_count = valueG_all_count + 1;
  //       valueG_count = valueG_count + opinions[index].valueG
  //     } 
  //     if (opinions[index].valueH) { 
  //       valueH_all_count = valueH_all_count + 1;
  //       valueH_count = valueH_count + opinions[index].valueH 
  //     } 
  //   }  
  //   })}

  //    //////////////////////////////////////////////////////////////////
  //    var islave = slave_all_count > 0 ? slave_count / slave_all_count : 0;
  //    var ivalueA = valueA_all_count > 0 ? valueA_count / valueA_all_count : 0;
  //    var ivalueB = valueB_all_count > 0 ? valueB_count / valueB_all_count : 0;
  //    var ivalueC = valueC_all_count > 0 ? valueC_count / valueC_all_count : 0;
  //    var ivalueD = valueD_all_count > 0 ? valueD_count / valueD_all_count : 0;
  //    var ivalueE = valueE_all_count > 0 ? valueE_count / valueE_all_count : 0;
  //    var ivalueF = valueF_all_count > 0 ? valueF_count / valueF_all_count : 0;
  //    var ivalueG = valueG_all_count > 0 ? valueG_count / valueG_all_count : 0;
  //    var ivalueH = valueH_all_count > 0 ? valueH_count / valueH_all_count : 0;

  //    //////////////////////////////////////////////////////////////////

  //   if (opinions) {
  //    this.setState({
  //     slave: islave.toFixed(2),
  //     valueA: ivalueA.toFixed(2),
  //     valueB: ivalueB.toFixed(2),
  //     valueC: ivalueC.toFixed(2),
  //     valueD: ivalueD.toFixed(2),
  //     valueE: ivalueE.toFixed(2),
  //     valueF: ivalueF.toFixed(2),
  //     valueG: ivalueG.toFixed(2),
  //     valueH: ivalueH.toFixed(2),

  //     svalueA: eleven[0],
  //     svalueB: eleven[1],
  //     svalueC: eleven[2],
  //     svalueD: eleven[3],
  //     svalueE: eleven[4],
  //     svalueF: eleven[5],
  //     svalueG: eleven[6],
  //     svalueH: eleven[7], 

  //     valueAparticipants: valueA_all_count,
  //     slaveparticipants: slave_all_count,
  //     valueBparticipants: valueB_all_count,
  //     valueCparticipants: valueC_all_count,
  //     valueDparticipants: valueD_all_count,
  //     valueEparticipants: valueE_all_count,
  //     valueFparticipants: valueF_all_count,
  //     valueGparticipants: valueG_all_count,
  //     valueHparticipants: valueH_all_count,
  //     one_count: one_count,
  //     two_count: two_count,
  //     three_count: three_count,
  //     four_count: four_count,
  //     five_count: five_count,
  //     six_count: six_count,
  //     seven_count: seven_count,
  //     eight_count: eight_count,
  //     nine_count: nine_count,
  //     ten_count: ten_count,
  //     one_arr: [one[0],one[1],one[2],one[3],one[4],one[5],one[6],one[7]],
  //     two_arr: [two[0],two[1],two[2],two[3],two[4],two[5],two[6],two[7]],
  //     three_arr: [three[0],three[1],three[2],three[3],three[4],three[5],three[6],three[7]],
  //     four_arr: [four[0],four[1],four[2],four[3],four[4],four[5],four[6],four[7]],
  //     five_arr: [five[0],five[1],five[2],five[3],five[4],five[5],five[6],five[7]],
  //     six_arr: [six[0],six[1],six[2],six[3],six[4],six[5],six[6],six[7]],
  //     seven_arr: [seven[0],seven[1],seven[2],seven[3],seven[4],seven[5],seven[6],seven[7]],
  //     eight_arr: [eight[0],eight[1],eight[2],eight[3],eight[4],eight[5],eight[6],eight[7]],
  //     nine_arr: [nine[0],nine[1],nine[2],nine[3],nine[4],nine[5],nine[6],nine[7]],
  //     ten_arr: [ten[0],ten[1],ten[2],ten[3],ten[4],ten[5],ten[6],ten[7]]
  //    })};

  //    //insertPollCount into user and users
  //    const updates = {}

  //    var finalScore = (ivalueA + ivalueB + ivalueC + ivalueD + ivalueE + ivalueF + ivalueG + ivalueH)/this.state.countHeadings
  //    console.log('scores final 1 : ', ivalueA + ' ' + ivalueB + ' ' + ivalueC + ' ' + ivalueD + ' ' + ivalueE + ' ' + ivalueF + ' ' + ivalueG + ' ' + ivalueH)
     
  //    console.log('scores-final-finalScore 6 : ', finalScore)
     
  //    console.log('scores final 3 ', finalScore)

  //    console.log('scores final finalScore 2 ', this.state.countHeadings)

  //    if(finalScore) {
  //     updates['/users/' + this.props.userdata.userID + '/feedback'] = finalScore.toFixed(2);
  //     updates['/user/' + this.props.userdata.uid + '/feedback' ] = finalScore.toFixed(2);
  //    }
 
  //    // server: create group - send data to firebase
  //    firebase.database.ref().update(
  //      updates,
  //      function (error) {
  //        if (error) {
  //          console.log('success : ');
  //        } else {
  //         console.log('err : ', error);
  //        }
  //      })
  // }

  insertPollCount = () => {
    const updates = {}
    var finalScore = (this.state.valueA + this.state.valueB + this.state.valueC + this.state.valueD + this.state.valueE + this.state.valueF + this.state.valueG + this.state.valueH) / this.state.countHeadings
     finalScore = parseInt(finalScore);
    //  console.log('finalScore-green : ', this.state.evalBoss + this.state.evalSlave + this.state.evalPeer + this.state.evalSelf)

    //  if(
    //   (this.state.evalBoss > 0)
    //   && (this.state.evalSlave > 0)
    //   && (this.state.evalPeer > 0)
    //   && (this.state.evalSelf > 0)
    //  )
    //   {
    //     updates['/users/' + this.props.userdata.userID + '/eval360'] = true;
    //     updates['/user/' + this.props.userdata.uid + '/eval360' ] = true;
    //   }

     updates['/users/' + this.props.userdata.userID + '/feedback'] = finalScore;
     updates['/user/' + this.props.userdata.uid + '/feedback' ] = finalScore;

    // server: create group - send data to firebase

  }

   getOpinion = (props) => {
    for (let x in props) {
      return props[x].valueA
    }
   }

   roundOff = (nr) => {
    var fnr = 0;
    fnr = nr.toFixed(0);
    return fnr;
   }

   filterZZZ = (comLable) => {
    let comLablex = comLable.toString()
    let myTruncatedString = comLablex.substring(0, 4)
    let finalstring = comLablex

    if (myTruncatedString === 'ZZZ_') {
      finalstring = comLablex.substring(4, comLablex.length)
    }
    if (myTruncatedString === 'AAA_') {
      finalstring = comLablex.substring(4, comLablex.length)
    }
    return finalstring
  };

  lastResp = (ans) => {
    this.setState({lastAnswer: ans})
  }


  betaDesc = () => {
    if (this.state.showing==='none') {
       this.setState({ showing: 'block' })
    }
    else
    {
      this.setState({ showing: 'none' })
    }
    console.log('betaDesc showing : ', this.state.showing);
  }

 // dateIsValid(date) {
   // if (isNaN(date))
    // if (
    //   typeof date === 'object' &&
    //   date !== null &&
    //   typeof date.getTime === 'function' &&
    //   !isNaN(date)
    // ) {
    //   return true;
    // }
  
    // return false;
  //}

  formatDate = (date) => {
   if (date) {
    var dd = new Date(date);
       var month = '' + (dd.getMonth() + 1);
       var day = '' + dd.getDate();
       var year = dd.getFullYear();
       var hour = dd.getHours();
       var minutes = dd.getMinutes(); 
       var nminutes;
       var time;

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    console.log('formatDate info : ', minutes)

    if (minutes < 10) {nminutes = '0' + minutes} else {nminutes = minutes}
    time = hour + ':' + nminutes
    return [year, month, day, time].join('-');
   }
   else
   {
    return 'no date'
   }
   
}

 printHistory = () => {
  const {
    headingA,
    commentRTime,
    commentHistoryA,
    commentHistoryB,
    commentHistoryC,
    commentHistoryD,
    commentHistoryE,
    commentHistoryF,
    commentHistoryG,
    commentHistoryH,
    commentRTimeSelf,
    commentHistoryAself,
    commentHistoryBself,
    commentHistoryCself,
    commentHistoryDself,
    commentHistoryEself,
    commentHistoryFself,
    commentHistoryGself,
    commentHistoryHself,
    slaveIDarray
  } = this.state;

  const {opinions} = this.props;

  var commentRt = [];
  var HistoryA = [];
  var HistoryB = [];
  var HistoryC = [];
  var HistoryD = [];
  var HistoryE = [];
  var HistoryF = [];
  var HistoryG = [];
  var HistoryH = [];

  var commentRtself = [];
  var HistoryAself = [];
  var HistoryBself = [];
  var HistoryCself = [];
  var HistoryDself = [];
  var HistoryEself = [];
  var HistoryFself = [];
  var HistoryGself = [];
  var HistoryHself = [];

  var s = 0;

 slaveIDarray && Object.keys(slaveIDarray).map((oslaveIDarrayID, i) => {
  opinions && Object.keys(opinions).map((opinionsID, index) => {
   /////////REAL-TIME///////////////////////////////////////////////////
  if (opinions[index].slaveID===slaveIDarray[i]) {
  //  var slA = opinions[index].slaveID;
  //  if (slA!==slB) { s = s + 1; }
  //  var slB = opinions[index].slaveID;

   if (opinions[index].simpleopinion && opinions[index].leaderID!==opinions[index].slaveID) { //slave evaluating leader
    commentRt.push(opinions[index].simpleopinion + ' - ' + this.formatDate(opinions[index].sdate));
   } 
  if (opinions[index].simpleopinion && opinions[index].leaderID===opinions[index].slaveID) {  //leader evaluating himself
    commentRtself.push(opinions[index].simpleopinion + ' - ' + this.formatDate(opinions[index].sdate));
  }
  //////////////////////////////////////////////////////////////////////////////////
     if (opinions[index].valueAopinion && opinions[index].leaderID!==opinions[index].slaveID) { //slave evaluating leader
      HistoryA.push(opinions[index].valueAopinion + ' - ' + this.formatDate(opinions[index].sdate));
     } 
    if (opinions[index].valueAopinion && opinions[index].leaderID===opinions[index].slaveID) {  //leader evaluating himself
     HistoryAself.push(opinions[index].valueAopinion + ' - ' + this.formatDate(opinions[index].sdate));
    }

    if (opinions[index].valueBopinion && opinions[index].leaderID!==opinions[index].slaveID) { //slave evaluating leader
      console.log('opinions history 2 : ', opinions[index].valueBopinion);
      HistoryB.push(opinions[index].valueBopinion + ' - ' + this.formatDate(opinions[index].sdate));
     } 
    if (opinions[index].valueBopinion && opinions[index].leaderID===opinions[index].slaveID) {//leader evaluating himself
     HistoryBself.push(opinions[index].valueBopinion + ' - ' + this.formatDate(opinions[index].sdate));
    }

    if (opinions[index].valueCopinion && opinions[index].leaderID!==opinions[index].slaveID) { //slave evaluating leader
      console.log('opinions history 2 : ', opinions[index].valueCopinion);
      HistoryC.push(opinions[index].valueCopinion + ' - ' + this.formatDate(opinions[index].sdate));
     }
    if (opinions[index].valueCopinion && opinions[index].leaderID===opinions[index].slaveID) {  //leader evaluating himself
     HistoryCself.push(opinions[index].valueCopinion + ' - ' + this.formatDate(opinions[index].sdate));
    }

    if (opinions[index].valueDopinion && opinions[index].leaderID!==opinions[index].slaveID) { //slave evaluating leader
      HistoryD.push(opinions[index].valueDopinion + ' - ' + this.formatDate(opinions[index].sdate));
     }
    if (opinions[index].valueDopinion && opinions[index].leaderID===opinions[index].slaveID) {  //leader evaluating himself
     HistoryDself.push(opinions[index].valueDopinion + ' - ' + this.formatDate(opinions[index].sdate));
    }

    if (opinions[index].valueEopinion && opinions[index].leaderID!==opinions[index].slaveID) { //slave evaluating leader
      HistoryE.push(opinions[index].valueEopinion + ' - ' + this.formatDate(opinions[index].sdate));
     }
    if (opinions[index].valueEopinion && opinions[index].leaderID===opinions[index].slaveID) {  //leader evaluating himself
     HistoryEself.push(opinions[index].valueEopinion + ' - ' + this.formatDate(opinions[index].sdate));
    }

    if (opinions[index].valueFopinion && opinions[index].leaderID!==opinions[index].slaveID) { //slave evaluating leader
      HistoryF.push(opinions[index].valueFopinion + ' - ' + this.formatDate(opinions[index].sdate));
     }
    if (opinions[index].valueFopinion && opinions[index].leaderID===opinions[index].slaveID) {  //leader evaluating himself
     HistoryFself.push(opinions[index].valueFopinion + ' - ' + this.formatDate(opinions[index].sdate));
    }

    if (opinions[index].valueGopinion && opinions[index].leaderID!==opinions[index].slaveID) { //slave evaluating leader
      HistoryG.push(opinions[index].valueGopinion + ' - ' + this.formatDate(opinions[index].sdate));
     }
    if (opinions[index].valueGopinion && opinions[index].leaderID===opinions[index].slaveID) {  //leader evaluating himself
     HistoryGself.push(opinions[index].valueGopinion + ' - ' + this.formatDate(opinions[index].sdate));
    }

    if (opinions[index].valueHopinion && opinions[index].leaderID!==opinions[index].slaveID) { //slave evaluating leader
      HistoryH.push(opinions[index].valueHopinion + ' - ' + this.formatDate(opinions[index].sdate));
     }
    if (opinions[index].valueHopinion && opinions[index].leaderID===opinions[index].slaveID) {  //leader evaluating himself
     HistoryHself.push(opinions[index].valueHopinion + ' - ' + this.formatDate(opinions[index].sdate));
    }
  //////////////////////////////////////////////////////////
    }
   }) 
  })
  ///////////////////////////////////////////////////////////
  // HistoryA.reverse();
  // HistoryB.reverse();
  // HistoryC.reverse();
  // HistoryD.reverse();
  // HistoryE.reverse();
  // HistoryF.reverse();
  // HistoryG.reverse();
  // HistoryH.reverse();

  // HistoryAself.reverse();
  // HistoryBself.reverse();
  // HistoryCself.reverse();
  // HistoryDself.reverse();
  // HistoryEself.reverse();
  // HistoryFself.reverse();
  // HistoryGself.reverse();
  // HistoryHself.reverse();
  //////////////////////////////////////////////////////////

  this.setState({
    commentRTime: commentRt,
    commentHistoryA: HistoryA,
    commentHistoryB: HistoryB,
    commentHistoryC: HistoryC,
    commentHistoryD: HistoryD,
    commentHistoryE: HistoryE,
    commentHistoryF: HistoryF,
    commentHistoryG: HistoryG,
    commentHistoryH: HistoryH,
    commentRTimeSelf: commentRtself,
    commentHistoryAself: HistoryAself,
    commentHistoryBself: HistoryBself,
    commentHistoryCself: HistoryCself,
    commentHistoryDself: HistoryDself,
    commentHistoryEself: HistoryEself,
    commentHistoryFself: HistoryFself,
    commentHistoryGself: HistoryGself,
    commentHistoryHself: HistoryHself,
  })
 }

 //showHide = () =>  {

 // const elementId = this.myElementRef.current.id;
    // console.log(`Element ID: ${elementId}`);
   // console.log('Element ID : ', elementId);
  //console.log('showHide 2 : ', ref.current.id);
  // var x = document.getElementById(divName);
  // if (x.style.display === "none") {
  //   x.style.display = "block";
  // } else {
  //   x.style.display = "none";
  // }
//}

showHide() {
  // ReactDOM.findDOMNode(this.myInputRef.current).focus();
  // console.log('Element ID 1 : ', elementId);

  const inputValue = this.myInputRef.current.value;
  console.log('Element ID 1 : ', inputValue)
}

count_evaluations(slaveIDarrayp) {
  let { opinions } = this.props;
  //let { slaveIDarrayp } = this.state;
  const updates = {}
  var slaveIDarray = slaveIDarrayp;
  slaveIDarray.push(this.props.userdata.userID);

   console.log('evalValues 0 : ', slaveIDarray)

    var evalPeer=0;
    var evalBoss=0;
    var evalSlave=0;
    var evalSelf=0;
    var threeSxy = false;
    var newLine = 0;

      if (slaveIDarray.length > 0) { 
         slaveIDarray.map((slaveID, i) => (                 
         opinions && Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
        
          if ((slaveIDarray[i] === opinions[index].slaveID) 
         // && (opinions[index].valueAopinion !== undefined) 
         // && (opinions[index].slaveID!==opinions[index].leaderID)
          && (this.props.userdata.userID===opinions[index].leaderID)                    
          ) {   
            console.log('evalValues B1 : ', this.props.userdata.userID + '-' + slaveIDarray[i])      
          if ((newLine !== slaveIDarray[i]) //skipp all until slaveIDarray[i] changes
          )
           { 
            newLine = slaveIDarray[i]
            // evalPeer=0;
            // evalBoss=0;
            // evalSlave=0;
            // evalSelf=0;
            console.log('evalValues B2 : ', opinions[index].type)          

            if (opinions[index].type===1) {
              console.log('evalValues A : ', opinions[index].slave)
                switch(opinions[index].slave) {
                  case 1: //your leader
                     evalBoss = evalBoss + 1;
                    break;
                  case 8: //your leader
                    evalBoss = evalBoss + 1;
                   break;
                  case 9: //your leader
                     evalBoss = evalBoss + 1;
                    break;                    
                  case 2: //your slave
                     evalSlave = evalSlave + 1;
                    break;
                  default: //peers
                     evalPeer = evalPeer + 1;
                }
              }
            if (opinions[index].type===3) {
              console.log('evalValues B : ', opinions[index].slave)
              evalSelf = evalSelf + 1;
            }
            console.log('evalValues 1 : ', evalBoss)
            //  this.setState({
            //    evalBoss: evalBoss,
            //    evalSlave: evalSlave,
            //    evalPeer: evalPeer,
            //    evalSelf: evalSelf
            //  });
          }
          console.log('evalValues 2 : ', evalBoss)
         }
         console.log('evalValues 3 : ', evalBoss)
        })
        )

        )}
        console.log('evalValues 4 : ', evalBoss)
        ////////////////////////////////////////////////////////
        console.log('finalScore-green : ', evalBoss + ' - ' + evalSlave + ' - ' +  evalPeer + ' - ' + evalSelf)

        if(
          ( (evalBoss > 0) && (evalSlave > 4) && (evalPeer > 2)  && (evalSelf > 0) ) ||
         ((evalSlave > 6) && (evalSelf > 0) )  )
          { threeSxy = true } else { threeSxy = false }

        if( threeSxy===true )
          {
            updates['/users/' + this.props.userdata.userID + '/eval360'] = true;
            updates['/user/' + this.props.userdata.uid + '/eval360' ] = true;
          
          firebase.database.ref().update(
            updates,
            function (error) {
              if (error) {
                console.log('success : ');
              } else {
               console.log('err : ', error);
              }
            })
          }
          //////////////////////////////////////////////////////
        this.setState({
               evalBoss: evalBoss,
               evalSlave: evalSlave,
               evalPeer: evalPeer,
               evalSelf: evalSelf
             });
    }

  render() {
    const { classes, companyValues, userdata, pldpTasks, pldpAllMyTasks, opinions, showing } = this.props
    const { 
      descA, 
      headingA, 
      descB, 
      headingB,
      descC, 
      headingC,
      descD, 
      headingD,
      descE, 
      headingE,
      descF, 
      headingF,
      descG, 
      headingG,
      descH, 
      headingH,
      commentRTime,
      commentHistoryA,
      commentHistoryB,
      commentHistoryC,
      commentHistoryD,
      commentHistoryE,
      commentHistoryF,
      commentHistoryG,
      commentHistoryH,

      commentRTimeSelf,
      commentHistoryAself,
      commentHistoryBself,
      commentHistoryCself,
      commentHistoryDself,
      commentHistoryEself,
      commentHistoryFself,
      commentHistoryGself,
      commentHistoryHself,

      shownR,
      shownA,
      shownB,
      shownC,
      shownD,
      shownE,
      shownF,
      shownG,
      shownH,

      shownRs,
      shownAs,
      shownBs,
      shownCs,
      shownDs,
      shownEs,
      shownFs,
      shownGs,
      shownHs,
      slaveIDarray
       } = this.state;

      const tableX = {
      color: "#515A5A",
      fontSize: 12,
      border: "1",
      backgroundColor: '#F2F3F4',
      // width: '7%',
      // maxWidth: '7%',
      width: 50/`${ this.state.countHeadings }%`,
      maxWidth: 50/`${ this.state.countHeadings }%`,
      padding: 1,
      borderRadius: 2
    }

    const tableY = {
      color: "#515A5A",
      fontSize: 12,
      border: "1",
      align: "left",
      //backgroundColor: '#F2F3F4',
      backgroundColor: '#FFFFFF',
       width: '100%',
       maxWidth: '100%',
      // width: 50/`${ this.state.countHeadings }%`,
      // maxWidth: 50/`${ this.state.countHeadings }%`,
      padding: 1,
      borderRadius: 2
    }

    const tableYi = {
      color: "#515A5A",
      fontSize: 12,
      border: "1",
      align: "left",
      //backgroundColor: '#F2F3F4',
      backgroundColor: '#FFFFFF',
       width: '100%',
       maxWidth: '100%',
      // width: 50/`${ this.state.countHeadings }%`,
      // maxWidth: 50/`${ this.state.countHeadings }%`,
      padding: 1,
      borderRadius: 2
    }

    const tableW = {
      color: "#515A5A",
      fontSize: 12,
      border: "0px",
      backgroundColor: '#FFFFFF',
      padding: 1,
      // width: '7%',
      // maxWidth: '7%',
      width: 50/`${ this.state.countHeadings }%`,
      maxWidth: 50/`${ this.state.countHeadings }%`,
      borderRadius: 2
    }

       var n = 0;
       var s = 0;
       var k = 0;
       var openclose;
       var newLine = 0;
       var lenS = slaveIDarray.length;
      // let opinionsB = this.props.opinions

    console.log('[Tcompany values list]', companyValues)
    console.log('[Tcompany values type]', typeof companyValues)
    console.log('green leaderboard feedback : ', this.props.userdata.execmember);

    console.log('green-slaveIDarray ', slaveIDarray)
    console.log('green-props-opinions ', this.props)

    console.log('green-commentHistoryA : ', commentHistoryA)
    console.log('green-lenS : ', lenS)

    const { tabsvalue, openedTasks } = this.state
    groupTasks = Object.values(pldpTasks).groupBy('moveAction')
    delete groupTasks.undefined
    const competenciesFound = includedCompetencies(openedTasks, pldpAllMyTasks)

    var alist = [];
    var MaxSdate = 0;
    var MaxSdate2 = 0;
    var MaxDate = 0;
    var MaxDate2 = 0;
    var MaxDate3 = 0;
    var MaxDate4 = 0;
    var MaxDate5 = 0;
    var MaxDate6 = 0;
    var MaxDate7 = 0;
    var MaxDate8 = 0;
    var MaxDate9 = 0;
    var MaxDate10 = 0;
    var MaxDate11 = 0;
    var MaxDate12 = 0;
    var MaxDate13 = 0;
    var MaxDate14 = 0;
    var MaxDate15 = 0;
    var MaxDate16 = 0;

    var valuePlaceholder = '';
    let opinionsB = this.props.opinions

    var scores = [];
    var dates = [];
    var ave = [];

    const testDD = (inData, Date) => {
      scores.push(inData);
      ave.push(1);
      dates.push(Date);
      console.log('greentestDD 1 : ', ave);
    }

    // const testDDx = (inData, inArr , Date) => {
    //   scores.push(inData);
    //   ave.push(inData);
    //   dates.push(Date);
    //   console.log('greentestDD 2 : ', ave);
    // }

    const testDDxx = (inData, inArr , Date) => {
      var tot;
      var Averge;
      tot = inArr.valueA + inArr.valueB + inArr.valueC + inArr.valueD + inArr.valueE + inArr.valueF + inArr.valueG + inArr.valueH;
      Averge = tot / this.state.countHeadings

      scores.push(inData);
      console.log('greentestDD a : ', this.state.countHeadings);
      ave.push(Averge);
      dates.push(Date);
      console.log('greentestDD x : ', ave);
    }

    function convertDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
    
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
    
      return [year, month, day].join('-');

    }

    const loadGraphData = (inData) => {
      scores.push(inData);
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={2} className={classes.header}>
          <Grid item sm={10} xs={6}>
            <Typography variant='subtitle2' gutterBottom>
              <div style={{ fontSize: 16, alignSelf: 'center' }}>
                <AccountBalance style={{ fontSize: 35, padding: 10 }}/>
             { userdata ?
                <Link to={{
                  pathname: '/Poe',
                  state: {
                    Everyone: userdata.userID
                  }
                }}
              >              
                <Button
                  size='small'
                  color='#000000'
                >
                  <font style={{
                    margin:"12px",
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    color: 'rgba(0,0,0,1)',
                    fontSize: '12px'
                  }}><b>&nbsp;&nbsp;Portfolio of Evidence of points</b></font>
                </Button>
                </Link>
                : null}
                
              </div>
            </Typography>
          </Grid>
          <Grid item sm={2} xs={6} style={{ textAlign: 'center' }}>

            <Button
              variant='contained'
              onClick={this.addAction}
              // color='primary'
              startIcon={<AddIcon />}
              className={classes.addIconstyles}
            >
               Add
            </Button>
            {/* </Button> */}
          </Grid>
          <Grid>
            <GenTutorial
                //url={'https://youtu.be/rsn6Vec3fQA'}
                //url={'https://youtu.be/s0WvGR94oLQ'}
                url={'https://youtu.be/7uM0nUAv4uA?feature=shared'}
              />
          </Grid>

          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <RadioGroup
                name='filteredtasks'
                selectedValue={this.state.filterby}
                onChange={this.handleFilteredTasks}
              >
                <Radio value='open' /> Active
                <Radio value='complete' style={{ marginLeft: '8px' }} /> Completed
                <Radio value='feedback' style={{ marginLeft: '8px' }} /> <b><i>LeaderTrack</i></b>
                <Radio value='all' style={{ marginLeft: '8px' }} /> All (POE)
              </RadioGroup>
            </div>
          </Grid>
        </Grid>
        {tabsvalue === 0 && (
          <TabContainer>
            <Grid container spacing={2}>
              {companyValues &&

                  Object.keys(companyValues).map(id => {
                    const labelId = `${companyValues[id].valueID}`
                    const labelName = `${companyValues[id].valueDesc}`
                    let tasksCount = 0


                    console.log('[companyValues]', companyValues[id])
                    if (groupTasks[labelId]) {
                      console.log('[Task info]', groupTasks[labelId])
                      console.log('[Task info type]', typeof groupTasks[labelId])
                      tasksCount = this.getTasksCount(groupTasks[labelId], labelName)
                    }

                    if (labelName !== 'undefined' && tasksCount > 0) {
                      return (
                        <Grid item xs={12} sm={12} lg={12}>
                          <ExpansionPanel expanded className={classes.panel}>
                            <ExpansionPanelSummary style={{ alignItems: 'center' }}>
 
                              <Typography
                                gutterBottom
                                variant='h6'
                                className={classes.title}

                              >

                                {labelName}
                                <Typography variant='caption' style={{ padding: '0px 15px', color: '#7393B3' }}>
                                  {labelName === headingA || labelName === headingB || labelName === headingC || labelName === headingD || labelName === headingE|| labelName === headingF || labelName === headingG || labelName === headingH ? 'Leadership Competency' : 'Leadership Task'}
                                </Typography>
                              </Typography>

                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails
                              style={{
                                padding: '8px 8px 24px'
                              }}
                            >

                              <MyTasks
                                groupTasks={groupTasks[labelId]}
                                compid={companyValues[id].valueID}
                                editTask={this.editTask.bind(this)}
                                completeTask={this.onMarkComplete.bind(this)}

                              />
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </Grid>
                      )
                    }
                    return null
                  })
              }

              {companyValues &&

Object.keys(companyValues).map(id => {
  const labelId = `${companyValues[id].valueID}`
  const labelName = `${companyValues[id].valueDesc}`
  let tasksCount = 0;

  // var alist = []

  console.log('[companyValues]', companyValues[id])

  console.log("greenstats 1 : ", this.state.five_count )
  console.log("greenstats 2 : ", this.state.slaveparticipants )

  //this.state.five_count / this.state.slaveparticipants

  if (groupTasks[labelId]) {
    ///////////////////////////////////////
     var tlist = groupTasks[labelId]

    console.log('[Task info]', groupTasks[labelId])
    console.log('[Task info Title]', groupTasks[labelId])
    console.log('[Task info type]', typeof groupTasks[labelId])
    tasksCount = this.getTasksCount(groupTasks[labelId], labelName)
  }

  if (labelName !== 'undefined' && tasksCount === 0) {
    return (
      <Grid item xs={12} sm={12} lg={12}>
        <div className={classes.unopendList}>
          <Typography
            gutterBottom
            variant='h6'
            className={classes.unopendtitle}
          >

            {labelName}
            <Typography variant='caption' style={{ padding: '0px 15px', color: '#e5e4e2' }}>
              {labelName === headingA || labelName === headingB || labelName === headingC || labelName === headingD || labelName === headingE || labelName === headingF || labelName === headingG || labelName === headingH ? 'Leadership Competency' : 'Leadership Task'} 
            </Typography>
          </Typography>
        </div>
      </Grid>
    )
  }

  return null
})
}

            </Grid>
          </TabContainer>
        )}
        <a name="feedback"></a>
        {tabsvalue === 1 && (
          <TabContainer>
            <TasksListView
              pldpAllMyTasks={pldpAllMyTasks}
              competenciesFound={competenciesFound}
              companyValues={companyValues}
              userdata={userdata}
              filterZZZ={this.filterZZZ}
              editTask={this.editTask.bind(this)}
              completeTask={this.onMarkComplete.bind(this)} />
          </TabContainer>
        )}

        {(tabsvalue === 2 || tabsvalue === 1 ) && (this.props.userdata.execmember) && (
           (
            <TabContainer>
              <div>&nbsp; </div>
              <div><b>Feedback Stats and Opinions</b></div>
              <div>
              <MDBContainer>  
                    <MDBRow> 
                      <MDBCol style={tableC} >
                      <b> Opinion</b>
                      </MDBCol>          
                      <MDBCol style={tableC1} >
                      <b> Mean Score</b>
                      </MDBCol> 
                      <MDBCol style={tableC2} >
                      <b> Participants</b>
                      </MDBCol> 
                      <MDBCol style={tableCa} >
                      <b> My Own Score</b>
                      </MDBCol> 
                    </MDBRow>  
                      {/* //////////////////////////////////////////////////////////////// */}

                     
                    <MDBRow> 
                      <MDBCol style={tableMh} >
                      <b>Realtime Feedback score:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                        { this.state.simple} 
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.simpleparticipants}
                      </MDBCol>  
                      <MDBCol style={tableM} colspan='5'>
                      { this.state.ssimple }
                      </MDBCol>                                         
                    </MDBRow>  

                    { this.state.headingA ?
                    <MDBRow> 
                      <MDBCol style={tableMh} >
                      <b>{headingA}:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueA }
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.valueAparticipants}
                      </MDBCol> 
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueA }
                      </MDBCol>                                          
                    </MDBRow>  
                  :null}

                  { this.state.headingB ?
                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> {headingB} :</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueB}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.valueBparticipants}
                      </MDBCol>  
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueB}
                      </MDBCol>                                          
                    </MDBRow>  
                  :null}

                 { this.state.headingC ?  
                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> {headingC}:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueC}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.valueCparticipants}
                      </MDBCol>  
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueC}
                      </MDBCol> 
                    </MDBRow>  
                   :null}

                    { this.state.headingD ?
                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> {headingD}:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueD}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='9'>
                       { this.state.valueDparticipants}
                      </MDBCol> 
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueD}
                      </MDBCol>                                          
                    </MDBRow>  
                   :null}

                    { this.state.headingE ?
                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> {headingE}:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueE}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.valueEparticipants}
                      </MDBCol>  
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueE}
                      </MDBCol>                                         
                    </MDBRow>  
                   :null}

                    { this.state.headingF ?
                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> {headingF}:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueF}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.valueFparticipants}
                      </MDBCol>    
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueF}
                      </MDBCol>                                        
                    </MDBRow>  
                   :null}

                    { this.state.headingG ?
                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> {headingG}:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueG}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.valueGparticipants}
                      </MDBCol>  
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueG}
                      </MDBCol>                                          
                    </MDBRow>
                    :null}

                    { this.state.headingH ?
                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> {headingH}:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.valueH}
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.valueHparticipants}
                      </MDBCol>  
                      <MDBCol style={tableM} colspan='5'>
                       { this.state.svalueH}
                      </MDBCol>                                         
                    </MDBRow>  
                    :null}
                    <MDBRow> 
                    
                      <MDBCol style={tableMh} >
                      &nbsp;
                      </MDBCol> 
                      <MDBCol style={tableM} >
                      &nbsp;
                      </MDBCol>   
                      <MDBCol style={tableM} colspan='5'>
                      &nbsp;
                      </MDBCol>    
                      <MDBCol style={tableM} colspan='5'>
                      &nbsp;
                      </MDBCol>                                       
                    </MDBRow> 

                    {/* get slave details to mark assesement */}
                    {/* { (slaveIDarray.length > 0) && slaveIDarray.map((slaveID, i) => (                 
                    opinions &&
                    headingA &&                  
                    Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                    
                      if ((slaveIDarray[i] === opinions[index].slaveID) 
                      && (opinions[index].valueAopinion !== undefined) 
                      && (opinions[index].slaveID!==opinions[index].leaderID)
                      && (this.props.userdata.userID===opinions[index].leaderID)                    
                      ) {         
                      if ((newLine !== slaveIDarray[i])
                        || (opinions[index].type===1) 
                        || (opinions[index].slave===1)
                        || (opinions[index].slave!==11)
                      )
                         { 
                        console.log('type-eval : ', opinions[index].slave)
                        let evalPeer: 0,
                        let evalBoss: 0
                      }
                    }
                    })
                   ))} */}  
                    {/* get slave details to mark assesement */}

              <div style={this.state.simple ? tableBlank : tableHselfhide}>
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on Realtime Feedback: </i>[{ this.state.simple }]</b>
                    :null}
                    </MDBRow>    

                {(slaveIDarray.length > 0) && slaveIDarray.map((slaveID, i) => (                 
                    opinions &&
                    this.state.simple &&                  
                    Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                    
                      if ((slaveIDarray[i] === opinions[index].slaveID) 
                      && (opinions[index].simpleopinion !== undefined) 
                      && (opinions[index].slaveID!==opinions[index].leaderID)
                      && (this.props.userdata.userID===opinions[index].leaderID) 
                      && (opinions[index].type===2)
                      ) {
                        
                      if (newLine !== slaveIDarray[i]) { MaxSdate = 0 }
                        newLine = slaveIDarray[i]
                   
                        MaxSdate++                 
                        return (MaxSdate === 1) ? (                         
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableH } 
                            onClick={() => this.setState({shownR: shownR===slaveIDarray[i] ? 'closed' : slaveIDarray[i] })}>                                         
                              {shownR===slaveIDarray[i] ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].simpleopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxSdate === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownR===slaveIDarray[i] ? tableH : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (slaveIDarray[i] === opinions[u].slaveID) 
                                   && (opinions[u].simpleopinion !== undefined)                                    
                                   && (opinions[u].simpleopinion !== opinions[index-1].simpleopinion) 
                                   && (opinions[u].slaveID!==opinions[u].leaderID)
                                   && (opinions[u].type===2)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                         {testDD(opinions[u].simple, convertDate(opinions[u].sdate))}{opinions[u].simpleopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                          
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownR===slaveIDarray[i] ? tableH : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }                    
                      return null; // Return null for elements you don't want to render
                    })
                  ))
                  }
                
                   {/* <React.Fragment>   */}
   
                   { Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                      if (
                          (opinions[index].slaveID===opinions[index].leaderID)
                       && (opinions[index].type===4)
                       && (this.props.userdata.userID===opinions[index].leaderID) 
                      ) {
          
                        MaxSdate2++                       
                        return (MaxSdate2 === 1) ? (
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableHself } 
                            onClick={() => this.setState({shownRs: shownRs===opinions[index].leaderID ? 'closed' : opinions[index].leaderID })}>             
                              {shownRs===opinions[index].leaderID ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].simpleopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxSdate2 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownRs===this.props.userdata.userID ? tableHself : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (opinions[u].simpleopinion !== opinions[index-1].simpleopinion) 
                                   && (opinions[u].slaveID===opinions[u].leaderID)
                                   && (opinions[u].type===4)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                       {testDD(opinions[u].simple, convertDate(opinions[u].sdate))} {opinions[u].simpleopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                          
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownRs===this.props.userdata.userID ? tableHself : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave }/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }
                     
                      return null; // Return null for elements you don't want to render
                    })
                     
                   }
                      {/* </React.Fragment>     */}
                    {/* <MDBRow>
                     <MDBCol style={ shownRs===this.props.userdata.userID ? tableHself : tableHselfhide } >
                    <Combining/>
                    </MDBCol>
                    </MDBRow> */}
                   </div>

                    {/* /////////////////////////////////////////////////////////////////// */}
                    <React.Fragment>  

                       <MDBRow>
                       <MDBCol>
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>                     
 
                   <div style={headingA ? tableBlank : tableHselfhide}>
                    <MDBRow>
                    {opinions && headingA && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on {headingA}: </i>[{ this.state.valueA }]</b>
                    :null}
                    </MDBRow>    

                {(slaveIDarray.length > 0) && slaveIDarray.map((slaveID, i) => (                 
                    opinions &&
                    headingA &&                  
                    Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                    
                      if ((slaveIDarray[i] === opinions[index].slaveID) 
                      && (opinions[index].valueAopinion !== undefined) 
                      && (opinions[index].slaveID!==opinions[index].leaderID)
                      && (this.props.userdata.userID===opinions[index].leaderID)                    
                      && (opinions[index].slave!==11)
                      && (opinions[index].type===1)
                      ) {
                        console.log('MaxDate 1 : ', opinions)
                        console.log('MaxDate i : ', i)
                        console.log('MaxDate slaveIDarray : ', slaveIDarray[i])
                        
                      if (newLine !== slaveIDarray[i]) { MaxDate = 0 }
                        newLine = slaveIDarray[i]
                   
                        MaxDate++                 
                        return (MaxDate === 1) ? (                         
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableH } 
                            onClick={() => this.setState({shownA: shownA===slaveIDarray[i] ? 'closed' : slaveIDarray[i] })}>                                         
                              {shownA===slaveIDarray[i] ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueAopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownA===slaveIDarray[i] ? tableH : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (slaveIDarray[i] === opinions[u].slaveID) 
                                   && (opinions[u].valueAopinion !== undefined)                                    
                                   && (opinions[u].valueAopinion !== opinions[index-1].valueAopinion) 
                                   && (opinions[u].slaveID!==opinions[u].leaderID)
                                   && (opinions[u].slave!==11)      
                                   && (opinions[u].type===1)                         
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ?  
                                     ( <li key={opID}>
                                       {testDDxx(opinions[u].valueA, opinions[u], convertDate(opinions[u].sdate))}{opinions[u].valueAopinion + ' - ' + this.formatDate(opinions[u].sdate)}                                
                                      </li> )         
                                   :null         
                                  ) 
                                 }      
                              </ul>
                              {console.log('MaxDate : ', MaxDate)} 
                                                                 
                            </MDBCol>

                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownA===slaveIDarray[i] ? tableH : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()} /> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>

                          </MDBRow> 
                        
                          :null    
                        )                   
                      }                    
                      return null; // Return null for elements you don't want to render
                    })                           
                   )                                                              
                  )    
                
                  // <div style={ shownAs===this.props.userdata.userID ? tableHself : tableHselfhide } >                
                                
                 }
                   
             
                   { Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                      if (
                          (opinions[index].slaveID===opinions[index].leaderID)
                       && (opinions[index].slave===11)
                       && (opinions[index].type===3)
                       && (this.props.userdata.userID===opinions[index].leaderID) 
                      ) {
                        console.log('MaxDate 2 : ', MaxDate2)
          
                        MaxDate2++                       
                        return (MaxDate2 === 1) ? (
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableHself } 
                            onClick={() => this.setState({shownAs: shownAs===opinions[index].leaderID ? 'closed' : opinions[index].leaderID })}>             
                              {shownAs===opinions[index].leaderID ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueAopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate2 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownAs===this.props.userdata.userID ? tableHself : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (opinions[u].valueAopinion !== opinions[index-1].valueAopinion) 
                                   && (opinions[u].slaveID===opinions[u].leaderID)
                                   && (opinions[u].slave===11)
                                   && (opinions[u].type===3)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                       {testDDxx(opinions[u].valueA, opinions[u], convertDate(opinions[u].sdate))} {opinions[u].valueAopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>
                              {console.log('MaxDate2 : ', MaxDate2)}                            
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownAs===this.props.userdata.userID ? tableHself : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }
                     
                      return null; // Return null for elements you don't want to render
                    })
                   }
                      {/* </React.Fragment>     */}

                   </div>

                   {/* /////////////////////////////////////////////////////////////////// */}
                   <React.Fragment>  
                       <MDBRow>
                       <MDBCol>

                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>   

                     <div style={headingB ? tableBlank : tableHselfhide}>
                    <MDBRow>
                    {opinions && headingB && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on {headingB}: </i>[{ this.state.valueB }]</b>
                    :null}
                    </MDBRow>    

                {(slaveIDarray.length > 0) && slaveIDarray.map((slaveID, i) => (                 
                    opinions &&
                    headingB &&                  
                    Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                    
                      if ((slaveIDarray[i] === opinions[index].slaveID) 
                      && (opinions[index].valueBopinion !== undefined) 
                      && (opinions[index].slaveID!==opinions[index].leaderID)
                      && (this.props.userdata.userID===opinions[index].leaderID) 
                      && (opinions[index].slave!==11)
                      && (opinions[index].type===1)
                      ) {                     
                      if (newLine !== slaveIDarray[i]) { MaxDate3 = 0 }
                        newLine = slaveIDarray[i]
                   
                        MaxDate3++                 
                        return (MaxDate3 === 1) ? (                         
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableH } 
                            onClick={() => this.setState({shownB: shownB===slaveIDarray[i] ? 'closed' : slaveIDarray[i] })}>                                         
                              {shownB===slaveIDarray[i] ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueBopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate3 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownB===slaveIDarray[i] ? tableH : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (slaveIDarray[i] === opinions[u].slaveID) 
                                   && (opinions[u].valueBopinion !== undefined)                                    
                                   && (opinions[u].valueBopinion !== opinions[index-1].valueBopinion) 
                                   && (opinions[u].slaveID!==opinions[u].leaderID)
                                   && (opinions[u].slave!==11)
                                   && (opinions[u].type===1)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                       {testDDxx(opinions[u].valueB, opinions[u], convertDate(opinions[u].sdate))}  {opinions[u].valueBopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                         
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownB===slaveIDarray[i] ? tableH : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }                    
                      return null; // Return null for elements you don't want to render
                    })
                  ))
                  }
                
                   {/* <React.Fragment>   */}
   
                   { Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                      if (
                          (opinions[index].slaveID===opinions[index].leaderID)
                       && (opinions[index].slave===11)
                       && (opinions[index].type===3)
                       && (this.props.userdata.userID===opinions[index].leaderID) 
                      ) {
          
                        MaxDate4++                       
                        return (MaxDate4 === 1) ? (
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableHself } 
                            onClick={() => this.setState({shownBs: shownBs===opinions[index].leaderID ? 'closed' : opinions[index].leaderID })}>             
                              {shownBs===opinions[index].leaderID ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueBopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate4 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownBs===this.props.userdata.userID ? tableHself : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (opinions[u].valueBopinion !== opinions[index-1].valueBopinion) 
                                   && (opinions[u].slaveID===opinions[u].leaderID)
                                   && (opinions[u].slave===11)
                                   && (opinions[u].type===3)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                          {testDDxx(opinions[u].valueB, opinions[u], convertDate(opinions[u].sdate))} {opinions[u].valueBopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                         
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownBs===this.props.userdata.userID ? tableHself : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }
                     
                      return null; // Return null for elements you don't want to render
                    })
                   }
                      {/* </React.Fragment>     */}
                   </div> 
                   {/* /////////////////////////////////////////////////////////////////// */}
                   <React.Fragment>  
                       <MDBRow>
                       <MDBCol>    
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>   

                  <div style={headingC ? tableBlank : tableHselfhide}>
                  <MDBRow>
                  {opinions && headingC && Object.keys(opinions).length > 0 ?     
                    <b><i>List of Opinions on {headingC}: </i>[{ this.state.valueC }]</b>
                  :null}
                  </MDBRow>    

                {(slaveIDarray.length > 0) && slaveIDarray.map((slaveID, i) => (                 
                    opinions &&
                    headingC &&                  
                    Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                    
                      if ((slaveIDarray[i] === opinions[index].slaveID) 
                      && (opinions[index].valueCopinion !== undefined) 
                      && (opinions[index].slaveID!==opinions[index].leaderID)
                      && (this.props.userdata.userID===opinions[index].leaderID) 
                      && (opinions[index].slave!==11)
                      && (opinions[index].type===1)
                      ) {
                        
                      if (newLine !== slaveIDarray[i]) { MaxDate5 = 0 }
                        newLine = slaveIDarray[i]
                   
                        MaxDate5++                 
                        return (MaxDate5 === 1) ? (                         
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableH } 
                            onClick={() => this.setState({shownC: shownC===slaveIDarray[i] ? 'closed' : slaveIDarray[i] })}>                                         
                              {shownC===slaveIDarray[i] ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueCopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate5 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownC===slaveIDarray[i] ? tableH : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (slaveIDarray[i] === opinions[u].slaveID) 
                                   && (opinions[u].valueCopinion !== undefined)                                    
                                   && (opinions[u].valueCopinion !== opinions[index-1].valueCopinion) 
                                   && (opinions[u].slaveID!==opinions[u].leaderID)
                                   && (opinions[u].slave!==11)
                                   && (opinions[u].type===1)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                         {testDDxx(opinions[u].valueC, opinions[u], convertDate(opinions[u].sdate))} {opinions[u].valueCopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                           
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownC===slaveIDarray[i] ? tableH : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }                    
                      return null; // Return null for elements you don't want to render
                    })
                  ))
                  }
                  
                   { Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                      if (
                          (opinions[index].slaveID===opinions[index].leaderID)
                       && (opinions[index].slave===11)
                       && (opinions[index].type===3)
                       && (this.props.userdata.userID===opinions[index].leaderID) 
                      ) {
          
                        MaxDate6++                       
                        return (MaxDate6 === 1) ? (
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableHself } 
                            onClick={() => this.setState({shownCs: shownCs===opinions[index].leaderID ? 'closed' : opinions[index].leaderID })}>             
                              {shownCs===opinions[index].leaderID ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueCopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate6 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownCs===this.props.userdata.userID ? tableHself : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (opinions[u].valueCopinion !== opinions[index-1].valueCopinion) 
                                   && (opinions[u].slaveID===opinions[u].leaderID)
                                   && (opinions[u].slave===11)
                                   && (opinions[u].type===3)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                          {testDDxx(opinions[u].valueC, opinions[u], convertDate(opinions[u].sdate))} {opinions[u].valueCopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                          
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownCs===this.props.userdata.userID ? tableHself : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }
                     
                      return null; // Return null for elements you don't want to render
                    })
                   }
                      {/* </React.Fragment>     */}
                   </div>
                   {/* /////////////////////////////////////////////////////////////////// */}
                   <React.Fragment>  
                       <MDBRow>
                       <MDBCol>
        
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>  
    
                <div style={headingD ? tableBlank : tableHselfhide}>
                    <MDBRow>
                    {opinions && headingD && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on {headingD}: </i>[{ this.state.valueD }]</b>
                    :null}
                    </MDBRow>    

                {(slaveIDarray.length > 0) && slaveIDarray.map((slaveID, i) => (                 
                    opinions &&
                    headingD &&                  
                    Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                    
                      if ((slaveIDarray[i] === opinions[index].slaveID) 
                      && (opinions[index].valueDopinion !== undefined) 
                      && (opinions[index].slaveID!==opinions[index].leaderID)
                      && (this.props.userdata.userID===opinions[index].leaderID) 
                      && (opinions[index].slave!==11)
                      && (opinions[index].type===1)
                      ) {
                        
                      if (newLine !== slaveIDarray[i]) { MaxDate7 = 0 }
                        newLine = slaveIDarray[i]
                   
                        MaxDate7++                 
                        return (MaxDate7 === 1) ? (                         
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableH } 
                            onClick={() => this.setState({shownD: shownD===slaveIDarray[i] ? 'closed' : slaveIDarray[i] })}>                                         
                              {shownD===slaveIDarray[i] ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueDopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate7 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownD===slaveIDarray[i] ? tableH : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (slaveIDarray[i] === opinions[u].slaveID) 
                                   && (opinions[u].valueDopinion !== undefined)                                    
                                   && (opinions[u].valueDopinion !== opinions[index-1].valueDopinion) 
                                   && (opinions[u].slaveID!==opinions[u].leaderID)
                                   && (opinions[u].slave!==11)
                                   && (opinions[u].type===1)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                        {testDDxx(opinions[u].valueD, opinions[u], convertDate(opinions[u].sdate))} {opinions[u].valueDopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                          
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownD===slaveIDarray[i] ? tableH : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }                    
                      return null; // Return null for elements you don't want to render
                    })
                  ))
                  }
                
                   {/* <React.Fragment>   */}
   
                   { Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                      if (
                          (opinions[index].slaveID===opinions[index].leaderID)
                       && (opinions[index].slave===11)
                       && (opinions[index].type===3)
                       && (this.props.userdata.userID===opinions[index].leaderID) 
                      ) {
          
                        MaxDate8++                       
                        return (MaxDate8 === 1) ? (
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableHself } 
                            onClick={() => this.setState({shownDs: shownDs===opinions[index].leaderID ? 'closed' : opinions[index].leaderID })}>             
                              {shownDs===opinions[index].leaderID ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueDopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate8 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownDs===this.props.userdata.userID ? tableHself : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (opinions[u].valueDopinion !== opinions[index-1].valueDopinion) 
                                   && (opinions[u].slaveID===opinions[u].leaderID)
                                   && (opinions[u].slave===11)
                                   && (opinions[u].type===3)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                         {testDDxx(opinions[u].valueD, opinions[u], convertDate(opinions[u].sdate))} {opinions[u].valueDopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                          
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownDs===this.props.userdata.userID ? tableHself : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }
                     
                      return null; // Return null for elements you don't want to render
                    })
                   }
                      {/* </React.Fragment>     */}
                   </div>
                   {/* /////////////////////////////////////////////////////////////////// */}
                   <React.Fragment>  
                       <MDBRow>
                       <MDBCol>
        
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>   

              <div style={headingE ? tableBlank : tableHselfhide}>
                    <MDBRow>
                    {opinions && headingE && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Opinions on {headingE}: </i>[{ this.state.valueE }]</b>
                    :null}
                    </MDBRow>    

                {(slaveIDarray.length > 0) && slaveIDarray.map((slaveID, i) => (                 
                    opinions &&
                    headingE &&                  
                    Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                    
                      if ((slaveIDarray[i] === opinions[index].slaveID) 
                      && (opinions[index].valueEopinion !== undefined) 
                      && (opinions[index].slaveID!==opinions[index].leaderID)
                      && (this.props.userdata.userID===opinions[index].leaderID) 
                      && (opinions[index].slave!==11)
                      && (opinions[index].type===1)
                      ) {
                        
                      if (newLine !== slaveIDarray[i]) { MaxDate9 = 0 }
                        newLine = slaveIDarray[i]
                   
                        MaxDate9++                 
                        return (MaxDate9 === 1) ? (                         
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableH } 
                            onClick={() => this.setState({shownE: shownE===slaveIDarray[i] ? 'closed' : slaveIDarray[i] })}>                                         
                              {shownE===slaveIDarray[i] ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueAopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate9 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownE===slaveIDarray[i] ? tableH : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (slaveIDarray[i] === opinions[u].slaveID) 
                                   && (opinions[u].valueEopinion !== undefined)                                    
                                   && (opinions[u].valueEopinion !== opinions[index-1].valueEopinion) 
                                   && (opinions[u].slaveID!==opinions[u].leaderID)
                                   && (opinions[u].slave!==11)
                                   && (opinions[u].type===1)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                         {testDDxx(opinions[u].valueE, opinions[u], convertDate(opinions[u].sdate))} {opinions[u].valueEopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                           
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownE===slaveIDarray[i] ? tableH : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[] }{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }                    
                      return null; // Return null for elements you don't want to render
                    })
                  ))
                  }
                
                   {/* <React.Fragment>   */}
   
                   { Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                      if (
                          (opinions[index].slaveID===opinions[index].leaderID)
                       && (opinions[index].slave===11)
                       && (opinions[index].type===3)
                       && (this.props.userdata.userID===opinions[index].leaderID) 
                      ) {
          
                        MaxDate10++                       
                        return (MaxDate10 === 1) ? (
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableHself } 
                            onClick={() => this.setState({shownEs: shownEs===opinions[index].leaderID ? 'closed' : opinions[index].leaderID })}>             
                              {shownEs===opinions[index].leaderID ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueEopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate10 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownEs===this.props.userdata.userID ? tableHself : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (opinions[u].valueEopinion !== opinions[index-1].valueEopinion) 
                                   && (opinions[u].slaveID===opinions[u].leaderID)
                                   && (opinions[u].slave===11)
                                   && (opinions[u].type===3)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                        {testDDxx(opinions[u].valueE, opinions[u], convertDate(opinions[u].sdate))} {opinions[u].valueEopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                          
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownEs===this.props.userdata.userID ? tableHself : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }
                     
                      return null; // Return null for elements you don't want to render
                    })
                   }
                      {/* </React.Fragment>     */}
                   </div>
                   {/* /////////////////////////////////////////////////////////////////// */}
                   <React.Fragment>  
                       <MDBRow>
                       <MDBCol>
        
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>   

               <div style={headingF ? tableBlank : tableHselfhide}>
                <MDBRow>
                {opinions && headingF && Object.keys(opinions).length > 0 ?     
                  <b><i>List of Opinions on {headingF}: </i>[{ this.state.valueF }]</b>
                :null}
                </MDBRow>    

                {(slaveIDarray.length > 0) && slaveIDarray.map((slaveID, i) => (                 
                    opinions &&
                    headingF &&                  
                    Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                    
                      if ((slaveIDarray[i] === opinions[index].slaveID) 
                      && (opinions[index].valueFopinion !== undefined) 
                      && (opinions[index].slaveID!==opinions[index].leaderID)
                      && (this.props.userdata.userID===opinions[index].leaderID) 
                      && (opinions[index].slave!==11)
                      && (opinions[index].type===1)
                      ) {
                        
                      if (newLine !== slaveIDarray[i]) { MaxDate11 = 0 }
                        newLine = slaveIDarray[i]
                   
                        MaxDate11++                 
                        return (MaxDate11 === 1) ? (                         
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableH } 
                            onClick={() => this.setState({shownF: shownF===slaveIDarray[i] ? 'closed' : slaveIDarray[i] })}>                                         
                              {shownF===slaveIDarray[i] ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueFopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate11 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownF===slaveIDarray[i] ? tableH : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (slaveIDarray[i] === opinions[u].slaveID) 
                                   && (opinions[u].valueFopinion !== undefined)                                    
                                   && (opinions[u].valueFopinion !== opinions[index-1].valueFopinion) 
                                   && (opinions[u].slaveID!==opinions[u].leaderID)
                                   && (opinions[u].slave!==11)
                                   && (opinions[u].type===1)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                         {testDDxx(opinions[u].valueF, opinions[u], convertDate(opinions[u].sdate))} {opinions[u].valueFopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                          
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownF===slaveIDarray[i] ? tableH : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }                    
                      return null; // Return null for elements you don't want to render
                    })
                  ))
                  }
                
                   {/* <React.Fragment>   */}
   
                   { Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                      if (
                          (opinions[index].slaveID===opinions[index].leaderID)
                       && (opinions[index].slave===11)
                       && (opinions[index].type===3)
                       && (this.props.userdata.userID===opinions[index].leaderID) 
                      ) {
          
                        MaxDate12++                       
                        return (MaxDate12 === 1) ? (
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableHself } 
                            onClick={() => this.setState({shownAs: shownFs===opinions[index].leaderID ? 'closed' : opinions[index].leaderID })}>             
                              {shownFs===opinions[index].leaderID ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueFopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate12 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownFs===this.props.userdata.userID ? tableHself : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (opinions[u].valueFopinion !== opinions[index-1].valueFopinion) 
                                   && (opinions[u].slaveID===opinions[u].leaderID)
                                   && (opinions[u].slave===11)
                                   && (opinions[u].type===3)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                          {testDDxx(opinions[u].valueF, opinions[u], convertDate(opinions[u].sdate))} {opinions[u].valueFopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                           
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownFs===this.props.userdata.userID ? tableHself : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }
                     
                      return null; // Return null for elements you don't want to render
                    })
                   }
                      {/* </React.Fragment>     */}
                   </div>
                   {/* /////////////////////////////////////////////////////////////////// */}
                   <React.Fragment>  
                       <MDBRow>
                       <MDBCol>
        
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>   

             <div style={headingG ? tableBlank : tableHselfhide}>
              <MDBRow>
              {opinions && headingG && Object.keys(opinions).length > 0 ?     
                <b><i>List of Opinions on {headingG}: </i>[{ this.state.valueG }]</b>
              :null}
              </MDBRow>    

                {(slaveIDarray.length > 0) && slaveIDarray.map((slaveID, i) => (                 
                    opinions &&
                    headingG &&                  
                    Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                    
                      if ((slaveIDarray[i] === opinions[index].slaveID) 
                      && (opinions[index].valueGopinion !== undefined) 
                      && (opinions[index].slaveID!==opinions[index].leaderID)
                      && (this.props.userdata.userID===opinions[index].leaderID) 
                      && (opinions[index].slave!==11)
                      && (opinions[index].type===1)
                      ) {
                        
                      if (newLine !== slaveIDarray[i]) { MaxDate13 = 0 }
                        newLine = slaveIDarray[i]
                   
                        MaxDate13++                 
                        return (MaxDate13 === 1) ? (                         
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableH } 
                            onClick={() => this.setState({shownG: shownG===slaveIDarray[i] ? 'closed' : slaveIDarray[i] })}>                                         
                              {shownG===slaveIDarray[i] ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueGopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate13 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownG===slaveIDarray[i] ? tableH : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (slaveIDarray[i] === opinions[u].slaveID) 
                                   && (opinions[u].valueGopinion !== undefined)                                    
                                   && (opinions[u].valueGopinion !== opinions[index-1].valueGopinion) 
                                   && (opinions[u].slaveID!==opinions[u].leaderID)
                                   && (opinions[u].slave!==11)
                                   && (opinions[u].type===1)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                         {testDDxx(opinions[u].valueG, opinions[u], convertDate(opinions[u].sdate))} {opinions[u].valueGopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                           
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownG===slaveIDarray[i] ? tableH : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }                    
                      return null; // Return null for elements you don't want to render
                    })
                  ))
                  }
                
                   {/* <React.Fragment>   */}
   
                   { Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                      if (
                          (opinions[index].slaveID===opinions[index].leaderID)
                       && (opinions[index].slave===11)
                       && (opinions[index].type===3)
                       && (this.props.userdata.userID===opinions[index].leaderID) 
                      ) {
          
                        MaxDate14++                       
                        return (MaxDate14 === 1) ? (
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableHself } 
                            onClick={() => this.setState({shownGs: shownGs===opinions[index].leaderID ? 'closed' : opinions[index].leaderID })}>             
                              {shownGs===opinions[index].leaderID ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueGopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate14 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownGs===this.props.userdata.userID ? tableHself : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (opinions[u].valueGopinion !== opinions[index-1].valueGopinion) 
                                   && (opinions[u].slaveID===opinions[u].leaderID)
                                   && (opinions[u].slave===11)
                                   && (opinions[u].type===3)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                         {testDDxx(opinions[u].valueG, opinions[u].valueG, convertDate(opinions[u].sdate))} {opinions[u].valueGopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                           
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownGs===this.props.userdata.userID ? tableHself : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }
                     
                      return null; // Return null for elements you don't want to render
                    })
                   }
                      {/* </React.Fragment>     */}
                   </div>
                   {/* /////////////////////////////////////////////////////////////////// */}
                   <React.Fragment>  
                       <MDBRow>
                       <MDBCol>
        
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>   

            <div style={headingH ? tableBlank : tableHselfhide}>
              <MDBRow>
              {opinions && headingH && Object.keys(opinions).length > 0 ?     
                <b><i>List of Opinions on {headingH}: </i>[{ this.state.valueH }]</b>
              :null}
              </MDBRow>    

                {(slaveIDarray.length > 0) && slaveIDarray.map((slaveID, i) => (                 
                    opinions &&
                    headingH &&                  
                    Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                    
                      if ((slaveIDarray[i] === opinions[index].slaveID) 
                      && (opinions[index].valueHopinion !== undefined) 
                      && (opinions[index].slaveID!==opinions[index].leaderID)
                      && (this.props.userdata.userID===opinions[index].leaderID) 
                      && (opinions[index].slave!==11)
                      && (opinions[index].type===1)
                      ) {
                       
                      if (newLine !== slaveIDarray[i]) { MaxDate15 = 0 }
                        newLine = slaveIDarray[i]
                   
                        MaxDate15++                 
                        return (MaxDate15 === 1) ? (                         
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableH } 
                            onClick={() => this.setState({shownH: shownH===slaveIDarray[i] ? 'closed' : slaveIDarray[i] })}>                                         
                              {shownH===slaveIDarray[i] ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueHopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate15 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownH===slaveIDarray[i] ? tableH : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (slaveIDarray[i] === opinions[u].slaveID) 
                                   && (opinions[u].valueHopinion !== undefined)                                    
                                   && (opinions[u].valueHopinion !== opinions[index-1].valueHopinion) 
                                   && (opinions[u].slaveID!==opinions[u].leaderID)
                                   && (opinions[u].slave!==11)
                                   && (opinions[u].type===1)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                         {testDDxx(opinions[u].valueH, opinions[u], convertDate(opinions[u].sdate))} {opinions[u].valueHopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                           
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownH===slaveIDarray[i] ? tableH : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }                    
                      return null; // Return null for elements you don't want to render
                    })
                  ))
                  }
                
                   {/* <React.Fragment>   */}
   
                   { Object.keys(opinions).map((opinionsID, index) => { //get the first evaluation from this leaderID
                      if (
                          (opinions[index].slaveID===opinions[index].leaderID)
                       && (opinions[index].slave===11)
                       && (opinions[index].type===3)
                       && (this.props.userdata.userID===opinions[index].leaderID) 
                      ) {
          
                        MaxDate16++                       
                        return (MaxDate16 === 1) ? (
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ tableHself } 
                            onClick={() => this.setState({shownHs: shownHs===opinions[index].leaderID ? 'closed' : opinions[index].leaderID })}>             
                              {shownHs===opinions[index].leaderID ? <OpenIcon /> : <CloseIcon />}&nbsp;&nbsp;
                              {opinions[index].valueHopinion + ' - ' + this.formatDate(opinions[index].sdate)}
                            </MDBCol>
                          </MDBRow>
                        ) : (  
                          MaxDate16 === 2 ?   //get ALL the older evaluations on this leaderID                  
                          <MDBRow key={opinionsID}>
                            <MDBCol style={ shownHs===this.props.userdata.userID ? tableHself : tableHselfhide } >
                              <ul>                                                      
                                { opinions &&
                                  Object.keys(opinions).map((opID, u) =>                            
                                   (                                
                                    (opinions[u].valueHopinion !== opinions[index-1].valueHopinion) 
                                   && (opinions[u].slaveID===opinions[u].leaderID)
                                   && (opinions[u].slave===11)
                                   && (opinions[u].type===3)
                                   && (this.props.userdata.userID===opinions[u].leaderID)) ? ( 
                                      <li key={opID}>
                                         {testDDxx(opinions[u].valueH, opinions[u], convertDate(opinions[u].sdate))}  {opinions[u].valueHopinion + ' - ' + this.formatDate(opinions[u].sdate)}
                                      </li>                       
                                   ):null     
                                  ) 
                                 }
                                   
                              </ul>                           
                            </MDBCol>
                            <MDBRow> 
                            </MDBRow> 
                            <MDBCol style={ shownHs===this.props.userdata.userID ? tableHself : tableHselfhide } >                           
                            <Combining scores={scores.reverse()} dates={dates.reverse()} ave={ave.reverse()}/> 
                            {/* { scores=[]} */}
                            {/* { dates=[]} */}
                            <div style={tableHselfhide }>{ scores=[]}{ ave=[]}{ dates=[]} </div>
                            {/* { dates.length = 0 } */}
                            </MDBCol>
                          </MDBRow> 
                          :null    
                        )                   
                      }
                     
                      return null; // Return null for elements you don't want to render
                    })
                   }
                      {/* </React.Fragment>     */}
                   </div>
                   {/* /////////////////////////////////////////////////////////////////// */}
                    <React.Fragment>  
                       <MDBRow>
                       <MDBCol>
                   
                      </MDBCol>
                      </MDBRow>  
                      <MDBRow>
                       <MDBCol>         
                        &nbsp; &nbsp; 
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>   

               {/* ////////////////////////////////////////////////////////////////////////// */}
               <MDBRow>  
                 <MDBCol style={tableYi} colspan={this.state.slaveparticipants}>
                   <b>Participant Categories</b>
                 </MDBCol> 
                </MDBRow> 
              <MDBRow>
                <MDBCol>
                  <ol>
                      <li style={headingA ? tableY: tableMi} >{headingA}</li>
                      <li style={headingB ? tableY: tableMi} >{headingB}</li>
                      <li style={headingC ? tableY: tableMi} >{headingC}</li>
                      <li style={headingD ? tableY: tableMi} >{headingD}</li>
                      <li style={headingE ? tableY: tableMi} >{headingE}</li>
                      <li style={headingF ? tableY: tableMi} >{headingF}</li>
                      <li style={headingG ? tableY: tableMi} >{headingG}</li>
                      <li style={headingH ? tableY: tableMi} >{headingH}</li>
                  </ol>
                 </MDBCol> 
                </MDBRow>
                   <MDBRow>   
                     <b><i>Statistics Breakdown</i></b>
                    </MDBRow>  

                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                       <b>Participant Categories ({this.state.slaveparticipants})</b>
                      </MDBCol>   
                      <MDBCol style={tableXi} >
                       <b>Nr</b>
                      </MDBCol> 

                      {/* <MDBCol style={headingA ? tableX: tableMi} >
                       <b>{headingA}</b>
                      </MDBCol> 
                      <MDBCol style={headingB ? tableX: tableMi} >
                       <b>{headingB}</b>
                      </MDBCol> 
                      <MDBCol style={headingC ? tableX: tableMi} >
                       <b>{headingC}</b>
                      </MDBCol> 
                      <MDBCol style={headingD ? tableX: tableMi} >
                       <b>{headingD}</b>
                      </MDBCol> 
                      <MDBCol style={headingE ? tableX: tableMi} >
                       <b>{headingE}</b>
                      </MDBCol> 
                      <MDBCol style={headingF ? tableX: tableMi} >
                       <b>{headingF}</b>
                      </MDBCol> 
                      <MDBCol style={headingG ? tableX: tableMi} >
                       <b>{headingG}</b>
                      </MDBCol> 
                      <MDBCol style={headingH ? tableX: tableMi} >
                       <b>{headingH}</b>
                      </MDBCol>  */}

                      <MDBCol style={headingA ? tableX: tableMi} >
                       <b>(1)</b>
                      </MDBCol> 
                      <MDBCol style={headingB ? tableX: tableMi} >
                       <b>(2)</b>
                      </MDBCol> 
                      <MDBCol style={headingC ? tableX: tableMi} >
                      <b>(3)</b>
                      </MDBCol> 
                      <MDBCol style={headingD ? tableX: tableMi} >
                      <b>(4)</b>
                      </MDBCol> 
                      <MDBCol style={headingE ? tableX: tableMi} >
                      <b>(5)</b>
                      </MDBCol> 
                      <MDBCol style={headingF ? tableX: tableMi} >
                      <b>(6)</b>
                      </MDBCol> 
                      <MDBCol style={headingG ? tableX: tableMi} >
                      <b>(7)</b>
                      </MDBCol> 
                      <MDBCol style={headingH ? tableX: tableMi} >
                      <b>(8)</b>
                      </MDBCol> 

                      <MDBCol style={tableXi} >
                       <b>Tot %</b>
                      </MDBCol>                                         
                    </MDBRow> 



                    <MDBRow> 
                      <MDBCol style={tableWh} colspan='2' size="3">
                      I report to the leader directly
                      </MDBCol>   
                      <MDBCol style={tableWj} >
                       {this.state.one_count}
                      </MDBCol> 


                      <MDBCol style={headingA ? tableW: tableWi} >
                       {this.roundOff(this.state.one_arr[0]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={headingB ? tableW: tableWi} >
                       {this.roundOff(this.state.one_arr[1]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={headingC ? tableW: tableWi} >
                       {this.roundOff(this.state.one_arr[2]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={headingD ? tableW: tableWi} >
                       {this.roundOff(this.state.one_arr[3]/this.state.one_count) }
                      </MDBCol>  
                      <MDBCol style={headingE ? tableW: tableWi} >
                       {this.roundOff(this.state.one_arr[4]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={headingF ? tableW: tableWi} >
                       {this.roundOff(this.state.one_arr[5]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={headingG ? tableW: tableWi} >
                       {this.roundOff(this.state.one_arr[6]/this.state.one_count) }
                      </MDBCol> 
                      <MDBCol style={headingH ? tableW: tableWi} >
                       {this.roundOff(this.state.one_arr[7]/this.state.one_count) }
                      </MDBCol> 

                      <MDBCol style={tableWj} >
                       {this.roundOff((this.state.one_count / this.state.slaveparticipants) * 100) }
                      </MDBCol>                      

                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                      The leader reports to me
                      </MDBCol>   
                      <MDBCol style={tableXi} >
                       {this.state.two_count}
                      </MDBCol>  
                       
                      <MDBCol style={headingA ? tableX: tableMi} >
                       {this.roundOff(this.state.two_arr[0]/this.state.two_count) }
                      </MDBCol> 
                      <MDBCol style={headingB ? tableX: tableMi} >
                       {this.roundOff(this.state.two_arr[1]/this.state.two_count) }
                      </MDBCol> 
                      <MDBCol style={headingC ? tableX: tableMi} >
                       {this.roundOff(this.state.two_arr[2]/this.state.two_count) }
                      </MDBCol> 
                      <MDBCol style={headingD ? tableX: tableMi} >
                       {this.roundOff(this.state.two_arr[3]/this.state.two_count) }
                      </MDBCol>                      
                      <MDBCol style={headingE ? tableX: tableMi} >
                       {this.roundOff(this.state.two_arr[4]/this.state.two_count) }
                      </MDBCol>  
                      <MDBCol style={headingF ? tableX: tableMi} >
                       {this.roundOff(this.state.two_arr[5]/this.state.two_count) }
                      </MDBCol>  
                      <MDBCol style={headingG ? tableX: tableMi} >
                       {this.roundOff(this.state.two_arr[6]/this.state.two_count) }
                      </MDBCol>  
                      <MDBCol style={headingH ? tableX: tableMi} >
                       {this.roundOff(this.state.two_arr[7]/this.state.two_count) }
                      </MDBCol>  

                      <MDBCol style={tableXi} >
                       {this.roundOff((this.state.two_count / this.state.slaveparticipants) * 100) }
                      </MDBCol> 
                     </MDBRow> 
                     <MDBRow> 
                      <MDBCol style={tableWh} colspan='2' size="3">
                      I work with the leader as a peer, colleague
                      </MDBCol>   
                      <MDBCol style={tableWj} >
                       {this.state.three_count}
                      </MDBCol>    
                       

                      <MDBCol style={headingA ? tableW: tableWi} >
                       {this.roundOff(this.state.three_arr[0]/this.state.three_count) }
                      </MDBCol> 
                      <MDBCol style={headingB ? tableW: tableWi} >
                       {this.roundOff(this.state.three_arr[1]/this.state.three_count) }
                      </MDBCol> 
                      <MDBCol style={headingC ? tableW: tableWi} >
                       {this.roundOff(this.state.three_arr[2]/this.state.three_count) }
                      </MDBCol> 
                      <MDBCol style={headingD ? tableW: tableWi} >
                       {this.roundOff(this.state.three_arr[3]/this.state.three_count)}
                      </MDBCol> 
                      <MDBCol style={headingE ? tableW: tableWi} >
                       {this.roundOff(this.state.three_arr[4]/this.state.three_count)}
                      </MDBCol> 
                      <MDBCol style={headingF ? tableW: tableWi} >
                       {this.roundOff(this.state.three_arr[5]/this.state.three_count)}
                      </MDBCol> 
                      <MDBCol style={headingG ? tableW: tableWi} >
                       {this.roundOff(this.state.three_arr[6]/this.state.three_count)}
                      </MDBCol> 
                      <MDBCol style={headingH ? tableW: tableWi} >
                       {this.roundOff(this.state.three_arr[7]/this.state.three_count)}
                      </MDBCol> 

                      <MDBCol style={tableWj} >
                       {this.roundOff((this.state.three_count / this.state.slaveparticipants) * 100) } 
                      </MDBCol> 
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                      I am an employee/member of the organisation
                      </MDBCol>   
                      <MDBCol style={tableXi} >
                       {this.state.four_count}
                      </MDBCol> 
                      

                      <MDBCol style={headingA ? tableX: tableMi} >
                       {this.roundOff(this.state.four_arr[0]/this.state.four_count) }
                      </MDBCol> 
                      <MDBCol style={headingB ? tableX: tableMi} >
                       {this.roundOff(this.state.four_arr[1]/this.state.four_count) }
                      </MDBCol> 
                      <MDBCol style={headingC ? tableX: tableMi} >
                       {this.roundOff(this.state.four_arr[2]/this.state.four_count) }
                      </MDBCol> 
                      <MDBCol style={headingD ? tableX: tableMi} >
                       {this.roundOff(this.state.four_arr[3]/this.state.four_count)}
                      </MDBCol> 
                      <MDBCol style={headingE ? tableX: tableMi} >
                       {this.roundOff(this.state.four_arr[4]/this.state.four_count)}
                      </MDBCol> 
                      <MDBCol style={headingF ? tableX: tableMi} >
                       {this.roundOff(this.state.four_arr[5]/this.state.four_count)}
                      </MDBCol> 
                      <MDBCol style={headingG ? tableX: tableMi} >
                       {this.roundOff(this.state.four_arr[6]/this.state.four_count)}
                      </MDBCol> 
                      <MDBCol style={headingH ? tableX: tableMi} >
                       {this.roundOff(this.state.four_arr[7]/this.state.four_count)}
                      </MDBCol> 

                      <MDBCol style={tableXi} >
                       {this.roundOff((this.state.four_count / this.state.slaveparticipants) * 100) }
                      </MDBCol>  
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableWh} colspan='2' size="3">
                      I am merely a concerned citizen / distant shareholder
                      </MDBCol>   
                      <MDBCol style={tableWj} >
                       {this.state.five_count}
                      </MDBCol>  
                        

                      <MDBCol style={headingA ? tableW: tableWi} >
                       {this.roundOff(this.state.five_arr[0]/this.state.five_count) }
                      </MDBCol> 
                      <MDBCol style={headingB ? tableW: tableWi} >
                       {this.roundOff(this.state.five_arr[1]/this.state.five_count) }
                      </MDBCol> 
                      <MDBCol style={headingC ? tableW: tableWi} >
                       {this.roundOff(this.state.five_arr[2]/this.state.five_count) }
                      </MDBCol> 
                      <MDBCol style={headingD ? tableW: tableWi} >
                       {this.roundOff(this.state.five_arr[3]/this.state.five_count)}
                      </MDBCol>                       
                      <MDBCol style={headingE ? tableW: tableWi} >
                       {this.roundOff(this.state.five_arr[4]/this.state.five_count)}
                      </MDBCol> 
                      <MDBCol style={headingF ? tableW: tableWi} >
                       {this.roundOff(this.state.five_arr[5]/this.state.five_count)}
                      </MDBCol> 
                      <MDBCol style={headingG ? tableW: tableWi} >
                       {this.roundOff(this.state.five_arr[6]/this.state.five_count)}
                      </MDBCol> 
                      <MDBCol style={headingH ? tableW: tableWi} >
                       {this.roundOff(this.state.five_arr[7]/this.state.five_count)}
                      </MDBCol> 

                      <MDBCol style={tableWj} >
                       {this.roundOff((this.state.five_count / this.state.slaveparticipants) * 100) } 
                      </MDBCol> 
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                      I am an outside supplier/client to this organisation that the leader leads
                      </MDBCol>   
                      <MDBCol style={tableXi} >
                       {this.state.six_count}
                      </MDBCol>
                        

                      <MDBCol style={headingA ? tableX: tableMi} >
                       {this.roundOff(this.state.six_arr[0]/this.state.six_count) }
                      </MDBCol> 
                      <MDBCol style={headingB ? tableX: tableMi} >
                       {this.roundOff(this.state.six_arr[1]/this.state.six_count) }
                      </MDBCol> 
                      <MDBCol style={headingC ? tableX: tableMi} >
                       {this.roundOff(this.state.six_arr[2]/this.state.six_count) }
                      </MDBCol> 
                      <MDBCol style={headingD ? tableX: tableMi} >
                       {this.roundOff(this.state.six_arr[3]/this.state.six_count)}
                      </MDBCol>      
                      <MDBCol style={headingE ? tableX: tableMi} >
                       {this.roundOff(this.state.six_arr[4]/this.state.six_count)}
                      </MDBCol> 
                      <MDBCol style={headingF ? tableX: tableMi} >
                       {this.roundOff(this.state.six_arr[5]/this.state.six_count)}
                      </MDBCol> 
                      <MDBCol style={headingG ? tableX: tableMi} >
                       {this.roundOff(this.state.six_arr[6]/this.state.six_count)}
                      </MDBCol> 
                      <MDBCol style={headingH ? tableX: tableMi} >
                       {this.roundOff(this.state.six_arr[7]/this.state.six_count)}
                      </MDBCol> 

                      <MDBCol style={tableXi} >
                       {this.roundOff((this.state.six_count / this.state.slaveparticipants) * 100) } 
                      </MDBCol> 

                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableWh} colspan='2' size="3">
                      I work with the leader from an outside community
                      </MDBCol>   
                      <MDBCol style={tableWj} >
                       {this.state.seven_count}
                      </MDBCol>  

                      <MDBCol style={headingA ? tableW: tableWi} >
                       {this.roundOff(this.state.seven_arr[0]/this.state.seven_count) }
                      </MDBCol> 
                      <MDBCol style={headingB ? tableW: tableWi} >
                       {this.roundOff(this.state.seven_arr[1]/this.state.seven_count) }
                      </MDBCol> 
                      <MDBCol style={headingC ? tableW: tableWi} >
                       {this.roundOff(this.state.seven_arr[2]/this.state.seven_count) }
                      </MDBCol> 
                      <MDBCol style={headingD ? tableW: tableWi} >
                       {this.roundOff(this.state.seven_arr[3]/this.state.seven_count)}
                      </MDBCol>  
                      <MDBCol style={headingE ? tableW: tableWi} >
                       {this.roundOff(this.state.seven_arr[4]/this.state.seven_count)}
                      </MDBCol>  
                      <MDBCol style={headingF ? tableW: tableWi} >
                       {this.roundOff(this.state.seven_arr[5]/this.state.seven_count)}
                      </MDBCol>  
                      <MDBCol style={headingG ? tableW: tableWi} >
                       {this.roundOff(this.state.seven_arr[6]/this.state.seven_count)}
                      </MDBCol>  
                      <MDBCol style={headingH ? tableW: tableWi} >
                       {this.roundOff(this.state.seven_arr[7]/this.state.seven_count)}
                      </MDBCol>  

                      <MDBCol style={tableWj} >
                       {this.roundOff((this.state.seven_count / this.state.slaveparticipants) * 100) } 
                      </MDBCol>  
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                      The leader is my direct coach/captain
                      </MDBCol>   
                      <MDBCol style={tableXi} >
                       {this.state.eight_count}
                      </MDBCol> 
                        

                      <MDBCol style={headingA ? tableX: tableMi} >
                       {this.roundOff(this.state.eight_arr[0]/this.state.eight_count) }
                      </MDBCol> 
                      <MDBCol style={headingB ? tableX: tableMi} >
                       {this.roundOff(this.state.eight_arr[1]/this.state.eight_count) }
                      </MDBCol> 
                      <MDBCol style={headingC ? tableX: tableMi} >
                       {this.roundOff(this.state.eight_arr[2]/this.state.eight_count) }
                      </MDBCol> 
                      <MDBCol style={headingD ? tableX: tableMi} >
                       {this.roundOff(this.state.eight_arr[3]/this.state.eight_count)}
                      </MDBCol>  
                      <MDBCol style={headingE ? tableX: tableMi} >
                       {this.roundOff(this.state.eight_arr[4]/this.state.eight_count)}
                      </MDBCol>  
                      <MDBCol style={headingF ? tableX: tableMi} >
                       {this.roundOff(this.state.eight_arr[5]/this.state.eight_count)}
                      </MDBCol>  
                      <MDBCol style={headingG ? tableX: tableMi} >
                       {this.roundOff(this.state.eight_arr[6]/this.state.eight_count)}
                      </MDBCol>  
                      <MDBCol style={headingH ? tableX: tableMi} >
                       {this.roundOff(this.state.eight_arr[7]/this.state.eight_count)}
                      </MDBCol>  

                      <MDBCol style={tableXi} >
                       {this.roundOff((this.state.eight_count / this.state.slaveparticipants) * 100) } 
                      </MDBCol>  
                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableWh} colspan='2' size="3">
                      I am an active student of the leaders work
                      </MDBCol>   
                      <MDBCol style={tableWj} >
                       {this.state.nine_count}
                      </MDBCol>  
                        

                      <MDBCol style={headingA ? tableW: tableWi} >
                       {this.roundOff(this.state.nine_arr[0]/this.state.nine_count) }
                      </MDBCol> 
                      <MDBCol style={headingB ? tableW: tableWi} >
                       {this.roundOff(this.state.nine_arr[1]/this.state.nine_count) }
                      </MDBCol> 
                      <MDBCol style={headingC ? tableW: tableWi} >
                       {this.roundOff(this.state.nine_arr[2]/this.state.nine_count) }
                      </MDBCol> 
                      <MDBCol style={headingD ? tableW: tableWi} >
                       {this.roundOff(this.state.nine_arr[3]/this.state.nine_count)}
                      </MDBCol>  
                      <MDBCol style={headingE ? tableW: tableWi} >
                       {this.roundOff(this.state.nine_arr[4]/this.state.nine_count)}
                      </MDBCol>
                      <MDBCol style={headingF ? tableW: tableWi} >
                       {this.roundOff(this.state.nine_arr[5]/this.state.nine_count)}
                      </MDBCol>
                      <MDBCol style={headingG ? tableW: tableWi} >
                       {this.roundOff(this.state.nine_arr[6]/this.state.nine_count)}
                      </MDBCol>
                      <MDBCol style={headingH ? tableW: tableWi} >
                       {this.roundOff(this.state.nine_arr[7]/this.state.nine_count)}
                      </MDBCol>

                      <MDBCol style={tableWj} >
                       {this.roundOff((this.state.nine_count / this.state.slaveparticipants) * 100) } 
                      </MDBCol> 

                    </MDBRow> 
                    <MDBRow> 
                      <MDBCol style={tableMhh} colspan='2' size="3">
                      Other
                      </MDBCol>   
                      <MDBCol style={tableXi} >
                       {this.state.ten_count}
                      </MDBCol> 
                      

                      <MDBCol style={headingA ? tableX: tableMi} >
                       {this.roundOff(this.state.ten_arr[0]/this.state.ten_count) }
                      </MDBCol> 
                      <MDBCol style={headingB ? tableX: tableMi} >
                       {this.roundOff(this.state.ten_arr[1]/this.state.ten_count) }
                      </MDBCol> 
                      <MDBCol style={headingC ? tableX: tableMi} >
                       {this.roundOff(this.state.ten_arr[2]/this.state.ten_count) }
                      </MDBCol> 
                      <MDBCol style={headingD ? tableX: tableMi} >
                       {this.roundOff(this.state.ten_arr[3]/this.state.ten_count)}
                      </MDBCol>  
                      <MDBCol style={headingE ? tableX: tableMi} >
                       {this.roundOff(this.state.ten_arr[4]/this.state.ten_count)}
                      </MDBCol> 
                      <MDBCol style={headingF ? tableX: tableMi} >
                       {this.roundOff(this.state.ten_arr[5]/this.state.ten_count)}
                      </MDBCol> 
                      <MDBCol style={headingG ? tableX: tableMi} >
                       {this.roundOff(this.state.ten_arr[6]/this.state.ten_count)}
                      </MDBCol> 
                      <MDBCol style={headingH ? tableX: tableMi} >
                       {this.roundOff(this.state.ten_arr[7]/this.state.ten_count)}
                      </MDBCol> 

                      <MDBCol style={tableXi} >
                       {this.roundOff((this.state.ten_count / this.state.slaveparticipants) * 100) } 
                      </MDBCol>  
                    </MDBRow> 
                    <MDBRow> 
                    </MDBRow> 
                    <MDBRow> 
                    &nbsp;&nbsp;
                    </MDBRow> 
                    {/* <MDBRow> 
                    <MDBCol style={{
                      display: this.state.showing,
                        color: "#B69C26",
                        bgColor: "#cccccc",
                        fontSize: 14,
                        align: 'left',
                        textAlign: 'left',
                        marginLeft: '20px',
                        alignItems: 'left',
                        justifyContent: 'left',
                        alignContent: 'left',

                        //display: 'flex',
                        alignContent: 'left',
                        border: 1,
                        padding: 1,
                        borderRadius: 2,
                        fontweight: 'bold',
                        backgroundColor: '#ffffff',
                        width: isMobile ? '90%' : '90%',
                        maxWidth: isMobile ? '90%' : '90%'}} 
                        colspan='2' 
                        // onClick={() => this.betaDesc()}
                        >
                      
                        Welcome to the cutting edge of innovation within Me-Vision Academy. 
                        As we introduce Aeon, your confidence Sage, we invite you to join us on this 
                        extraordinary journey of refinement and growth. We acknowledge that as we push 
                        the boundaries of technology and interpret our platform with AI, there may be 
                        occasional glitches. However, we embrace these challenges as opportunities for 
                        improvement and invite you to participate in shaping this incredible offering. 
                        Your involvement will not only allow us to fine-tune our system but also 
                        demonstrate your commitment to innovation and your willingness to explore 
                        uncharted territories. Together, let's embrace the risks and pave the way for 
                        an even more exceptional Me-Vision Academy experience.
                        
                    </MDBCol> 
                    </MDBRow>  */}
                    <MDBRow> 
                    <MDBCol style={tableCcc} colspan='2'>
                    &nbsp;&nbsp;
                    </MDBCol> 
                    </MDBRow> 
                    <MDBRow> 
                    <MDBCol style={tableCc} >
                      <Avatar
                        className={classes.bigAvatar}
                        aria-label='Photo'
                        src={wiseman}
                        // sx={{ width: '38px', height: '38px' }}
                      />
                    </MDBCol>   
                    <MDBCol style={tableSage} onClick={() => this.betaDesc()}>   
                     <b><i>Consult Aeon - Your Confidence Sage </i></b>
                     <span 
                    style={{
                      fontSize: 7, 
                      verticalAlign: isMobile ? 'top': 'bottom'}}
                      >beta</span>
                    </MDBCol>  
                    </MDBRow> 

                    <MDBRow> 
                    <MDBCol style={{
                      display: this.state.showing,
                        color: "#B69C26",
                        bgColor: "#cccccc",
                        fontSize: 14,
                        align: 'left',
                        textAlign: 'left',
                        marginLeft: '20px',
                        alignItems: 'left',
                        justifyContent: 'left',
                        alignContent: 'left',

                        //display: 'flex',
                        alignContent: 'left',
                        border: 1,
                        padding: 1,
                        borderRadius: 2,
                        fontweight: 'bold',
                        backgroundColor: '#ffffff',
                        width: isMobile ? '90%' : '90%',
                        maxWidth: isMobile ? '90%' : '90%'}} 
                        colspan='2' 
                        >
                      
                        Welcome to the cutting edge of innovation within Me-Vision Academy. 
                        As we introduce Aeon, your confidence Sage, we invite you to join us on this 
                        extraordinary journey of refinement and growth. We acknowledge that as we push 
                        the boundaries of technology and interpret our platform with AI, there may be 
                        occasional glitches. However, we embrace these challenges as opportunities for 
                        improvement and invite you to participate in shaping this incredible offering. 
                        Your involvement will not only allow us to fine-tune our system but also 
                        demonstrate your commitment to innovation and your willingness to explore 
                        uncharted territories. Together, let's embrace the risks and pave the way for 
                        an even more exceptional Me-Vision Academy experience.
                        
                    </MDBCol> 
                    </MDBRow> 

                    <MDBRow>   
                    &nbsp;&nbsp;
                    </MDBRow>  
                <MDBRow> 
                <MDBCol style={tableAI} >


{/* Portfolio of Evidence questions:
Based on data captured in my Portfolio of Evidence, what kind of leader am I likely becoming? 
If what value could I be to my current or any future organisation? */}
          {console.log('chatgptthis.state.allopinions : ', this.state.allopinions)}
          
               {/* <ChatGPT
                ask={this.state.alist + 'Based on these tasks that I am focusing on every day, what does it indicate about me as a leader?'} 
                email={this.state.myemail}
                formalQuestion={'Based on these tasks that I am focusing on every day, what does it indicate about me as a leader?'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                /> */}
                {/* <ChatGPT
                ask={'If my colleagues say that I am lazy and angry, what does it indicate about me as a leader?'} 
                email={this.state.myemail}
                formalQuestion={'If my colleagues say that I am lazy and angry, what does it indicate about me as a leader?'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                /> */}
                <ChatGPT
                ask={this.state.allopinions + 'Based on these opinions, what does it indicate about me as a leader?'} 
                email={this.state.myemail}
                formalQuestion={'Based on my real-time feedback from colleagues, what does it indicate about me as a leader?'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'What development areas would you recommend, in order to move towards my Best Self?'} 
                email={this.state.myemail}
                formalQuestion={'What development areas would you recommend, in order to move towards my Best Self?'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'What are my key strengths based on real-time feedback? '} 
                email={this.state.myemail}
                formalQuestion={'What are my key strengths based on real-time feedback? '}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'How would you suggest I leverage my strengths to become the best leader I can be?'} 
                email={this.state.myemail}
                formalQuestion = {'How would you suggest I leverage my strengths to become the best leader I can be?'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'Please suggest a most effective development path going forward?'} 
                email={this.state.myemail}
                formalQuestion = {'Please suggest a most effective development path going forward?'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'Based on recommendations for a development path, what would be some feasible SMART Goals I could consider?'} 
                email={this.state.myemail}
                formalQuestion = {'Based on recommendations for a development path, what would be some feasible SMART Goals I could consider?'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'Please suggest specific online certificates and diplomas I can participate in, preferably for free.'} 
                email={this.state.myemail}
                formalQuestion = {'Please suggest specific online certificates and diplomas I can participate in, preferably for free.'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'Please suggest relevant podcasts, videos and articles out there that would enhance my real-time journey.'} 
                email={this.state.myemail}
                formalQuestion = {'Please suggest relevant podcasts, videos and articles out there that would enhance my real-time journey.'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
                />
                <ChatGPT
                ask={this.state.allopinions + 'What value could I be to my current or any future organisation?'} 
                email={this.state.myemail}
                formalQuestion = {'What value could I be to my current or any future organisation?'}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
               />
                </MDBCol>  
                </MDBRow> 
                <MDBRow> 
               <MDBCol>
               &nbsp; &nbsp; 
               </MDBCol> 
               </MDBRow> 

                <MDBRow> 
                <MDBCol style={tableSage}>   
                     <b><i>Type your question to Aeon: </i></b>
                </MDBCol>  
               </MDBRow> 
                
                <MDBRow> 
               <MDBCol>  

               <TextField
                  //label='Type your question to Aeon:'
                  variant='outlined'
                  multiline
                  key="Type your question to Aeon:"
                 // disableUnderline
                 // id="fullWidth"
                  id="Type your question to Aeon:"
                 // id="demo-helper-text-aligned-no-helper"
                  fullWidth
                  rows={2}
                  //InputLabelProps={{ shrink: true }}
                  //inputProps={{ maxLength: 280 }}
                  aria-describedby='thought'
                  value={this.state.gtpquestion}
                  helperText={''} //{this.state.maxLengthThought}
                  //className={classes.textField}
                  onChange={e => this.handleChange(e, 'Type your question to Aeon:')}
                  inputProps={{
                    className: classes.textField,
                  }}
                  //className={classes.root}
                  //margin='normal'
                  InputLabelProps={{
                    shrink: true,
                    className: classes.textFieldLabel
                  }}
                />
               <ChatGPTfree
                ask={this.state.allopinions + this.state.gtpquestion} 
                //showFreeQ={this.state.showFreeQ}
                clearQuestion={this.clearQuestion}
                email={this.state.myemail}
                formalQuestion={this.state.gtpquestion}
                photoURL={wiseman}
                lastResp={this.lastResp}
                lastAnswer={this.state.lastAnswer}
               />
               </MDBCol>  
                </MDBRow> 
                
                 <MDBRow> 
                 <MDBCol>
                 &nbsp; &nbsp; 
                 {/* <ChatGPTHistory
                    ask={'Show the history of my questions.'} 
                    //showFreeQ={this.state.showFreeQ}
                    clearQuestion={this.clearQuestion}
                    email={this.state.myemail}
                    formalQuestion={'Show the history of my questions.'}
                    photoURL={wiseman}
                    lastResp={this.lastResp}
                    lastAnswer={this.state.lastAnswer}
                 /> */}
                 </MDBCol>  
                </MDBRow> 

               {/* </MDBCol>  
                </MDBRow>  */}
                </MDBContainer>                 
                </div>
                </TabContainer>
          )
        )}
        {this.props.userdata.execmember ? null : <div align='center'><b>Please PLEDGE in order to access all functions of Me-Vision Academy, including development plan, detailed feedback, real-time points system and more.</b></div>}
        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={false}
          className={classes.modal}
        >
          <div style={modalStyle} className={classes.paper}>
            {this.state.addTask ? (
              <Task
                hasTask={false}
                updateOnSave={this.state.editTask}
                companyValue={this.state}
                OnSaveTask={this.handleOnSaveTask}
                title='Add Action'
                journalUser={userdata}
                onClose={this.handleClose} />
            ) : (
              <Task
                hasTask={true}
                task={this.state.taskOpened}
                companyValue={this.state}
                onBackClick={this.handleOnBackClick}
                OnSaveTask={this.handleOnSaveTask}
                updateOnSave={this.state.editTask}
                title='Edit Action'
                journalUser={userdata}
                onClose={this.handleClose}
              />
            )}

          </div>
        </Modal>
        <Modal
          aria-labelledby='confirmation-modal'
          aria-describedby='confirmation-modal-description'
          open={this.state.openConfirm}
          className={classes.modal}
        >
          <div style={isMobile ? modalStyle3 : modalStyle2} className={classes.paper}>
            <Typography variant='h6' gutterBottom>Please Confirm</Typography>
            <Typography variant='body' gutterBottom>Mark {this.state.editText} as {this.state.openComplete}?</Typography>
           <div>
              <ExpansionPanelActions>
                <Button onClick={this.handleClose} size='small'>Cancel</Button>
                <Button onClick={this.completedTask} size='small' color='primary'>{this.state.active ? 'Re-Open' : 'Complete'}</Button>
              </ExpansionPanelActions>
            </div>
          </div>
        </Modal>

        <Dialog
          open={this.state.open}
          aria-labelledby='form-dialog-title'
          fullWidth
          maxWidth='md'
          classes={{ paper: classes.dialogHeight }}
        >
          <DialogContent className={classes.divStyle}>
            {this.state.addTask ? (
              <Task
                hasTask={false}
                updateOnSave={this.state.editTask}
                companyValue={this.state}
                OnSaveTask={this.handleOnSaveTask}
                title='Add Action/ Goals/ Learnings / Impact'
                journalUser={userdata}
                onClose={this.handleClose} />
            ) : (
              <Task
                hasTask={true}
                task={this.state.taskOpened}
                companyValue={this.state}
                onBackClick={this.handleOnBackClick}
                OnSaveTask={this.handleOnSaveTask}
                updateOnSave={this.state.editTask}
                title='Edit Action/ Goals/ Learnings / Impact'
                journalUser={userdata}
                onClose={this.handleClose}
              />
            )}
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

Competencies.propTypes = {
  fetchCompanyValues: PropTypes.func.isRequired,
  userdata: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

const modalStyle = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`
}
const modalStyle2 = {
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${top}%, -${left}%)`,
  padding: '16px 12px 0px'
}
const modalStyle3 = {
  top: '50px', //`${top}%`,
  left: '0px', // left: `${left}%`,
  //transform: `translate(-${top}%, -${left}%)`,
  padding: '16px 12px 0px'
}
const mapStateToProps = state => {
  return {
    classes: PropTypes.object.isRequired,
    userdata: state.userDialog.userDetails[0],
    pldpTasks: state.pldp.pldpTasks,
    companyValues: state.pldp.companyValues,
    pldpAllMyTasks: state.pldp.pldpAllMyTasks,
    opinions: state.opinions.opinion
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCompanyValues: params => dispatch(getCompanyValues(params)),
    deleteCompanyValue: params => dispatch(deleteCompanyValue(params)),
    fetchMyPLDPTasks: (userid, active) => dispatch(fetchMyPLDPTasks(userid, active)),
    fetchAllMyPLDPTasks: params => dispatch(fetchAllMyPLDPTasks(params)),
    updateNewPLDPTask: (task, edit) => dispatch(updateNewPLDPTask(task, edit)),
    completeTask: params => dispatch(completeTask(params)),
    addMorePoints: params => dispatch(addMorePoints(params)),
    CgetOpinions: params => dispatch(CgetOpinions(params)),
    GgetOpinions: params => dispatch(GgetOpinions(params)),
    getOpinions: params => dispatch(getOpinions(params)),
    showToast: params => dispatch(showToast(params)),
  }
}

const CompetenciesStyled = withStyles(styles)(Competencies)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetenciesStyled)
