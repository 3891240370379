import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import {
  SHOW_CALF, SIMPLE_VAL2, CHECK_TOKEN, SHOW_TOAST, FB_URL
} from '../store/actions/types'
import { showCalf } from '../store/actions/calfActions'
import { convertToken, getMyToken, createToken, getAdmin, checkToken } from '../store/actions/companyAdminActions'
import AddMyTokensDialog from '../components/Dialogs/AddMyTokensDialog'
//components/Dialogs/AddMyTokensDialog.js
import ReactPlayer from 'react-player'
import img1 from './eximg/img1a.jpg'
import img2 from './eximg/img2.jpg'
import img3 from './eximg/img3.jpg'
import * as qs from 'qs'
import GenTutorial from './PointsSurveys/GenTutorial'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'
import { simpleValAction2 } from '../store/actions/simpleValAction2'
import { showToast } from '../store/actions/toastAction'
//import GetUserField from './GetPatron'
import firebase from '../store/firebase'
import { initializeApp } from 'firebase/app';
import { getAuth, updateUser } from "firebase/auth";
import { signOut } from '../store/actions/authActions'
import PrevPage from './PrevPage'
import     './Sendmecoins.js'
// import url from 'url'
// import patreonAPI, { oauth as patreonOAuth } from 'patreon'

class Gotopatreon extends React.Component {
  state = {
    code: null,
    calfmember: false,
    tokenData: null,
    success: true,
    openDialog: false,
    tokenRex: false,
    oauthGrantCode: false,
    NewWalletAddress: '',
    PrivateKey: '',
    coinsBought: 0,
  };

  constructor(props) {
    super(props)

   console.log('gotopatreonxx 1 : ', this.props.rep)
   console.log('gotopatreonxx 2 : ', this.props)
   console.log('gotopatreonxx 3 : ', PrevPage)

   if ( this.props.rep===true ) {this.props.history.push('/gotopatreon')} //correct url to use oauthGrantCodex

  //  var url1 = window.location.href;
  //  var urly = url1.substring(
  //    url1.indexOf("=") + 1, 
  //    url1.lastIndexOf("&")
  //  );
  //  console.log('gotopatreonxx 4 : ', urly);
  //   if (urly===false) {
     
  //   } else {
  //    if ( this.props.rep===true ) {this.props.history.push('/gotopatreon')} 
  //   }

    // this.sendGetRequest(this.props.calfemail, this.props, '2') //set onething = true if on calf databse
  }

  askPermission = () => {
    const { tokenRequested} = this.props
    // this.props.loadingAction({
    //   leaderboard_loading: 1,
    //   type: LEADERBOARD_LOADING
    // })
    console.log('inside askpermission 1 ', tokenRequested)
    const promise = this.getPermission()
    promise.then(this.onFulfilled, this.onRejected)
  }

  handleCloseDialog = () => {
    console.log('green createToken usersData 2 : ', this.state.openDialog);
    this.setState({ openDialog: !this.state.openDialog })
  };

///////////////////////////////////////////
getOwnerProps(props, typ) {
  console.log('green createToken params 0 : ', props); 
  for (let x in props) {
    switch(typ) {
      case 'email':
        return props[x].email
        break;
      case 'firstName':
        return props[x].firstName
        break;
      case 'lastName':
        return props[x].lastName
        break;
      case 'companyID':
        return props[x].companyID
        break;
      case 'userID':
        return props[x].userID
        break;
      default:
        // code block
    }
  }
}

loadTokenA = (params, data) => {
  console.log('loadtokenA 1 : ', this.props)
 // if (this.props.adminAcc) {
   const promise = this.loadTokenB(params, data)
   promise.then(this.onFulfilled, this.onRejected)
 // } 
}

loadTokenB = (params, data) => {
  const { createToken } = this.props
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      console.log('this.props.adminAcc 4 : ', this.props.adminAcc)    
      if (this.state.success) {
        let data = {
          email: this.getOwnerProps(this.props.adminAcc, 'email'),
          firstName: this.getOwnerProps(this.props.adminAcc, 'firstName'),
          lastName: this.getOwnerProps(this.props.adminAcc, 'lastName'),
          companyID: this.getOwnerProps(this.props.adminAcc, 'companyID'),
          userID: this.getOwnerProps(this.props.adminAcc, 'userID'),
    
          personID: this.props.userdata.userID, //token sent to
          personName: this.props.userdata.firstName,
          personLastName: this.props.userdata.lastName,
          personEmail: this.props.userdata.email,
          tokenType: params.tokenType,
         // amount: this.props.amount
        } 
        //console.log('green createToken params 1 : ', this.props);  
        console.log('green createToken params 2 : ', data);  
         if (data.email) {
          createToken(data);
          console.log('in createToken 1')
        } 
         else
         {
          console.log('in createToken 2 - no data')
         }
      } else {
        reject('Failed to the user list')
      }
    }, 10000)
  })
}

  handleSubmitUser = async usersData => {
    const { getAdmin, userdata } = this.props
     let data = {companyID: userdata.companyID}
     this.setState({tokenRex: true})

     let paramsX = {
      type: SHOW_TOAST,
      open: true,
      variant: 'success',
      message: 'Your Token has been Requested. Please wait a moment.'
    }

    this.props.showToast(paramsX)

     await getAdmin(data);

    let params={
      tokenType: usersData.tokenType
    }
    console.log('loadTokenA 0 ', this.props)
    this.loadTokenA(params, data)

       // this.handleCloseDialog()
  };

  getPermission = () => {
    const { convertToken, onething, simpleValAction2, getMyToken, mytoken, tokenRequested } = this.props
    var tokentext
    var tok;

    console.log('inside askpermission 2 ', tokenRequested)
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('inside askpermission 3', tokenRequested)
        console.log('green token reducer 0 ', this.props.mytoken)
        if (this.state.success) {
          console.log('inside askpermission 4', this.props.onething)
         // if (this.state.calfmember === true && this.props.userdata.from !== 'StrategyX') { // FIRST TIME GOING THROUGH
         // && this.props.userdata.from !=='StrategyX'
             if (this.props.mytoken && tokenRequested === 'yes' && this.props.onething === true ) { // FIRST TIME GOING THROUGH means user is on calf database
            //token requested and payment already made
            //CALF PRODUCTS MODIFY HERE
            console.log('inside askpermission 5')
            if (this.props.mytoken.tokenType === 'app') { tokentext = 'ThinkLead app' }
            if (this.props.mytoken.tokenType === 'ex1') { tokentext = 'Executive Club 1' }
            if (this.props.mytoken.tokenType === 'ex2') { tokentext = 'Executive Club 2' }

            tok = this.props.mytoken.token

            // const answerToken = prompt(
            //   'Please provide a Token to join the Executive Club.'
            //   + ' You have purchased the ' + tokentext + '. ' + 
            //   'Here is your token: ' + tok + '', tok
            // )
            answerToken = 'yes'
           // if (answerToken === '' || answerToken === null || answerToken === 'no') {
           //   console.log('green token reducer 6')
           // } else {
              console.log('tokenRequested 3 : ', this.props.tokenRequested );
              this.sendGetRequest(this.props.calfemail, this.props, '3') //set execmember=true & open EC
              let val = {
                token: answerToken,
                userID: this.props.userdata.userID
              }
              convertToken(val)  // CONVERT TOKEN TO CREDIT
           // }
          }
          else {

            
          }
        } 
        else {
          reject('Failed to the user list')
        }
      }, 6000)
    })
  }

  findTokenA = () => {
    console.log('greentokenusers 6 : ', this.props.tokenRequested);
    const promise = this.findTokenB()
    promise.then(this.onFulfilled, this.onRejected)
  }
  
  findTokenB = () => {
    const { tokenRequested } = this.props
   
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.state.success) {
          console.log('greentokenusers 7 : ', this.props.tokenRequested);
        
          if (tokenRequested==='no' || tokenRequested===undefined) {
            this.setState({openDialog: true})
           }
        } else {
          reject('Failed to the user list')
        }
      }, 6000)
    })
  }
//////////////////////////////////////////////////////////////////////////////////////////
// handleOAuthRedirectRequest(request, response) {
//   // const CLIENT_ID = null     // Replace with your data
//   // const CLIENT_SECRET = null // Replace with your data
//   // const redirectURL = null   // Replace with your data

//   const redirectURL = 'https://thinklead.app'; //'http://localhost:3000/callback'; // This should match your redirect URI in the Patreon Developer Dashboard
//   const CLIENT_ID = '4uk2TdW-YL2r-YB-sVcqw9jzGQhzZX-YFJs6b10hR372n5qjoQG0PsLLfIvga-Ti'     // Replace with your data
//   const CLIENT_SECRET = 'aQdMxoIIhT_NWoiKgxmD_KBcmzshHDkKvYD_7W5-UZS62DGTwlFDM79exw2NmbIn' 

//   const patreonOAuthClient = patreonOAuth(CLIENT_ID, CLIENT_SECRET)
//   const oauthGrantCode = url.parse(request.url, true).query.code

//     patreonOAuthClient
//         .getTokens(oauthGrantCode, redirectURL)
//         .then(tokensResponse => {
//             const patreonAPIClient = patreonAPI(tokensResponse.access_token)
//             return patreonAPIClient('/current_user')
//         })
//         .then(({ store }) => {
//             response.end(store.findAll('user').map(user => user.serialize()))
//         })
//         .catch(err => {
//             console.error('error!', err)
//             response.end(err)
//         })
// }

//     //Get the raw json from the response. See for the expected format of the data
//     patreon_response = patreon_client('/current_user').then(function(result) {
//       user_store = result.store
//       const data = result.rawJson
//       /*  data.data will contain the current_user, but there might be more users returned and loaded into the store.
//       *  Get the id of the requested user, and find it in the store
//       */
//       const myUserId = data.data.id
//       creator = user_store.find('user', myUserId)
//     })

  getSubstring(string, char1, char2) {
    return string.slice(
      string.indexOf(char1) + 1,
      string.lastIndexOf(char2),
    );
  }
  
  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * 
  charactersLength));
    }
    return result;
  }

  async MakeWallet_test() {
    const userwalletaddress = 'XXX'
    const updates = {};
    const userID = this.props.userdata.userID;
    const uid = this.props.userdata.uid;

    // const userwalletaddress = this.props.wallet;//address of user buying coins
          this.setState({
            NewWalletAddress: '0xxxx123',
            PrivateKey: '0x123234'
          });
         ///////////////////////////////////////////////////////////////////////
         updates['/user/' + uid + '/wallet'] = '0xxxx123';
         updates['/user/' + uid + '/privateKey'] = '0x123234';

         updates['/users/' + userID + '/wallet'] = '0xxxx123';
         updates['/users/' + userID + '/privateKey'] = '0x123234';

         firebase.database.ref().update(
            updates,
            function (error) {
              if (error) {
                console.log('MakeWallet success : ');
              } else {
               console.log('err in MakeWallet: ', error);
              }
            })       
       }

  async MakeWallet() {
    const userwalletaddress = 'XXX'
    const updates = {};
    const userID = this.props.userdata.userID;
    const uid = this.props.userdata.uid;

    // const userwalletaddress = this.props.wallet;//address of user buying coins
           await axios
           .post(`${FB_URL}/m107-mywallet`, {params: { 
               wallet: userwalletaddress //user wallet address
             } })
           .then(res => {
               console.log('mywallet 2', res);
               this.setState({
                 NewWalletAddress: res.data.NewWalletAddress,
                 PrivateKey: res.data.PrivateKey
               });
         ///////////////////////////////////////////////////////////////////////

        if (res.data.NewWalletAddress) {

         updates['/user/' + uid + '/wallet'] = res.data.NewWalletAddress;
         updates['/user/' + uid + '/privateKey'] = res.data.PrivateKey;

         updates['/users/' + userID + '/wallet'] = res.data.NewWalletAddress;
         updates['/users/' + userID + '/privateKey'] = res.data.PrivateKey;

         firebase.database.ref().update(
            updates,
            function (error) {
              if (error) {
                console.log('MakeWallet success : ');
              } else {
               console.log('err in MakeWallet: ', error);
              }
            }) 
            }
          })
       }

async SendMeVisionCoin(userwalletaddress, amount) {
 // const userwalletaddress = this.props.wallet;//address of user buying coins
     console.log('Sendmecoin 1 ', userwalletaddress)
        await axios
        .post(`${FB_URL}/m106-sendmevcoin`, {params: { 
            wallet: userwalletaddress, //user wallet address
            n: '111', 
            amount: amount //number of coins to buy
          } })
        .then(res => {
            console.log('Sendmevcoin 2', res);
        })
    }

    async BuyMeVisionCoin_test(userwalletaddress, amount) {
      // console.log('userwalletAddress : ', walletAddress)
          console.log('userwalletaddress 1 ', userwalletaddress)
                 console.log('Buymevcoin 2', res);
                 this.setState({
                  coinsBought: res.data.amountofcoinspurchased,
                  mevCoinPriceInUSD: res.data.mevCoinPriceInUSD
                 });      
         }

    async BuyMeVisionCoin(userwalletaddress, amount) {
      // console.log('userwalletAddress : ', walletAddress)
          console.log('mywallet 4', userwalletaddress);
         // console.log('userwalletaddress 1 ', userwalletaddress)
             await axios
             .post(`${FB_URL}/m108-buymevision`, {params: { 
                 wallet: userwalletaddress, //user wallet address
                 n: '111', 
                 amount: amount //number of coins to buy
               } })
             .then(res => {
                 console.log('Buymevcoin 2', res);
                 this.setState({
                  coinsBought: res.data.amountofcoinspurchased,
                  mevCoinPriceInUSD: res.data.mevCoinPriceInUSD
                 });
             })
         }
         

  async componentDidMount() {
    // Trigger the Patreon authorization only if it hasn't been triggered before
    console.log('getpatrons-green 1 ');  
    var uid;
    var uidA;
    var userID;
    var userIDA;
    var userEmail;
    var userEmail_trim;
    var emailT;
    var emailA = [];
    var emailB = [];
    var companyID;
    const updates = {};
    var doUpdates = false;
    var userwalletaddress;
    var skipUser = false;
    var len;
    var pledge;
    var i = 0;
    var trans = false;

    console.log('wallet: ', this.props.userdata.wallet);

   // if (!localStorage.getItem('patreonAuthorizationDone')) {
     //if (window.location.href==='http://localhost:3000/gotopatreon') {
      if (window.location.href==='https://thinklead.app/gotopatreon') {
          this.handlePatreonAuth();
        
        ////////////////////////////////////////////// 
        // var url1 = window.location.href;
        // console.log('oauthGrantCode-1 ', url1);
        // try {
        //   var urlx = window.location.href;
        //  //var params = url.split('?');
        //  urlx.split('=').pop().split('&')[0];
        //  const oauthGrantCode = urlx;
        //  console.log('oauthGrantCode-2 ', url1);
        //  console.log('oauthGrantCode-3 ', oauthGrantCode);
        //   await axios
        //   .post(`${FB_URL}/m105-getpatreon`, {params: { 
        //       code: oauthGrantCode, 
        //       n: '111', 
        //       inc: '777' 
        //     } })
        //   .then(res => {
        //        console.log('getpatrons-green : ', res);  
        //       // Set a flag to indicate that Patreon authorization is done
        //       localStorage.setItem('patreonAuthorizationDone', 'true');
        //   })
        //  }
        //   catch(err) {
        //     console.log('getpatrons-green err: ', err)   
        //   }  
        //////////////////////////////////////////////
      }  
      else{
        try {
          var url1 = window.location.href;

          //const urlx = url1.split('=').pop().split('&')[0];
          var urlx = url1.substring(
            url1.indexOf("=") + 1, 
            url1.lastIndexOf("&")
          );
          const oauthGrantCodex = urlx;
          console.log('oauthGrantCode-4 ', url1);
          console.log('oauthGrantCode-5 ', oauthGrantCodex);

          await axios
          .post(`${FB_URL}/m105-getpatreon`, {params: { 
              code: oauthGrantCodex, 
              n: '111', 
              inc: '11698007'
             // inc: 'bb53d30b7678408daad7eac005b6f5cd'
             // inc: 'eyJ3IjoyMDB9'
            } })
          .then(res => {
            //console.log('getpatrons-green 0:', res.data.pledges[1].data.relationships.patron.data.id)
              //  console.log('getpatrons-green 1[' + i + ']:', res.data.user[0].data.attributes.email);  
              //  console.log('getpatrons-green 2[' + i + ']:', res.data.campaign[0].data.attributes.pledge_sum); 
              // Set a flag to indicate that Patreon authorFization is done

              //console.log('getpatrons-green x:', res.data.pledges[1].data.relationships.patron.data.id)
              console.log('getpatrons-green x:', res.data.emails[0]);
              emailA = res.data.emails[0];
              console.log('getpatrons-green y1:', emailA[0]);
              console.log('getpatrons-green y2:', emailA[1]);

              var count = Object.keys(emailA).length;

             // len = emailA.length; //res.data.emails.length;
             // let lenn = parseInt(len);
              console.log('getpatrons-green z:', count)
          
           for (let i = 0; i < count; i++) {

             emailT = emailA[i];

             emailB = emailT;//emailT.split("#");

             console.log('getpatrons-green A:', emailT)

            //  console.log('getpatrons-green B:', emailB[0]) //email
            //  console.log('getpatrons-green C:', emailB[1]) //pledge
            
            console.log('getpatrons-green B:', emailT.email) //email
            console.log('getpatrons-green C:', emailT.amountCents) //pledge
            console.log('getpatrons-green D:', this.props.userdata.email) //pledge

          if (this.props.userdata.email.toLowerCase()===emailT.email.toLowerCase()) { //user is a patron
            console.log('getpatrons-green E:', emailT.amountCents);

               doUpdates = true;   
               userEmail = emailT.email; //res.data.emails[i];
               userEmail_trim = String(userEmail.trim());
               uidA = this.props.userdata.uid;
               uid = uidA.trim();
               userIDA = this.props.userdata.userID;
               userwalletaddress = this.props.userdata.wallet;
               userID = userIDA.trim();
               companyID = this.props.userdata.companyID;
               pledge = emailT.amountCents;
               console.log('pledgedd A :', pledge);
               
               console.log('getpatronsuserEmail:', userEmail);
               console.log('getpatron uid:', uid);
               ////////EMAIL EXCEPTIONS///////////////////////////////////////////////////////
                  if ( //EXCEPTIONS - DO NOT change these users profile settings
                  userEmail_trim==='adriaan@leadershipplatform.com' 
                    || userEmail_trim==='christo@tle.global'
                    || userEmail_trim==='mychristo@gmail.com'
                    || userEmail_trim==='tradekingchristo@hotmail.com'
                    ) {
                      skipUser = true; //dont change companyID_userType and profile for this user
                    } 
               //////////////////////////////////////////////////////////////////////
               }
              }

              localStorage.setItem('patreonAuthorizationDone', 'true');
          })
         }
          catch(err) {
            console.log('getpatrons-green err: ', err)   
          } 
   
          if (doUpdates) {
            lastPay = this.props.userdata.payoutdate;
             var newPay;
             var dateA;
             var dateB;
             var newPayBool;
             var timeNow;
             var lastPay;
             var plev;
             var profile;
             var profile_n;
             var points;
             var pledge_pers;
             var pledge_pers_pool;

            //console.log('patreon-pledge : ', this.props.userdata.payoutdate)
            console.log('patreon-data : ', this.props.userdata)
            // new Date();
             timeNow = new Date();//.getTime();

            if (this.props.userdata.payoutdate && this.props.userdata.patron) { //have payed out before
              lastPay = this.props.userdata.payoutdate;
             
             //newPay = Math.round(Math.abs((timeNow - this.props.userdata.payoutdate) / 86400000))  
             //dateA = d.getMonth()
             try {
              dateA = lastPay;
              } catch (err) {
              dateA = '123'
              console.log('no thought.props'. err)
              }

              dateB = timeNow.getMonth();
              //newPay = dateB.replace(/\s+/g, '');
              newPay = dateB;//dateB.trim();
              console.log('patreon-newPay 3 : ', dateA + ' --- ' + dateB);
              console.log('patreon-newPay 3b : ', newPay);

            //  if (newPay < 30) {
            //     newPayBool = false;
            //   }
            //   else{
            //     newPayBool = true;
            //   }
               if (dateA === dateB) {
                  newPayBool = false;
                }
                else{
                  newPayBool = true;
                }
                console.log('patreon-newPay 4 : ', newPayBool);
            }
            else //first payout
            {             
              newPay = timeNow.getMonth() ;
              newPayBool = true;
              console.log('patreon-newPay 5 : ', newPay);
            }
      
         if (newPayBool===true) {
        //  if (newPayBool===newPayBool) {
           pledge_pers = Number(pledge) / 4;
           //find user wallet address if exists
           if (userwalletaddress) {
            //wallet already exists
           } else {
            //create wallet
            //this.MakeWallet_test();
            //this.MakeWallet(); 
           
            userwalletaddress = this.state.NewWalletAddress;
            console.log('mywallet 3', userwalletaddress);
             // PrivateKey: this.state.PrivateKey
           
           }
           //buy coins with 25% pledge money
           var walletAddress = this.props.userdata.wallet; //STILL HAVE TO TEST IF IT ARRIVES IN TIME

           if (walletAddress) {
            //this.BuyMeVisionCoin_test(walletAddress, pledge_pers);
           // this.BuyMeVisionCoin(walletAddress, pledge_pers);
           }
           
           pledge_pers_pool = Number(pledge_pers) * 2;

            switch (Number(pledge)) {
              case 450:
                console.log('pledgedd 1')
                profile = 'Public Contributor'
                profile_n = 3
                points = parseInt(this.props.userdata.points) +  parseInt(pledge_pers);
                break
              case 1100:
                console.log('pledgedd 2')
                profile = 'Public Contributor'
                profile_n = 3
                points = parseInt(this.props.userdata.points) +  parseInt(pledge_pers);
                break
              case 2200:
                console.log('pledgedd 3')
                profile = 'Public Contributor'
                profile_n = 3
                points = parseInt(this.props.userdata.points) +  parseInt(pledge_pers);
                break
              case 5000:
                console.log('pledgedd 4')
                profile = 'Public Contributor'
                profile_n = 3
                points = parseInt(this.props.userdata.points) +  parseInt(pledge_pers);
                break
              default:
                console.log('pledgedd 5')
                profile = 'Public Contributor'
                profile_n = 3
                points = parseInt(this.props.userdata.points) +  parseInt(pledge_pers);
                console.log('patreon-pledge-problem : ', pledge)
                break
              }
            //    let timePast = (timeNow - this.props.userdata.)/(86400000)

          //////////////////////////////////////////////////////////////////////////
         // console.log('getpatrons-blue : ', timeNow + ' -- ' + pledge + ' -- ' + newPay + ' -- ' + points + ' -- ' + pledge_pers);
         //console.log('getpatrons-blue : ', profile + ' -- ' + pledge + ' -- ' + newPay + ' -- ' + points + ' -- ' + pledge_pers);
          
          const getPatData = {
            profile_n: profile_n,
            profile: profile,
            pledge: pledge,
            newPay: newPay,
            points: points,
            pledge_pers: pledge_pers,
            userPoints: this.props.userdata.points,
            userEmail_trim: userEmail_trim
           }
          console.log('getPatData : ', getPatData)

         var trailID = this.makeid(10)
         var MEVID = this.makeid(10);
         var now = new Date();
         var isoString = now.toISOString();

         console.log('skipuser== ', skipUser);

         if (skipUser===false) {
          updates['/users/' + userID + '/userDescription'] = profile;
          updates['/users/' + userID + '/userType'] = profile_n;
          updates['/users/' + userID + '/companyID_userType'] = companyID + '_' + profile_n.toString();
         }
          updates['/users/' + userID + '/feedbackOn'] = true;
          updates['/users/' + userID + '/gfeedbackOn'] = true;     
          updates['/users/' + userID + '/execmember'] = true;
          updates['/users/' + userID + '/patron'] = true;
          updates['/users/' + userID + '/pledge'] = pledge;
          updates['/users/' + userID + '/payoutdate'] = newPay
          updates['/users/' + userID + '/points'] = points;
          // if(this.state.PrivateKey) { //IF WALLET WAS CREATED NOW
          //   updates['/users/' + userID + '/wallet'] = userwalletaddress
          //   updates['/users/' + userID + '/privateKey'] = this.state.PrivateKey;
          // }
////////////////////////////////////////////////////////////////////////////////////
          if (skipUser===false) {
            updates['/user/' + uid + '/userDescription'] = profile;
            updates['/user/' + uid + '/userType'] = profile_n;
            updates['/user/' + uid + '/companyID_userType'] = companyID + '_' + profile_n.toString();
          }
          updates['/user/' + uid + '/feedbackOn'] = true;
          updates['/user/' + uid + '/gfeedbackOn'] = true;
          updates['/user/' + uid + '/execmember'] = true;
          updates['/user/' + uid + '/patron'] = true;
          updates['/user/' + uid + '/pledge'] = pledge;
          updates['/user/' + uid + '/payoutdate'] = newPay
          updates['/user/' + uid + '/points'] = points;
          // if(this.state.PrivateKey) { //IF WALLET WAS CREATED NOW
          //   updates['/users/' + uid + '/wallet'] = userwalletaddress
          //   updates['/users/' + uid + '/privateKey'] = this.state.PrivateKey;
          // }
          ////////////////////////////////////////////////////////////////////////////////////
           updates['/pointsTrail/' + trailID] = {
            userID: userID,
            fdate: timeNow,
            trailID: trailID,
            companyName: this.props.userdata.companyName,
            firstName: this.props.userdata.firstName,
            lastName: this.props.userdata.lastName,
            title: 'Pledge converted',
            //actionType: this.state.coinsBought ? this.state.coinsBought + ' MEV coins purchased.': 'Pledge converted to LC',
            //actionDesc: this.state.coinsBought ? this.state.coinsBought + ' MEV coins purchased.': 'Pledge converted to LC',
            actionType: 'Pledge converted to LC',
            actionDesc: 'Pledge converted to LC',
            pointsAdded: pledge_pers

           }
          ////ACTIVATE WHEN PROCESS IS COMPLETE
          //  updates['/MEV/' + MEVID] = {
          //     price: this.state.mevCoinPriceInUSD,
          //     time: isoString
          //  }
           
          var ddd = {
            userID: userID,
            fdate: timeNow,
            trailID: trailID,
            companyName: this.props.userdata.companyName,
            firstName: this.props.userdata.firstName,
            lastName: this.props.userdata.lastName,
            title: 'Pledge converted',
            //actionType: this.state.coinsBought ? this.state.coinsBought + ' MEV coins purchased.': 'Pledge converted to LC',
            //actionDesc: this.state.coinsBought ? this.state.coinsBought + ' MEV coins purchased.': 'Pledge converted to LC',
            pointsAdded: pledge_pers,
            price: this.state.mevCoinPriceInUSD,
            time: isoString
          }
          
          console.log('ddd-ddd 2 : ', ddd)

          ///////////////////////////////////////////////////////////////////////
          firebase.database.ref().update(
            updates,
            function (error) {
              if (error) {
                console.log('patreon success : ');
              } else {
               console.log('err : ', error);
              }
            })         
          //////UPDATE LCPOOL////////////////////////////////////////////////////
          //Path to the integer field you want to update
          console.log('LCPOOL Transaction start', pledge_pers);
          const fieldPath = '/patreon/LCPOOL';

          // Perform a transaction
          firebase.database.ref(fieldPath).transaction(currentValue => {
            console.log('LCPOOL Transaction currentvalue', currentValue);
            
              if (currentValue === null || currentValue === undefined || currentValue === '') {
                  // If the current value is null, undefined, or empty, set it to the pledge_pers
                  return parseFloat(pledge_pers_pool); // Parse pledge_pers as integer
              } else {
                  // If the current value exists, add pledge_pers to it
                  
                  if (trans===false)  {  
                    console.log('LCPOOL Transaction int', currentValue + ' -- ' + pledge_pers_pool);    
                    trans = true;                   
                    return (parseFloat(currentValue) || 0) + parseFloat(pledge_pers_pool); // Add pledge_pers to the existing value
                  }
              }
          })
          .then(result => {
              console.log('LCPOOL Transaction completed successfully!');
          })
          .catch(error => {
              console.error('LCPOOL Transaction failed:', error);
          });
        /////////////////////////////////////////////////////////////////
          }
        }
      } 
  //  }
  }

  handlePatreonAuth = () => {
    console.log('getpatrons-handlePatreonAuth 2 : ');  
    //const REDIRECT_URI = 'http://localhost:3000/gotopatreon'; 
    const REDIRECT_URI = 'https://thinklead.app/gotopatreon';

    const CLIENT_ID = 'tjyO6BK_Czgorkem1ulfH0Urogrf0Dlem9kMNZ8jUSEwPVzvsKcVy1g6UtrmsVne';

    window.location.href = `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;
  };

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      const oauthGrantCode = this.getOAuthcodeB();
      if (oauthGrantCode) {
        console.log('getpatrons-green 2 : ', oauthGrantCode);  
        //this.setState({oauthGrantCode: true})
        try {
          await axios
          .post(`${FB_URL}/m105-getpatreon`, {params: { 
             code: oauthGrantCode, 
             n: '111', 
             inc: '777' 
            } })
          .then(res => {
               console.log('getpatrons-green 3 : ', res);  
              // Set a flag to indicate that Patreon authorization is done
              localStorage.setItem('patreonAuthorizationDone', 'true');
          })
         }
          catch(err) {
            console.log('getpatrons-green err: ', err)   
          }
      } else {
        console.log('OAuth Grant Code not available');
      }
    }
  }

  getOAuthcodeB = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('code');
  };


async componentDidMount_XXX() {
  const { getMyToken, mytoken, getAdmin, checkToken, tokenRequested } = this.props

//do patreon API call////////////
//find out if user(email address) is on the pledge list & if so, which tier they belong to
var res = {}
//const REDIRECT_URI = 'http://localhost:3000/gotopatreon'//'https://thinklead.app'; //'http://localhost:3000/callback'; // This should match your redirect URI in the Patreon Developer Dashboard
const REDIRECT_URI = 'https://thinklead.app/gotopatreon';
const CLIENT_ID = 'tjyO6BK_Czgorkem1ulfH0Urogrf0Dlem9kMNZ8jUSEwPVzvsKcVy1g6UtrmsVne'     // Replace with your data
//const CLIENT_SECRET = 'd9s_mDivIMbv60oYRj8DSFqR0bElSkdfStLxKDAi3Uow8g19hk81eYWPUxG80Za1' 

const handlePatreonAuth = () => {
  window.location.href = `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;
};

//console.log('getpatrons-blue1 : ', code);
//console.log('getpatrons-blue2 : ', handlePatreonAuth.code);
//console.log('getpatrons-blue3 : ', handlePatreonAuth);
const urlParams = new URLSearchParams(window.location.search);
const oauthGrantCode = urlParams.get('code');
console.log('OAuth Grant Code:', oauthGrantCode);

try {
 await axios
 //.post(`${FB_URL}/m105-getpatreon?code=${222}`, {})
 //.post(`${FB_URL}/m105-getpatreon`, {params: {code: '123', n: '111', inc: '777'}})
 //.post(`${FB_URL}/m105-getpatreon`, {params: {n: '111', inc: '777'}})
 .post(`${FB_URL}/m105-getpatreon`, {})
 .then(res => {
 console.log('getpatrons-green : ', res);
 })
}
 catch(err) {
   console.log('getpatrons-green err: ', err)   
 }
/////////////////////////////////

}
//////////////////////////////////////////////////////////////////////////////////////////

 async componentDidMount_xx() {
     const { getMyToken, mytoken, getAdmin, checkToken, tokenRequested } = this.props

   //do patreon API call////////////
   //find out if user(email address) is on the pledge list & if so, which tier they belong to
   var res = {}
   try {
    await axios
    //.post(`${FB_URL}/m105-getpatreon?code=${222}`, {})
    .post(`${FB_URL}/m105-getpatreon`, {params: {code: '123', n: '111', inc: '777'}})
    .then(res => {
    console.log('getpatrons-green : ', res);
    })
   }
    catch(err) {
      console.log('getpatrons-green err: ', err)   
    }
   /////////////////////////////////
     if((this.props.tokenRequested==="yes") && (this.state.tokenRex===true)) {
      //nothing
     }
    else {
     // this.preSubmit();
     } 

    const dd = {
      type: CHECK_TOKEN,
      userID: this.props.userdata.userID,
      orderChild: 'tpersonID'
    }
    await checkToken(dd); //check if token has been requested already
   
    if (this.props.userdata.execmember) {
      //
    }
    else{
        this.findTokenA()
    }
  
    const miTOken = {
      tpersonID: this.props.userdata.userID
    }
    await getMyToken(miTOken); //get token details

    //this.loadTokenA()
    console.log('tokenRequested 1 : ', this.props.tokenRequested );

    await this.sendGetRequest(this.props.calfemail, this.props, '2') //set onething = true

    console.log('inside askpermission 0 ', tokenRequested)

    if (this.props.userdata.execmember) { // NORMAL EC member execmember = true
      console.log('tokenRequested 2 : ', this.props.tokenRequested );
      this.sendGetRequest(this.props.calfemail, this.props, '1') //open exec page
    } else // NOT MARKED AS EC MEMBER YET...execmember = false
    {
      // this.sendGetRequest(this.props.calfemail, this.props, '2') //set onething = true
      console.log('inside askpermission 11 ', tokenRequested)
      this.askPermission()

    }

  }

  handleOpen = url => {
    // console.log('handleopen: ', url);
    window.open(url, '_blank')
  };

  async sendGetRequest(cemail, props, status) {
    const { simpleValAction2, checkToken } = this.props
    console.log('greentokenreducer s1  : ', status);

    var code
    const data = {
      username: 'ZGYrTGlJc3VoZTZhbDZacmZrSHNDbW5iOTFTaXJha1pnbHY5Qkt2bDJWMD0=',
      password: 'AQ2GD&*Q'
    }
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: 'https://mp.exec-club.com/api/auth/session-create'
    }

   await axios(options)
      .then(function (response) {
        const data = {
          email_id: cemail
        }
        const xoptions = {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Token': response.data.token
          },
          data: data,
          url: 'https://mp.exec-club.com/api/validate-user'
        }
       axios(xoptions)
          .then(res => {
            console.log('greentokenreducer s2 :', status);
            code = res.data.login_url
            if (code && code !== undefined) {
              // ///////////////////////////////reached here means EXECUTIVE CLUB member

              if (status === '2') {
                console.log('greentokenreducer s3 :', status);
                let body = {
                  type: SIMPLE_VAL2,
                  surveyid: true,
                  go: true
                }
                 simpleValAction2(body)
              }

              if ( status === '3' || status === '1') {
                console.log('green token reducer d :', status);
                let params = {
                  type: SHOW_CALF,
                  code: code,
                  uid: props.userdata.uid,
                  userID: props.userdata.userID,
                  companyID: props.userdata.companyID,
                  userType: props.userdata.userType
                }
                props.showCalf(params)
                //if (status === '1') {
                window.open(res.data.login_url, '_blank')
                //}
              }
              console.log('valid calf member')
            } else {
              console.log('green params 5', cemail)
            }
          })
          .catch(error => {
            console.log('green params 6: ', error)
            return 2
          })
          .catch(function (error) {
            console.log('green params 7: ', error)
            return 3
          })
      })
      .catch(function (error) {
        console.log('green params 8: ', error)
        return 3
      })
  }

  preSubmit = () => {
   // const { onSubmitUserClick} = this.props
   // this.setState({ lockButton: true })

  //  <MenuItem key={'app'} value={'app'} >
  //  {'Executive Club Ownership $85/month'}
  //  <MenuItem key={'ex1'} value={'ex1'} >
  //  {'Executive Club Membership 1: $28/month'}
  //  <MenuItem key={'ex2'} value={'ex2'} >

    this.handleSubmitUser({
          tokenType:this.state.tokenType,
          amount: this.state.amount
      });

   }

  render() {
    const { calf } = this.props
     console.log('green createToken usersData A: ', this.props.adminAcc);
    if (this.props.userdata.execmember === false) { // NOT SUBSCRIBED

      return (
        <React.Fragment>
          <div style={{
            display: 'block',
            // marginLeft: isMobile ? "10px" : "0px",
            backgroundColor: '#FFFBE6',
            color: '#695800',
            padding: '20px',
            width: isMobile ? '100%' : '80%',
            size: '16px'
          }}>
            
            <h2>Why PLEDGE?</h2>

            <p><div align='center'>
              <a target='main' href='https://thinkleadecosystem.com/levels-of-leadership-fitness/' >
                <img src={img1} style={{ width: '80%' }} />
              </a>
            </div>
            </p>

   
          </div>
        </React.Fragment>

      )
    }
    else { //SUBSCRIBED YES

      return (
        <React.Fragment>
          <div style={{
            display: 'block',
            // marginLeft: isMobile ? "10px" : "0px",
            backgroundColor: '#FFFBE6',
            color: '#695800',
            padding: '20px',
            width: isMobile ? '100%' : '80%',
            size: '16px'
          }}>
       {(calf) ?
              <p align='center'>
                <button
                  onClick={() => this.setState({openDialog: true})}
                  style={{
                    backgroundColor: '#cdbc74',
                    border: 'none',
                    color: 'white;',
                    padding: '15px 32px',
                    textAlign: 'center',
                    textDecoration: ' none',
                    display: 'inline-block',
                    fontSize: '16px',
                    margin: '4px 2px',
                    cursor: 'pointer'
                  }}
                >Buy Products
                </button></p>
              :
              [ this.props.tokenRequested === 'yes' ?  <p align='center'> <a target='main' href='https://mp.exec-club.com/' >
              <button
                 style={{
                   backgroundColor: '#cdbc74',
                   border: 'none',
                   color: 'white;',
                   padding: '15px 32px',
                   textAlign: 'center',
                   textDecoration: ' none',
                   display: 'inline-block',
                   fontSize: '20px',
                   margin: '4px 2px',
                   cursor: 'pointer'
                 }}
               >Sign Up
               </button></a></p> : null]
            }

<h2>Why SIGN UP?</h2>
           {/* <div>PLACEHOLDER 1</div> */}
           <blockquote>
           <p>We are searching for and will acknowledge - with an exclusive Successful Leadership Award Certificate - #30000SuccessfulLeaders by 2030.</p> 

          <p>Everyone demands that you show up as your Best Self - all the time - at work and at home? In this VUCA ever changing world it’s more challenging than ever to navigate one’s personal growth, relationships, career or business successfully.</p>

          <p>This platform will support you every step on this journey, whether you are a specialist or a leader of others, organisation or society. Our digital ecosystem with all its powerful services and functions will empower you with the confidence, skills and abilities needed in order to navigate your career and life from student to qualified specialist to supervisor and successful leader status.</p>

          <p>How will an employer and broader society know that you are “Best Self Fit” and continually ready for the next career or leadership move?</p>

          <p>All participation earns you real-time CBS (Continuous Best Self) Points - the equivalent of CPD (Continuous Professional Development) Points.
          Sufficient CBS Points secure your annual “Best Self (professional) status”, on your current level, and in any chosen sector and country – see further down points system.
          Your progression on specific levels will be recorded and a certificate awarded for every level achieved - declaring you Best Self Fit - and when you are in a leadership position - a “Successful Leader”, depending on your <b>LeaderTrack</b> results - see more below. 
          All participation will be captured on the <b>LeaderTrack</b> Leaderboard and inside a personal Portfolio of Evidence.</p>
          </blockquote>
            {calf ?
              <p align='center'>
                <button
                  onClick={() => this.setState({openDialog: true})}
                  style={{
                    backgroundColor: '#cdbc74',
                    border: 'none',
                    color: 'white;',
                    padding: '15px 32px',
                    textAlign: 'center',
                    textDecoration: ' none',
                    display: 'inline-block',
                    fontSize: '16px',
                    margin: '4px 2px',
                    cursor: 'pointer'
                  }}
                >Buy Products
                </button></p>
              :
              [ this.props.tokenRequested === 'yes' ?  <p align='center'> <a target='main' href='https://mp.exec-club.com/' >
              <button
                 style={{
                   backgroundColor: '#cdbc74',
                   border: 'none',
                   color: 'white;',
                   padding: '15px 32px',
                   textAlign: 'center',
                   textDecoration: ' none',
                   display: 'inline-block',
                   fontSize: '20px',
                   margin: '4px 2px',
                   cursor: 'pointer'
                 }}
               >Sign Up
               </button></a></p> : null]
            }
         <blockquote>
          <p>Consistently being one’s Best Self as a Successful Leader, in today’s world, is and always will be a deeply personal journey. No sausage-factory approach has ever been nor will it ever be up to the task.</p>

          <p>So Pledge and access many more opportunities to be rewarded with real-time CBS Points that appear next to your name on <b>LeaderTrack</b>, in your chosen sector, which affects your annual Best Self (professional) status. It also affects your place in the rankings, in your chosen sector, should that be important to you. The points display next to your picture when you LEAD (share messages inside the Global Forum) with your skills, expertise, knowledge, experience and wisdom, for which you receive real-time CBS Points as well.</p>

          <p>Signing Up makes it possible for ME-Vision Academy to reward you with real-time points for all activities inside your Development Plan. </p>


          <p>After Signing Up you will notice the endless certificates and diplomas available under your Personal Development space. Choose any relevant courses and while completing it, remember to record learnings inside your Personal Development Plan, in order to capture it inside your Portfolio of Evidence and earn valuable CBS Points.</p>

          <p>You can also purchase a licence for your own ME-Vision Academy Platform and help us transform society through this incredible ecosystem. After doing so we will hold a personal conversation with you about how to manage your own Academy, including the opportunity of earning residual income.</p>
          <h2>LeaderTrack:</h2>     

          {/* <div>PLACEHOLDER 3</div> */}
          <p>Inside the Ecosystem - because you are on <b>LeaderTrack</b> - you are able to mobilise those who work with and for you to track your leadership through real-time feedback. They click on your avatar on the leaderboard and offer you real-time (continuous), anonymous feedback on either your companies chosen competencies or these four universal leadership levels:</p>

            <ul>
            <li>leading self,</li>
            <li>leading others,</li>
            <li>leading organisation (non-people stuff) and</li>
            <li>leading society (beyond work).</li>
            </ul>

            <p>And then, based on this feedback, you develop yourself while tracking all activities, learnings, goals and commitments inside your Development Plan.</p>

            <p>Note you can see your detailed feedback only after signing up for <b>LeaderTrack</b>.</p>

            <h2>Successful Leadership Award Certificate: </h2>

            <p>When your feedback rating is consistently above 8:00, based on input from your boss, 3-4 peers and all direct reports or those who work for them, you receive an exclusive “Successful Leadership” Certificate Award. You also need to show that you are working towards your annual 3000 CBS Points. </p>
            <p><div align='center'>
      

              <a target='main' href='https://thinkleadecosystem.com/levels-of-leadership-fitness/' >
                <img src={img1} style={{ width: '80%' }} />
              </a>
            </div>
            </p>
            <p>A consistent rating above 9:00 average by your leader, peers and all direct reports, confirmation from your organisation that you are performing, and if 60% or more of your direct reports qualify for the Successful Leadership Certificate, you will earn an Exceptional Leadership Award Certificate. Remember, an exceptional leader grows more successful leaders. For this award you need to achieve 3000 CBS points in a given year, or sooner. </p>

            <p>In both these instances you join the #30000SuccessfulLeaders by 2030 vision and exclusive network. </p>
            </blockquote>
          {/* <div>PLACEHOLDER 4</div> */}

            {(calf) ?
              <p align='center'>
                <button
                  onClick={() => this.setState({openDialog: true})}
                  style={{
                    backgroundColor: '#cdbc74',
                    border: 'none',
                    color: 'white;',
                    padding: '15px 32px',
                    textAlign: 'center',
                    textDecoration: ' none',
                    display: 'inline-block',
                    fontSize: '16px',
                    margin: '4px 2px',
                    cursor: 'pointer'
                  }}
                >Buy Products
                </button></p>
              :
              [ this.props.tokenRequested === 'yes' ?  <p align='center'> <a target='main' href='https://mp.exec-club.com/' >
              <button
                 style={{
                   backgroundColor: '#cdbc74',
                   border: 'none',
                   color: 'white;',
                   padding: '15px 32px',
                   textAlign: 'center',
                   textDecoration: ' none',
                   display: 'inline-block',
                   fontSize: '20px',
                   margin: '4px 2px',
                   cursor: 'pointer'
                 }}
               >Sign Up
               </button></a></p> : null]
            }
          <blockquote>

            <p>Adriaan Groenewald </p>

            <p>Leadership Activist and Co-Founder ThinkLead Ecosystem</p>
            </blockquote>
          </div>

        </React.Fragment>

      )
    }
  }
}

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    calf: state.calf.calf,
    onething: state.oneVal2.sVal2,
    mytoken: state.mainToken.mtoken,
    adminAcc: state.mainToken.oadmin,
    tokenRequested: state.hasToken.hasToken
    // onething: state.sVal
    // simplething: state.simpleVal.simplething
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showCalf: params => dispatch(showCalf(params)),
    getMyToken: params => dispatch(getMyToken(params)),
    convertToken: params => dispatch(convertToken(params)),
    createToken: params => dispatch(createToken(params)),
    simpleValAction2: params => dispatch(simpleValAction2(params)),
    getAdmin: params => dispatch(getAdmin(params)),
    checkToken: params => dispatch(checkToken(params)),
    showToast: params => dispatch(showToast(params)),
    signOut: () => dispatch(signOut())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Gotopatreon)
