/* eslint-disable no-inline-comments */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  ExpansionPanelActions,
  Dialog,
  Button,
  Typography
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DoneIcon from '@material-ui/icons/Done'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import { showToast } from '../../../store/actions/toastAction'
import { fetchSubCompetencies, deleteSubCompetency } from '../../../store/actions/subcompetenciesActions'
import { LIGHTGREY, GREEN } from '../../../utils/color_utils'
import { VisibilityRounded, AlarmOnRounded, AlarmOffRounded } from '@material-ui/icons'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import { teal } from '@material-ui/core/colors'
import { SHOW_TOAST } from '../../../store/actions/types'

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: '#fff'
  },
  rootdiv: {
    width: '100%',
    padding: '0 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 12px'
    }
  },
  icons: {
    cursor: 'pointer'
  },
  modal: {
    outline: 'none',
    border: 'none'
  },
  paper: {
    position: 'absolute',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    outline: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  section: {
    marginBottom: 10
  }
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions)

class TasksList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sortby: '',
      openView: false,
      selectedItem: {}
    }
  }

  editTask = (id, name, task) => e => {
    this.props.editTask(id, name, task)
    this.setState({
      openView: false
    })
  }

  filterZZZAAA = (comLable) => {
    let comLablex = comLable.toString()
    let myTruncatedString = comLablex.substring(0, 4)
    let finalstring = comLablex

    if (myTruncatedString === 'ZZZ_') {
      finalstring = comLablex.substring(4, comLablex.length)
    }
    if (myTruncatedString === 'AAA_') {
      finalstring = comLablex.substring(4, comLablex.length)
    }
    return finalstring
  };

  completeTask = (id, name, task, active) => e => {
    let timeNow = new Date().getTime();
    let timePast = (timeNow - task.dateRegistered)/(86400000)
    console.log('green plfp completeTask 3 : ', task.moveActionDesc);
    let action = task.moveActionDesc.substr(0, 9);
   // this.props.completeTask(id, name, task, active)

    var pass = false;
    
    switch (active) {
      case true: //re-open task
        pass = true;
        break;
      case false: //complete task
      if (timePast > 29 || action === "Activity:") {pass=true}
        break;
    }

    if (pass) {
     this.props.completeTask(id, name, task, active)
     }
     else
     {
       this.props.showToast({
         type: 'SHOW_TOAST',
         open: true,
         variant: 'success',
         message: 'Tasks can only be ticked as completed after 30 days, to ensure integrity of changing behaviours and adopting new habits.'
       })
     }
  }

  handleCloseView = () => {
    this.setState({
      openView: false,
      selectedItem: {}
    })
  }
  handleViewOpen = (labelId, labelName, listTask) => {
    this.setState({
      openView: true,
      selectedItem: listTask
    })
  }
  render() {
    const { classes, compid, subcompetencies_list, listTasks, labelName } = this.props
    return (
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <div className={classes.rootdiv}>
            <List
              subheader={
                <ListSubheader
                  style={
                    { backgroundColor: `${LIGHTGREY}`, fontWeight: 'bold', fontSize: '13px', lineHeight: '28px', color: '#000', borderRadius: '4px' }
                  }>
                  {this.filterZZZAAA(labelName)}
                </ListSubheader>}
              dense
              className={classes.root}>
              {listTasks && Object.keys(listTasks).map(id => {
                const labelId = `${listTasks[id].myPLDPID}`
                const labelName = `${listTasks[id].title}`
                // console.log('purple listTasks: ', listTasks[id].dateRegistered);

                function completedStyle(active) {
                  return {
                    textDecoration: active ? 'none' : 'line-through'
                  }
                }
                console.log('[listTask]', listTasks[id])
                console.log('[listTask id]', listTasks[id].myPLDPID)
                console.log('[listTask]', listTasks[id].title)
                return (
                  <div style={completedStyle(listTasks[id].active)}>
                    <ListItem key={labelId} button>
                      <ListItemText
                        id={labelId}
                        primary={`${labelName}`}
                      />
                      <ListItemSecondaryAction>
                        {listTasks[id].active ?
                          <div>
                            <Tooltip title='View'>
                              <IconButton size='small'
                                onClick={() => this.handleViewOpen(labelId, labelName, listTasks[id])}
                                edge='end'
                                aria-label='edit'>
                                <VisibilityRounded fontSize='small' color='secondary' />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Edit'>
                              <IconButton size='small'
                                onClick={this.editTask(labelId, labelName, listTasks[id])}
                                edge='end'
                                aria-label='edit'>
                                <EditIcon fontSize='small' color='primary' />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Mark Complete'>
                              <IconButton
                                style={{ color: `${GREEN}` }}
                                size='small'
                                onClick={this.completeTask(labelId, labelName, listTasks[id], false)}
                                edge='end'
                                aria-label='complete'>
                                <DoneIcon fontSize='small' />
                              </IconButton>
                            </Tooltip>
                          </div>
                          : <Tooltip title='Reopen Task'>
                            <IconButton
                              style={{ color: `${GREEN}` }}
                              size='small'
                              onClick={this.completeTask(labelId, labelName, listTasks[id], true)}
                              edge='end'
                              aria-label='complete'>
                              <PlaylistAddCheckIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>}

                      </ListItemSecondaryAction>
                    </ListItem>
                  </div>


                )
              })

              }
            </List>
          </div>

        </Grid>

        <Dialog
          onClose={this.handleCloseView}
          aria-labelledby='customized-dialog-title'
          open={this.state.openView}
          maxWidth={'sm'}
          fullWidth={true}
        >
          <DialogTitle id='customized-dialog-title' onClose={this.handleCloseView}>
            {this.state.selectedItem.title}
          </DialogTitle>
          <DialogContent dividers>
            <div className={classes.section}>
              <Typography variant='body1'> Competency : {this.state.selectedItem.moveActionDesc}</Typography>
            </div>
            <div className={classes.section}>
              {`Task Created on ${this.state.selectedItem.stringDateRegistered}`}
            </div>
            {
              this.state.selectedItem.reminderOn ?
                <div>
                  <div className={classes.section}>
                    <Typography variant='body1'>
                      <AlarmOnRounded style={{ fontSize: 35, color: '#ccbc7b', paddingRight: 5 }} />
                      Reminder date: {this.state.selectedItem.stringReminderDate} {this.state.selectedItem.stringReminderTime}
                    </Typography>
                  </div>
                  <div className={classes.section}>
                    Frequency: {this.state.selectedItem.reminderFrequency}
                  </div>
                </div>
                :
                <div className={classes.section}>
                  `Frequency: Does not Repeat`
                </div>
            }
            
          </DialogContent>
          <DialogActions>
            <Button onClick={this.editTask(this.state.selectedItem.myPLDPID, labelName, this.state.selectedItem)} color='primary'>
              Edit Task
            </Button>
            <Button onClick={this.completeTask(this.state.selectedItem.myPLDPID, labelName, this.state.selectedItem, false)} color='primary'>
              Mark as Complete
            </Button>
          </DialogActions>
        </Dialog>

      </Grid>


    )
  }
}

TasksList.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    classes: PropTypes.object.isRequired,
    userdata: state.userDialog.userDetails[0],
    pldpTasks: state.pldp.pldpTasks,
    subcompetencies_list: state.subcompetenciesList.subcompetencies_list
  }
}

const SubCompetenciesStyled = withStyles(styles)(TasksList)

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    fetchSubCompetencies: params => dispatch(fetchSubCompetencies(params))
    // deleteSubCompetency: params => dispatch(deleteSubCompetency(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubCompetenciesStyled)