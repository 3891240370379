/* eslint-disable no-inline-comments */
/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
//import SpeedDialIcon from '@material-ui/lab/Alert'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import Button from '@mui/material/Button';
import Videocam from '@material-ui/icons/Videocam'
import Mic from '@material-ui/icons/Mic'
import RemoveRedEye from '@material-ui/icons/RemoveRedEye'
import ChatIcon from '@material-ui/icons/Chat'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import { PINK, RED, DARK } from '../utils/color_utils'
import { RESTRICTED_USER_TYPES, HIDE_ADD_BTN } from '../store/actions/types'
// import DownloadNote from "../store/actions/downloadmsg";
import DownloadNote from '../store/actions/DownloadEXEnote'
// import Checkuser from "../store/actions/checkUser";
import { isBrowser } from 'react-device-detect'

const styles = theme => ({
  root: {
    height: 0
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing.unit * 4,
    right: theme.spacing.unit * 3
  },
  speedColor: {
    'backgroundColor': '#CEBC74', //'#daa520',
    'color': '#fff',
    '&:hover': {
      backgroundColor: '#daa520' //'#ffd700'
    }
  },
  TooltipClasses: {
    backgroundColor: `${DARK}`,
    color: '#ffffff'
  }
  // MuiSpeedDialActionStaticTooltip: {
  //   backgroundColor: `#eeeeee`,
  //   color: "#ffffff"
  // },
  // MuiSpeedDialActionTooltipPlacementLeft:{
  //   backgroundColor: `#eeeeee`,
  //   color: "#ffffff"
  // },
  // MuiSpeedDialActionStaticTooltipClosed:{
  //   backgroundColor: `#eeeeee`,
  //   color: "#ffffff"
  // },
  // MuiSpeedDialRoot: {
  //   backgroundColor: `#eeeeee`,
  //   color: "#ffffff",
  //   visibility: true
  // }
})
// MuiSpeedDial-root MuiSpeedDial-directionUp SpeedDialTooltipOpen-speedDial-127
let actions = [
  /* { icon: <Videocam />, name: 'Video' },
  { icon: <Mic />, name: 'Podcast' },
  { icon: <RemoveRedEye />, name: 'Article' },
  { icon: <ChatIcon />, name: 'Thought' },
  { icon: <AddCircleIcon />, name: 'Post' } */
  /*  { icon: <AddCircleIcon />, name: 'Post' }, */
  { icon: <ChatIcon />, name: 'Thought' }

]

class SpeedDialTooltipOpen extends React.Component {
  state = {
    open: false,
    position: 0
  };

  constructor(props) {
    super(props)
    // console.log('>>>>>>>>>>>>>>>>>>>>>>>SpeedDial props: ', this.props)
  }

  handleVisibility = () => {
    this.setState(state => ({
     open: false
    }))
  };

  handleClick = () => {
    this.setState(state => ({
      open: !state.open
    }))
    this.props.openModal('Thought')
  };

  handleOpen = () => {
    clearTimeout(this.state.position)
    if (!this.props.hidden) {
      this.setState({
        open: true
      })
    }
  };

  handleCloseDelayd = () => {
    this.setState({ position: setTimeout(this.handleClose, 5000) })
  };

  // handleClose = () => {
  //   setTimeout( this.setState({
  //     open: false
  //   }), 15000);
  // };

  handleClose = () => {
    this.setState({
      open: false
    })
  };

  componentDidMount() {
    // Show / Hide menus per user type
    console.log('MainSpeedDial : ', this.props)
    if (RESTRICTED_USER_TYPES[this.props.userType]) {
      /* actions = [{ icon: <AddCircleIcon />, name: 'Post' }] */
      actions = [{ icon: <ChatIcon />, name: 'Thought' }]
    }
  }

  render() {
   // const { classes, userType, hidden } = this.props
    const { classes, userType, hidden } = this.props
    const { open } = this.state
    // console.log('mainspeeddial: ', HIDE_ADD_BTN[window.location.pathname]);
    // console.log('mainspeeddial window.location.pathname: ', window.location.pathname);


    let lastSlash = window.location.pathname.lastIndexOf('/')
    let pathname = window.location.pathname

    if (lastSlash !== 0) {
      pathname = pathname.substring(0, lastSlash)
    }

    // device detection
    // if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    //     || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
    //     this.setState({isMobile:true})
    // }

    return (
      <div className={classes.root}>
        {isBrowser ? <DownloadNote usrIDD={this.props.usrIDD} /> : null}

        {HIDE_ADD_BTN[pathname] ? null : (
        // <Button variant="contained" color="success"
        //     ariaLabel='Add posts'
        //     className={classes.speedDial}
        //     hidden={hidden}
        //     //icon={<SpeedDialIcon />}
        //     onClick={() => this.handleClick(this.props)}
        //     open={open}
        //     onBlur={this.handleCloseDelayd}
        //     onClose={this.handleCloseDelayd}
        //     // onMouseEnter={this.handleOpen}
        //     // onMouseLeave={this.handleCloseDelayd}
        //     classes={{ fab: classes.speedColor }}
        // >
        //   LEAD
        // </Button>
          <SpeedDial
           ariaLabel='Add posts'
           className={classes.speedDial}
           hidden={this.props.hidden ? this.props.hidden : false}
           icon={<SpeedDialIcon />}
           onClick={() => this.handleClick()}
           open={open}
           onBlur={this.handleCloseDelayd}
           onClose={this.handleCloseDelayd}
           // onMouseEnter={this.handleOpen}
           // onMouseLeave={this.handleCloseDelayd}
           classes={{ fab: classes.speedColor }}
          >
         </SpeedDial>

        )}
      </div>
    )
  }
}

SpeedDialTooltipOpen.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SpeedDialTooltipOpen)
