import React from 'react'
import { connect } from 'react-redux'
import Avatar from '@mui/material/Avatar';
import { PHOTO_PROFILE } from '../../utils/photo_utils'
import {
  isMobile,
  isBrowser
} from 'react-device-detect'


class LeadsList extends React.Component {
    constructor(props) {
      super(props)
    }

    render() {
      const { classes, topleads, showLeadb } = this.props
      console.log('green categories topleads 3 : ', this.props.topleads)
      console.log('green categories topleads 4 : ', this.props)

     // const address = 'http://localhost:3000/filtered-thoughts/#/';
      const address = 'filtered-thoughts/#/';

    
    if(isBrowser) { return (
        <div 
        // style={{
        //     color: '#515A5A',
        //     fontSize: '9',
        //     border: '0px',
        //     backgroundColor: '#F2F3F4',
        //     padding: '1',
        //     borderRadius: '2',
        //     align: 'left'
        // }}
        >
         {this.props.topleads &&
        Object.keys(this.props.topleads).map(id => {
        if (this.props.topleads[id] !== null) {
          const labelId = `${this.props.topleads[id].dailyThoughtID}`
          const labelName = `${this.props.topleads[id].title}`
          const leadPoints = `${this.props.topleads[id].leadPoints}`
          const leadshort = labelName.slice(0, 15) + '...'
          const pic = `${this.props.topleads[id].photoURL}`
          
          return (
            <div 
            style={{ 
              display: 'flex',
              cursor: 'pointer',
              justifyContent: 'left',
              alignItems: 'center',
              align:'center',
              color: '#cca338', 
             // textShadow: '3px 3px 3px gold',
              width: '70%',
              maxWidth: '70%'
              }}
            >
            {/* <a href={address + labelId}>{labelName}</a>  */}
            {/* <button
                 style={{
                   backgroundColor: '#cdbc74',
                   border: 'none',
                   color: 'white;',
                   padding: '15px 32px',
                   textAlign: 'center',
                   textDecoration: ' none',
                   display: 'inline-block',
                   fontSize: '16px',
                   margin: '4px 2px',
                   cursor: 'pointer'
                 }}
             // onClick={this.props.showLeadb(labelId)}
             // onClick={() => this.props.showLeadb(labelId)}
             onClick={() => this.props.showLeadb(this.props.topleads[id])}
            >
            {labelName}
            </button> */}

            <Avatar
              //alt={'dfgsdfgsdfg'}
              alt="Remy Sharp"
              src={PHOTO_PROFILE(pic)}
              sx={{ width: 28, height: 28 }}
              //src={PHOTO_PROFILE(require("/static/images/remy.jpg"))} 
              //src={PHOTO_PROFILE('../images/default-user.png')}
              //src={PHOTO_PROFILE(this.props.srcx)}
              //src={PHOTO_PROFILE('https://firebasestorage.googleapis.com/v0/b/thinklead-b3299.appspot.com/o/testphotos%2Fimage_2021-03-03T08%3A39%3A23.736Z%20-%20bruce.jpeg?alt=media&token=f0f0d61d-bb3c-4cea-b03d-88facfa1290e')}
             // id={this.props.id}
              onClick={() => this.props.showLeadb(this.props.topleads[id])}
            />
            &nbsp; &nbsp; <span
               onClick={() => this.props.showLeadb(this.props.topleads[id])}
               style={{
                color: '#515A5A',
                fontSize: '8',
                border: '0px',
                backgroundColor: '#F2F3F4',
                padding: '1',
                borderRadius: '2',
                align: 'left'
               }}
              >
               {/* {labelName.slice(0, 15)} */}
               {leadshort}
               {/* {leadPoints} */}
              </span>
            </div> 
          )
              }
          })}
        </div>

      )} else {
        //mobile view
        return (
          <div 
          style={{ 
            display: 'flex',
            justifyContent: 'flex-start',
            cursor: 'pointer',
            justifyContent: 'left',
            width: '100%',
            textAlign: 'center',
            color: '#cca338', 
           // textShadow: '3px 3px 3px gold',
            width: '70%',
            maxWidth: '70%'
            }}
          >
           {this.props.topleads &&
          Object.keys(this.props.topleads).map(id => {
            const labelId = `${this.props.topleads[id].dailyThoughtID}`
            const labelName = `${this.props.topleads[id].title}`
            const leadPoints = `${this.props.topleads[id].leadPoints}`
            const leadshort = labelName.slice(0, 15) + '...'
            const pic = `${this.props.topleads[id].photoURL}`
            
            return (
              <Avatar
                //alt={'dfgsdfgsdfg'}
                alt="Remy Sharp"
                src={PHOTO_PROFILE(pic)}
                sx={{ width: 28, height: 28 }}
                //src={PHOTO_PROFILE(require("/static/images/remy.jpg"))} 
                //src={PHOTO_PROFILE('../images/default-user.png')}
                //src={PHOTO_PROFILE(this.props.srcx)}
                //src={PHOTO_PROFILE('https://firebasestorage.googleapis.com/v0/b/thinklead-b3299.appspot.com/o/testphotos%2Fimage_2021-03-03T08%3A39%3A23.736Z%20-%20bruce.jpeg?alt=media&token=f0f0d61d-bb3c-4cea-b03d-88facfa1290e')}
               // id={this.props.id}
                onClick={() => this.props.showLeadb(this.props.topleads[id])}
              />
           
            )
  
            })}
          </div>
  
        )
      }

    }
  }

  export default LeadsList