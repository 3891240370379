import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  withStyles,
} from '@material-ui/core/styles'
import {
  Paper,
  Grid,
  Typography, IconButton, Button, InputAdornment,
  FormControl, InputLabel, Input, Breadcrumbs, Chip, Dialog,
  DialogActions, DialogContent, Select, DialogTitle, TextField, MenuItem,
  Stepper, Step, StepLabel,
  Avatar,
  Card,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  ListItemIcon,
  Menu,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Link } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import { SHOW_TOAST } from '../../store/actions/types'
import { showToast } from '../../store/actions/toastAction'
import { getAllCompanyUsers, createCompanyGroup, getAllCompanyGroupUsers, getAllCompanySurveys, getCompanyProgrammes, deleteUserFromGroup, getCompanyActiveSurveys, fetchUserGroups } from "../../store/actions/companyAdminActions";
import { PHOTO_PROFILE } from '../../utils/photo_utils'
import { LIGHTGREY } from '../../utils/color_utils'
import SurveysList from './SurveysList'
import SurveyView from './SurveyView'
import defBackground from "../../assets/images/survey.jpg";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    marginTop: 0,
    overflowX: 'auto',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      marginTop: '130px',
      padding: '0 10px'
    }
  },

  papertop: {
    padding: theme.spacing(),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginTop: "-10px!important",
    marginLeft: "-10px!important",
    marginRight: "-10px!important",
    height: "420px!important",
    [theme.breakpoints.down("xs")]: {
      marginTop: "32px!important"
    },
    backgroundColor: "#fff",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    borderRadius: "4px",
    width: "100%",
    backgroundImage: `url(${defBackground})`
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  pageHeader: {
    marginTop: 0,
    padding: theme.spacing(1),
    backgroundColor: 'transparent'
  }
})

class Surveys extends Component {
  state = {
    companyID: '',
    companyName: '',
    tabsvalue: 0,
    company_surveys: [],
    userID: '',
    survey_link: '',
    tabsvalue: 0
  }

  componentDidMount() {
    this.setState({
      userID: this.props.userdata.userID,
      companyID: this.props.userdata.companyID,
      companyName: this.props.userdata.companyName,
    });

    this.props.getCompanyActiveSurveys({ companyID: this.props.userdata.companyID })
    this.props.fetchUserGroups({ companyID: this.props.userdata.companyID, userID: this.props.userdata.userID, userType: this.props.userdata.userType })
  }

  previewSurvey = (id) => {
    this.setState({
      survey_link: this.props.company_surveys[id].survey_link,
      tabsvalue: 1
    })
  }

  resetPage = () => {
    this.setState({
      survey_link: '',
      tabsvalue: 0
    })
  }

  render() {
    const { classes, company_surveys, user_groups } = this.props
    const { tabsvalue } = this.state

    return (
      <div className={classes.root}>
        <Paper elevation={0} style={{ maxWidth: '100vw' }} className={classes.pageHeader}>
          <Grid container spacing={2}>
            <Grid style={{ backgroundColor: '#fff' }} item xs={12} sm={12}>
              <Typography variant='h5'>Surveys</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
            </Grid>
            <Grid item xs={12} sm={3}>
              <SurveysList company_surveys={company_surveys} user_groups={user_groups} previewSurvey={this.previewSurvey} />
            </Grid>
            <Grid item xs={12} sm={9}>
              <div>
                {tabsvalue === 0 && (
                  <TabContainer>
                    <div className={classes.papertop} />
                  </TabContainer>
                )}
                {tabsvalue === 1 && (
                  <TabContainer>
                    <div><Button onClick={this.resetPage}>Reset</Button></div>
                    <SurveyView survey_link={this.state.survey_link} />
                  </TabContainer>
                )}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>

    )
  }
}

Surveys.propTypes = {
  classes: PropTypes.object
}

const mapStateToProps = state => {
  return {
    classes: PropTypes.object,
    userdata: state.userDialog.userDetails[0],
    company_surveys: state.users.company_surveys,
    user_groups: state.users.user_groups,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    getCompanyActiveSurveys: params => dispatch(getCompanyActiveSurveys(params)),
    fetchUserGroups: params => dispatch(fetchUserGroups(params)),
  }
}

const ViewSurveysStyled = withStyles(styles)(Surveys)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewSurveysStyled)
