/* eslint-disable consistent-return */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from
  '@material-ui/core'
import { showToast } from '../../../store/actions/toastAction'
import { fetchSubCompetencies } from '../../../store/actions/subcompetenciesActions'
import TasksList from './TasksList'

Array.prototype.groupBy = function (prop) {
  return this.reduce(function (groups, item) {
    const val = item[prop]
    groups[val] = groups[val] || []
    groups[val].push(item)
    return groups
  }, {})
}

function rand() {
  return Math.round(Math.random() * 20) - 10
}
const top = 40 + rand()
const left = 50 + rand()

const styles = theme => ({
  root: {
    width: '100%'
  },
  icons: {
    cursor: 'pointer'
  },
  modal: {
    outline: 'none',
    border: 'none'
  }
})

class MyTasks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editText: '',
      editID: '',
      open: false,
      openConfirm: false
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate() {
    // console.log("groupTasks obj: ", this.props.groupTasks)
  }

  componentWillMount = () => {
    this.fetchData()
  };

  fetchData = () => {
    this.props.fetchSubCompetencies({
      compid: this.props.compid
    })
  };

  filterZZZ = (comLable) => {
    let comLablex = comLable.toString()
    let myTruncatedString = comLablex.substring(0, 4)
    let finalstring = comLablex

    if (myTruncatedString === 'ZZZ_') {
      finalstring = comLablex.substring(4, comLablex.length)
    }
    return finalstring
  };

  render() {
    const { classes, compid, pldpTasks, subcompetencies_list, groupTasks } = this.props
    const listTasks = Object.values(pldpTasks).groupBy('subcompetencyid')

    delete listTasks.undefined
    //  console.log("purple listTasks: ",listTasks);

    return (
      <Grid container spacing={2}>

        <Grid item xs={12}>
          {subcompetencies_list[compid] && Object.keys(subcompetencies_list[compid]).map(id => {
            const labelId = `${subcompetencies_list[compid][id].id}`
            const labelName = `${subcompetencies_list[compid][id].name}`
            const labelNamex = this.filterZZZ(labelName)

            // console.log('purple pldp listTasks: ', listTasks[labelId]);
            // console.log('purple pldp subcompetencies_list: ', subcompetencies_list);
            // console.log('purple pldp labelName: ', labelName);

            if (labelName !== 'undefined') {
              return (
                <div>
                  {listTasks && listTasks[labelId] ?
                    <TasksList
                      editTask={this.props.editTask.bind(this)}
                      completeTask={this.props.completeTask.bind(this)}
                      listTasks={listTasks[labelId]}
                      compid={compid}
                      labelName={labelNamex}
                    />
                    :
                    null}
                </div>
              )
            }
          })}
        </Grid>
      </Grid>
    )
  }
}

MyTasks.propTypes = {
  classes: PropTypes.object.isRequired
}


const mapStateToProps = state => {
  return {
    classes: PropTypes.object.isRequired,
    userdata: state.userDialog.userDetails[0],
    pldpTasks: state.pldp.pldpTasks,
    subcompetencies_list: state.subcompetenciesList.subcompetencies_list
  }
}

const SubCompetenciesStyled = withStyles(styles)(MyTasks)

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    fetchSubCompetencies: params => dispatch(fetchSubCompetencies(params))
    // deleteSubCompetency: params => dispatch(deleteSubCompetency(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubCompetenciesStyled)
