import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import UserTabs from "./UserTabs";
import UploadFiles from "../UploadFiles";
import { PHOTO_PROFILE } from "../../utils/photo_utils";
import {
  SHOW_TOAST,
  UPDATE_USER_SUCCESS,
  CLEAR_UPDATE_USER_SUCCESS
} from "../../store/actions/types";
import {
  updateUserProfile,
  showUserProfile
} from "../../store/actions/userDialogActions";
import { showToast, clearStates } from "../../store/actions/toastAction";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router-dom";


const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: "80px 20px 20px"
    }
  },
  backButton: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px"
    }
  },
  paper: {
    padding: theme.spacing.unit,
    textAlign: "left",
    color: theme.palette.text.secondary
  },
  avatar: {
    margin: 10
  },
  bigAvatar: {
    margin: 10,
    width: 100,
    height: 100
  },
  flexCenterItems: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing.unit,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      padding: "8px 8px 0px"
    },
    flexDirection: "column"
  },
  flexLeftItems: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    padding: theme.spacing.unit,
    color: theme.palette.text.secondary,
    height: "100%",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "center"
    }
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 16
  },
  buttonSmall: {
    fontSize: 12
  }
});
class UserProfile extends Component {
  state = {
    photoURL: "",
    imagename: "",
    imageuploaded: false,
    uploadinprogress: false
  };

  imageUploadFinished = (url, filename) => {
    this.setState({
      ...this.state,
      photoURL: url,
      imagename: filename,
      imageuploaded: url ? true : false,
      uploadinprogress: false
    });

    var body = {
      photoURL: url,
      companyID: this.props.userdetails[0].companyID,
      email: this.props.userdetails[0].email || "",
      userID: this.props.userdetails[0].userID,
      uid: this.props.userdetails[0].id
    };

    // Post CHANGES
    this.props.updateProfileChanges(body);
  };

  uploadInProgress = status => {
    this.setState({
      ...this.state,
      uploadinprogress: status
    });
  };

  componentWillMount = () => {
    this.props.showUserProfile({ type: "SHOW_USER_PROFILE_DIALOG" });
  };

  componentDidUpdate = () => {
    if (this.props.UPDATE_USER === UPDATE_USER_SUCCESS) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "success",
        message: "User profile updated successfully."
      };

      this.setState({
        ...this.state,
        photoURL: "",
        imagename: "",
        imageuploaded: false,
        uploadinprogress: false
      });

      // show success toast
      this.props.showToast(params);

      // clear success state
      this.props.clearStates({ type: CLEAR_UPDATE_USER_SUCCESS });

      // Reload states
      this.props.showUserProfile({ type: "SHOW_USER_PROFILE_DIALOG" });

      // go back to previous page
      this.props.history.goBack();
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes, userdetails } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={24}>
          <Grid container xs={12}>
            <Grid item xs={12} class={classes.backButton}>
              <Paper elevation="0">
                <IconButton
                  className={classes.button}
                  aria-label="Back"
                  onClick={this.goBack}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation="0" className={classes.flexCenterItems}>
                <Typography variant="h5">Edit User Profile</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation="0" className={classes.flexCenterItems}>
                {userdetails.length > 0 ? (
                  <Avatar
                    alt={userdetails[0].firstName}
                    src={PHOTO_PROFILE(userdetails[0].photoURL)}
                    className={classes.bigAvatar}
                  />
                ) : (
                  <Avatar
                    alt="profile"
                    src={PHOTO_PROFILE("")}
                    className={classes.bigAvatar}
                  />
                )}
                <UploadFiles
                  onUploadComplete={this.imageUploadFinished.bind(this)}
                  onUploadProgress={this.uploadInProgress.bind(this)}
                  uploadtype="avatar"
                />
              </Paper>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Paper elevation="0" className={classes.paper}>
                {userdetails.length > 0 ? (
                  <UserTabs userdetails={userdetails} />
                ) : null}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired
};

const EditUserProfile = withStyles(styles)(UserProfile);

const mapStateToProps = state => {
  return {
    userdetails: state.userDialog.userDetails,
    UPDATE_USER: state.userDialog.UPDATE_USER
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    clearStates: params => dispatch(clearStates(params)),
    updateProfileChanges: params => dispatch(updateUserProfile(params)),
    showUserProfile: params => dispatch(showUserProfile(params))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditUserProfile)
);
