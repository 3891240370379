import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import {
    FB_URL
  } from '../store/actions/types'
import {Button} from '@material-ui/core'
//import RealTimeChart from './components/RealTimeChart';
import RealTimeChart from '../store/actions/RealTimeChart.js';

class Sendmecoins extends React.Component {
    state = {
    //NOTHING
     wallet: ""
    };
  
    constructor(props) {
      super(props)
    }
    componentDidMount() {
    //NOTHING
   // console.log('userdataxxx : ', this.props.userdata.wallet)
    }

    async MakeWallet() {
      const userwalletaddress = 'XXX'
      //New Wallet Address: 0xB978BAf9C0EA47b2fbE6A807145918c964E910E3
      //Private Key: 0x6fbd8faaf944de77ca248b662da0bc92a3c66704ccb5738ac84ed2134708369e

      // const userwalletaddress = this.props.wallet;//address of user buying coins
             await axios
             .post(`${FB_URL}/m107-mywallet`, {params: { 
                 wallet: userwalletaddress //user wallet address
               } })
             .then(res => {
                 console.log('mywallet 2', res);
                 this.setState({ wallet: res.data.NewWalletAddress})
             })
         }

async SendMeVisionCoin(userwalletaddress, amount) {
   // const userwalletaddress = this.props.wallet;//address of user buying coins
       console.log('Sendmecoin 1 ', userwalletaddress)
          await axios
          .post(`${FB_URL}/m106-sendmevcoin`, {params: { 
              wallet: userwalletaddress, //user wallet address
              n: '111', 
              amount: amount //number of coins to buy
            } })
          .then(res => {
              console.log('Sendmevcoin 2', res);
          })
      }

      async BuyMeVisionCoin(userwalletaddress, privKey, amount) {
        // console.log('userwalletAddress : ', walletAddress)
            console.log('mywallet 4', userwalletaddress);
               await axios
               .post(`${FB_URL}/m108-buymevision`, {params: { 
                   wallet: userwalletaddress, //user wallet address
                   privateKey: privKey, 
                   amount: amount //$$ sent to buy coins
                 } })
               .then(res => {
                   console.log('Buymevcoin 2', res);
                   this.setState({
                    coinsBought: res.data.amountofcoinspurchased,
                    mevCoinPriceInUSD: res.data.mevCoinPriceInUSD
                   });
               })
           }

  render() {
    var walletAddress = "0xB978BAf9C0EA47b2fbE6A807145918c964E910E3";
    var privKey = "0x6fbd8faaf944de77ca248b662da0bc92a3c66704ccb5738ac84ed2134708369e";
      //New Wallet Address: 0xB978BAf9C0EA47b2fbE6A807145918c964E910E3
      //Private Key: 0x6fbd8faaf944de77ca248b662da0bc92a3c66704ccb5738ac84ed2134708369e
    console.log('userdatamev walletAddress: ', this.props.userdata.wallet);
    console.log('userdatamev privkey: ', this.props.userdata.privateKey);

    return(
      <div>
       <Button 
       onClick={() => this.MakeWallet()} 
       style={{ backgroundColor: "#83AAAC", color: "#fff" }}
       >Create MEV Wallet
       </Button>
       &nbsp;&nbsp;
       <Button 
       //onClick={() => this.SendMeVisionCoin('0xB978BAf9C0EA47b2fbE6A807145918c964E910E3', 2)}
       style={{ backgroundColor: "#83A8DC", color: "#fff" }}
       >Air drop 2 coins
       </Button>
       &nbsp;&nbsp;
       {walletAddress?
       <Button 
       //onClick={() => this.BuyMeVisionCoin(walletAddress, 20)}
       onClick={() => this.BuyMeVisionCoin(walletAddress, privKey, 2)}
       style={{ backgroundColor: "#83A8DC", color: "#fff" }}
       >Buy 2 coins
       </Button>
       :null}
      

      <header>
        <h1>MEV Token Price Tracker</h1>
      </header>
      <div style={{ width:"500px", height:"500px"}}>
        <RealTimeChart />
      </div>
</div>


    )
  }
}

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sendmecoins)
