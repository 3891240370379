import React, { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
//import firebase from "../../config/fbConfig";
import firebase from "../firebase";
import TradeViewChart from 'react-crypto-chart';

// Register necessary Chart.js components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const RealTimeChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const mevRef = firebase.database.ref('MEV');

    const handleData = (snapshot) => {
      const mevData = snapshot.val();

      const timeLabels = [];
      const prices = [];

      for (let key in mevData) {
        if (mevData.hasOwnProperty(key)) {
          const tick = mevData[key];
          timeLabels.push(tick.time);
          prices.push(tick.price);
        }
      }

      if (chartRef.current) {
        chartRef.current.data.labels = timeLabels;
        chartRef.current.data.datasets[0].data = prices;
        chartRef.current.update();
      }
    };

    mevRef.on('value', handleData);

    return () => {
      mevRef.off('value', handleData);
    };
  }, []);

  const data = {
    labels: [],
    datasets: [
      {
        label: 'MEV Price',
        data: [],
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
      },
    ],
  };

  return (
    <div>
    <div>
      <h2>Real-Time MEV Price Chart</h2>
      <Line ref={chartRef} data={data} />
    </div>
    <div>
     <TradeViewChart pair="BTCBUSD" />;
    </div>
    </div>
  );
};

export default RealTimeChart;
