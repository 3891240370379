/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-inline-comments */
import React from 'react'
import PropTypes from 'prop-types'
import CssBaseline from '@material-ui/core/CssBaseline'
import { withStyles } from '@material-ui/core/styles'
import HelpOutline from '@material-ui/icons/HelpOutline'
import LeaderboardAv from '@material-ui/icons/ListAltSharp'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Person from '@material-ui/icons/Person'
import IorgEval from '@material-ui/icons/GroupWorkTwoTone'
import IgenEval from '@material-ui/icons/GroupTwoTone'
import IorgStats from '@material-ui/icons/LibraryBooksSharp'
import IgenStats from '@material-ui/icons/ListTwoTone'
import LibraryBooks from '@material-ui/icons/LibraryBooks'
import SpeakerNotes from '@material-ui/icons/SpeakerNotes'
import PersonPin from '@material-ui/icons/PersonPin'
import PoeIcon from '@material-ui/icons/ContactsRounded'
import Videocam from '@material-ui/icons/Videocam'
import Mic from '@material-ui/icons/Mic'
import RemoveRedEye from '@material-ui/icons/RemoveRedEye'
import PeopleIcon from '@material-ui/icons/People'
import GroupIcon from '@material-ui/icons/GroupWork'
import ACUnitIcon from '@material-ui/icons/AcUnit'
import BusinessIcon from '@material-ui/icons/Business'
import ExitToApp from '@material-ui/icons/ExitToApp'
import InsertChart from '@material-ui/icons/BrightnessHigh'
import IanalyticsChart from '@material-ui/icons/GraphicEqSharp'
import LeaderboardIcon from '@material-ui/icons/ListRounded'
// import ExecCLub from '@material-ui/icons/MoneySharp'
// import Trophy from "@material-ui/icons/alarm-light";
// import { Icon, InlineIcon } from '@iconify/react';
import EmojiEventsIcon from '@material-ui/icons/WbSunnyOutlined'
import pledgeIcon from '@material-ui/icons/CardGiftcard'
import AlisonIcon from '@material-ui/icons/StarHalfOutlined'
import BYUIcon from '@material-ui/icons/StarBorderRounded'
import MEvisionIcon from '@material-ui/icons/PersonSharp'
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck'
import Downloadapp from '@material-ui/icons/CloudDownload'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DownloadNote from '../../store/actions/DownloadEXEnoteb'
// import LiveHelpIcon from '@material-ui/icons/LiveHelp'
// import CompetenciesManage from "../../components/categories/competenciesManage";
// import competenciesIcon from "@material-ui/icons/Accessible";
import Badge from '@material-ui/core/Badge'
import StarRoundedIcon from '@material-ui/icons/StarRounded'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'
import { hideToast, showToast } from '../../store/actions/toastAction'
import {
  APP_TYPE,
  ACCESS_COMPANY_PROFILE_USER,
  IS_COMPANY_ADMIN,
  PUBLIC_COMPANY_ID, USER_CLICKS_SECTION,
  SHOW_SPEEDDIAL,
  LEADERBOARD_LOADING
} from '../../store/actions/types'

import {
  loadingAction
} from '../../store/actions/loadingAction'

import {
  captureUserActivity
} from '../../store/actions/analyticsActions'
import { showSpeedDial } from '../../store/actions/speedDialAction'
import {
  getAllCompanyLeaders
} from '../../store/actions/companyAdminActions'
import { DEEPORANGE } from '../../utils/color_utils'
import { Link } from 'react-router-dom'
import { isBrowser } from 'react-device-detect'
import ChatIcon from '@material-ui/icons/Chat'
import Exectext from './Exectext'
import Button from '@material-ui/core/Button'
import {
  CgetOpinions
} from '../../store/actions/opinionCActions'
import {
  GgetOpinions
} from '../../store/actions/opinionG_Actions'
import {
  getOpinions
} from '../../store/actions/opinionActions'

const styles = () => ({
  root: {
    width: '100%',
    // backgroundColor: '#222d32',
    backgroundColor: '#ffffff', // '#000',
    color: '#ffffff' // '#fff'
  },
  inset: {
    paddingLeft: '12px'
  },
  listselected: {
    color: `${DEEPORANGE}`
  },
  drawer: {
    width: '280px'
  },
  subheader: {
    textTransform: 'upperCase',
    fontSize: '9pt',
    // color: '#fff',
    color: '#000000',
    fontWeight: 'bold',
    // boxShadow: '0px 0px 10px rgba(50,50,50,0.5)',
    // backgroundColor: '#2a2e37',
    backgroundColor: '#fff',
    lineHeight: '32px'
  },
  lsubheader: {
    textTransform: 'upperCase',
    fontSize: '9pt',
    // color: '#fff',
    color: '#000000',
    fontWeight: 'bold',
    // boxShadow: '0px 0px 10px rgba(50,50,50,0.5)',
    // backgroundColor: '#2a2e37',
    backgroundColor: '#fff',
    lineHeight: '32px',
    textDecoration: 'none'
    // a:link: { 'textDecoration': 'none' },
    // a:visited: { textDecoration: 'none' },
    // a:hover: { textDecoration: 'none' },
    // a:active: { textDecoration: 'none' }
  },
  listtext: {
    'fontSize': '10pt',
    // color: 'silver',
    'color': '#000',
    '&:hover, &.active:hover': {
      color: '#000000'
    }
  },
  listExec: {
    'fontSize': '10pt',
    // color: 'silver',
    'color': '#000000',
    '&:hover, &.active:hover': {
      color: '#000000'
    }
  },
  listitem: {
    'height': '50px',
    '&.active': {
      '& path': {
        color: 'red'
      }
    },
    '&:hover, &.active:hover': {
      '& path': {
        color: '#cccccc'
      }
    }
  },
  active: {
    backgroundColor: 'red',
    color: 'red'
  },
  iconBorder: {
    width: '35px!important',
    height: '35px!important',
    borderRadius: '50%',
    textAlign: 'center',
    backgroundColor: 'rgba(255,255,255,0.085)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'small'
  },
  menuIcons: {
    color: '#000',
    marginRight: '0',
    fontSize: 'small',
    minWidth: '0px!important'
  },
  badge: {
    backgroundColor: DEEPORANGE,
    padding: 2,
    borderRadius: 4
  },
  accordion: {
    // backgroundColor: '#2a2e37',
    backgroundColor: '#fff'
  },
  accordionItem: {
    width: '100%'
  },
  column: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    width: '100%'
  }

})

class SideBar extends React.Component {
  state = {
    showDownloadNote: false
    // loadingLeaders: false
  };

  loadLeaderboard = () => {
    // this.setState({ loadingLeaders: true })
    this.props.loadingAction({
      leaderboard_loading: 1,
      type: LEADERBOARD_LOADING
    })
    console.log('green loadLeaderboard : ', this.props.userdata.companyID)
    const promise = this.getUsers()
    promise.then(this.onFulfilled, this.onRejected)
  }

  getUsers = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.props.getAllCompanyLeaders({
          orderChild: 'LeaderboardKey',
          LeaderboardKey: 'x=x=x=x',
          companyID: this.props.userdata.companyID
        })
      }, 3000)
    })
  }

  componentDidMount() {
    if (this.props.userdata) {
      this.props.CgetOpinions({ // loads the opinions of leaders recorded in state
        leaderID: this.props.userdata.companyID
      })
      this.props.GgetOpinions({ // loads the opinions of leaders recorded in state
        leaderID: this.props.userdata.companyID
      })
      this.props.getOpinions({ // loads the opinions of leaders recorded in state
        leaderID: this.props.userdata.userID
      })
    }
  }

  setDownload = () => {
    this.setState({ showDownloadNote: true })
  }

  loadCompetencies = () => {
    this.setState({ showCompmenu: true })
  }

  toggleDrawer = (page) => {
    let params2 = {
      userdata: this.props.userdata,
      type: USER_CLICKS_SECTION,
      page
    }

    this.props.captureUserActivity({ ...params2 })
    this.props.toggleDrawer()
    // console.log('toggleDrawerx: ', this.props.speedDPath);

    let body = {
      type: SHOW_SPEEDDIAL,
      path: page
    }
    this.props.showSpeedDial(body)
    // console.log('toggleDrawerx: ', this.props.speedDPath);
  };

  render() {
    const {
      classes,
      unseenotifications,
      unseengroupnotifications,
      unseenpldpnotifications,
      userdata,
      loadingLBLeaders
    } = this.props

    var path = window.location.pathname;
    var page = path.split("/").pop();
    console.log('green mean sidebar : ', page)

    if (this.props.userdata) {
      console.log('green mean this.props.userdata: ', this.props.userdata)
    }
    var totalLeadershipConversationNotifications = 0;
    console.log('green totalNotifications unseenotifications.companythoughts: ', unseenotifications.companythoughts)
    console.log('green totalNotifications unseengroupnotifications.unseengroupnotificationscnt: ', unseengroupnotifications.unseengroupnotificationscnt)
    console.log('green totalNotifications unseenotifications.ileadcorporate: ', unseenotifications.ileadcorporate)
   if (unseengroupnotifications.unseengroupnotificationscnt || 
    unseenotifications.ileadcorporate) {
        totalLeadershipConversationNotifications = unseengroupnotifications.unseengroupnotificationscnt + unseenotifications.ileadcorporate
    }
    else
    {
      totalLeadershipConversationNotifications = 0
    }
    const totalLibraryNotifications = unseenotifications.topleaderthoughts + unseenotifications.dailythoughts
    const totalPLFPNotifications = unseenpldpnotifications.pldpnotifications

    const drawer = (
      <div>
        { userdata && (((userdata.companyID === '-KgsUcgfo7z1U9MXgd9i') ||
      (userdata.companyID === '-LjW7UI7e0uUikYrw-Vj') ||
      (userdata.companyID === '-MXbWQy6QoZ4RfN04pXe') ||
      (userdata.companyID === '-MRZC7xU_69moSco5_sX') ||
      (userdata.companyID === '-MZwJp46RoDtehNEGly3') ||
      (userdata.companyID === '-MHRD2gH8B-4umEza1eG') ||
      (userdata.companyID === '-Mci2tVbY406VWKWa5Ov') ||
      (userdata.companyID === '-LoacuJOqQP7sZU7_sFQ') ||
      (userdata.companyID === '-MwlTcmm_zvbLlRqmJP3') ||
      (userdata.companyID === '-MxtPrQQGFMGUZOcl_QZ') ||
      (userdata.companyID === '-N3xp_AnYTDW_vLIB6h3') ||
      (userdata.companyID === '-NG0pAn_r4HUmzGjwhH4') ||
      (userdata.companyID === '-NTUaz-CD0gKI2jagykx') ||
      (userdata.companyID === '-NV-bhSOa3T8iQKaYMNZ') ||
      (userdata.companyID === '-NVZ5YC5BVUhSb-BhyX7') ||
      (userdata.companyID === '-NRbTwxbqFLepk62Eq6M')
        )) ?
          // <List>
          //   <ListItem
          //     className={classes.listitem}
          //     component={Link}
          //     to='/gotocalf'
          //     button
          //     onClick={() => {
          //       this.toggleDrawer('gotocalf')
          //     }}
          //   >
          //     <div className={classes.iconBorder}>
          //       <ListItemIcon classes={{ root: classes.menuIcons }}>
          //         <InsertChart />
          //       </ListItemIcon>
          //     </div>
          //     <ListItemText
          //       classes={{ primary: classes.listtext, inset: classes.inset }}
          //       inset
          //       primary={this.props.userdata.execmember ? 'Executive Club' : <Exectext />}
          //     />
          //   </ListItem>
          // </List>
          <div>

          {/* PATREON//////////////////////////////////////////////////////////////////////// */}
          { userdata && (userdata.patron !== true) ?
            <List>
            <Link
              style={{ textDecoration: 'none' }}
              to={{
                pathname: '/gotopatreon',
              }}
              onClick={() => {
                this.toggleDrawer('gotopatreon')
              }}
            >
              <Badge>
              <Typography className={classes.lsubheader}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Confirm Pledge</Typography> 
              </Badge>
            </Link>
          </List>
          : null}
          {/* /////////////////////////////////////////////////////////////////////////////////////// */}
          { userdata && (userdata.patron !== true) ?
          <List>
            <ListItem
              className={classes.listitem}
            >
              {/* <div className={classes.iconBorder}>
                <ListItemIcon classes={{ root: classes.menuIcons }}>
                  <pledgeIcon />
                </ListItemIcon>
              </div> */}
              <a target='main' href='https://www.patreon.com/mevision'
                style={{
                  textDecoration: 'none', color: '#000000',
                  backgroundColor: 'none', paddingLeft: '3px',
                  paddingRight: '8px', paddingTop: '0px', paddingBottom: '0px',
                  marginTop: '0px', fontSize: '12px'
                }}><b>PLEDGE</b>
              </a>
              <ListItemText
                classes={{ primary: classes.listtext, inset: classes.inset }}
              />
            </ListItem>
          </List>  
          : null}
          {/* //////PATREON END LINKS///////////////////////////////////////////////////////////////////////////////// */}
          <List>
            <Link
              style={{ textDecoration: 'none' }}
              to={{
                pathname: '/gotocalf',
                // state: {
                //   Everyone: 'xx=x=x=x'
                // }
              }}
              onClick={() => {
                this.toggleDrawer('gotocalf')
              }}
            >
              <Badge>
                {/* <Typography className={classes.lsubheader}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.userdata.execmember ? 'Executive Club' : <Exectext />}</Typography> */}
              <Typography className={classes.lsubheader}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Learning Platform</Typography> 
              </Badge>
            </Link>
          </List>
          </div>
          : null}
        {/* LEADERBOARD ------------------------------------*/}
        {this.props.leaderboardData ?
          <List>
            <Link
              style={{ textDecoration: 'none' }}
              to={{
                pathname: '/LeaderOnList',
                state: {
                  Everyone: 'xx=x=x=x'
                }
              }}
              onClick={() => {
                // console.log('this.toggleDrawer:', this.toggleDrawer);
                // console.log('state:', {
                //   Everyone: 'xx=x=x=x'
                // });
                this.toggleDrawer('leaderOnList')
              }}
            >
              <Badge>
                <Typography className={classes.lsubheader}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Leader-track</Typography>
              </Badge>
            </Link>
          </List>
        // <Link
        //    style={{ textDecoration: 'none', fontSize: '14px' }}
        //    to={{
        //       pathname: '/LeaderOnList',
        //       state: {
        //         Everyone: 'xx=x=x=x'
        //       }
        //     }}
        //    onClick={() => {
        //     this.toggleDrawer('leaderOnList')
        //    }}
        //   >
        //    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<LeaderboardAv />
        //   <font style={{
        //     margin:"12px",
        //     fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        //     color: '#000000',
        //     fontSize: '14px'
        //   }}>&nbsp;&nbsp;Leaderboard</font>
        // </Link>
          :
          <List>
            <Button
              size='small'
              color='#000000'
              type='submit'
              onClick={() => this.loadLeaderboard()}
            >
              <Badge>
                <Typography className={classes.lsubheader}>&nbsp;&nbsp;&nbsp;&nbsp;Load Leader-track</Typography>
              </Badge>

              { this.props.leaderboardData ? null : loadingLBLeaders === 1 ?
                <div style={{
                  color: 'red',
                  align: 'center'
                }}>...Loading...</div> : null
              }

            </Button>
          </List>
          // <Button
          // size='small'
          // color='#000000'
          // type="submit" onClick={() => this.loadLeaderboard()}
          // >
          // <LeaderboardAv />
          // <font style={{
          //   margin:"2px",
          //   textShadow: '0.2px 0.2px rgb(247, 220, 111), -0.5px -0.5px rgba(247, 220, 111)',
          //   fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          //   color: 'rgba(29, 29, 24)',
          //   fontSize: '14px'
          // }}>Load the Leaderboard</font>

          // { this.props.leaderboardData ? null : loadingLBLeaders===1 ?
          // <div style={{
          //   color: 'red',
          //   align: 'center'
          //   }}>Loading the Leaderboard...</div> :null
          // }

          // </Button>
        }
        {/* CALF APP ---------------------------------------*/}
        {/* Seamless Certificate -actionSA--------------------------------------*/}
        { userdata && userdata.companyID === '-MHRD2gH8B-4umEza1eG' ?
          <List>
            <ListItem
              className={classes.listitem}
            >
              <div className={classes.iconBorder}>
                <ListItemIcon classes={{ root: classes.menuIcons }}>
                  <EmojiEventsIcon />
                </ListItemIcon>
              </div>
              <a target='main' href='https://www.actionsa.org.za/certify/'
                style={{
                  textDecoration: 'none', color: '#000000',
                  backgroundColor: 'none', paddingLeft: '12px',
                  paddingRight: '8px', paddingTop: '8px', paddingBottom: '15px',
                  marginTop: '10px'
                }}>Action-Leadership Cert.
              </a>
              <ListItemText
                classes={{ primary: classes.listtext, inset: classes.inset }}
              />
            </ListItem>
          </List>
          : null}

        {/* Seamless Certificate -leadership platform--------------------------------------*/}
                {/* Alison  -------------------------------------*/}
                {/* { userdata && userdata.execmember ?
          <List>
            <ListItem
              className={classes.listitem}
            >
              <div className={classes.iconBorder}>
                <ListItemIcon classes={{ root: classes.menuIcons }}>
                  <EmojiEventsIcon />
                </ListItemIcon>
              </div>
              <a target='main' href='https://alison.com/?utm_source=alison_user&utm_medium=affiliates&utm_campaign=30620902'
                style={{
                  textDecoration: 'none', color: '#000000',
                  backgroundColor: 'none', paddingLeft: '12px',
                  paddingRight: '8px', paddingTop: '8px', paddingBottom: '15px',
                  marginTop: '10px'
                }}>Online Learning
              </a>
              <ListItemText
                classes={{ primary: classes.listtext, inset: classes.inset }}
              />
            </ListItem>
          </List>
          : null} */}

        {/* Alison--------------------------------------*/}
        { userdata && (userdata.companyID === '-KgsUcgfo7z1U9MXgd9i') ?
          <List>
            <Badge>
              <Typography className={classes.lsubheader}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a target='main' href='https://bit.ly/37fEG32'
                style={{ textDecoration: 'none', color: '#000000' }}>
                Seamless Leadership Certificate</a></Typography>
            </Badge>

          </List>
          // <List>
          //   <ListItem
          //     className={classes.listitem}
          //   >
          //     <div className={classes.iconBorder}>
          //       <ListItemIcon classes={{ root: classes.menuIcons }}>
          //         <EmojiEventsIcon />
          //       </ListItemIcon>
          //     </div>
          //     <a target='main' href='https://bit.ly/37fEG32'
          //       style={{
          //         textDecoration: 'none', color: '#000000',
          //         backgroundColor: 'none', paddingLeft: '12px',
          //         paddingRight: '8px', paddingTop: '8px', paddingBottom: '15px',
          //         marginTop: '10px'
          //       }}>Seamless Leadership Certificate
          //     </a>
          //     <ListItemText
          //       classes={{ primary: classes.listtext, inset: classes.inset }}
          //     />
          //   </ListItem>
          // </List>
          : null}

        {/* GLI APP ---------------------------------------*/}
        { userdata && userdata.companyID === '-LoacuJOqQP7sZU7_sFQ' ?
          <List>
            <ListItem
              className={classes.listitem}
            >
              <div className={classes.iconBorder}>
                <ListItemIcon classes={{ root: classes.menuIcons }}>
                  <InsertChart />
                </ListItemIcon>
              </div>
              <a target='main' href='https://global-leadership-initiatives-inc.teachable.com/'
                style={{
                  textDecoration: 'none', color: 'white',
                  backgroundColor: 'none', paddingLeft: '12px',
                  paddingRight: '8px', paddingTop: '8px', paddingBottom: '15px',
                  marginTop: '10px'
                }}>GLI Leadership Courses
              </a>
              <ListItemText
                classes={{ primary: classes.listtext, inset: classes.inset }}
              />
            </ListItem>
          </List>
          : null}

        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: ' #ccbc74' }} />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Badge
              invisible={
                totalLeadershipConversationNotifications > 0 ? false : true
              }
              badgeContent={
                <span className={classes.badge}>
                  {totalLeadershipConversationNotifications}
                </span>
              }
            >
              <Typography className={classes.subheader}>LEAD Conversations</Typography>
            </Badge>

          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.column}>
              <div className={classes.accordionItem}>
                {APP_TYPE !== 'PUBLIC' ? (
                  <div>
                    {/* {userdata && userdata.companyID === '-Lu4BlhZ-tsDSnd-fnFd' ? (
                      <span>
                        <ListItem
                          classes={{
                            root: classes.listitem,
                            selected: classes.listselected
                          }}
                          exact
                          component={Link}
                          to='/groupmessages'
                          button
                          onClick={() => {
                            this.toggleDrawer('groupmessages')
                          }}
                        >
                          <div className={classes.iconBorder}>
                            <ListItemIcon classes={{ root: classes.menuIcons }}>
                              <Badge
                                invisible={
                                  unseengroupnotifications.unseengroupnotificationscnt > 0 ? false : true
                                }
                                badgeContent={
                                  <span className={classes.badge}>
                                    {unseengroupnotifications.unseengroupnotificationscnt}
                                  </span>
                                }
                              >
                                <GroupIcon />
                              </Badge>
                            </ListItemIcon>
                          </div>
                          <ListItemText
                            classes={{ primary: classes.listtext, inset: classes.inset }}
                            inset
                            primary='Group Forums'
                          />
                        </ListItem>
                        <ListItem
                          classes={{
                            root: classes.listitem,
                            selected: classes.listselected
                          }}
                          exact
                          component={Link}
                          to='/organisational'
                          button
                          onClick={() => {
                            this.toggleDrawer('organisational')
                          }}
                        >
                          <div className={classes.iconBorder}>
                            <ListItemIcon classes={{ root: classes.menuIcons }}>
                              <Badge
                                invisible={
                                  unseenotifications.companythoughts > 0 ? false : true
                                }
                                badgeContent={
                                  <span className={classes.badge}>
                                    {unseenotifications.companythoughts}
                                  </span>
                                }
                              >
                                <BusinessIcon />
                              </Badge>
                            </ListItemIcon>
                          </div>
                          <ListItemText
                            classes={{ primary: classes.listtext, inset: classes.inset }}
                            inset
                            primary='Club Forum'
                          />
                        </ListItem>
                      </span>
                    ) : ( */}
                    <span>
                      {userdata && userdata.companyID === '-LjW7UI7e0uUikYrw-Vj' ? (<div/>) : (
                        <ListItem
                          classes={{
                            root: classes.listitem,
                            selected: classes.listselected
                          }}
                          exact
                          component={Link}
                          to='/organisational'
                          button
                          onClick={() => {
                            this.toggleDrawer('organisational')
                          }}
                        >
                          <div className={classes.iconBorder}>
                            <ListItemIcon classes={{ root: classes.menuIcons }}>
                              <Badge
                                invisible={
                                  unseenotifications.companythoughts > 0 ? false : true
                                }
                                badgeContent={
                                  <span className={classes.badge}>
                                    {unseenotifications.companythoughts}
                                  </span>
                                }
                              >
                                <BusinessIcon />
                              </Badge>
                            </ListItemIcon>
                          </div>
                          <ListItemText
                            classes={{ primary: classes.listtext, inset: classes.inset }}
                            inset
                            primary=' nnnn'
                          />
                        </ListItem>
                      )}

                      <ListItem
                        classes={{
                          root: classes.listitem,
                          selected: classes.listselected
                        }}
                        exact
                        component={Link}
                        to='/groupmessages'
                        button
                        onClick={() => {
                          this.toggleDrawer('groupmessages')
                        }}
                      >
                        <div className={classes.iconBorder}>
                          <ListItemIcon classes={{ root: classes.menuIcons }}>
                            <Badge
                              invisible={
                                unseengroupnotifications.unseengroupnotificationscnt > 0 ? false : true
                              }
                              badgeContent={
                                <span className={classes.badge}>
                                  {unseengroupnotifications.unseengroupnotificationscnt}
                                </span>
                              }
                            >
                              <GroupIcon />
                            </Badge>
                          </ListItemIcon>
                        </div>
                        <ListItemText
                          classes={{ primary: classes.listtext, inset: classes.inset }}
                          inset
                          primary='Group Forums'
                        />
                      </ListItem>
                    </span>


                    {userdata && userdata.companyID === '-Lu4BlhZ-tsDSnd-fnFd' ? (null) : (
                      <ListItem
                        className={classes.listitem}
                        component={Link}
                        to={page==='ilead' ? '/' : '/ilead'}
                        onClick={() => {
                          var npage;
                          if (page==='ilead') {npage = '/'} else {npage = '/ilead'}
                          this.toggleDrawer(npage)
                        }}
                      >
                        <div className={classes.iconBorder}>
                          <ListItemIcon classes={{ root: classes.menuIcons }}>
                            <Badge
                              invisible={
                                unseenotifications.ileadcorporate > 0 ? false : true
                              }
                              badgeContent={
                                <span className={classes.badge}>
                                  {unseenotifications.ileadcorporate}
                                </span>
                              }
                            >
                              <ACUnitIcon />
                            </Badge>
                          </ListItemIcon>
                        </div>
                        <ListItemText
                          classes={{ primary: classes.listtext, inset: classes.inset }}
                          inset
                          primary='Global Forum'
                        />
                      </ListItem>
                    )}

                  </div>
                ) : userdata ? (
                  <div>
                    {/* {userdata.companyID === '-Lu4BlhZ-tsDSnd-fnFd' ? (
                      <span>
                        <ListItem
                          classes={{
                            root: classes.listitem,
                            selected: classes.listselected
                          }}
                          exact
                          component={Link}
                          to='/groupmessages'
                          button
                          onClick={() => {
                            this.toggleDrawer('groupmessages')
                          }}
                        >
                          <div className={classes.iconBorder}>
                            <ListItemIcon classes={{ root: classes.menuIcons }}>
                              <Badge
                                invisible={
                                  unseengroupnotifications.unseengroupnotificationscnt > 0 ? false : true
                                }
                                badgeContent={
                                  <span className={classes.badge}>
                                    {unseengroupnotifications.unseengroupnotificationscnt}
                                  </span>
                                }
                              >
                                <GroupIcon />
                              </Badge>
                            </ListItemIcon>
                          </div>
                          <ListItemText
                            classes={{ primary: classes.listtext, inset: classes.inset }}
                            inset
                            primary='Group Forums'
                          />
                        </ListItem>
                        <ListItem
                          classes={{
                            root: classes.listitem,
                            selected: classes.listselected
                          }}
                          exact
                          component={Link}
                          to='/organisational'
                          button
                          onClick={()=> {
                            this.toggleDrawer('organisational')
                          }}
                        >
                          <div className={classes.iconBorder}>
                            <ListItemIcon classes={{ root: classes.menuIcons }}>
                              <Badge
                                invisible={
                                  unseenotifications.companythoughts > 0 ? false : true
                                }
                                badgeContent={
                                  <span className={classes.badge}>
                                    {unseenotifications.companythoughts}
                                  </span>
                                }
                              >
                                <BusinessIcon />
                              </Badge>
                            </ListItemIcon>
                          </div>
                          <ListItemText
                            classes={{ primary: classes.listtext, inset: classes.inset }}
                            inset
                            primary='Club Forum'
                          />
                        </ListItem>
                      </span>
                    ) :  */}

                    {userdata && userdata.companyID === '-LjW7UI7e0uUikYrw-Vj' ? (<div/>) : (
                      <span>
                        <ListItem
                          classes={{
                            root: classes.listitem,
                            selected: classes.listselected
                          }}
                          exact
                          component={Link}
                          to='/organisational'
                          button
                          onClick={()=> {
                            this.toggleDrawer('organisational')
                          }}
                        >
                          <div className={classes.iconBorder}>
                            <ListItemIcon classes={{ root: classes.menuIcons }}>
                              <Badge
                                invisible={
                                  unseenotifications.companythoughts > 0 ? false : true
                                }
                                badgeContent={
                                  <span className={classes.badge}>
                                    {unseenotifications.companythoughts}
                                  </span>
                                }
                              >
                                <BusinessIcon />
                              </Badge>
                            </ListItemIcon>
                          </div>
                          <ListItemText
                            classes={{ primary: classes.listtext, inset: classes.inset }}
                            inset
                            primary='Club Forum'
                          />
                        </ListItem>
                      </span>
                    )}

                    <span>
                      <ListItem
                        classes={{
                          root: classes.listitem,
                          selected: classes.listselected
                        }}
                        exact
                        component={Link}
                        to='/groupmessages'
                        button
                        onClick={()=> {
                          this.toggleDrawer('groupmessages')
                        }}
                      >
                        <div className={classes.iconBorder}>
                          <ListItemIcon classes={{ root: classes.menuIcons }}>
                            <Badge
                              invisible={
                                unseengroupnotifications.unseengroupnotificationscnt > 0 ? false : true
                              }
                              badgeContent={
                                <span className={classes.badge}>
                                  {unseengroupnotifications.unseengroupnotificationscnt}
                                </span>
                              }
                            >
                              <GroupIcon />
                            </Badge>
                          </ListItemIcon>
                        </div>
                        <ListItemText
                          classes={{ primary: classes.listtext, inset: classes.inset }}
                          inset
                          primary='Group Forums'
                        />
                      </ListItem>
                    </span>
                    <ListItem
                      className={classes.listitem}
                      component={Link}
                      to={page==='ilead' ? '/' : '/ilead'}
                      button
                      onClick={()=> {
                        //this.toggleDrawer('ilead')
                        var npage;
                        if (page==='ilead') {npage = '/'} else {npage = '/ilead'}
                        this.toggleDrawer(npage)
                      }}
                    >
                      <div className={classes.iconBorder}>
                        <ListItemIcon classes={{ root: classes.menuIcons }}>
                          <Badge
                            invisible={
                              unseenotifications.ileadpublic > 0 ? false : true
                            }
                            badgeContent={
                              <span className={classes.badge}>
                                {unseenotifications.ileadpublic}
                              </span>
                            }
                          >
                            <ACUnitIcon />
                          </Badge>
                        </ListItemIcon>
                      </div>
                      <ListItemText
                        classes={{ primary: classes.listtext, inset: classes.inset }}
                        inset
                        primary='Global Forum'
                      />
                    </ListItem>
                  </div>
                ) : <ListItem
                  className={classes.listitem}
                  component={Link}
                  to={page==='ilead' ? '/' : '/ilead'}
                  button
                  onClick={()=> {
                   // this.toggleDrawer('ilead')
                   var npage;
                   if (page==='ilead') {npage = '/'} else {npage = '/ilead'}
                   this.toggleDrawer(npage)
                  }}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>
                      <Badge
                        invisible={
                          unseenotifications.ileadpublic > 0 ? false : true
                        }
                        badgeContent={
                          <span className={classes.badge}>
                            {unseenotifications.ileadpublic}
                          </span>
                        }
                      >
                        <ACUnitIcon />
                      </Badge>
                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='Global Forum'
                  />
                </ListItem>}
              </div>
              <br />
              <div className={classes.accordionItem}>
                <ListItem
                  className={classes.listitem}
                  component={Link}
                  to='/myposts'
                  button
                  onClick={()=> {
                    this.toggleDrawer('myposts')
                  }}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>
                      <PersonPin />
                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='My LEADS'
                  />
                </ListItem>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {this.props.userdata && this.props.userdata.companyID === '-Lu4BlhZ-tsDSnd-fnFd' ? (<List
          component='nav'
          subheader={
            <ListSubheader className={classes.subheader} component='div'>
      Harmony Practical Case Studies
            </ListSubheader>
          }
          className={classes.root}
        />) : (
          null
        )}


        {userdata && ['-LgsUykfo8z1U9MXgd9'].includes(this.props.userdata.companyID) ? (null) : (
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: '#ccbc74' }} />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Badge
                invisible={
                  totalPLFPNotifications > 0 ? false : true
                }
                badgeContent={
                  <span className={classes.badge}>
                    {totalPLFPNotifications}
                  </span>
                }
              >
                <Typography className={classes.subheader}>Personal Development</Typography>
              </Badge>

            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.column}>
                <ListItem
                  className={classes.listitem}
                  component={Link}
                  to='/pldp'
                  button
                  onClick={()=> {
                    this.toggleDrawer('pldp')
                  }}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>
                      <Badge
                        invisible={
                          unseenpldpnotifications.pldpnotifications > 0 ? false : true
                        }
                        badgeContent={
                          <span className={classes.badge}>
                            {unseenpldpnotifications.pldpnotifications}
                          </span>
                        }
                      >
                        <LibraryBooks />
                      </Badge>
                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='Personal Development Plan' // primary="Personal (LDP)"
                  />
                </ListItem>

          {/* Me-Vision--------------------------------------*/}
          { userdata ?

            <ListItem
              className={classes.listitem}
            >
            <div className={classes.iconBorder}>
            <ListItemIcon classes={{ root: classes.menuIcons }}>
              <MEvisionIcon />
            </ListItemIcon>
            </div>
            <a target='main' href='https://www.mevisionacademy.com'
            style={{
              textDecoration: 'none', color: '#000000',
              backgroundColor: 'none', paddingLeft: '12px',
              paddingRight: '8px', paddingTop: '8px', paddingBottom: '15px',
              marginTop: '10px', fontSize: '13px'
            }}>Me-Vision Academy
            </a>
            <ListItemText
            classes={{ primary: classes.listtext, inset: classes.inset }}
            />
            </ListItem>
            : null} 

                <ListItem
                  className={classes.listitem}
                  component={Link}
                  to='/favourites'
                  button
                  onClick={()=> {
                    this.toggleDrawer('pldp')
                  }}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>

                      <StarRoundedIcon />

                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='Favourites' // primary="Personal (LDP)"
                  />
                </ListItem>

                {/* Alison  -------------------------------------*/}
         {/* { userdata && userdata.execmember ? */}
         { userdata ?
          <List>
            <ListItem
              className={classes.listitem}
            >
              <div className={classes.iconBorder}>
                <ListItemIcon classes={{ root: classes.menuIcons }}>
                  <AlisonIcon />
                </ListItemIcon>
              </div>
              <a target='main' href='https://alison.com/?utm_source=alison_user&utm_medium=affiliates&utm_campaign=30620902'
                style={{
                  textDecoration: 'none', color: '#000000',
                  backgroundColor: 'none', paddingLeft: '12px',
                  paddingRight: '8px', paddingTop: '8px', paddingBottom: '15px',
                  marginTop: '10px', fontSize: '13px'
                }}>Certificates and Diplomas
              </a>
              <ListItemText
                classes={{ primary: classes.listtext, inset: classes.inset }}
              />
            </ListItem>
          </List>
          : null} 

        {/* Alison--------------------------------------*/}
        { userdata ?
          <List>
            <ListItem
              className={classes.listitem}
            >
              <div className={classes.iconBorder}>
                <ListItemIcon classes={{ root: classes.menuIcons }}>
                  <BYUIcon />
                </ListItemIcon>
              </div>
              <a target='main' href='https://www.byupathway.org/applynow'
                style={{
                  textDecoration: 'none', color: '#000000',
                  backgroundColor: 'none', paddingLeft: '12px',
                  paddingRight: '8px', paddingTop: '8px', paddingBottom: '15px',
                  marginTop: '10px', fontSize: '13px'
                }}>International Degrees
              </a>
              <ListItemText
                classes={{ primary: classes.listtext, inset: classes.inset }}
              />
            </ListItem>
          </List>
          : null} 

        {/* Alison--------------------------------------*/}
                {/* <ListItem
                  className={classes.listitem}
                  // component={Link}
                  // to='https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/files%2FPoints%20template.docx?alt=media&token=20d1b269-9da4-4732-a6d6-976e7ed8fb12'
                  button
                  onClick={()=> {
                    this.toggleDrawer('pldp')
                    window.open('https://firebasestorage.googleapis.com/v0/b/leadershipplatform-158316.appspot.com/o/files%2FReport%20Template%203.docx?alt=media&token=01d260a7-74aa-43d6-b0d4-fed22e4d9328')
                  }}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>
                      <LiveHelpIcon />
                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='Generate Points' // primary="Personal (LDP)"
                  />
                </ListItem> */}

              </div>
            </AccordionDetails>
          </Accordion>
        )}

{/* <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: '#ccbc74' }} />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >

            <Badge
              invisible={
                totalLibraryNotifications > 0 ? false : true
              }
              badgeContent={
                <span className={classes.badge}>
                  {totalLibraryNotifications}
                </span>
              }
            >
              <Typography className={classes.subheader}>LIBRARY
              </Typography>
            </Badge>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.column}>
              {userdata && userdata.companyID === '-Lu4BlhZ-tsDSnd-fnFd' ? (null) : (
                <ListItem
                  className={classes.listitem}
                  component={Link}
                  to='/globalcontributors'
                  button
                  onClick={()=> {
                    this.toggleDrawer('globalcontributors')
                  }}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>
                      <Badge
                        invisible={
                          unseenotifications.topleaderthoughts > 0 ? false : true
                        }
                        badgeContent={
                          <span className={classes.badge}>
                            {unseenotifications.topleaderthoughts}
                          </span>
                        }
                      >
                        <PeopleIcon />
                      </Badge>
                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='Global Contributors'
                  />
                </ListItem>
              )
              }

              <ListItem
                    className={classes.listitem}
                    component={Link}
                    to="/Bookshop"
                    button
                    onClick={e => { this.toggleDrawer("bookshop") }}
                  >
                    <div className={classes.iconBorder}>
                      <ListItemIcon classes={{ root: classes.menuIcons }}>
                        <LibraryBooks />
                      </ListItemIcon>
                    </div>
                    <ListItemText
                      classes={{ primary: classes.listtext, inset: classes.inset }}
                      inset
                      primary="Bookshop"
                    />
                  </ListItem> 

              <ListItem
                className={classes.listitem}
                component={Link}
                to='/videos'
                button
                onClick={()=> {
                  this.toggleDrawer('videos')
                }}
              >
                <div className={classes.iconBorder}>
                  <ListItemIcon classes={{ root: classes.menuIcons }}>
                    <Badge
                      invisible={unseenotifications.videos > 0 ? false : true}
                      badgeContent={
                        <span className={classes.badge}>
                          {unseenotifications.videos}
                        </span>
                      }
                    >
                      <Videocam />
                    </Badge>
                  </ListItemIcon>
                </div>
                <ListItemText
                  classes={{ primary: classes.listtext, inset: classes.inset }}
                  inset
                  primary='Videos'
                />
              </ListItem>
              <ListItem
                className={classes.listitem}
                component={Link}
                to='/podcasts'
                button
                onClick={()=> {
                  this.toggleDrawer('podcasts')
                }}
              >
                <div className={classes.iconBorder}>
                  <ListItemIcon classes={{ root: classes.menuIcons }}>
                    <Badge
                      invisible={unseenotifications.podcasts > 0 ? false : true}
                      badgeContent={
                        <span className={classes.badge}>
                          {unseenotifications.podcasts}
                        </span>
                      }
                    >
                      <Mic />
                    </Badge>
                  </ListItemIcon>
                </div>
                <ListItemText
                  classes={{ primary: classes.listtext, inset: classes.inset }}
                  inset
                  primary='Podcasts & Audios'
                />
              </ListItem>
              <ListItem
                className={classes.listitem}
                component={Link}
                to='/articles'
                button
                onClick={()=> {
                  this.toggleDrawer('articles')
                }}
              >
                <div className={classes.iconBorder}>
                  <ListItemIcon classes={{ root: classes.menuIcons }}>
                    <Badge
                      invisible={unseenotifications.articles > 0 ? false : true}
                      badgeContent={
                        <span className={classes.badge}>
                          {unseenotifications.articles}
                        </span>
                      }
                    >
                      <RemoveRedEye />
                    </Badge>
                  </ListItemIcon>
                </div>
                <ListItemText
                  classes={{ primary: classes.listtext, inset: classes.inset }}
                  inset
                  primary='Articles'
                />
              </ListItem>

            </div>
          </AccordionDetails>
        </Accordion> */}

        {/* <React.Fragment>
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: '#ccbc74' }} />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography className={classes.subheader}>RATE COLLECTIVE LEADERSHIP</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.column}>

                <ListItem
                  className={classes.listitem}
                  component={Link}
                  to='/genEval'
                  button
                  onClick={()=> {
                    this.toggleDrawer('genEval')
                  }}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>
                      <IgenEval />
                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='Collective Assessment'
                  />
                </ListItem>

                <ListItem
                  className={classes.listitem}
                  component={Link}
                  to='/genStats'
                  button
                  onClick={()=> {
                    this.toggleDrawer('genStats')
                  }}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>
                      <IgenStats />
                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='Collective Assessment Results'
                  />
                </ListItem>
              </div>
            </AccordionDetails>
          </Accordion>
        </React.Fragment> */}

        {/* { this.props.userdata && ACCESS_COMPANY_PROFILE_USER[this.props.userdata.userType] ? ( */}
             {this.props.userdata &&
              IS_COMPANY_ADMIN[this.props.userdata.userType] ? (
          <React.Fragment>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#ccbc74' }} />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography className={classes.subheader}>CLUB PROFILE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.column}>
               {/* ///ADITIONAL STATS AND SURVEYS/////////////////////////  */}
                  {/* <ListItem
                    className={classes.listitem}
                    component={Link}
                    to='/orgEval'
                    button
                    onClick={()=> {
                      this.toggleDrawer('orgEval')
                    }}
                  >
                    <div className={classes.iconBorder}>
                      <ListItemIcon classes={{ root: classes.menuIcons }}>
                        <IorgEval />
                      </ListItemIcon>
                    </div>
                    <ListItemText
                      classes={{ primary: classes.listtext, inset: classes.inset }}
                      inset
                      primary='Rate Leadership Development'
                    />
                  </ListItem>

                  <ListItem
                    className={classes.listitem}
                    component={Link}
                    to='/orgStats'
                    button
                    onClick={()=> {
                      this.toggleDrawer('orgStats')
                    }}
                  >
                    <div className={classes.iconBorder}>
                      <ListItemIcon classes={{ root: classes.menuIcons }}>
                        <IorgStats />
                      </ListItemIcon>
                    </div>
                    <ListItemText
                      classes={{ primary: classes.listtext, inset: classes.inset }}
                      inset
                      primary='Leadership Dev Assessment Results'
                    />
                  </ListItem>

                  <ListItem
                  className={classes.listitem}
                  component={Link}
                  to='/genEval'
                  button
                  onClick={()=> {
                    this.toggleDrawer('genEval')
                  }}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>
                      <IgenEval />
                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='Collective Assessment'
                  />
                </ListItem>

                <ListItem
                  className={classes.listitem}
                  component={Link}
                  to='/genStats'
                  button
                  onClick={()=> {
                    this.toggleDrawer('genStats')
                  }}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>
                      <IgenStats />
                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='Collective Assessment Results'
                  />
                </ListItem> */}
               {/* //////////////////////////// */}
                  {this.props.userdata &&
                   IS_COMPANY_ADMIN[this.props.userdata.userType] ? (
                      <ListItem
                        className={classes.listitem}
                        component={Link}
                        to='/analytics'
                        button
                        onClick={()=> {
                          this.toggleDrawer('analytics')
                        }}
                      >
                        <div className={classes.iconBorder}>
                          <ListItemIcon classes={{ root: classes.menuIcons }}>
                            <IanalyticsChart />
                          </ListItemIcon>
                        </div>
                        <ListItemText
                          classes={{ primary: classes.listtext, inset: classes.inset }}
                          inset
                          primary='Analytics'
                        />
                      </ListItem>
                    ) : null}

                  {/* {this.props.userdata &&
                   IS_COMPANY_ADMIN[this.props.userdata.userType] ? (
                      <ListItem
                        className={classes.listitem}
                        component={Link}
                        to='/surveys'
                        button
                        onClick={()=> {
                          this.toggleDrawer('surveys')
                        }}
                      >
                        <div className={classes.iconBorder}>
                          <ListItemIcon classes={{ root: classes.menuIcons }}>
                            <InsertChart />
                          </ListItemIcon>
                        </div>
                        <ListItemText
                          classes={{ primary: classes.listtext, inset: classes.inset }}
                          inset
                          primary='Surveys'
                        />
                      </ListItem>
                    ) : null} */}

                  {this.props.userdata && this.props.userdata.superUser ? (
                    <ListItem
                      className={classes.listitem}
                      component={Link}
                      to='/companies'
                      button
                      onClick={()=> {
                        this.toggleDrawer('companies')
                      }}
                    >
                      <div className={classes.iconBorder}>
                        <ListItemIcon classes={{ root: classes.menuIcons }}>
                          <InsertChart />
                        </ListItemIcon>
                      </div>
                      <ListItemText
                        classes={{ primary: classes.listtext, inset: classes.inset }}
                        inset
                        primary='Companies'
                      />
                    </ListItem>
                  ) : null}

                  {this.props.userdata &&
                   IS_COMPANY_ADMIN[this.props.userdata.userType] ? (
                      <ListItem
                        className={classes.listitem}
                        component={Link}
                        to='/companyadmin'
                        button
                        onClick={()=> {
                          this.toggleDrawer('companyadmin')
                        }}
                      >
                        <div className={classes.iconBorder}>
                          <ListItemIcon classes={{ root: classes.menuIcons }}>
                            <Person />
                          </ListItemIcon>
                        </div>
                        <ListItemText
                          classes={{ primary: classes.listtext, inset: classes.inset }}
                          inset
                          primary='Club Admin'
                        />
                      </ListItem>
                    ) : null}
                </div>
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        ) : this.props.userdata && APP_TYPE === 'CORPORATE' && ACCESS_COMPANY_PROFILE_USER[this.props.userdata.userType] ? (
          <React.Fragment>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#ccbc74' }} />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography className={classes.subheader}>CLUB PROFILE
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.column}>
                  <ListItem
                    className={classes.listitem}
                    component={Link}
                    to='/analytics'
                    button
                    onClick={()=> {
                      this.toggleDrawer('analytics')
                    }}
                  >
                    <div className={classes.iconBorder}>
                      <ListItemIcon classes={{ root: classes.menuIcons }}>
                        <InsertChart />
                      </ListItemIcon>
                    </div>
                    <ListItemText
                      classes={{ primary: classes.listtext, inset: classes.inset }}
                      inset
                      primary='Analytics'
                    />
                  </ListItem>

                  <ListItem
                    className={classes.listitem}
                    component={Link}
                    to='/surveys'
                    button
                    onClick={()=> {
                      this.toggleDrawer('surveys')
                    }}
                  >
                    <div className={classes.iconBorder}>
                      <ListItemIcon classes={{ root: classes.menuIcons }}>
                        <InsertChart />
                      </ListItemIcon>
                    </div>
                    <ListItemText
                      classes={{ primary: classes.listtext, inset: classes.inset }}
                      inset
                      primary='Surveys'
                    />
                  </ListItem>

                  {this.props.userdata && this.props.userdata.superUser ? (
                    <ListItem
                      className={classes.listitem}
                      component={Link}
                      to='/companies'
                      button
                      onClick={()=> {
                        this.toggleDrawer('companies')
                      }}
                    >
                      <div className={classes.iconBorder}>
                        <ListItemIcon classes={{ root: classes.menuIcons }}>
                          <InsertChart />
                        </ListItemIcon>
                      </div>
                      <ListItemText
                        classes={{ primary: classes.listtext, inset: classes.inset }}
                        inset
                        primary='Companies'
                      />
                    </ListItem>
                  ) : null}
                  {this.props.userdata &&
                   IS_COMPANY_ADMIN[this.props.userdata.userType] ? (
                      <ListItem
                        className={classes.listitem}
                        component={Link}
                        to='/companyadmin'
                        button
                        onClick={()=> {
                          this.toggleDrawer('companyadmin')
                        }}
                      >
                        <div className={classes.iconBorder}>
                          <ListItemIcon classes={{ root: classes.menuIcons }}>
                            <Person />
                          </ListItemIcon>
                        </div>
                        <ListItemText
                          classes={{
                            primary: classes.listtext,
                            inset: classes.inset
                          }}
                          inset
                          primary='Club Admin'
                        />
                      </ListItem>
                    ) : null}
                </div>
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        )
          : null
        }


        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: '#ccbc74' }} />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography className={classes.subheader}>More...</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.column}>
              {/* <ListItem
                  className={classes.listitem}
                  component={Link}
                  to='/LeaderOnList'
                  button
                  onClick={()=> {
                    this.toggleDrawer('LeaderOnList')
                  }}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>
                      <LeaderboardIcon />
                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='Leaderboard'
                  />
                </ListItem> */}
              {/* <ListItem
                    className={classes.listitem}
                    component={Link}
                    to="/Bookshop"
                    button
                    onClick={e => { this.toggleDrawer("bookshop") }}
                  >
                    <div className={classes.iconBorder}>
                      <ListItemIcon classes={{ root: classes.menuIcons }}>
                        <LibraryBooks />
                      </ListItemIcon>
                    </div>
                    <ListItemText
                      classes={{ primary: classes.listtext, inset: classes.inset }}
                      inset
                      primary="Bookshop"
                    />
                  </ListItem>   */}
              { userdata ?
                <Link
                  style={{ textDecoration: 'none', fontSize: '14px' }}
                  to={{
                    pathname: '/Poe',
                    state: {
                      Everyone: userdata.userID
                    }
                  }}
                  onClick={() => {
                    this.toggleDrawer('poe')
                  }}
                >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<PoeIcon />
                  <font style={{
                    margin: '12px',
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    color: '#676767',
                    fontSize: '14px'
                  }}>&nbsp;&nbsp;Portfolio of Evidence</font><br/> <br/>
                </Link>
                : null}

              {/* <ListItem
                  className={classes.listitem}
                  component={Link}
                  to='/poe'
                  button
                  onClick={()=> {
                    this.toggleDrawer('poe')
                  }}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>
                      <PoeIcon />
                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='Portfolio of Evidence'
                  />
                </ListItem> */}

              {userdata && ['-LgsUykfo8z1U9MXgd9', '-Lu4BlhZ-tsDSnd-fnFd'].includes(this.props.userdata.companyID) ? (null) : (
                <ListItem
                  className={classes.listitem}
                  component={Link}
                  to='/tutorials'
                  button
                  onClick={()=> {
                    this.toggleDrawer('tutorials')
                  }}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>
                      <HelpOutline />
                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='Tutorials'
                  />
                </ListItem>
              )}
              {userdata && ['-LgsUykfo8z1U9MXgd9'].includes(this.props.userdata.companyID) ? (null) : (
                <ListItem
                  className={classes.listitem}
                  component={Link}
                  to='/tcs'
                  button
                  onClick={()=> {
                    this.toggleDrawer('tcs')
                  }}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>
                      <PlaylistAddCheck />
                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='Terms & Conditions'
                  />
                </ListItem>
              )}
              {isBrowser ?
                <ListItem
                  className={classes.listitem}
                  // component={Link}
                  // to="/tcs"
                  button
                  onClick={() => this.setDownload()}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>
                      <Downloadapp />
                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='Download Desktop App'
                  />
                </ListItem>
                : null}
                <ListItem
                  className={classes.listitem}
                  component={Link}
                  to='/wallet'
                  button
                  onClick={()=> {
                    this.toggleDrawer('wallet')
                  }}
                >
                  <div className={classes.iconBorder}>
                    <ListItemIcon classes={{ root: classes.menuIcons }}>
                      <PlaylistAddCheck />
                    </ListItemIcon>
                  </div>
                  <ListItemText
                    classes={{ primary: classes.listtext, inset: classes.inset }}
                    inset
                    primary='Send MeVision Coins'
                  />
                </ListItem>
            </div>
          </AccordionDetails>
        </Accordion>

        <List
          component='nav'
          subheader={
            <ListSubheader className={classes.subheader} component='div' />
          }
          className={classes.root}
        >
          <ListItem
            className={classes.listitem}
            component={Link}
            to='/'
            //state={{ repp: false }}
            // divider={true}
            // key={'aaa'}
            //to={page==='ilead' ? '/' : '/ilead'}

            render={(props) => <Gotopatreon rep={false} {...props} />}
            //state={{ contact: 'aaa' }}
            button
            onClick={this.props.signOut}
          >
            <div className={classes.iconBorder}>
              <ListItemIcon classes={{ root: classes.menuIcons }}>
                <ExitToApp style={{ color: '#ccbc74' }} />
              </ListItemIcon>
            </div>
            <ListItemText
              classes={{ primary: classes.listExec, inset: classes.inset }}
              inset
              primary='Sign Out'
            />
          </ListItem>
        </List>
      </div>
    )

    return (
      <React.Fragment>
        <div className={classes.root}>
          {this.state.showDownloadNote ? <DownloadNote key={Math.random()} /> : null}

          <CssBaseline />
          {drawer}
        </div>
      </React.Fragment>
    )
  }
}
SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

// const hoverActive = {
//   "&.active": {
//     "& path": {
//       color: "red"
//     }
//   },
//   "&:hover, &.active:hover": {
//     "& path": {
//       color: "red"
//     }
//   }
// };


const SideBarModule = withStyles(styles, { withTheme: true })(SideBar)

const mapStateToProps = state => {
  return {
    unseenotifications: state.userdata.unseenotifications,
    unseengroupnotifications: state.userdata.unseengroupnotifications,
    unseenpldpnotifications: state.userdata.unseenpldpnotifications,
    speedDPath: state.speedDialP.speedDPath,
    userdata: state.userDialog.userDetails[0] || null,
    leaderboardData: state.users.leaderboardData,
    loadingLBLeaders: state.loadingLeaders.leaderboardloading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
    captureUserActivity: params => dispatch(captureUserActivity(params)),
    showSpeedDial: params => dispatch(showSpeedDial(params)),
    getAllCompanyLeaders: params => dispatch(getAllCompanyLeaders(params)),
    loadingAction: params => dispatch(loadingAction(params)),
    CgetOpinions: params => dispatch(CgetOpinions(params)),
    GgetOpinions: params => dispatch(GgetOpinions(params)),
    getOpinions: params => dispatch(getOpinions(params)),
    showToast: (params) => dispatch(showToast(params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBarModule)
