/* eslint-disable no-undefined */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-inline-comments */
/* eslint-disable consistent-this */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import axios from 'axios'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link
} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Progressbar from './components/ProgressBar'

// // Pages
import SignIn from './pages/Auth/SignIn'
import LandingPage from './pages/Auth/LandingPage'
import SignUp from './pages/Auth/SignUp'
import ForgotPassword from './pages/Auth/ForgotPassword'
import TermsConditions from './pages/TermsConditions'
import CompanyAdmin from './pages/CompanyAdmin'

import orgEval from './components/UserProfile/orgEval.js'
import orgStats from './pages/PLDP/Competencies/orgStats.js'

import genEval from './components/UserProfile/genEval.js'
import genStats from './pages/PLDP/Competencies/genStats.js'

import Organisational from './pages/Organisational'
import MyGroups from './pages/MyGroups'
import FilteredPosts from './pages/filteredPosts'
import Gotopatreon from './pages/Gotopatreon'

import PLDP from './pages/PLDP'
import pldp_ledain from './pages/PLDP/Competencies/index_ledain'

import Favourites from './pages/Favourites'
import Wallet from './pages/Sendmecoins'
import Navbar from './components/layout/Navbar'
import Ilead from './pages/Ilead'
//import About from './pages/About'
import LeaderOnList from './components/Dialogs/LeaderList'
import GcThoughts from './pages/gcThoughts'
import DailyThoughts from './pages/dailyThoughts'
import MyPosts from './pages/MyPosts'
import Surveys from './pages/Surveys'
import PointsSurveys from './pages/PointsSurveys'
import Videos from './pages/Videos'
import Podcasts from './pages/Podcasts'
import Poe from './pages/Poe'
import Articles from './pages/Articles'
import Ebooks from './pages/Ebooks'
import Bookshop from './pages/Bookshop'
import Analytics from './pages/Analytics'
import TutorialsPage from './pages/Tutorials/index'
import Competencies from './pages/Competencies'
import Tokens from './pages/Tokens.js'
import Companies from './pages/Companies'
import UserProfile from './components/UserProfile'
import Groups from './components/groups'
import StudyMaterials from './components/groups/StudyMaterials'
import Programmes from './components/groups/Programmes'
import SurveyConfigurations from './components/groups/SurveyConfigurations'
import Facilitators from './components/groups/Facilitators'
import GroupCourses from './components/groups/GroupCourses'
import GroupDetails from './components/groups/groupDetails'
import FileRepo from './pages/FileRepo'
import ReadArticles from './components/Views/ReadArticles'
import MainSpeedDial from './components/MainSpeedDial'
import AddModal from './components/Dialogs/AddModal'
import ToastMessage from './components/ToastMessage'
import LoaderBar from './components/LoaderBar'
import ViewUserProfile from './components/UserProfile/ViewUserProfile'
import FileViewer from './components/FileViewer'
import { hideToast, showToast } from './store/actions/toastAction'
import { hideLoader, showLoader } from './store/actions/loaderActions'
import { authStateChanged } from './store/actions/authActions'
import { loggedInProfileActions } from './store/actions/loggedInProfileActions'
import { getScores, handleOAuthRedirectRequest } from './store/actions/companyAdminActions'
// import {
//   getOpinions
// } from './store/actions/opinionActions'
// import {
//   CgetOpinions
//  } from './store/actions/opinionCActions'
//  import {
//   GgetOpinions
//  } from './store/actions/opinionG_Actions'

import {
  APP_TYPE,
  USER_LOGGED_IN,
  GLP_LOGGED_COMPANY,
  GLP_LOGGED_PROFILE,
  SHOW_TOAST,
  SHOW_LOADER,
  HIDE_LOADER,
  BLOCKED_USER_TYPES,
  PUBLIC_COMPANY_ID,
  ACCESS_COMPETENCIES,
  SUPPORTED_FILE_TYPES,
  HIDE_ADD_BTN,
  SHOW_SPEEDDIAL
} from './store/actions/types'

import firebase from './store/firebase'
import Search from './pages/Search'
import Gotocalf from './pages/Gotocalf'
//import Gotopatreon from './pages/Gotopatreon'

import packageJson from '../package.json'
// import { getBuildDate } from "./utils/utils";
import withClearCache from './ClearCache'
import moment from 'moment'
const ClearCacheComponent = withClearCache(MainApp)
//import { ScrollRestoration } from "react-router-dom";

const drawerWidth = 280
const topHeight = 64

const styles = (theme) => ({
  root: {
    // width: "100%"
    width: 'calc(100% - 1px)'
  },
  grow: {
    flexGrow: 1
  },
  content: {
    marginTop: topHeight,
    padding: '10px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth
    },
    [theme.breakpoints.down('md')]: {
      padding: '0',
      marginTop: '90px!important'
    }
  }
})

function MainApp(props) {
  return (
    <div className='App'>
      <header className='App-header'>
        {console.log('Build date:', getBuildDate(packageJson.buildDate))}
      </header>
    </div>
  )
}
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format('DD-MM-YYY HH:MM')
  return buildDate
}

class App extends Component {
  state = {
    userloggedin: false,
    opendialog: false,
    openfileviewer: false,
    dialogname: '',
    loading: true,
    filename: '',
    filetype: 'pdf',
    filepath: '',
    Gotopatreon: false
  };

  openModal = (modalName) => {
    console.log('openModal : ', modalName)
    this.setState((state) => ({
      opendialog: true,
      dialogname: modalName
    }))
    console.log('openModalxxx')
  };

  handleModalClose = () => {
    this.setState((state) => ({
      opendialog: false,
      dialogname: ''
    }))
  };

  handleFileViewerClose = () => {
    this.setState((state) => ({
      openfileviewer: false,
      filename: '',
      filetype: '',
      filepath: ''
    }))
  };

  openFileViewer = (file) => {
    try {
      let filename = file.filename
      let filepath = file.filepath
      let lastDot = filename.lastIndexOf('.')
      const ext = filename.substring(lastDot + 1)
      filename = filename.substring(0, filename.lastIndexOf('.'))

      if (SUPPORTED_FILE_TYPES[ext]) {
        this.setState((state) => ({
          openfileviewer: true,
          filetype: ext,
          filepath: filepath,
          filename: filename
        }))
      } else {
        let params = {
          type: SHOW_TOAST,
          open: true,
          variant: 'warning',
          message: 'Unsupported file type!'
        }

        this.props.showToast(params)
      }
    } catch (error) {
      console.log(error)
    }
  };

  componentDidUpdate = () => {
    let self = this
    // if (this.props.userdata) {
    //   console.log('patreon-Gotopatreon : ', this.props.userdata);
    // }
   
    // if (this.props.userdata) {
    //   console.log('app-userdata 4: ', this.props.userdata)
    //   var newPay;
    //   var timeNow;
    //   var lastPay;
      
    //   //IF PATRON then DISTRIBUT PLEDGE IN USER LC and LC POOL
    //     if (this.props.userdata.payoutdate && this.props.userdata.patron) { //have payed out before
    //       timeNow = (new Date().getTime())/(86400000);
    //       lastPay = this.props.userdata.payoutdate;

    //       newPay = timeNow - lastPay
    //       console.log('patreon-newPay : ', newPay);
    //       console.log('patreon-Gotopatreon : ', this.state.Gotopatreon);

    //       if (newPay > 0) { //IF user is patron AND user LC hasnt been updated for more than 30 days
    //         this.setState({Gotopatreon: true})
    //       }
    //       else
    //       {
    //         //do nothing
    //       }
    //     } 
    // }

    if (this.props.opentoast === true) {
      setTimeout(() => {
        self.props.hideToast()
      }, 6000)
    }
  };

  // checkSpeedDial = (path) => {
  //   console.log('inside checkSpeedDial: ', path);
  //   if(HIDE_ADD_BTN[path]) {
  //     //this.setState({showSpeedDial: false});
  //     console.log('inside setState');
  //   }
  // }

  componentDidMount = () => {
    const { userdata } = this.props;
 
    // if (userdata) {
     // console.log('green---userdata : ', parseInt((new Date().getTime())/(86400000)))
    // }
    let self = this

    // if (this.props.userdata) {
    //   this.props.CgetOpinions({ //loads the opinions of leaders recorded in state
    //    leaderID: this.props.userdata.companyID
    //   })
    //   this.props.GgetOpinions({ //loads the opinions of leaders recorded in state
    //     leaderID: this.props.userdata.companyID
    //    })
    // }

    // if (this.props.userdata) {
    //   this.props.getOpinions({ // loads the opinions of leaders recorded in state
    //     leaderID: this.props.userdata.userID
    //   })
    // }
   // console.log('BLOCKED_USER_TYPES[userdata.userType] : ', BLOCKED_USER_TYPES[this.props.userdata.userType])
       
    firebase.auth.onAuthStateChanged(function (user) {
      if (user) {
      
        let displayName = ''

        if (!user.displayName) {
          displayName = user.email.substring(0, user.email.indexOf('@'))
        } else {
          displayName = user.displayName
        }
        // console.log('green calf scores : ', user);

        let userobj = {
          uid: user.uid,
          displayName: displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          refreshToken: user.refreshToken,
          metadata: user.metadata
        }

        // update calf-score
        console.log('green score 2 : ', userobj)
        self.props.getScores({
          uid: user.uid,
          email: user.email
        })
        //patreon client//////////////////////////////////////////////////////
        //this.props.handleOAuthRedirectRequest
        /////////////////////////////////////////////////////////////////////
        if (!user.emailVerified && APP_TYPE === 'PUBLIC') {
          self.setState({ ...self.state, userloggedin: true })
          localStorage.setItem('USER_LOGGED_IN', JSON.stringify(userobj))
          self.props.authStateChanged(userobj)

          self.props.loggedInProfileActions(userobj.uid)
          return user
        }
        self.setState({ ...self.state, userloggedin: true })
        localStorage.setItem('USER_LOGGED_IN', JSON.stringify(userobj))
        self.props.authStateChanged(userobj)

        self.props.loggedInProfileActions(userobj.uid)
        return user
      }
      self.setState({ ...self.state, userloggedin: false })
      localStorage.removeItem('USER_LOGGED_IN')
      localStorage.removeItem('GLP_LOGGED_COMPANY')
      localStorage.removeItem('GLP_LOGGED_PROFILE')
      return null
    })
    
  };

  // componentWillUnmount() {
  //   this.setState({ ...this.state, userloggedin: false })
  //   localStorage.removeItem('USER_LOGGED_IN')
  //   localStorage.removeItem('GLP_LOGGED_COMPANY')
  //   localStorage.removeItem('GLP_LOGGED_PROFILE')
  // }

  updateLoginStatus = () => {
    this.setState({ ...this.state, userloggedin: true })
  }

  render() {
    const {
      userloggedin,
      loading,
      openfileviewer,
      filetype,
      filepath,
      filename
    } = this.state
    const {
      classes,
      theme,
      variant,
      pos,
      showprogress,
      message,
      opentoast,
      userdata,
      showprofiledlg
    } = this.props

    let self = this
    // const checkSpeedDial = (path) => {
    //   console.log('inside checkSpeedDial: ', path);
    //   if(HIDE_ADD_BTN[path]) {
    //     //this.setState({showSpeedDial: false});
    //     console.log('inside setState');
    //   }

    // }
   
    // console.log('app-userdata 3: ', userdata)
    // console.log('app-userdata 4: ', this.props.userdata)

    // if (this.props.userdata) {
    //   console.log('app-userdata 4: ', this.props.userdata)
    //   var newPay;
    //   var timeNow;
    //   var lastPay;
      

   // if (((new Date().getTime())/(86400000) - (this.props.userdata.payoutdate) ) > 30) {}

    //   //IF PATRON then DISTRIBUT PLEDGE IN USER LC and LC POOL
    //     if (this.props.userdata.payoutdate && this.props.userdata.patron) { //have payed out before
    //       timeNow = (new Date().getTime())/(86400000);
    //       lastPay = this.props.userdata.payoutdate;

    //       newPay = timeNow - lastPay
    //       console.log('patreon-newPay : ', newPay);
    //       console.log('patreon-Gotopatreon : ', this.state.Gotopatreon);

    //       if (newPay > 30) { //IF user is patron AND user LC hasnt been updated for more than 30 days
    //         this.setState({Gotopatreon: true})
    //       }
    //       else
    //       {

    //       }
    //     } 
    // }
    
    // request interceptor
    axios.interceptors.request.use(
      function (config) {
        self.props.showLoader({ type: SHOW_LOADER })
        return config
      },
      function (error) {
        self.props.showLoader({ type: HIDE_LOADER })
        return Promise.reject(error)
      }
    )

    // response interceptor
    axios.interceptors.response.use(
      function (response) {
        self.props.showLoader({ type: HIDE_LOADER })
        return response
      },
      function (error) {
        self.props.showLoader({ type: HIDE_LOADER })
        return Promise.reject(error)
      }
    )
    /////////////////////////////////////////////////////////////////////////////////////
    if (userdata) {
    var newPay;
    var dateA;
    var dateB;
    var newPayBool;
    var timeNow;
    var lastPay;
    timeNow = new Date();//.getTime();

    console.log('patreon-newPay A : ', this.props.userdata);
    console.log('patreon-newPay payoutdate : ', this.props.userdata.payoutdate);
    console.log('patreon-newPay patron : ', this.props.userdata.patron);

    if (this.props.userdata.payoutdate && this.props.userdata.patron) { //have payed out before
      lastPay = this.props.userdata.payoutdate;
     
      // newPay = Math.round(Math.abs((timeNow - this.props.userdata.payoutdate) / 86400000))  

      try {
        dateA = lastPay;
        } catch (err) {
        dateA = '123'
        console.log('no thought.props'. err)
        }

      dateB = timeNow.getMonth();
      console.log('patreon-newPay 1 : ', dateA + ' --- ' + dateB);
    //  if (newPay < 30) {
    //     newPayBool = false;
    //   }
    //   else{
    //     newPayBool = true;
    //   }

      if (dateA === dateB) { //still same month
        newPayBool = false;
      }
      else{
        newPayBool = true;
      }
      console.log('patreon-newPay 2 : ', newPayBool);
    }
    }
  /////////////////////////////////////////////////////////////////////////////////////

    return (
     <Router>
        <div>
          {/* <Switch> */}
          {userloggedin ? (
            <div className={classNames(classes.content)}>
              {/* {userdata ? (<Navbar themeColor={this.props.themeColor} openFileViewer={this.openFileViewer} userdata={userdata}/> ):null} */}
              <Navbar
                themeColor='#ffffff'
                openFileViewer={this.openFileViewer}
              />
              {/* <Navbar themeColor={this.props.themeColor} openFileViewer={this.openFileViewer}/>  */}
              {userdata ? (
                <React.Fragment>
                  {/* <Route exact path="/" component={Organisational} /> */}
                  {/* <Route path="/organisational" component={Organisational} /> */}
                  {APP_TYPE === 'PUBLIC' ? (
                    <div>  
                    { newPayBool===true ? //activate confirmation of pledge AUTOMATICALLY
                      <Route exact path='/' render={(props) => <Gotopatreon rep={this.props.rep ? this.props.rep : true} {...props} />}  />
                      :
                      <Route exact path="/" component={Ilead} />}

                      {/* <Route exact path='/' component={this.state.Gotopatreon ? Gotopatreon : Ilead} /> */}
                      {/* const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay)); */}
                      {/* const diffDays = Math.round(Math.abs((new Date().getTime() - this.props.userdata.payoutdate) / 86400000)); */}
                      {/* <Route exact path='/' component={ this.props.userdata.payoutdate && (((new Date().getTime())/(86400000) - this.props.userdata.payoutdate ) > 30) ? Gotopatreon : Ilead} /> */}
                     
                      {/* <Route exact path='/' component={ this.props.userdata.payoutdate && (  (Math.round(Math.abs((new Date().getTime() - this.props.userdata.payoutdate) / 86400000))) > 30  ) ? Gotopatreon : Ilead} /> */}

                      {userdata.companyID ? (
                        <Route
                          exact
                          path='/organisational'
                          render={(props) => (
                            <React.Fragment>
                              <Organisational
                                openFileViewer={this.openFileViewer}
                                {...props}
                              />
                            </React.Fragment>
                          )}
                        />
                      ) : null}
                    </div>
                  ) : (
                    <div>
                      <Route
                        exact
                        path='/'
                        render={(props) => (
                          <React.Fragment>                         
                            <Organisational
                              openFileViewer={this.openFileViewer}
                              {...props}
                            />
                          </React.Fragment>
                        )}
                      />
                      <Route
                        exact
                        path='/organisational'
                        render={(props) => (
                          <React.Fragment>
                            <Organisational
                              openFileViewer={this.openFileViewer}
                              {...props}
                            />
                          </React.Fragment>
                        )}
                      />
                    </div>
                  )}
                  <Route
                    exact
                    path='/groupmessages'
                    render={(props) => (
                      <React.Fragment>
                        <MyGroups
                          openFileViewer={this.openFileViewer}
                          {...props}
                        />
                      </React.Fragment>
                    )}
                  />
                  
                  <Route exact path='/wallet' component={Wallet} />
                  <Route exact path='/pldp' component={PLDP} />
                  {/* <Route exact path='/pldp' component={pldp_ledain} /> */}
                  <Route exact path='/favourites' component={Favourites} />
                  <Route exact path='/ilead' component={Ilead} />
                  {/* <Route exact path='/about' component={About} /> */}
                  <Route exact path='/LeaderOnList' component={LeaderOnList} />
                  {/* <Route exact path='/' component={LeaderOnList} /> */}
                  <Route
                    exact
                    path='/globalcontributors'
                    render={(props) => (
                      <React.Fragment>
                        <GcThoughts
                          openFileViewer={this.openFileViewer}
                          {...props}
                        />
                      </React.Fragment>
                    )}
                  />
                  <Route
                    exact
                    path='/daily'
                    render={(props) => (
                      <React.Fragment>
                        <DailyThoughts
                          openFileViewer={this.openFileViewer}
                          {...props}
                        />
                      </React.Fragment>
                    )}
                  />
                  <Route
                    exact
                    path='/myposts'
                    render={(props) => (
                      <React.Fragment>
                        <MyPosts
                          openFileViewer={this.openFileViewer}
                          {...props}
                        />
                      </React.Fragment>
                    )}
                  />
                  <Route exact path='/videos' component={Videos} />
                  <Route exact path='/podcasts' component={Podcasts} />
                  <Route
                    exact
                    path='/articles'
                    render={(props) => (
                      <React.Fragment>
                        <Articles
                          openFileViewer={this.openFileViewer}
                          {...props}
                        />
                      </React.Fragment>
                    )}
                  />
                  <Route exact path='/readarticle' component={ReadArticles} />
                  <Route exact path='/ebooks' component={Ebooks} />
                  <Route exact path='/poe' component={Poe} />
                  <Route path='/bookshop' component={Bookshop} />
                  <Route exact path='/analytics' component={Analytics} />
                  {/* <Route path='/surveys' component={Surveys} /> */}
                  <Route
                    exact
                    path='/pointssurveys'
                    component={PointsSurveys}
                  />
                  <Route exact path='/companies' component={Companies} />
                  <Route exact path='/companyadmin' component={CompanyAdmin} />
                  <Route exact path='/orgEval' component={orgEval} />
                  <Route exact path='/orgStats' component={orgStats} />
                  <Route exact path='/genEval' component={genEval} />
                  <Route exact path='/genStats' component={genStats} />
                  {/* <Route path='/gotogli' component={Gotogli} />  */}
                  {/* <Route path='/gotoExjoin' component={GotoExjoin} /> */}
                  {/* <Route path='/gotocalf' render={props => (
                      <React.Fragment>
                        <Gotocalf
                         calfname={this.props.userdata ?
                             this.props.userdata.calfname
                             :'none'
                          }
                          calfemail={this.props.userdata ?
                            this.props.userdata.calfemail
                            :'none'
                         }
                         calfpassword={this.props.userdata ?
                          this.props.userdata.calfpassword
                          :'none'
                       }
                        {...props} />
                      </React.Fragment>
                    )} />
                    <Route path='/readarticle' component={ReadArticles} />
                    <Route path='/ebooks' component={Ebooks} />
                    <Route path='/analytics' component={Analytics} />
                    {/* <Route path='/surveys' component={Surveys} /> */}
                  <Route exact path='/companies' component={Companies} />
                  {/* <Route path='/gotogli' component={Gotogli} />  */}

                  {/* <Route path='/gotoExjoin' render={props => (
                    <React.Fragment>
                      <GotoExjoin
                        calfname={this.props.userdata ?
                          this.props.userdata.calfname
                          : 'none'
                        }
                        calfemail={this.props.userdata ?
                          this.props.userdata.email
                          : 'none'
                        }
                        calfpassword={this.props.userdata ?
                          this.props.userdata.calfpassword
                          : 'none'
                        }
                        {...props} />
                    </React.Fragment>
                  )} /> */}

                  <Route
                    exact
                    path='/gotocalf'
                    render={(props) => (
                      <React.Fragment>
                        <Gotocalf
                          // calfname={this.props.userdata ?
                          //   this.props.userdata.calfname
                          //   : 'none'
                          // }
                          calfemail={
                            this.props.userdata
                              ? this.props.userdata.email
                              : 'none'
                          }
                          // calfpassword={this.props.userdata ?
                          //   this.props.userdata.calfpassword
                          //   : 'none'
                          // }
                          {...props}
                        />
                      </React.Fragment>
                    )}
                  />

                  <Route
                    exact
                    path='/gotopatreon'
                    render={(props) => (
                      <React.Fragment>
                        <Gotopatreon
                          // calfname={this.props.userdata ?
                          //   this.props.userdata.calfname
                          //   : 'none'
                          // }
                          calfemail={
                            this.props.userdata
                              ? this.props.userdata.email
                              : 'none'
                          }
                          // calfpassword={this.props.userdata ?
                          //   this.props.userdata.calfpassword
                          //   : 'none'
                          // }
                          {...props}
                        />
                      </React.Fragment>
                    )}
                  />                 
                  {/* <Route path='/gotogli' component={() => {
                      window.location.href = 'https://www.google.com';
                       return null;
                     }}/>  */}
                  {/* <Route path='/seamlesscertificate' component={SeamlessCertificate} />  */}
                  <Route exact path='/search' component={Search} />
                  <Route exact path='/userprofile' component={UserProfile} />
                  <Route
                    exact
                    path='/groupdetails/:id'
                    component={GroupDetails}
                  />
                  <Route
                    exact
                    path='/studymaterials'
                    component={StudyMaterials}
                  />
                  <Route exact path='/programmes' component={Programmes} />
                  <Route
                    exact
                    path='/surveyconfigurations'
                    component={SurveyConfigurations}
                  />
                  <Route exact path='/facilitators' component={Facilitators} />
                  <Route exact path='/groupcourses' component={GroupCourses} />
                  <Route exact path='/groups' component={Groups} />
                  <Route exact path='/filerepository' component={FileRepo} />
                  <Route exact path='/tokens' component={Tokens} />
                  {(APP_TYPE === 'PUBLIC' || APP_TYPE === 'CORPORATE') &&
                  // company admin for public app superuser
                  ACCESS_COMPETENCIES[userdata.companyID] ? (
                      <Route
                        exact
                        path='/competencies'
                        component={Competencies}
                      />
                    ) : (
                      <Redirect to='/' />
                    )}
                  {[
                    '/filtered-articles',
                    '/filtered-thoughts',
                    '/filtered-podcasts',
                    '/filtered-videos',
                    '/filtered-group-posts'
                  ].map((path, index) => (
                    <Route
                      exact
                      path={path}
                      component={FilteredPosts}
                      key={index}
                    />
                  ))}

                  {['/surveys'].map((path, index) => (
                    <Route exact path={path} component={Surveys} key={index} />
                  ))}

                  {HIDE_ADD_BTN[this.props.speedDPath] && userdata &&
                  this.props.speedDPath !== undefined ? null : (
                      <MainSpeedDial
                        hidden={BLOCKED_USER_TYPES[userdata.userType]}
                        openModal={this.openModal}
                        userType={userdata.userType}
                        usrIDD={userdata.userID}
                      // speedPath={this.props.speedDialPath}
                      />   
                     // <div/>                                
                     )
                    }
                </React.Fragment>
              ) : null}

              <Route path='/tutorials' component={TutorialsPage} />
              <Route path='/tcs' component={TermsConditions} />
              <Route path='/terms-conditions' component={TermsConditions} />

              <AddModal
                handleModalClose={this.handleModalClose}
                dialogname={this.state.dialogname}
                opendialog={this.state.opendialog}
                {...this.props}
              />
            </div>
          ) : (
            <div>
              <Route exact path='/' render={(props) => <LandingPage {...props} updateLoginStatus={this.updateLoginStatus} />} />
              {APP_TYPE === 'PUBLIC' ? (
                <Route exact path='/signup' render={(props) => <LandingPage {...props} updateLoginStatus={this.updateLoginStatus} />} />
              ) : (
                <Redirect to='/' />
              )}
              <Route exact path='/reset-password' component={ForgotPassword} />
              {[
                '/filtered-articles',
                '/filtered-thoughts',
                '/filtered-podcasts',
                '/filtered-videos',
                '/filtered-group-posts'
              ].map((path, index) => (
                <Route path={path} render={(props) => <LandingPage {...props} updateLoginStatus={this.updateLoginStatus} />} key={index} />
              ))}
            </div>
          )}
          {/* </Switch> */}

          <ToastMessage
            opentoast={opentoast}
            variant={variant}
            message={message}
            pos={pos}
          />
          <ToastContainer />


          {/* <LoaderBar showprogress={showprogress} /> */}

          <ViewUserProfile showprofiledlg={showprofiledlg} />

          <FileViewer
            filetype={filetype}
            filename={filename}
            filepath={filepath}
            opendialog={openfileviewer}
            handleClose={this.handleFileViewerClose}
          />
        </div>
       </Router>
    )
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

const GLPApp = withStyles(styles, { withTheme: true })(App)

const mapStateToProps = (state) => {
  // console.log('app state: ', state)
  return {
    opentoast: state.toast.open,
    showprogress: state.loader.showloader,
    variant: state.toast.variant,
    pos: state.toast.pos,
    message: state.toast.message,
    auth: state.auth.auth,
    speedDPath: state.speedDialP.speedDPath,
    showprofiledlg: state.userbiography.showprofiledlg,
    userdata: state.userDialog.userDetails[0] || null,
    themeColor: state.loggedInCompany.companydata
      ? state.loggedInCompany.companydata.primaryColor
      : '#e15225'
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showToast: (params) => dispatch(showToast(params)),
    hideToast: () => dispatch(hideToast()),
    showLoader: (params) => dispatch(showLoader(params)),
    hideLoader: (params) => dispatch(hideLoader(params)),
    authStateChanged: (userobj) => dispatch(authStateChanged(userobj)),
    loggedInProfileActions: (params) =>
      dispatch(loggedInProfileActions(params)),
    getScores: (params) => dispatch(getScores(params)),
   // getOpinions: params => dispatch(getOpinions(params)),
    // CgetOpinions: params => dispatch(CgetOpinions(params)),
    // GgetOpinions: params => dispatch(GgetOpinions(params))
    // showSpeedDial: params => dispatch(showSpeedDial(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GLPApp)
