import React, { Component } from 'react'
import { Route, Link } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import orange from '@material-ui/core/colors/orange'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import SettingsIcon from '@material-ui/icons/Settings'
import PrintIcon from '@material-ui/icons/Print'
import Paper from '@material-ui/core/Paper'
import ReactToPrint from 'react-to-print'
// Custom Components
import AboutPLDP from './AboutPLDP'
import Favorites from './Favorites'
import CompanyValues from './CompanyValues'

import MyTasks from './MyTasks'
import Competencies from './Competencies'
import Competencies_ledain from './Competencies/index_ledain'
import { Button } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
  fetchMyPLDPTasks,
  fetchCompanyValues,
  removePLDPTask,
  updateNewPLDPTask
} from './../../store/actions/pldpActions'
import { SET_SEEN_PLDP_NOTIFICATIONS, ACCESS_COMPETENCIES, GET_O } from './../../store/actions/types'
import { fetchUserData } from './../../store/actions/userActions'
import { showToast } from './../../store/actions/toastAction'
import {
  getOpinions
} from './../../store/actions/opinionActions'

function TabContainer({ children, dir }) {
  return (
    <Typography component='div' dir={dir} style={{ padding: 8 }}>
      {children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
}

const TabItem = props => {
  return <Tab className={props.classes.tab} label={props.label} />
}

const styles = theme => ({
  'root': {
    backgroundColor: theme.palette.background.paper,
    width: '100%'
  },
  'topHeader': {
    marginTop: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: '130px'
    }
  },
  'tabsRoot': {},
  'tabRoot': {
    fontWeight: theme.typography.fontWeightBold
  },

  'tabsIndicator': {
    borderBottom: '4px solid ' + orange[500],
    backgroundColor: orange[500]
  },
  '&$tabSelected': {
    color: orange[500],
    fontWeight: theme.typography.fontWeightMedium
  },
  'tabSelected': {
    color: orange[500],
    fontWeight: theme.typography.fontWeightMedium
  },
  'button': {
    'margin': theme.spacing.unit * 2,
    'backgroundColor': '#0288d1',
    'fontSize': 14,
    '&:hover': {
      backgroundColor: '#0288d1'
    }
  },
  'settingsIcons': {
    margin: theme.spacing.unit
  },
  'paperHeader': {
    backgroundColor: grey[300]
  },
  'arrowIcon': {
    color: '#ffffff'
  }
})

class ViewTasks extends Component {
  state = {
    value: 0,
    addTask: false,
    tasks: [],
    postTab: 0
  };

  fetchData = (active) => {
    this.props.fetchMyPLDPTasks(this.props.userdata.userID, active)
  };

  componentDidMount() {
    if (this.props.userdata.userID) {
      console.log('statsstate 1 : ', this.props.userdata.userID) 
      this.props.getOpinions({ //loads the opinions of leaders recorded in state
        type: GET_O,
        leaderID: this.props.userdata.userID
      })
     } 

    this.props.fetchMyPLDPTasks(this.props.userdata.userID, true)
    this.props.fetchCompanyValues(this.props.userdata.companyID)

    console.log('greenfilteredPLDP : ', this.props )
    // console.log('location state:', this.props);

    // ----
    // LOGIC: Indicate to navigate to specific tab based on index passed in through location.state
    this.setState({ value: this.props.location.state ? this.props.location.state.detail : 0 })

    this.props.fetchUserData({
      type: SET_SEEN_PLDP_NOTIFICATIONS,
      userID: this.props.userdata.userID,
      path: this.props.match.url,
      unseenpldpnotifications: this.props.unseenpldpnotifications
        .unseenpldpnotifications
    })
    console.log('statsstate y : ', this.props.opinions) 
   }

  handleChange = (event, value) => {
    this.setState({ value })
  };

  handleChangeIndex = index => {
    this.setState({ value: index })
  };

  handleDeleteTask = pldpTask => {
    const { userdata } = this.props

    this.props.removePLDPTask(pldpTask, userdata.userID)
  };
  render() {
    const {
      pldpTasks,
      classes,
      theme,
      match,
      companyValues,
      userdata,
      opinions
    } = this.props
    const { value } = this.state

    //console.log('PLDPVIew opinons : ', Object.keys(this.props.opinions).length)
    console.log('PLDPVIew userdata.companyID : ', userdata.companyID)

    return (
      <React.Fragment>
        {ACCESS_COMPETENCIES[userdata.companyID] ?
          <div>
            <ReactToPrint
              trigger={() => {
                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return (<Button startIcon={<PrintIcon />} variant='outlined' size='small' color='secondary' href='#'>
                      Print Tasks</Button>)
              }}
              content={() => this.componentRef}
            />

     {Object.keys(this.props.opinions).length > 0 ? 
     (userdata.companyID==='-MloJFGCyJJbs_bNlwTg' ? //Ledaine
    //  <Competencies_ledain ref={el => (this.componentRef = el)} fetchData={this.fetchData} opinions={this.props.opinions}/>
    <Competencies ref={el => (this.componentRef = el)} fetchData={this.fetchData} opinions={this.props.opinions}/>  
    :
     <Competencies ref={el => (this.componentRef = el)} fetchData={this.fetchData} opinions={this.props.opinions}/>  ):null 
     }
    
          </div>
          :
          Object.keys(this.props.opinions).length > 0 ? 
          <MyTasks
            companyValues={companyValues}
            pldpTasks={pldpTasks}
            onDeleteTask={this.handleDeleteTask}
            router={match}
            userdata={userdata}
            opinions={this.props.opinions}
            {...this.props}
          />
          :null
          }
      </React.Fragment>
    )
  }
}

const ShowPLDPTasks = withStyles(styles, { withTheme: true })(ViewTasks)

const mapStateToProps = (state, ownProps) => {
  return {
    pldpTasks: state.pldp.pldpTasks,
    companyValues: state.pldp.companyValues,
    userdata: state.userDialog.userDetails[0],
    unseenpldpnotifications: state.userdata.unseenpldpnotifications,
    opinions: state.opinions.opinion
  }
}
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    fetchMyPLDPTasks,
    fetchCompanyValues,
    removePLDPTask,
    showToast,
    updateNewPLDPTask,
    fetchUserData,
    getOpinions
  },
  dispatch
  )
})

const ShowTasks = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowPLDPTasks)

class PLDPView extends Component {
  // constructor(props){
  //   super(props)
  // }

  // componentDidMount () {
  //   if (this.props.userdata.userID) {
  //     console.log('statsstate 1 : ', this.props.userdata.userID) 
  //       this.props.getOpinions({ //loads the opinions of leaders recorded in state
  //       type: GET_O,
  //       leaderID: this.props.userdata.userID
  //     })
  //   } 
  // }

  render() {
    const { classes, theme, match, location } = this.props
    return (
      <div className={classNames(classes.root, classes.topHeader)}>
        <Paper style={{ minHeight: '100vh' }}>
          {this.props.userdata && this.props.userdata.superUser ? (
            <div className={classNames(classes.paperHeader)}>
              {location.pathname === '/pldp/companyvalues' ? (
                <Button
                  size='small'
                  className={classes.button}
                  component={Link}
                  to={`${match.url}`}
                  variant='contained'
                  color='primary'
                >
                  <ArrowBackIcon className={classes.settingsIcons} />
                  Back To PLDP
                </Button>
              ) : (
                <Button
                  size='small'
                  className={classes.button}
                  component={Link}
                  to={`${match.url}/companyvalues`}
                  variant='contained'
                  color='primary'
                >
                  <SettingsIcon className={classes.settingsIcons} />
                  Manage LEAD Categories
                </Button>
              )}
            </div>
          ) : null}
          <Route path={`${match.url}/`} exact component={ShowTasks} />
          <Route
            path={`${match.url}/companyvalues`}
            exact
            component={CompanyValues}
          />
        </Paper>
      </div>
    )
  }
}

const PLDPViewStyled = withStyles(styles, { withTheme: true })(PLDPView)
export default connect(
  mapStateToProps,
  null
)(PLDPViewStyled)
